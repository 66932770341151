import React from 'react';
import styles from '../../styles/modules/aboutFounder.module.css';

const AboutFounder = () => {
  return (
    <section className={styles.founderSection}>
      <div className={styles.founderContent}>
        <img 
          src={require('../../images/DF.png')} 
          alt="Deanna Fay" 
          className={styles.founderImage}
        />
        <h3 className={styles.founderName}>Deanna Fay</h3>
        <p className={styles.founderTitle}>SOMETHING TOLD | FOUNDER & CEO</p>
        <p className={styles.founderDescription}>
        My love language is quality time and the virtue I value most is truth. It wasn’t until the sudden loss of my uncle in 2021 that I realized how much time and truth are embedded in our most cherished treasures—and how powerful it would be to create a space where people can preserve all three: Time, Truth, and Treasure… one story at a time.
        </p>
      </div>
    </section>
  );
};

export default AboutFounder; 