// File: ProgressiveImage.js

import React, { useState, useCallback, memo, useRef, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { Skeleton } from '@mui/material';

// Styled container for the image
const ImageWrapper = styled('div')({
  position: 'relative',
  width: '100%',
  height: '100%',
  overflow: 'hidden',
  backgroundColor: '#f0f0f0', // Placeholder color
});

// Styled <img> element that transitions opacity when loaded.
// It uses the "loaded" prop to determine whether it should be fully opaque.
const StyledImg = styled('img')(({ loaded }) => ({
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  transition: 'opacity 0.3s ease-out, transform 0.3s ease-in-out',
  opacity: loaded ? 1 : 0,
  transform: loaded ? 'scale(1)' : 'scale(1.05)',
  willChange: 'transform, opacity', // Optimize for animations
}));

const LoadingSkeleton = styled(Skeleton)({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  transform: 'none', // Prevent default MUI skeleton animation
});

/**
 * ProgressiveImage
 *
 * This component renders an image that fades in when fully loaded.
 * It does not use the JavaScript Image constructor, thus avoiding the 
 * "Image is not a constructor" error.
 *
 * Props:
 * - src: Image URL.
 * - alt: Alternate text.
 * - LoadingComponent: Custom component to show while loading.
 * - ...props: Any additional props to pass to the underlying <img> tag.
 */
const ProgressiveImage = memo(({ src, alt, LoadingComponent = LoadingSkeleton, ...props }) => {
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(false);
  const imageRef = useRef(null);

  useEffect(() => {
    // Reset states when src changes
    setLoaded(false);
    setError(false);

    // Check if image is already cached
    if (imageRef.current?.complete) {
      setLoaded(true);
    }
  }, [src]);

  const handleLoad = useCallback(() => {
    setLoaded(true);
  }, []);

  const handleError = useCallback(() => {
    setError(true);
    setLoaded(true);
  }, []);

  // If error, show fallback image
  if (error) {
    return (
      <ImageWrapper>
        <StyledImg
         
          alt="Unable to load image"
          loaded={1}
          {...props}
        />
      </ImageWrapper>
    );
  }

  return (
    <ImageWrapper>
      {!loaded && <LoadingComponent variant="rectangular" animation="wave" />}
      <StyledImg
        ref={imageRef}
        src={src}
        alt={alt}
        loaded={loaded ? 1 : 0}
        onLoad={handleLoad}
        onError={handleError}
        loading="lazy"
        decoding="async"
        {...props}
        style={{
          ...props.style,
          transition: 'opacity 0.3s ease-in-out, transform 0.3s ease-in-out',
          opacity: loaded ? 1 : 0,
        }}
      />
    </ImageWrapper>
  );
});

ProgressiveImage.displayName = 'ProgressiveImage';

export default ProgressiveImage;
