import React from 'react';
import styles from '../../styles/modules/contentBlock.module.css';

const ContentBlock = () => {
  return (
    <section className={styles.contentSection}>
      <div className={styles.container}>
        {/* Text Section */}
        <div className={styles.textWrapper}>
          <div className={styles.textContent}>
            <h2 className={styles.title}>Preserve the Story</h2>
            <p className={styles.description}>
              Turn cherished objects into lasting legacies, weaving together the threads of stories that connect past, present, and future generations. Whether it's a family heirloom, a cherished gift, or a memento from a special moment or place—imprint its story in your own digital treasure chest.
            </p>
          </div>
        </div>

        {/* Image Section */}
        <div className={styles.imageWrapper}>
          <img 
            src={require('../../images/Fam.png')} 
            alt="Family sharing stories" 
            className={styles.image}
          />
        </div>
      </div>
    </section>
  );
};

export default ContentBlock; 