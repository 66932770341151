import { Box, Typography, Container } from '@mui/material';
import { styled } from '@mui/material/styles';

const FooterWrapper = styled('footer')(({ theme }) => ({
  width: '100%',
  height: '60px',
  backgroundColor: 'white',
  borderTop: `1px solid ${theme.palette.divider}`,
  position: 'relative',
  [theme.breakpoints.down('sm')]: {
    height: '50px',
  }
}));

const Footer = () => {
  return (
    <FooterWrapper>
      <Container maxWidth="lg">
        <Box
          sx={{
            py: 2,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography variant="body2" color="text.secondary">
            {new Date().getFullYear()} Something Told ™. All rights reserved.
          </Typography>
        </Box>
      </Container>
    </FooterWrapper>
  );
};

export default Footer; 