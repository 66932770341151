import React, { useState, useEffect, useRef } from 'react';
import { styled } from '@mui/material/styles';
import Masonry from 'react-masonry-css';
import { formatDistanceToNow } from 'date-fns';
import {
  Box,
  Typography,
  IconButton,
  Fade,
  Paper,
} from '@mui/material';
import { Favorite, LocationOn, AccessTime, PlayArrow } from '@mui/icons-material';
import { useScrollManagement } from '../contexts/ScrollManagementContext';
import AddTreasureCard from './common/AddTreasureCard';
import PropTypes from 'prop-types';

// Reuse the formatCreatedAt function from Explore
const formatCreatedAt = (timestamp) => {
  try {
    if (!timestamp) return 'Recently';
    
    // Convert UTC timestamp to local time
    const utcDate = new Date(timestamp.replace(' ', 'T'));
    // Adjust for timezone offset
    const localDate = new Date(utcDate.getTime() - (utcDate.getTimezoneOffset() * 60000));
    
    return formatDistanceToNow(localDate, { 
      addSuffix: true,
      includeSeconds: false,
      roundingMethod: 'floor'
    }).replace('about ', '');
  } catch (error) {
    console.error('Date formatting error:', error);
    return 'Recently';
  }
};

const StyledMasonry = styled(Masonry)(({ theme }) => ({
  display: 'flex',
  margin: -1,
  width: 'auto',
  '& .masonry-grid_column': {
    padding: 1,
    backgroundClip: 'padding-box',
    '& > *': {
      width: '100%',
      marginBottom: 2,
    }
  },
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  '-ms-overflow-style': 'none',  /* IE and Edge */
  'scrollbarWidth': 'none',      /* Firefox */
}));

const ImageContainer = styled(Box)({
  position: 'relative',
  backgroundColor: 'rgba(0,0,0,0.05)',
  '&::before': {
    content: '""',
    display: 'block',
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    backgroundColor: 'inherit',
    transition: 'opacity 0.3s ease',
  }
});

const StyledImage = styled('img')({
  opacity: 0,
  transition: 'opacity 0.3s ease',
  '&.loaded': {
    opacity: 1,
  }
});

// Image component with loading state
const ProgressiveImage = ({ src, alt, height, ...props }) => {
  const [isLoaded, setIsLoaded] = useState(false);

  return (
    <ImageContainer>
      <StyledImage
        src={src}
        alt={alt}
        className={isLoaded ? 'loaded' : ''}
        onLoad={() => setIsLoaded(true)}
        loading="lazy"
        {...props}
      />
    </ImageContainer>
  );
};

const TreasureItem = styled(Paper)(({ theme }) => ({
  position: 'relative',
  borderRadius: 0,
  overflow: 'hidden',
  cursor: 'pointer',
  border: '0.5px solid rgba(0, 0, 0, 0.1)',
  boxShadow: 'none',
  transition: 'transform 0.2s ease-in-out',
  '&:hover': {
    transform: 'translateY(-4px)',
    '& .overlay': {
      opacity: 1,
    },
  },
  [theme.breakpoints.down('sm')]: {
    '&:hover': {
      transform: 'none',  // Disable lift effect on mobile
      '& .overlay': {
        opacity: 1,
      }
    }
  }
}));

const ImageWrapper = styled(Box)({
  position: 'relative',
  width: '100%',
  overflow: 'hidden',
  '& img': {
    width: '100%',
    height: 'auto',
    display: 'block',
    objectFit: 'cover',
  },
});

const Overlay = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  background: 'linear-gradient(to top, rgba(0,0,0,0.9) 0%, rgba(0,0,0,0.3) 60%, rgba(0,0,0,0.1) 100%)',
  opacity: 0,
  transition: 'opacity 0.2s ease-in-out',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  padding: { 
    xs: '12px 16px',  // Slightly more padding on sides for mobile
    sm: '16px 20px'   // More padding on sides for desktop
  },
  color: 'white',
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  }
}));

const LikeButton = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 8,
  right: 8,
  zIndex: 2,
  padding: 8,
  borderRadius: '50%',
  backgroundColor: 'rgba(0, 0, 0, 0.3)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  }
}));

// Define aspect ratio variations for visual interest
const aspectRatios = [
  '1/1',    // Square
  '4/5',    // Portrait
  '1/1',    // Square
  '3/2',    // Landscape
  '1/1',    // Square
  '2/3',    // Portrait
  '1/1',    // Square
  '16/9',   // Landscape
  '1/1',    // Square
];

const GridExplore = ({ treasures, onTreasureClick, onLikeClick, isDashboard }) => {
  const { registerScrollableDiv, setScrollableDivs } = useScrollManagement();
  const gridRef = useRef(null);

  useEffect(() => {
    let currentRef = gridRef.current;
    if (gridRef.current) {
      registerScrollableDiv(gridRef.current);
    }
    return () => {
      if (currentRef) {
        setScrollableDivs(prev => prev.filter(div => div !== currentRef));
      }
    };
  }, [registerScrollableDiv, setScrollableDivs]);

  const breakpointCols = {
    default: 5,
    1400: 4,
    1100: 3,
    700: 3,
    500: 2,
  };

  // Function to get aspect ratio for an item
  const getAspectRatio = (index) => {
    return aspectRatios[index % aspectRatios.length];
  };

  return (
    <StyledMasonry
      ref={gridRef}
      breakpointCols={breakpointCols}
      className="masonry-grid"
      columnClassName="masonry-grid_column"
      style={{ WebkitOverflowScrolling: 'touch' }}
    >
      {/* Add Treasure Card for Dashboard */}
      {isDashboard && (
        <TreasureItem>
          <Box sx={{ height: '300px' }}>  {/* Match height with other cards */}
            <AddTreasureCard onClick={(e) => onTreasureClick(e, { isAddCard: true })} />
          </Box>
        </TreasureItem>
      )}

      {treasures.filter(t => !t.isAddCard).map((treasure, index) => (
        <TreasureItem
          key={treasure.id}
          onClick={(e) => onTreasureClick(e, treasure)}
        >
          <ImageWrapper>
            <LikeButton
              onClick={(e) => {
                e.stopPropagation();
                onLikeClick(e, treasure.id);
              }}
            >
              <Favorite 
                sx={{ 
                  fontSize: '1.2rem',
                  color: treasure.has_liked ? 'error.main' : 'white',
                  transition: 'all 150ms ease',
                }}
              />
            </LikeButton>
            <Box
              component="img"
              src={treasure.is_video ? treasure.video_thumbnail : treasure.image_url}
              alt={treasure.name}
              loading="lazy"
              sx={{
                aspectRatio: getAspectRatio(index),
                objectFit: 'cover',
                width: '100%',
                height: '100%',
              }}
            />
            {treasure.is_video && (
              <Box
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  zIndex: 2,
                  width: '24px',
                  height: '24px',
                  borderRadius: '50%',
                  backgroundColor: 'rgba(0, 0, 0, 0.5)',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <PlayArrow sx={{ color: 'white', fontSize: '16px' }} />
              </Box>
            )}
            <Overlay className="overlay">
              <Typography 
                variant="caption" 
                color="inherit"
                sx={{ 
                  mb: 1,
                  display: 'block',
                  opacity: 0.6,
                  fontStyle: 'italic',
                  fontSize: '0.75rem'
                }}
              >
                {treasure.category}
              </Typography>
              <Typography 
                variant="h6" 
                sx={{ 
                  mb: 1,
                  fontSize: '1.1rem',
                  fontWeight: 600,
                }}
              >
                {treasure.name}
              </Typography>
              <Typography 
                variant="body2"
                sx={{ 
                  mb: 2,
                  opacity: 0.9,
                  display: '-webkit-box',
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: 'vertical',
                  overflow: 'hidden',
                  lineHeight: 1.5,
                }}
              >
                {treasure.story}
              </Typography>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Box sx={{ 
                  display: 'flex', 
                  alignItems: 'center', 
                  gap: 2,
                  width: '100%',
                  justifyContent: 'space-between'
                }}>
                  {/* Placeholder box to maintain layout when no location */}
                  <Box sx={{ minWidth: '20px' }}>
                    {treasure.location !== 'Unknown Location' && (
                      <Box sx={{ 
                        display: 'flex', 
                        alignItems: 'center', 
                        gap: 0.5,
                      }}>
                        <LocationOn sx={{ fontSize: '1rem', opacity: 0.8 }} />
                        <Typography 
                          variant="caption"
                          sx={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            opacity: 0.8
                          }}
                        >
                          {treasure.location}
                        </Typography>
                      </Box>
                    )}
                  </Box>
                  <Box sx={{ 
                    display: 'flex', 
                    alignItems: 'center', 
                    gap: 0.5,
                    overflow: 'hidden'
                  }}>
                    <AccessTime sx={{ fontSize: '1rem', opacity: 0.6 }} />
                    <Typography 
                      variant="caption"
                      sx={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        opacity: 0.6,
                        fontStyle: 'italic',
                        fontSize: '0.75rem'
                      }}
                    >
                      {formatCreatedAt(treasure.created_at)}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Overlay>
          </ImageWrapper>
        </TreasureItem>
      ))}
    </StyledMasonry>
  );
};

GridExplore.propTypes = {
  treasures: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      name: PropTypes.string.isRequired,
      image_url: PropTypes.string.isRequired,
      story: PropTypes.string,
      location: PropTypes.string,
      created_at: PropTypes.string,
      category: PropTypes.string,
      isAddCard: PropTypes.bool,
    })
  ).isRequired,
  onTreasureClick: PropTypes.func.isRequired,
  onLikeClick: PropTypes.func.isRequired,
  isDashboard: PropTypes.bool,
};

GridExplore.defaultProps = {
  isDashboard: false,
};

export default GridExplore; 