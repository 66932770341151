import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';

const LogoContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '2px',
  justifyContent: 'center',
}));

const SomethingText = styled(Typography)(({ theme, size }) => ({
  ...theme.typography.logo,
  color: theme.palette.primary.main,
  fontStyle: 'italic',
  fontSize: size,
}));

const ToldText = styled(Typography)(({ theme, size }) => ({
  ...theme.typography.logoTold,
  color: theme.palette.secondary.main,
  fontSize: size,
}));

const Logo = ({ size = '2rem' }) => (
  <LogoContainer>
    <SomethingText size={size}>Something</SomethingText>
    <ToldText size={size}>TOLD</ToldText>
  </LogoContainer>
);

export default Logo;

export const LogoWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  height: '64px',
  position: 'absolute',
  left: '50%',
  transform: 'translateX(-50%)',
  '& a': {
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    color: theme.palette.text.primary,
  }
})); 