import { useState, useEffect, useRef } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Box,
  Button,
  IconButton,
  styled,
  Grid,
  useTheme,
  useMediaQuery,
  CircularProgress,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ShareIcon from '@mui/icons-material/Share';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { EditOutlined, Close } from '@mui/icons-material';
import { useToast } from '../contexts/ToastContext';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import ConfirmDialog from './common/ConfirmDialog';
import Loader from './common/Loader/Loader';
import { getAuth } from 'firebase/auth';
import api from '../services/api';
import EditTreasureModal from './modals/EditTreasureModal';
import Logo from './common/Logo';
import InstagramStyleVideoPlayer from './common/InstagramStyleVideoPlayer';
import LogoIcon from '../assets/ST_Logo_Gold-on-Green.svg';
import texture from '../assets/Texture.png';
import StoryDisplayBox from './common/StoryDisplayBox';

const DialogContentStyled = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4),
  position: 'relative',
  minHeight: '100%',
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(3),
    overflow: 'visible',
  },
  /* Hide scrollbar for Chrome, Safari and Opera */
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  /* Hide scrollbar for IE, Edge and Firefox */
  '-ms-overflow-style': 'none',  /* IE and Edge */
  'scrollbar-width': 'none',  /* Firefox */
}));

const DetailBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  border: `1px solid ${theme.palette.divider}`,
  marginBottom: theme.spacing(2),
}));

const ActionButton = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(0.75, 1.5),
  fontSize: '0.8125rem',
  minHeight: '32px',
  transition: 'all 0.2s ease',
  '& .MuiSvgIcon-root': {
    fontSize: '1.125rem'
  },
  '&:hover': {
    transform: 'translateY(-1px)',
  }
}));

const DialogContentMobile = styled(DialogContent)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2),
  }
}));

const ContentWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(3),
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    gap: theme.spacing(2),
  }
}));

const Column = styled(Box)(({ theme }) => ({
  width: '50%',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  }
}));

const ImageContainer = styled(Box)(({ theme, isVideo }) => ({
  marginBottom: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  overflow: 'hidden',
  boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
  position: 'relative',
  paddingTop: isVideo ? 'auto' : '100%',
  backgroundColor: theme.palette.grey[50],
  border: `1px solid ${theme.palette.divider}`,
  userSelect: 'none',
  WebkitTouchCallout: 'none',
  transition: 'transform 0.2s ease-in-out',
  ...(isVideo && {
    height: 'auto',
    maxHeight: '500px',
    paddingTop: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }),
  ...(!isVideo && {
    '&:hover': {
      '& img': {
        transform: 'scale(1.05)',
        transition: 'transform 0.3s ease'
      },
      '&::after': {
        opacity: 1
      }
    },
    '&::after': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      background: 'linear-gradient(180deg, rgba(0,0,0,0.02) 0%, rgba(0,0,0,0.1) 100%)',
      opacity: 0,
      transition: 'opacity 0.3s ease',
      zIndex: 2,
      pointerEvents: 'none'
    }
  })
}));

const DetailsContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  borderRadius: '8px',
  boxShadow: '0 1px 20px rgba(122, 118, 118, 0.39)',
  backgroundImage: `url(${texture})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  color: theme.palette.common.white,
  marginBottom: theme.spacing(2),
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: 1
}));

const TreasureDetails = ({ open, onClose, treasure, onDelete, onUpdate }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { showToast } = useToast();
  const location = useLocation();
  const [isDeleting, setIsDeleting] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [copied, setCopied] = useState(false);
  const [showPublicConfirm, setShowPublicConfirm] = useState(false);
  const [isPublic, setIsPublic] = useState(treasure?.is_public);

  useEffect(() => {
    setIsPublic(treasure?.is_public);
  }, [treasure?.is_public]);

  const handleMakePublic = async () => {
    try {
      await api.patch(`/api/treasures/${treasure.id}`, 
        { is_public: true }
      );
      
      setIsPublic(true);
      if (onUpdate) {
        onUpdate({ ...treasure, is_public: true });
      }
      
      showToast('Treasure is now public!', 'success');
      setShowPublicConfirm(false);

      // Let user click share button manually after making public
    } catch (error) {
      console.error('Error making treasure public:', error);
      showToast('Failed to make treasure public', 'error');
    }
  };

  const handleShare = async () => {
    if (!isPublic) {
      setShowPublicConfirm(true);
      return;
    }
    
    if (!treasure?.id) return;
    const shareUrl = `${window.location.protocol}//${window.location.host}/treasurechest/treasure/${treasure.id}`;
    console.log('Generated share URL:', shareUrl);
    
    try {
      console.log('Clipboard API available:', !!navigator.clipboard);
      if (navigator.clipboard && typeof navigator.clipboard.writeText === 'function') {
        await navigator.clipboard.writeText(shareUrl);
        console.log('Successfully copied to clipboard');
        showToast('Link copied to clipboard!', 'success', 3000);
      } else {
        console.log('Using fallback copy method');
        const textArea = document.createElement('textarea');
        textArea.value = shareUrl;
        document.body.appendChild(textArea);
        textArea.select();
        try {
          document.execCommand('copy');
          showToast('Link copied to clipboard!', 'success', 3000);
        } catch (err) {
          console.error('Fallback copy failed:', err);
          showToast('Failed to copy link. Please try again.', 'error');
        }
        document.body.removeChild(textArea);
      }
    } catch (error) {
      console.error('Error copying to clipboard:', error);
      showToast('Failed to copy link. Please try again.', 'error');
    }
  };

  const handleDelete = async () => {
    try {
      setIsDeleting(true);
      await api.delete(`/api/treasures/${treasure.id}`);
      showToast('Treasure deleted successfully', 'success');
      onClose();
      onDelete(treasure.id);
    } catch (error) {
      console.error('Error deleting treasure:', error);
      showToast('Failed to delete treasure', 'error');
    } finally {
      setIsDeleting(false);
      setShowDeleteConfirm(false);
    }
  };

  const handleEdit = () => {
    setIsEditModalOpen(true);
  };

  const handleEditUpdate = (updatedTreasure) => {
    if (onUpdate) {
      onUpdate(updatedTreasure);
    }
    setIsEditModalOpen(false);
  };

  // Add image load tracking
  const handleImageLoad = () => {
    // You could implement analytics here
    console.log('Image loaded from CloudFront:', treasure?.image_url);
  };

  // Calculate dynamic sizes based on content and viewport
  const calculateSizes = () => {
    const viewportWidth = window.innerWidth;
    const titleLength = treasure?.name?.length || 0;
    
    if (viewportWidth < 600) { // mobile
      const isVideoLandscape = treasure?.is_video && viewportWidth > 400;
     

      // Adjusted thresholds for landscape videos
      if (isVideoLandscape) {
        if (titleLength > 15) return { logo: '1rem', text: '0.8rem' };
        if (titleLength > 10) return { logo: '1.2rem', text: '0.9rem' };
        return { logo: '2.2rem', text: '1rem' };
      }

      // Longer title = smaller sizes
      if (titleLength > 30) {
        return { logo: '2.2rem', text: '0.8rem' };
      } else if (titleLength > 20) {
        return { logo: '2.2rem', text: '0.9rem' };
      }
      return { logo: '2.2rem', text: '1rem' };
    }
    
    return { logo: '2.2rem', text: '1.25rem' }; // desktop defaults
  };

  const sizes = calculateSizes();

  return (
    <>
      <Dialog 
        open={open} 
        onClose={onClose} 
        maxWidth="md" 
        fullWidth
        fullScreen={isMobile}
        TransitionProps={{ exit: false }}
        PaperProps={{
          sx: {
            maxHeight: { sm: '90vh' },
            height: isMobile ? '100%' : 'auto',
          }
        }}
      >
        <DialogTitle 
          sx={{ 
            p: { xs: 2, sm: 3 }, 
            borderBottom: '1px solid', 
            borderColor: 'divider',
            position: 'sticky',
            top: 0,
            bgcolor: 'background.paper',
            zIndex: 1
          }}
        >
          <Box sx={{ 
            display: 'flex', 
            flexDirection: { xs: 'column', sm: 'row' },
            alignItems: { xs: 'flex-start', sm: 'center' }, 
            justifyContent: 'space-between',
          }}>
            <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
              <IconButton
                edge="start"
                onClick={onClose}
                aria-label="back"
                sx={{ 
                  mr: 1,
                  display: { xs: 'flex', sm: 'none' }
                }}
              >
                <ArrowBackIcon />
              </IconButton>
              <Box sx={{ 
                display: 'flex', 
                alignItems: 'center',
                flex: 1,
                width: '100%',
                justifyContent: { xs: 'flex-start', sm: 'flex-start' }
              }}>
                <img
                  src={LogoIcon}
                  alt="Something Told Logo"
                  style={{
                    width: sizes.logo,
                    height: sizes.logo,
                  }}
                />
                <Box 
                  sx={{ 
                    width: '1px', 
                    height: 24, 
                    bgcolor: 'divider', 
                    mx: 1,
                    opacity: 0.2 
                  }} 
                />
                <Typography 
                  variant="h6" 
                  component="div" 
                  noWrap
                  sx={{ 
                    fontWeight: 400,
                    fontSize: sizes.text,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    flex: 1,
                    minWidth: 0 // Allow text to shrink below its content size
                  }}
                >
                  {treasure?.name
                  ? treasure.name.charAt(0).toUpperCase() + treasure.name.slice(1)
                  : ''}
                </Typography>
              </Box>
              <Box sx={{ 
                display: { xs: 'none', sm: 'flex' },
                alignItems: 'center',
                gap: 2
              }}>
                <IconButton onClick={onClose}>
                  <Close />
                </IconButton>
              </Box>
            </Box>
          </Box>
        </DialogTitle>

        <DialogContentMobile sx={{ p: { xs: 2, sm: 3 } }}>
          <ContentWrapper>
            {/* Left Column - Image and Details */}
            <Column>
              <ImageContainer 
                isVideo={treasure?.is_video}
                onContextMenu={(e) => e.preventDefault()}
                onMouseDown={(e) => e.preventDefault()}
              >
                <Box
                  sx={{
                    position: 'absolute',
                    top: 8,
                    right: 8,
                    display: 'inline-flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '2px 8px',
                    borderRadius: '16px',
                    fontSize: '0.6rem',
                    fontWeight: 500,
                    textTransform: 'uppercase',
                    backgroundColor: 'rgba(0, 0, 0, 0.3)',
                    color: 'rgba(255, 255, 255, 0.9)',
                    letterSpacing: '0.5px',
                    backdropFilter: 'blur(8px)',
                    border: '1px solid rgba(255, 255, 255, 0.2)',
                    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.2)',
                    height: '22px',
                    zIndex: 3
                  }}
                >
                  {treasure?.category}
                </Box>
                {treasure?.is_video ? (
                  <InstagramStyleVideoPlayer src={treasure.image_url} />
                ) : (
                  <Box
                    component="img"
                    src={treasure?.image_url}
                    onLoad={handleImageLoad}
                    alt={treasure?.name}
                    draggable="false"
                    sx={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                      objectPosition: 'center',
                      transition: 'transform 0.3s ease',
                      zIndex: 1,
                      userSelect: 'none',
                      WebkitUserDrag: 'none',
                      WebkitTouchCallout: 'none',
                      pointerEvents: 'none'
                    }}
                  />
                )}
                <Box
                  sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    background: 'linear-gradient(180deg, rgba(0,0,0,0.02) 0%, rgba(0,0,0,0) 100%)',
                    zIndex: 2,
                    pointerEvents: 'none'
                  }}
                />
              </ImageContainer>

              <DetailBox>
                <DetailsContainer>
                  <Box sx={{ 
                    display: 'flex', 
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                    gap: 1,
                    width: '100%',
                    textAlign: 'center',
                    lineHeight: 1.2,
                    flexDirection: { xs: 'column', sm: 'row' }
                  }}>
                    <Box sx={{ 
                      display: 'flex', 
                      alignItems: 'center',
                      justifyContent: 'center',
                      flex: '1 1 auto'
                    }}>
                      <Typography 
                        variant="body2" 
                        color="common.white"
                        sx={{ 
                          opacity: 0.6,
                          fontStyle: 'italic',
                          fontSize: '0.75rem',
                          mr: 0.5
                        }}
                      >
                        From:
                      </Typography>
                      <Typography 
                        variant="body1" 
                        color="common.white"
                        sx={{ 
                          fontWeight: 500,
                          fontSize: '0.875rem'
                        }}
                      >
                        {treasure?.from_person
                        ? treasure.from_person.charAt(0).toUpperCase() + treasure.from_person.slice(1)
                        : ''}
                      </Typography>
                    </Box>
                    
                    <Box 
                      sx={{ 
                        height: '16px',
                        width: '1px',
                        opacity: 0.3,
                        bgcolor: 'rgba(255, 255, 255, 0.3)',
                        mx: 2,
                        display: { xs: 'none', sm: 'block' }
                      }}
                    />

                    <Box sx={{ 
                      display: 'flex', 
                      alignItems: 'center',
                      justifyContent: 'center',
                      flex: '1 1 auto'
                    }}>
                      <Typography 
                        variant="body2" 
                        color="common.white"
                        sx={{ 
                          opacity: 0.6,
                          fontStyle: 'italic',
                          fontSize: '0.75rem',
                          mr: 0.5
                        }}
                      >
                        Received:
                      </Typography>
                      <Typography 
                        variant="body1" 
                        color="common.white"
                        sx={{ 
                          fontWeight: 500,
                          fontSize: '0.875rem'
                        }}
                      >
                        {treasure?.date_acquired ? new Date(treasure.date_acquired + 'T00:00:00').toLocaleDateString('en-US', {
                          month: 'long',
                          day: 'numeric',
                          year: 'numeric',
                          timeZone: 'UTC'
                        }) : 'Not specified'}
                      </Typography>
                    </Box>
                  </Box>
                </DetailsContainer>

                <Box sx={{ 
                  display: 'flex', 
                  gap: { xs: 0.5, sm: 1 }, 
                  mt: 2,
                  borderTop: '1px solid',
                  borderColor: 'divider',
                  pt: 2,
                  flexWrap: { xs: 'wrap', sm: 'nowrap' }
                }}>
                  <ActionButton
                    variant="outlined"
                    startIcon={<EditOutlined />}
                    onClick={handleEdit}
                    sx={{ 
                      flex: { xs: '1 1 calc(50% - 4px)', sm: 1 },
                      mb: { xs: 1, sm: 0 }
                    }}
                  >
                    Edit
                  </ActionButton>
                  <ActionButton
                    variant="outlined"
                    startIcon={<ShareIcon />}
                    onClick={handleShare}
                    sx={{ 
                      flex: { xs: '1 1 calc(50% - 4px)', sm: 1 },
                      mb: { xs: 1, sm: 0 },
                      disabled: !isPublic
                    }}
                  >
                    {isPublic ? 'Share' : 'Private'}
                  </ActionButton>
                  <ActionButton
                    variant="outlined"
                    color="error"
                    startIcon={isDeleting ? <CircularProgress size={16} color="error" /> : <DeleteOutlineIcon />}
                    onClick={() => setShowDeleteConfirm(true)}
                    disabled={isDeleting}
                    sx={{ 
                      flex: { xs: '1 1 100%', sm: 1 }
                    }}
                  >
                    {isDeleting ? 'Deleting...' : 'Delete'}
                  </ActionButton>
                </Box>
              </DetailBox>
            </Column>

            {/* Right Column - Story */}
            <Column>
              <StoryDisplayBox story={treasure?.story} />
            </Column>
          </ContentWrapper>
        </DialogContentMobile>

        <EditTreasureModal
          open={isEditModalOpen}
          onClose={() => setIsEditModalOpen(false)}
          treasure={treasure}
          onUpdate={handleEditUpdate}
        />
      </Dialog>

      <ConfirmDialog
        open={showDeleteConfirm}
        onClose={() => setShowDeleteConfirm(false)}
        onConfirm={handleDelete}
        title="Delete Treasure"
        message="Are you sure you want to delete this treasure? This action cannot be undone."
        confirmText="Delete"
        confirmColor="error"
      />

      <ConfirmDialog
        open={showPublicConfirm}
        onClose={() => setShowPublicConfirm(false)}
        onConfirm={handleMakePublic}
        title="Make Treasure Public?"
        message="Share your treasure with the world! Making it public allows others to discover and appreciate your special memory. This helps build our community of shared stories and experiences. You can always make it private again later."
        confirmText="Make Public"
        cancelText="Keep Private"
      />
    </>
  );
};

export default TreasureDetails; 