import { createContext, useContext, useEffect, useState, useCallback, useMemo } from 'react';

const ScrollManagementContext = createContext();

// Track scroll position for iOS Safari restricted area
let lastKnownScrollPosition = 0;
let ticking = false;

export const ScrollManagementProvider = ({ children }) => {
  const [scrollableDivs, setScrollableDivs] = useState([]);
  const [nonScrollableDivs, setNonScrollableDivs] = useState([]);
  const [isLocked, setIsLocked] = useState(false);

  // Prevent bounce effect
  const scrollToPreventBounce = useCallback((element) => {
    const { scrollTop, offsetHeight, scrollHeight } = element;
    if (scrollTop <= 0) {
      element.scrollTo(0, 1);
      return;
    }
    if (scrollTop + offsetHeight >= scrollHeight) {
      element.scrollTo(0, scrollHeight - offsetHeight - 1);
    }
  }, []);

  // Memoize the scroll handler to prevent re-creation on every render
  const handleScroll = useCallback(() => {
    if (!ticking) {
      window.requestAnimationFrame(() => {
        lastKnownScrollPosition = window.scrollY;
        ticking = false;
      });
      ticking = true;
    }
  }, []);

  // Memoize register functions
  const registerScrollableDiv = useCallback((div) => {
    setScrollableDivs(prev => [...prev, div]);
  }, []);

  const registerNonScrollableDiv = useCallback((div) => {
    setNonScrollableDivs(prev => [...prev, div]);
  }, []);

  useEffect(() => {
    const preventUnwantedScroll = (e) => {
      // Allow scroll in scrollable divs
      if (scrollableDivs.some(div => div && div.contains && div.contains(e.target))) {
        return;
      }
      // Prevent scroll in non-scrollable divs
      if (nonScrollableDivs.some(div => div && div.contains && div.contains(e.target))) {
        e.preventDefault();
      }
    };

    // Prevent unwanted scroll
    window.addEventListener('touchmove', preventUnwantedScroll, { passive: false });
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('touchmove', preventUnwantedScroll);
      window.removeEventListener('scroll', handleScroll);
    };
  }, [scrollableDivs, nonScrollableDivs, handleScroll]);

  // Memoize the context value to prevent unnecessary re-renders
  const contextValue = useMemo(() => ({
    scrollableDivs,
    setScrollableDivs,
    nonScrollableDivs,
    setNonScrollableDivs,
    isLocked,
    setIsLocked,
    lastKnownScrollPosition,
    registerScrollableDiv,
    registerNonScrollableDiv,
    scrollToPreventBounce
  }), [
    scrollableDivs, 
    nonScrollableDivs, 
    isLocked, 
    registerScrollableDiv,
    registerNonScrollableDiv,
    scrollToPreventBounce
  ]);

  return (
    <ScrollManagementContext.Provider value={contextValue}>
      {children}
    </ScrollManagementContext.Provider>
  );
};

export const useScrollManagement = () => {
  const context = useContext(ScrollManagementContext);
  if (!context) {
    throw new Error('useScrollManagement must be used within ScrollManagementProvider');
  }
  return context;
}; 