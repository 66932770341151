import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const CloseButton = ({ onClose, sx = {} }) => {
  return (
    <IconButton
      onClick={onClose}
      aria-label="close"
      sx={{
        position: 'absolute',
        right: 16,
        top: 16,
        color: 'text.secondary',
        '&:hover': {
          color: 'text.primary',
        },
        ...sx
      }}
    >
      <CloseIcon />
    </IconButton>
  );
};

export default CloseButton; 