import React, { useState, useEffect } from 'react';
import { useParams, Link as RouterLink, useNavigate } from 'react-router-dom';
import DOMPurify from 'dompurify';
import api from '../../../services/api';
import styles from '../../styles/modules/blogDetail.module.css';
import PublicNav from '../common/PublicNav';
import ProgressiveImage from '../../../components/ProgressiveImage';
import Newsletter from '../home/Newsletter';
import Footer from '../common/Footer';
import { Divider, Box } from '@mui/material';
import { IconButton } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import XIcon from '@mui/icons-material/X';
import ShareIcon from '@mui/icons-material/Share';

const BlogDetail = () => {
  const { slug } = useParams();
  const [article, setArticle] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchArticle = async () => {
      try {
        const response = await api.get(`/api/articles/${slug}`);
        const data = response.data;
        setArticle(data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching article:', error);
        setLoading(false);
        navigate('/articles', { replace: true });
      }
    };

    fetchArticle();
  }, [slug, navigate]);

  const handleShare = (platform) => {
    const url = window.location.href;
    const text = article?.title;

    const shareUrls = {
      facebook: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`,
      twitter: `https://twitter.com/intent/tweet?url=${encodeURIComponent(url)}&text=${encodeURIComponent(text)}`,
      linkedin: `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(url)}`
    };

    window.open(shareUrls[platform], '_blank', 'width=600,height=400');
  };

  // NEW: Function to handle native sharing or fallback to copy the URL
  const handleNativeShare = async () => {
    // Check if the browser supports the native share API
    if (navigator.share) {
      try {
        await navigator.share({
          title: article?.title, // Using article title as the share title
          text: article?.title,  // You can extend this to a snippet of the article description if needed
          url: window.location.href // Sharing the current page URL
        });
      } catch (error) {
        console.error('Error during native sharing:', error);
      }
    } else {
      // Fallback for desktop: copy the link to the clipboard and notify the user
      try {
        await navigator.clipboard.writeText(window.location.href);
        alert('Link copied to clipboard');
      } catch (error) {
        console.error('Failed to copy link:', error);
      }
    }
  };

  if (loading) {
    return <div className={styles.loading}>Loading...</div>;
  }

  return (
    <div className={styles.blogPage}>
      <PublicNav />
      
      <main className={styles.mainContent}>
        {/* Breadcrumbs */}
        <div className={styles.breadcrumbs}>
          <RouterLink to="/">Home</RouterLink>
          <span className={styles.separator}>/</span>
          <RouterLink to="/blogs">Blog</RouterLink>
          <span className={styles.separator}>/</span>
          <span>{article.title}</span>
        </div>

        {/* Hero Section */}
        <div className={styles.heroSection}>
          <div className={styles.heroImage}>
            <ProgressiveImage
              src={article.image_url}
              alt={article.title}
              className={styles.featuredImage}
            />
          </div>
        </div>

        {/* Article Content */}
        <article className={styles.articleContent}>
          <div className={styles.articleHeader}>
            <div className={styles.category}>{article.category}</div>
            <h1>{article.title}</h1>
            
            {/* Author Info */}
            <div className={styles.authorSection}>
              <img 
                src={article.author_photo} 
                alt={article.author_name} 
                className={styles.authorPhoto}
              />
              <div className={styles.authorInfo}>
                <span className={styles.authorName}>{article.author_name}</span>
                <span className={styles.articleMeta}>
                  {new Date(article.date).toLocaleDateString()}
                  <span className={styles.dot} />
                  {article.read_time}
                </span>
              </div>
            </div>
          </div>

          {/* Article Body */}
          <div 
            className={styles.articleBody}
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(article.description)
            }}
          />

          {/* NEW Share Section */}
          <div className={styles.shareSection} style={{ paddingBottom: '20px' }}>
            {/* Share section heading with lighter font weight for a modern look */}
            <h2 className={styles.shareHeading} style={{ fontWeight: 400, marginBottom: '15px' }}>
              Share this article
            </h2>
            <div className={styles.shareButtons}>
              {/* Facebook share button */}
              <IconButton 
                onClick={() => handleShare('facebook')}
                aria-label="Share on Facebook"
                sx={{ color: "#1a4a2b", mr: 1 }}
              >
                <FacebookIcon />
              </IconButton>
              {/* X (Twitter) share button */}
              <IconButton 
                onClick={() => handleShare('twitter')}
                aria-label="Share on X"
                sx={{ color: "#1a4a2b", mr: 1 }}
              >
                <XIcon />
              </IconButton>
              {/* Native share button for devices supporting the native share API */}
              <IconButton 
                onClick={handleNativeShare}
                aria-label="Share via native options"
                sx={{ color: "#1a4a2b" }}
              >
                <ShareIcon />
              </IconButton>
            </div>
          </div>
        </article>
      </main>
      <Newsletter />
      <Box sx={{ 
            backgroundColor: 'rgba(255, 255, 255, 0.02)', // Subtle color
            height: '1px', // Very thin line
            width: '100%',
            margin: 0,
         
          }} />
      <Footer />
    </div>
  );
};

export default BlogDetail; 