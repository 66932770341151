/**
 * @UI-PROTECTED
 * WARNING: This file contains critical UI components and routing.
 * DO NOT:
 * - Modify existing routes
 * - Remove MainLayout wrapper
 * - Change UI component structure
 * - Alter existing layouts
 * 
 * Only allowed changes:
 * - Add new routes
 * - Update non-UI logic
 * - Fix security issues
 * - Add new features without modifying existing UI
 */

import { createBrowserRouter, RouterProvider, Navigate, Outlet } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { theme } from './styles/theme';
import { AuthProvider } from './contexts/AuthContext';
import { ToastProvider } from './contexts/ToastContext';
import ProtectedRoute from './components/ProtectedRoute';
import Auth from './components/Auth';
import Dashboard from './components/Dashboard';
import ErrorBoundary from './components/ErrorBoundary';
import Explore from './components/Explore';
import Events from './components/Events';
import Blog from './components/Blog';
import MainLayout from './components/common/MainLayout';
import Notifications from './components/Notifications';
import Settings from './components/Settings';
import Home from './components/Home';
import PublicBlog from './components/PublicBlog';
import TreasureDetails from './components/TreasureDetails';
import { ScrollManagementProvider } from './contexts/ScrollManagementContext';
import RootLayout from './components/RootLayout';
import TreasureStandalone from './components/TreasureStandalone';
import { Box, Typography, Link } from '@mui/material';
import { ScrollPositionProvider } from './contexts/ScrollPositionContext';
import EventStandalone from './components/EventStandalone';
import AuthWrapper from './components/AuthWrapper';
import Articles from './components/Articles';
import ArticleDetail from './components/ArticleDetail';
import EmailVerification from './components/auth/EmailVerification';
import AuthLayout from './components/auth/AuthLayout';
import { getAuth, applyActionCode } from "firebase/auth";
import HandleEmailVerification from './components/auth/HandleEmailVerification';
import SubscriptionSuccess from './components/SubscriptionSuccess';
import HomePage from './publicPages/HomePage';
import AboutPage from './publicPages/AboutPage';
import BlogList from './publicPages/components/blog/BlogList';
import BlogDetail from './publicPages/components/blog/BlogDetail';
import TermsPage from './publicPages/TermsPage';

// Create router with the new configuration
const router = createBrowserRouter([
  {
    element: (
      <AuthProvider>
        <ToastProvider>
          <ScrollManagementProvider>
            <Outlet />
          </ScrollManagementProvider>
        </ToastProvider>
      </AuthProvider>
    ),
    children: [
      {
        path: '/',
        element: <HomePage />,
        errorElement: <Navigate to="/" replace />
      },
      {
        path: '/aboutus',
        element: <AboutPage />,
        errorElement: <Navigate to="/" replace />
      },
      {
        path: '/blogs',
        element: <BlogList />
      },
      {
        path: '/blogs/:slug',
        element: <BlogDetail />
      },
      {
        path: '/login',
        element: <Auth />
      },
      // Specific standalone treasure route comes BEFORE the more generic /treasurechest route
      {
        path: '/treasurechest/treasure/:id',
        element: (
          <AuthWrapper>
            {(isLoggedIn) => 
              isLoggedIn ? (
                <MainLayout>
                  <TreasureStandalone />
                </MainLayout>
              ) : (
                <TreasureStandalone />
              )
            }
          </AuthWrapper>
        )
      },
      {
        path: '/treasurechest',
        element: (
          <ProtectedRoute>
            <MainLayout>
              <Dashboard />
            </MainLayout>
          </ProtectedRoute>
        )
      },
      {
        path: '/explore',
        element: (
          <ProtectedRoute>
            <MainLayout>
              <Explore />
            </MainLayout>
          </ProtectedRoute>
        )
      },
      {
        path: '/events',
        element: (
          <ProtectedRoute>
            <MainLayout>
              <Events />
            </MainLayout>
          </ProtectedRoute>
        )
      },
      {
        path: '/blog',
        element: (
          <ProtectedRoute>
            <MainLayout>
              <Blog />
            </MainLayout>
          </ProtectedRoute>
        )
      },
      {
        path: '/notifications',
        element: (
          <ProtectedRoute>
            <MainLayout>
              <Notifications />
            </MainLayout>
          </ProtectedRoute>
        )
      },
      {
        path: '/settings',
        element: (
          <ProtectedRoute>
            <MainLayout>
              <Settings />
            </MainLayout>
          </ProtectedRoute>
        )
      },
      {
        path: '/events/:id',
        element: (
          <AuthWrapper>
            {(isLoggedIn) => 
              isLoggedIn ? (
                <MainLayout>
                  <EventStandalone />
                </MainLayout>
              ) : (
                <EventStandalone />
              )
            }
          </AuthWrapper>
        )
      },
      {
        path: '/verify-email',
        element: (
          <HandleEmailVerification 
            onSuccess={() => navigate('/login')}
            onError={(error) => console.error('Verification error:', error)}
          />
        )
      },
      {
        path: '/subscription-success',
        element: (
          <ProtectedRoute>
            <SubscriptionSuccess />
          </ProtectedRoute>
        )
      },
      {
        path: '/terms',
        element: <TermsPage />
      },
      {
        path: '*',
        element: (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100vh',
              p: 3,
            }}
          >
            <Typography>
              Page not found. <Link href="/">Go home</Link>
            </Typography>
          </Box>
        )
      }
    ]
  }
], {
  future: {
    v7_startTransition: true,
    v7_relativeSplatPath: true
  }
});


const App = () => {
  return (
    <div style={{ minHeight: '100%', display: 'flex', flexDirection: 'column' }}>
      <ScrollPositionProvider>
        <ErrorBoundary>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <RouterProvider router={router} />
          </ThemeProvider>
        </ErrorBoundary>
      </ScrollPositionProvider>
    </div>
  );
};

export default App;