import React from 'react';
import styles from '../../styles/modules/aboutContent.module.css';

const AboutContent = () => {
  const contentBlocks = [
    {
      title: "Who We Are",
      description: [
        "At Something Told, we transform treasured objects into living portals, capturing the heart and history behind each meaningful item. More than just a digital archive, we’re creating an intimate space where family heirlooms, beloved keepsakes, and precious mementos can share their secrets with future generations. Our platform weaves together the physical and emotional threads of your legacy, ensuring that when someone holds a treasured item years from now, they’ll hold its story too.",
      ],
      image: require('../../images/mother-daughter.jpg'),
      reversed: false
    },
    {
      title: "How it works",
      description: [
        "We've created a safekeeping for your keepsakes. Whether you have one cherished treasure or one hundred, our platform makes it simple to preserve their stories for generations to come. Upload images, add written or spoken words, and create a timeless catalog of your most meaningful possessions.",
        "Think of it as your gift to the future—ensuring the stories behind your treasures are never lost with time."
      ],
      image: require('../../images/rings-hand.jpg'),
      reversed: true
    },
    {
      title: "Why Choose Us?",
      description: [
        "Built on love, we help you preserve meaning, not just things.  Our intuitive platform makes it easy to attach narratives, photos and reflections to the things that matter most—keeping their meaning intact, even as they change hands.",
        "We bring together a like-hearted community that values the beauty in personal things and their histories. Through shared stories, events, and meaningful connections, we celebrate the significance behind the objects we hold dear and we help you to begin making every treasure a gateway to its past.",
        "Founded in 2023 by Deanna Fay, Something Told stems from a story of big love and sudden loss which ignited a curiosity around family treasures whose stories were unknown.  Bringing to light how short life can be, yet how long our treasures can live, she felt called to bridge the gap between time, truth and treasure…one story at a time.",
      ],
      image: require('../../images/phone-app.jpg'),
      reversed: false
    }
  ];

  return (
    <section className={styles.contentSection}>
      {contentBlocks.map((block, index) => (
        <div 
          key={index} 
          className={`${styles.container} ${block.reversed ? styles.reversed : ''}`}
        >
          <div className={styles.imageWrapper}>
            <img 
              src={block.image} 
              alt={block.title} 
              className={styles.image}
            />
          </div>
          <div className={styles.textWrapper}>
            <div className={styles.textContent}>
              <h3>{block.title}</h3>
              {block.description.map((paragraph, i) => (
                <p key={i}>{paragraph}</p>
              ))}
            </div>
          </div>
        </div>
      ))}
    </section>
  );
};

export default AboutContent; 