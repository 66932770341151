import { useState, useRef, useEffect } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import {
  Box,
  Drawer,
  AppBar,
  Toolbar,
  List,
  Typography,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  Avatar,
  Menu,
  MenuItem,
  Link,
} from '@mui/material';
import {
  Menu as MenuIcon,
  Dashboard as DashboardIcon,
  Assignment as AssignmentIcon,
  Message as MessageIcon,
  Settings as SettingsIcon,
  Logout as LogoutIcon,
  Notifications as NotificationsIcon,
  Edit as EditIcon,
  HelpOutline as HelpOutlineIcon,
} from '@mui/icons-material';
import { useAuth } from '../../contexts/AuthContext';
import { useNavigate, useLocation } from 'react-router-dom';
import { auth } from '../../firebase';
import Footer from './Footer';
import EditProfileModal from '../EditProfileModal';
import { ReactComponent as TrunkIcon } from '../../assets/icons/trunk.svg';
import { ReactComponent as DDsettings } from '../../assets/icons/settings.svg';
import { ReactComponent as DDexplore } from '../../assets/icons/explore.svg';
import { ReactComponent as DDevents } from '../../assets/icons/events.svg';
import { ReactComponent as DDchat } from '../../assets/icons/chat.svg';
import { ReactComponent as DDblog } from '../../assets/icons/Blog.svg';
import useScrollFocus from '../../hooks/useScrollFocus';
import { useMediaQuery } from '@mui/material';
import { useScrollManagement } from '../../contexts/ScrollManagementContext';
import Logo from './Logo';
import { useScrollPosition } from '../../contexts/ScrollPositionContext';
import logo from '../../assets/logo.svg';
import LogoIcon from '../../assets/logo.svg';
import SomethingToldTexture from '../../assets/SomethingTold_Texture.png';
import Diversity1Icon from '@mui/icons-material/Diversity1';
import TreasureVaultModal from '../TreasureVaultModal';

const drawerWidth = 240;
const miniDrawerWidth = 65;
const MOBILE_SCROLL_POSITION = 0; // We'll adjust this value

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: {
      xs: theme.spacing(0),
      sm: theme.spacing(3)
    },
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('lg')]: {
      marginLeft: miniDrawerWidth,
      width: `calc(100% - ${miniDrawerWidth}px)`,
      ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['margin', 'width'], {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
      }),
    },
  })
);

const FooterWrapper = styled(Box)(({ theme }) => ({
  position: 'fixed',
  bottom: 0,
  right: 0,
  width: '100%',
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${miniDrawerWidth}px)`,
  },
  zIndex: theme.zIndex.drawer - 1,
}));

const LogoContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '2px',
  background: 'transparent',
  position: 'absolute',
  left: '50%',
  transform: 'translateX(-50%)',
  zIndex: 1,
}));

const SomethingText = styled(Typography)(({ theme }) => ({
  ...theme.typography.logo,
  color: theme.palette.primary.main,
  fontStyle: 'italic',
}));

const ToldText = styled(Typography)(({ theme }) => ({
  ...theme.typography.logoTold,
  color: theme.palette.secondary.main,
}));

// Add the styled component for the logo with responsive sizing
const MenuLogo = styled('img')(({ theme, open }) => ({
  width: '42px',
  height: '42px',
  display: 'block',
  margin: '20px auto',
  cursor: 'pointer',
  transition: theme.transitions.create(['margin', 'transform', 'box-shadow'], {
    easing: theme.transitions.easing.easeInOut,
    duration: '0.3s',
  }),
  position: 'relative',
  zIndex: 1,
  '&:hover': {
    transform: 'scale(1.3)',
    zIndex: 10,
    boxShadow: '0 5px 15px rgba(0, 0, 0, 0.2)',
  }
}));

const MainLayout = ({ children }) => {
  const [open, setOpen] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const { currentUser, userData, signOut } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  const [editProfileOpen, setEditProfileOpen] = useState(false);
  const { registerScrollableDiv } = useScrollManagement();
  const scrollContainerRef = useRef(null);
  const { searchBarPosition } = useScrollPosition();
  const [menuOpen, setMenuOpen] = useState(false);
  const [isHovering, setIsHovering] = useState(false);
  const [isPinned, setIsPinned] = useState(true);
  const [hoverTimer, setHoverTimer] = useState(null);
  const [hoverCloseTimer, setHoverCloseTimer] = useState(null);
  const [vaultModalOpen, setVaultModalOpen] = useState(false);

  useScrollFocus(scrollContainerRef);

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;
    if (scrollContainer) {
      const isMobile = window.innerWidth < 1200;
      if (isMobile && searchBarPosition) {
        scrollContainer.scrollTo({
          top: searchBarPosition - 20, // Subtract some padding
          behavior: 'smooth'
        });
      } else {
        scrollContainer.scrollTop = 0;
      }
    }
  }, [location.pathname, searchBarPosition]);

  useEffect(() => {
    const handleResize = () => {
      const scrollContainer = scrollContainerRef.current;
      if (scrollContainer) {
        const isMobile = window.innerWidth < 1200;
        if (isMobile) {
          scrollContainer.scrollTo({
            top: MOBILE_SCROLL_POSITION,
            behavior: 'smooth'
          });
        }
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [location.pathname]);

  useEffect(() => {
    if (scrollContainerRef.current) {
      registerScrollableDiv(scrollContainerRef.current);
    }
  }, [registerScrollableDiv]);

  useEffect(() => {
    // Only handle hover effects if not mobile and not pinned
    if (!isMobile && !isPinned) {
      if (isHovering) {
        // Cancel any pending close
        if (hoverCloseTimer) {
          clearTimeout(hoverCloseTimer);
          setHoverCloseTimer(null);
        }

        // Set delay for opening
        if (!open) {
          const timer = setTimeout(() => {
            setOpen(true);
          }, 300);
          setHoverTimer(timer);
        }
      } else {
        // Clear opening timer if exists
        if (hoverTimer) {
          clearTimeout(hoverTimer);
          setHoverTimer(null);
        }

        // Set delay for closing
        if (open && !menuOpen) {
          const closeTimer = setTimeout(() => {
            setOpen(false);
          }, 300);
          setHoverCloseTimer(closeTimer);
        }
      }
    }

    return () => {
      // Cleanup both timers
      if (hoverTimer) clearTimeout(hoverTimer);
      if (hoverCloseTimer) clearTimeout(hoverCloseTimer);
    };
  }, [isHovering, isMobile, isPinned, menuOpen, open]);

  const handleDrawerToggle = () => {
    setOpen(!open);
    setIsPinned(!open);
    // Clear any pending timers when manually toggling
    if (hoverTimer) {
      clearTimeout(hoverTimer);
      setHoverTimer(null);
    }
    if (hoverCloseTimer) {
      clearTimeout(hoverCloseTimer);
      setHoverCloseTimer(null);
    }
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
    setMenuOpen(true);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setMenuOpen(false);
  };

  const handleLogout = async () => {
    try {
      await signOut();
      navigate('/login');
    } catch (error) {
      console.error('Error logging out:', error);
    }
    handleMenuClose();
  };

  const handleMenuItemClick = (path) => {
    navigate(path);
  };

  const menuItems = [
    { text: 'Treasure Chest', icon: <TrunkIcon />, path: '/treasurechest' },
    { text: 'Explore', icon: <DDexplore />, path: '/explore' },
    { text: 'Events', icon: <DDevents />, path: '/events' },
    { text: 'Blog', icon: <DDblog />, path: '/blog' },
    { text: 'Notifications', icon: <DDchat />, path: '/notifications' },
  ];

  const bottomMenuItem = {
    text: 'Settings',
    icon: <DDsettings />,
    path: '/settings'
  };

  const handleDrawerMouseEnter = () => {
    setIsHovering(true);
  };

  const handleDrawerMouseLeave = () => {
    setIsHovering(false);
  };

  const handleLogoClick = () => {
    navigate('/explore');
  };

  return (
    <Box 
      sx={{ 
        display: 'flex',
        height: '100vh',
        overflow: 'hidden',
      }}
    >
      <AppBar
        position="fixed"
        sx={(theme) => ({
          backgroundColor: 'white',
          color: 'text.primary',
          boxShadow: 'none',
          borderBottom: '1px solid transparent',
          zIndex: {
            xs: theme.zIndex.appBar,
            lg: theme.zIndex.drawer + 1
          },
          width: '100%',
          [`${theme.breakpoints.up('lg')}`]: {
            width: `calc(100% - ${open ? drawerWidth : miniDrawerWidth}px)`,
            marginLeft: `${open ? drawerWidth : miniDrawerWidth}px`,
          },
          transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
        })}
      >
        <Toolbar>
          {/* Desktop menu toggle */}
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerToggle}
            edge="start"
            sx={{ 
              mr: 2,
              display: { xs: 'none', lg: 'block' } // Show on desktop only
            }}
          >
            <MenuIcon />
          </IconButton>

          {/* Mobile menu toggle */}
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerToggle}
            edge="start"
            sx={{ 
              mr: 2,
              display: { xs: 'block', lg: 'none' } // Show on mobile only
            }}
          >
            <MenuIcon />
          </IconButton>

          {/* Add Mobile-only centered logo */}
          <Box
            sx={{ 
              position: 'absolute',
              left: '50%',
              transform: 'translateX(-50%)',
              display: { xs: 'flex', lg: 'none' },
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
           
          </Box>
        {/* Add Mobile-only centered logo */}
          <Box sx={{ flexGrow: 1 }} />

          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <IconButton
              onClick={handleMenuOpen}
              size="small"
              sx={{ 
                ml: 2,
                opacity: menuOpen ? 0 : 1,
                transition: 'opacity 0.3s ease',
                pointerEvents: menuOpen ? 'none' : 'auto'
              }}
            >
              <Avatar
                src={currentUser?.photoURL || userData?.profile?.userPhoto}
                sx={{ 
                  width: 40, 
                  height: 40,
                  transition: 'transform 0.3s ease',
                  '&:hover': {
                    transform: 'scale(1.1)'
                  }
                }}
              >
                {!currentUser?.photoURL && !userData?.profile?.userPhoto && currentUser?.email?.[0]?.toUpperCase()}
              </Avatar>
            </IconButton>
          </Box>

          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            PaperProps={{
              sx: {
                borderRadius: '12px',
                boxShadow: '0 10px 32px rgba(0,0,0,0.1)',
                minWidth: '200px',
                width: '200px',
                marginTop: '0',
                backgroundColor: theme.palette.background.paper,
                border: `1px solid ${theme.palette.divider}`,
                position: 'absolute',
                top: '8px !important',
                right: '8px !important'
              }
            }}
            sx={{
              '& .MuiMenuItem-root': {
                padding: '12px 20px',
                transition: 'transform 0.5s cubic-bezier(0.23, 1, 0.32, 1), background-color 0.3s ease, box-shadow 0.3s ease',
                transformOrigin: 'left center',
                willChange: 'transform, background-color',
                position: 'relative',
                '&::before': {
                  content: '""',
                  position: 'absolute',
                  top: 0,
                  left: -4,
                  width: '4px',
                  height: '100%',
                  backgroundColor: '#C6AB70',
                  transform: 'scaleX(0)',
                  transformOrigin: 'right center',
                  transition: 'transform 0.5s cubic-bezier(0.23, 1, 0.32, 1)',
                },
                '&:hover': {
                  backgroundColor: '#1a4a2b',
                  transform: 'translateX(8px) scale(1.02)',
                  boxShadow: '0 4px 12px rgba(0,0,0,0.15)',
                  '&::before': {
                    transform: 'scaleX(1)',
                  },
                  '& .MuiListItemText-root': {
                    color: '#fff !important',
                  },
                  '& .MuiSvgIcon-root': {
                    color: '#fff !important',
                  }
                }
              },
              '& .MuiList-root': {
                padding: '8px 0',
              }
            }}
          >
            {/* User Profile Header */}
            <Box sx={{ 
              padding: '16px 20px 12px',
              position: 'relative',
              marginBottom: '4px'
            }}>
              {/* Gradient divider */}
              <Box sx={{
                position: 'absolute',
                bottom: 0,
                left: 0,
                right: 0,
                height: '1px',
                background: 'linear-gradient(90deg, transparent 0%, rgba(198,171,112,0.3) 50%, transparent 100%)'
              }} />
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <Avatar
                  src={currentUser?.photoURL || userData?.profile?.userPhoto}
                  sx={{ 
                    width: 40, 
                    height: 40,
                    cursor: 'pointer',
                    transition: 'transform 0.3s ease',
                    '&:hover': {
                      transform: 'scale(1.1)'
                    }
                  }}
                  onClick={() => {
                    setEditProfileOpen(true);
                    handleMenuClose();
                  }}
                />
                <Box>
                  <Typography variant="subtitle2" sx={{ 
                    color: 'text.primary',
                    lineHeight: 1.2 
                  }}>
                    {userData?.profile?.firstName || 'User'}
                  </Typography>
                  <Typography variant="caption" sx={{ 
                    color: 'text.secondary',
                    fontSize: '0.75rem'
                  }}>
                    {currentUser?.email}
                  </Typography>
                </Box>
              </Box>
            </Box>

            <MenuItem onClick={() => {
              setEditProfileOpen(true);
              handleMenuClose();
            }}>
              <ListItemIcon sx={{ minWidth: '36px !important', color: 'text.secondary' }}>
                <EditIcon sx={{ fontSize: '20px' }} />
              </ListItemIcon>
              <ListItemText 
                primary="Edit Profile" 
                primaryTypographyProps={{ 
                  variant: 'body2',
                  sx: { fontWeight: 500 } 
                }} 
              />
            </MenuItem>
            
            <MenuItem onClick={() => {
              setVaultModalOpen(true);
              handleMenuClose();
            }}>
              <ListItemIcon sx={{ minWidth: '36px !important', color: 'text.secondary' }}>
                <Diversity1Icon sx={{ fontSize: '20px' }} />
              </ListItemIcon>
              <ListItemText 
                primary="Something Told" 
                primaryTypographyProps={{ 
                  variant: 'body2',
                  sx: { fontWeight: 500 } 
                }} 
              />
            </MenuItem>

            <MenuItem onClick={handleLogout}>
              <ListItemIcon sx={{ 
                minWidth: '36px !important', 
                color: 'text.secondary',
                transition: 'color 0.3s cubic-bezier(0.4, 0, 0.2, 1)' 
              }}>
                <LogoutIcon sx={{ fontSize: '20px' }} />
              </ListItemIcon>
              <ListItemText 
                primary="Log Out" 
                primaryTypographyProps={{ 
                  variant: 'body2',
                  sx: { 
                    fontWeight: 500,
                    transition: 'color 0.3s cubic-bezier(0.4, 0, 0.2, 1)'
                  } 
                }} 
              />
            </MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>

      <Drawer
        variant="temporary"
        open={open}
        onClose={handleDrawerToggle}
        sx={(theme) => ({
          display: { xs: 'block', lg: 'none' },
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
            backgroundImage: `url(${SomethingToldTexture})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            zIndex: theme.zIndex.appBar + 100,
            height: '100%',
            top: 0,
            paddingTop: 0,
            '& .MuiList-root': {
              paddingTop: 0
            }
          },
        })}
      >
        <Box onClick={handleLogoClick} sx={{ cursor: 'pointer' }}>
          <MenuLogo 
            src={LogoIcon} 
            alt="Something Told Logo" 
            open={true} 
          />
        </Box>
        <Box sx={{ 
          display: 'flex', 
          flexDirection: 'column', 
          height: '100%',
          mt: 0
        }}>
          <List>
            {menuItems.map((item) => (
              <ListItem 
                button 
                key={item.text}
                onClick={() => {
                  navigate(item.path);
                  if (isMobile) {
                    handleDrawerToggle();
                  }
                }}
                selected={location.pathname === item.path}
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                  cursor: 'pointer',
                  position: 'relative',
                  '&:hover': {
                    cursor: 'pointer',
                    bgcolor: 'rgba(255, 255, 255, 0.1)'
                  },
                  ...(location.pathname === item.path && {
                    borderLeft: '4px solid #C6AB70',
                    '& .MuiListItemIcon-root': {
                      color: '#C6AB70'
                    },
                    '& .MuiListItemText-root': {
                      color: '#C6AB70'
                    }
                  })
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                    cursor: 'pointer',
                    color: location.pathname === item.path ? '#C6AB70' : '#F5F5F5'
                  }}
                >
                  {item.icon}
                </ListItemIcon>
                <ListItemText 
                  primary={item.text} 
                  sx={{ 
                    opacity: open ? 1 : 0,
                    visibility: open ? 'visible' : 'hidden',
                    transition: theme => theme.transitions.create(['opacity'], {
                      duration: 150,
                    }),
                    whiteSpace: 'nowrap',
                    cursor: 'pointer',
                    width: open ? 'auto' : 0,
                    overflow: 'hidden',
                    color: location.pathname === item.path ? '#C6AB70' : '#F5F5F5',
                    '& .MuiTypography-root': {
                      color: location.pathname === item.path ? '#C6AB70' : '#F5F5F5'
                    }
                  }} 
                />
              </ListItem>
            ))}
          </List>
          <Box sx={{ flexGrow: 1 }} />
          <List>
            <ListItem 
              button 
              onClick={() => {
                handleMenuItemClick(bottomMenuItem.path);
                if (isMobile) {
                  handleDrawerToggle();
                }
              }}
              selected={location.pathname === bottomMenuItem.path}
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
                cursor: 'pointer',
                '&:hover': {
                  cursor: 'pointer'
                }
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                  cursor: 'pointer',
                  color: '#C6AB70'
                }}
              >
                {bottomMenuItem.icon}
              </ListItemIcon>
              <ListItemText 
                primary={bottomMenuItem.text} 
                sx={{ 
                  opacity: open ? 1 : 0,
                  visibility: open ? 'visible' : 'hidden',
                  transition: theme => theme.transitions.create(['opacity'], {
                    duration: 150,
                  }),
                  whiteSpace: 'nowrap',
                  cursor: 'pointer',
                  width: open ? 'auto' : 0,
                  overflow: 'hidden',
                  color: '#C6AB70',
                  '& .MuiTypography-root': {
                    color: '#C6AB70'
                  }
                }} 
              />
            </ListItem>
          </List>
        </Box>
      </Drawer>

      <Drawer
        variant="permanent"
        open={open}
        onMouseEnter={handleDrawerMouseEnter}
        onMouseLeave={handleDrawerMouseLeave}
        sx={(theme) => ({
          display: { xs: 'none', lg: 'block' },
          '& .MuiDrawer-paper': {
            width: open ? drawerWidth : miniDrawerWidth,
            boxSizing: 'border-box',
            backgroundImage: `url(${SomethingToldTexture})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            transition: theme.transitions.create('width', {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.enteringScreen,
            }),
          },
        })}
      >
        <Box onClick={handleLogoClick} sx={{ cursor: 'pointer' }}>
          <MenuLogo 
            src={LogoIcon} 
            alt="Something Told Logo" 
            open={open} 
          />
        </Box>
        <Box sx={{ 
          display: 'flex', 
          flexDirection: 'column', 
          height: '100%' 
        }}>
          <List>
            {menuItems.map((item) => (
              <ListItem 
                button 
                key={item.text}
                onClick={() => {
                  navigate(item.path);
                  if (isMobile) {
                    handleDrawerToggle();
                  }
                }}
                selected={location.pathname === item.path}
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                  cursor: 'pointer',
                  position: 'relative',
                  '&:hover': {
                    cursor: 'pointer',
                    bgcolor: 'rgba(255, 255, 255, 0.1)'
                  },
                  ...(location.pathname === item.path && {
                    borderLeft: '4px solid #C6AB70',
                    '& .MuiListItemIcon-root': {
                      color: '#C6AB70'
                    },
                    '& .MuiListItemText-root': {
                      color: '#C6AB70'
                    }
                  })
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                    cursor: 'pointer',
                    color: location.pathname === item.path ? '#C6AB70' : '#F5F5F5'
                  }}
                >
                  {item.icon}
                </ListItemIcon>
                <ListItemText 
                  primary={item.text} 
                  sx={{ 
                    opacity: open ? 1 : 0,
                    visibility: open ? 'visible' : 'hidden',
                    transition: theme => theme.transitions.create(['opacity'], {
                      duration: 150,
                    }),
                    whiteSpace: 'nowrap',
                    cursor: 'pointer',
                    width: open ? 'auto' : 0,
                    overflow: 'hidden',
                    color: location.pathname === item.path ? '#C6AB70' : '#F5F5F5',
                    '& .MuiTypography-root': {
                      color: location.pathname === item.path ? '#C6AB70' : '#F5F5F5'
                    }
                  }} 
                />
              </ListItem>
            ))}
          </List>
          <Box sx={{ flexGrow: 1 }} />
          <List>
            <ListItem 
              button 
              onClick={() => {
                handleMenuItemClick(bottomMenuItem.path);
                if (isMobile) {
                  handleDrawerToggle();
                }
              }}
              selected={location.pathname === bottomMenuItem.path}
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
                cursor: 'pointer',
                '&:hover': {
                  cursor: 'pointer'
                }
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                  cursor: 'pointer',
                  color: '#C6AB70'
                }}
              >
                {bottomMenuItem.icon}
              </ListItemIcon>
              <ListItemText 
                primary={bottomMenuItem.text} 
                sx={{ 
                  opacity: open ? 1 : 0,
                  visibility: open ? 'visible' : 'hidden',
                  transition: theme => theme.transitions.create(['opacity'], {
                    duration: 150,
                  }),
                  whiteSpace: 'nowrap',
                  cursor: 'pointer',
                  width: open ? 'auto' : 0,
                  overflow: 'hidden',
                  color: '#C6AB70',
                  '& .MuiTypography-root': {
                    color: '#C6AB70'
                  }
                }} 
              />
            </ListItem>
          </List>
        </Box>
      </Drawer>

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
        }}
      >
        <Toolbar />
        <Main open={open}>
          <Box 
            ref={scrollContainerRef}
            sx={(theme) => ({
              flexGrow: 1,
              overflowY: 'auto',
              height: 'calc(100vh - 128px)',
              position: 'relative',
              WebkitOverflowScrolling: 'touch',
              '&:focus': {
                outline: 'none'
              },
              [theme.breakpoints.down('sm')]: {
                paddingBottom: '50px'
              }
            })}
          >
            {children}
          </Box>
        </Main>
        <FooterWrapper>
          <Footer />
        </FooterWrapper>
      </Box>

      <EditProfileModal 
        open={editProfileOpen} 
        onClose={() => setEditProfileOpen(false)} 
      />

      <TreasureVaultModal 
        open={vaultModalOpen}
        onClose={() => setVaultModalOpen(false)}
      />
    </Box>
  );
};

export default MainLayout; 