// File: articles.js

import React, { useState, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import '../styles/public.css';

// --------------------------------------
// MUI & Other Imports
// --------------------------------------
import {
  Box,
  Typography,
  Button,
  Grid,
  Container,
  Link as MUILink,
  AppBar,
  Toolbar,
  Paper,
  CardContent,
  Skeleton,
  useTheme,
  useMediaQuery
} from '@mui/material';
import { styled, keyframes } from '@mui/material/styles';
import axios from 'axios';
import { format } from 'date-fns';
import { Link as RouterLink } from 'react-router-dom';

// --------------------------------------
// Custom Components (e.g., Logo)
// --------------------------------------
import Logo, { LogoWrapper } from './common/Logo';
import ProgressiveImage from './ProgressiveImage';
import Footer from './common/Footer';

// --------------------------------------
// STYLES & ANIMATIONS
// --------------------------------------

// A simple pulse animation for the dot between date & read_time
const pulse = keyframes`
  0% { 
    transform: scale(0.95); 
    box-shadow: 0 0 0 0 rgba(255, 152, 0, 0.7);
  }
  70% { 
    transform: scale(1); 
    box-shadow: 0 0 0 6px rgba(255, 152, 0, 0);
  }
  100% { 
    transform: scale(0.95); 
    box-shadow: 0 0 0 0 rgba(255, 152, 0, 0);
  }
`;

// The small animated dot that sits between date and read time
const TimeDivider = styled('span')(({ theme }) => ({
  display: 'inline-block',
  width: '4px',
  height: '4px',
  borderRadius: '50%',
  backgroundColor: theme.palette.warning.main,
  margin: '0 8px',
  verticalAlign: 'middle',
  animation: `${pulse} 2s infinite`,
  transformOrigin: 'center',
}));

// Used to color-code categories differently
const categoryColors = [
  {
    bg: 'rgba(0, 123, 255, 0.1)', // Blue
    color: 'primary.main'
  },
  {
    bg: 'rgba(255, 152, 0, 0.1)', // Orange
    color: 'warning.main'
  },
  {
    bg: 'rgba(76, 175, 80, 0.1)', // Green
    color: 'success.main'
  },
  {
    bg: 'rgba(233, 30, 99, 0.1)', // Pink
    color: 'error.main'
  }
];

// Helper function to remove HTML tags from a string
const stripHtmlTags = (html) => {
  const temp = document.createElement('div');
  temp.innerHTML = html;
  return temp.textContent || temp.innerText || '';
};

// --------------------------------------
// STYLED COMPONENTS
// --------------------------------------

// Styled NavBar with white background and bottom border
const NavBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: 'white',
  boxShadow: 'none',
  borderBottom: `1px solid ${theme.palette.divider}`,
}));

// Hero section at the top
const HeroSection = styled(Box)(({ theme }) => ({
  position: 'relative',
  backgroundColor: theme.palette.grey[200],
  height: '400px',
  width: '100%',
  marginBottom: theme.spacing(8),
}));

// The blog-card container with hover effects
const BlogCardContainer = styled(Paper)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  borderRadius: theme.shape.borderRadius,
  overflow: 'hidden',
  boxShadow: 'none',
  border: `1px solid ${theme.palette.divider}`,
  transition: 'transform 0.2s ease-in-out',
  '&:hover': {
    transform: 'translateY(-4px)',
    boxShadow: theme.shadows[4]
  },
  minHeight: 500,
}));

// Container for the blog's image
const BlogImage = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '250px',
  position: 'relative',
  backgroundColor: theme.palette.grey[200],
  overflow: 'hidden',
}));

// Wrapper for the card content (title, category, etc.)
const ContentWrapper = styled(CardContent)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  padding: theme.spacing(3),
}));

// A badge showing the blog's category
const CategoryBadge = styled(Box)(({ theme, colorIndex }) => ({
  display: 'inline-block',
  padding: '4px 10px',
  borderRadius: '15px',
  fontSize: '0.75rem',
  fontWeight: 600,
  textTransform: 'uppercase',
  backgroundColor: categoryColors[colorIndex].bg,
  color: theme.palette[
    categoryColors[colorIndex].color.split('.')[0]
  ][
    categoryColors[colorIndex].color.split('.')[1]
  ],
  margin: '0 auto',
}));

// --------------------------------------
// MAIN COMPONENT
// --------------------------------------
const Articles = () => {
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));

  // Single observer for the grid container
  const { ref, inView } = useInView({
    threshold: 0,
    triggerOnce: true,
    rootMargin: '50px 0px'
  });

  // Fetch articles on mount
  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const response = await axios.get('/api/articles');
        setArticles(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching articles:', error);
        setError('Failed to load articles');
        setLoading(false);
      }
    };

    fetchArticles();
  }, []);

  useEffect(() => {
    document.body.classList.add('public-page');
    return () => document.body.classList.remove('public-page');
  }, []);

  // If error occurs, show it
  if (error) {
    return (
      <Box sx={{ p: 3, textAlign: 'center' }}>
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  return (
    <div className="public-page">
      <div className="public-page-container">
        <div className="public-page-content">
          <NavBar position="static">
            <Toolbar sx={{ justifyContent: 'space-between' }}>
              {/* Spacer for layout symmetry */}
              <Box sx={{ width: 100 }} />

              {/* Logo in the center */}
              <LogoWrapper>
                <Box component={MUILink} href="/">
                  <Logo size="1.75rem" />
                </Box>
              </LogoWrapper>

              {/* Nav links on the right */}
              <Box sx={{ 
                display: 'flex', 
                gap: 3,
                width: 100,
                justifyContent: 'flex-end'
              }}>
                <MUILink 
                  href="/" 
                  color="text.secondary" 
                  sx={{ textDecoration: 'none' }}
                >
                  Home
                </MUILink>
                <MUILink 
                  href="/login" 
                  color="text.secondary" 
                  sx={{ textDecoration: 'none' }}
                >
                  Sign In
                </MUILink>
              </Box>
            </Toolbar>
          </NavBar>

          <HeroSection>
            <Container maxWidth="lg">
              <Box sx={{ maxWidth: 600, pt: 8 }}>
                <Typography 
                  variant="subtitle2" 
                  sx={{ mb: 1, color: 'text.secondary' }}
                >
                  Something Told
                </Typography>
                <Typography 
                  variant="h2" 
                  sx={{ 
                    mb: 2,
                    fontWeight: 600 
                  }}
                >
                  Blog
                </Typography>
                <Typography 
                  variant="body1" 
                  color="text.secondary" 
                  paragraph
                  sx={{ mb: 3 }}
                >
                  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, 
                  sed diam nonumy eirmod tempor invidunt ut labore et dolore magna.
                </Typography>
                <Button 
                  variant="outlined"
                  sx={{ 
                    borderRadius: 0,
                    textTransform: 'none',
                    px: 3,
                    py: 1
                  }}
                >
                  Read Now
                </Button>
              </Box>
            </Container>
          </HeroSection>

          <Container maxWidth="lg">
            <Grid container spacing={4} ref={ref}>
              {/* Loading skeletons if still fetching data */}
              {loading ? (
                [...Array(6)].map((_, index) => (
                  <Grid item xs={12} sm={6} md={4} key={`skeleton-${index}`}>
                    <Paper
                      sx={{
                        backgroundColor: 'grey.200',
                        height: '450px',
                        display: 'flex',
                        flexDirection: 'column',
                        borderRadius: 0,
                        overflow: 'hidden',
                        boxShadow: 'none'
                      }}
                    >
                      <Skeleton variant="rectangular" height={300} />
                      <CardContent>
                        <Skeleton variant="text" height={28} />
                        <Skeleton variant="text" height={20} />
                        <Box sx={{ mt: 2 }}>
                          <Skeleton variant="text" height={20} width="60%" />
                        </Box>
                      </CardContent>
                    </Paper>
                  </Grid>
                ))
              ) : (
                // Render fetched articles
                articles.map((article, index) => {
                  const colorIndex = index % categoryColors.length;

                  return (
                    <Grid item xs={12} sm={6} md={4} key={article.id}>
                      {inView && (  // Only render when container is in view
                        <BlogCardContainer>
                          <BlogImage>
                            <RouterLink to={`/articles/${article.slug}`}>
                              <ProgressiveImage
                                src={article.image_url}
                                alt={article.title}
                                style={{
                                  width: '100%',
                                  height: '100%',
                                  objectFit: 'cover',
                                  position: 'absolute',
                                  top: 0,
                                  left: 0,
                                  cursor: 'pointer'
                                }}
                              />
                            </RouterLink>
                          </BlogImage>

                          {/* Card content area */}
                          <ContentWrapper>
                            {/* Category badge */}
                            <CategoryBadge colorIndex={colorIndex}>
                              {article.category}
                            </CategoryBadge>

                            {/* Title */}
                            <Typography
                              variant="h6"
                              sx={{
                                fontWeight: 600,
                                fontSize: '1.1rem',
                                mb: 1.5,
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                display: '-webkit-box',
                                WebkitLineClamp: 2,
                                WebkitBoxOrient: 'vertical',
                              }}
                            >
                              {article.title}
                            </Typography>

                            {/* Description with HTML stripped */}
                            <Typography
                              variant="body2"
                              color="text.secondary"
                              sx={{
                                mb: 2,
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                display: '-webkit-box',
                                WebkitLineClamp: 4,
                                WebkitBoxOrient: 'vertical',
                              }}
                            >
                              {stripHtmlTags(article.description)}
                            </Typography>

                            {/* Author, date, read time */}
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                mt: 'auto',
                              }}
                            >
                              <Box>
                                <Typography variant="subtitle2">{article.author}</Typography>
                                <Typography variant="caption" color="text.secondary">
                                  {format(new Date(article.date), 'MMMM d, yyyy')}
                                  {article.read_time && <TimeDivider />}
                                  {article.read_time}
                                </Typography>
                              </Box>
                            </Box>

                            {/* Read More placeholder */}
                            <Button
                              component={RouterLink}
                              to={`/articles/${article.slug}`}
                              variant="outlined"
                              size="small"
                              sx={{
                                mt: 2,
                                borderRadius: theme.shape.borderRadius,
                                textTransform: 'none',
                                borderColor: 'grey.400',
                                '&:hover': { borderColor: 'grey.600' },
                              }}
                            >
                              Read More
                            </Button>
                          </ContentWrapper>
                        </BlogCardContainer>
                      )}
                    </Grid>
                  );
                })
              )}
            </Grid>
          </Container>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default Articles;
