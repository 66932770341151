import { forwardRef, useState } from 'react';
import { Button, CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import pica from 'pica';
import { VideoCall } from '@mui/icons-material';
import { useMediaQuery } from '@mui/material';

const Input = styled('input')({
  display: 'none'
});

const MAX_FILE_SIZE = 100 * 1024 * 1024; // 50MB limit for videos
const MAX_DIM = 640;  // Match ImageUpload max dimension

const VideoUpload = forwardRef(({ onUploadComplete, onUploadError }, ref) => {
  const [isSelecting, setIsSelecting] = useState(false);
  const isMobile = useMediaQuery('(max-width:600px)');

  const generateThumbnail = async (videoFile) => {
    console.log('[generateThumbnail] Starting thumbnail generation');
    const video = document.createElement('video');
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    return new Promise((resolve, reject) => {
      let hasResolved = false;

      video.autoplay = true;
      video.muted = true;
      video.playsInline = true;

      console.log('[generateThumbnail] Setting up video events');

      video.onloadedmetadata = async () => {
        if (hasResolved) return;
        console.log('[generateThumbnail] Metadata loaded, attempting to play');
        
        try {
          await video.play();
          video.pause();

          // Create source canvas at video size
          const srcCanvas = document.createElement('canvas');
          srcCanvas.width = video.videoWidth;
          srcCanvas.height = video.videoHeight;
          const srcCtx = srcCanvas.getContext('2d');
          srcCtx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);

          // Calculate target dimensions
          let targetWidth = video.videoWidth;
          let targetHeight = video.videoHeight;

          if (targetWidth > targetHeight) {
            if (targetWidth > MAX_DIM) {
              targetHeight = (targetHeight * MAX_DIM) / targetWidth;
              targetWidth = MAX_DIM;
            }
          } else {
            if (targetHeight > MAX_DIM) {
              targetWidth = (targetWidth * MAX_DIM) / targetHeight;
              targetHeight = MAX_DIM;
            }
          }

          // Create target canvas
          canvas.width = Math.round(targetWidth);
          canvas.height = Math.round(targetHeight);

          // Use pica to resize
          const picaInstance = pica();
          await picaInstance.resize(srcCanvas, canvas, {
            quality: 3,
            alpha: false
          });

          // Convert to blob
          const { thumbnailUrl, thumbnailBlob } = await new Promise((resolve) => {
            canvas.toBlob((blob) => {
              resolve({
                thumbnailUrl: URL.createObjectURL(blob),
                thumbnailBlob: blob
              });
            }, 'image/jpeg');
          });

          console.log('[generateThumbnail] Thumbnail generated successfully');
          hasResolved = true;
          resolve({ thumbnailUrl, thumbnailBlob });

        } catch (error) {
          console.error('[generateThumbnail] Error during play:', error);
          reject(error);
        }
      };

      video.onerror = (e) => {
        console.error('[generateThumbnail] Video error:', video.error);
        reject(new Error(`Video error: ${video.error?.message || 'Unknown error'}`));
      };

      const objectUrl = URL.createObjectURL(videoFile);
      console.log('[generateThumbnail] Created object URL:', objectUrl);
      video.src = objectUrl;

      // Add timeout to prevent infinite loading
      setTimeout(() => {
        if (!hasResolved) {
          console.error('[generateThumbnail] Timeout - video failed to load');
          reject(new Error('Video loading timeout'));
        }
      }, 10000); // Increased timeout to 10 seconds for iOS
    });
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    setIsSelecting(true);
    console.log('[VideoUpload] File selected:', file.name);

    try {
      // Validate file size
      if (file.size > MAX_FILE_SIZE) {
        throw new Error('File size must be less than 100MB');
      }

      // Validate file type
      if (!file.type.startsWith('video/')) {
        throw new Error('Only video files are allowed');
      }

      // Generate thumbnail
      const { thumbnailUrl, thumbnailBlob } = await generateThumbnail(file);

      // Create video URL for preview
      const videoUrl = URL.createObjectURL(file);

      // Return structured data matching our database schema
      onUploadComplete(
        videoUrl,        // Main video URL
        file,           // Video blob
        thumbnailUrl,   // Thumbnail URL for preview
        thumbnailBlob,  // Thumbnail blob for upload
        true            // is_video flag
      );

    } catch (error) {
      console.error('Error processing video:', error);
      onUploadError(error.message);
    } finally {
      setIsSelecting(false);
    }
  };

  return (
    <>
      <Input
        ref={ref}
        accept="video/*"
        type="file"
        onChange={handleFileChange}
        id="video-upload-input"
      />
      <label htmlFor="video-upload-input">
        <Button
          variant="contained"
          component="span"
          startIcon={!isMobile && <VideoCall />}
          sx={{
            bgcolor: '#11311B',
            '&:hover': {
              bgcolor: '#11311B',
              opacity: 0.9,
            }
          }}
        >
          {isMobile ? <VideoCall sx={{ mr: 1 }} /> : null}
          {isMobile ? "Video" : "Upload Video"}
        </Button>
      </label>
    </>
  );
});

VideoUpload.propTypes = {
  onUploadComplete: PropTypes.func.isRequired,
  onUploadError: PropTypes.func.isRequired
};

export default VideoUpload; 