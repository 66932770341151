import React from 'react';
import { Box, Typography, Button, CircularProgress } from '@mui/material';
import { motion } from 'framer-motion';
import StarIcon from '@mui/icons-material/Star';
import StarBurst from '../assets/StarBurst.svg';
import './NightCard.css';

const NightCard = ({ plan, loading, onSubscribe }) => {
  const isAnnualPlan = plan.title === 'Annual Plan';
  
  return (
    <motion.div 
      className={`night-card ${isAnnualPlan ? 'featured-card' : ''}`}
      initial={{ y: 20, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{ 
        type: "spring", 
        stiffness: 100, 
        delay: isAnnualPlan ? 0.1 : 0.2 
      }}
      whileHover={{ 
        y: -15,
        transition: { type: "spring", stiffness: 300, damping: 15 }
      }}
    >
      {isAnnualPlan && (
        <div className="star-burst-badge">
          <img src={StarBurst} alt="Save 17% annually" className="star-burst-svg" />
        </div>
      )}
      
      <div className="night-background">
        <div className="moons">
          <div className="first-moon"></div>
          <div className="second-moon"></div>
          <div className="glow-effect"></div>
          {Array.from({ length: 24 }).map((_, i) => (
            <div key={i} className={`star star-${i + 1}`} />
          ))}
        </div>
      </div>

      <Box
        sx={{
          padding: 3,
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
          zIndex: 2,
          height: '100%',
          overflow: 'visible'
        }}
      >
        <Typography 
          variant="h5" 
          className={`plan-title ${isAnnualPlan ? 'featured-title' : ''}`}
          sx={{ 
            mb: 1, 
            fontWeight: isAnnualPlan ? 700 : 500, 
            color: '#ffffff',
            position: 'relative',
            display: 'inline-block'
          }}
        >
          {plan.title}
        </Typography>

        <Box sx={{ display: 'flex', alignItems: 'baseline', mb: 2 }}>
          <Typography 
            variant="h3" 
            className="price"
            sx={{ 
              fontWeight: 700, 
              color: '#ffffff',
              fontFamily: 'Roboto',
              letterSpacing: '-0.02em'
            }}
          >
            {plan.price}
          </Typography>
          {plan.period && (
            <Typography 
              variant="body1" 
              sx={{ 
                color: 'rgba(255,255,255,0.85)', 
                ml: 1,
                fontWeight: 300
              }}
            >
              {plan.period}
            </Typography>
          )}
        </Box>

        {plan.description && (
          <Typography 
            variant="body2" 
            sx={{ 
              mb: 3, 
              color: 'rgba(255,255,255,0.85)',
              fontWeight: 400,
              fontSize: '0.95rem',
              letterSpacing: '0.015em',
              lineHeight: 1.5
            }}
          >
            {plan.description}
          </Typography>
        )}

        <Box sx={{ flex: 1 }}>
          {plan.features.map((feature) => (
            <Typography 
              key={feature} 
              variant="body2"
              className="feature-item"
              sx={{ 
                mb: 1.5,
                display: 'flex',
                alignItems: 'flex-start',
                gap: 1.5,
                color: '#ffffff',
                position: 'relative'
              }}
            >
              <Box
                className="feature-dot"
                sx={{
                  width: 8,
                  height: 8,
                  borderRadius: '50%',
                  bgcolor: isAnnualPlan ? '#CCAB62' : '#ffffff',
                  flexShrink: 0,
                  mt: 0.7
                }}
              />
              <span>{feature}</span>
            </Typography>
          ))}
        </Box>

        <Button
          variant="contained"
          fullWidth
          onClick={onSubscribe}
          disabled={loading}
          className={`subscribe-button ${isAnnualPlan ? 'featured-button' : ''}`}
          sx={{
            bgcolor: isAnnualPlan ? '#CCAB62' : 'rgba(255,255,255,0.15)',
            color: isAnnualPlan ? '#11311B' : '#ffffff',
            '&:hover': {
              bgcolor: isAnnualPlan ? '#d9b872' : 'rgba(255,255,255,0.25)',
              transform: 'translateY(-2px)',
              boxShadow: isAnnualPlan ? '0 8px 25px rgba(204, 171, 98, 0.4)' : 'none'
            },
            mt: '24px',
            textTransform: 'none',
            fontSize: '0.95rem',
            fontWeight: 600,
            borderRadius: '12px',
            padding: '10px 16px',
            height: '48px',
            transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
            position: 'relative',
          }}
        >
          {loading ? (
            <CircularProgress size={24} color="inherit" />
          ) : (
            <>
              {isAnnualPlan && <span className="button-glow"></span>}
              {plan.buttonText}
            </>
          )}
        </Button>
      </Box>
    </motion.div>
  );
};

export default NightCard; 