import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    h1: {
      fontSize: '2.5rem',
      '@media (min-width:600px)': {
        fontSize: '3.5rem',
      },
    },
    logo: {
      fontFamily: '"Playfair Display", serif',
      fontSize: '2rem',
    },
    logoTold: {
      fontFamily: '"Roboto", sans-serif',
      fontWeight: 600,
      letterSpacing: '0.1em',
    },
    // ... other typography variants
  },
  spacing: 8,
  palette: {
    primary: {
      main: '#0A1929',
      dark: '#061320',
    },
    secondary: {
      main: '#D4AF37',
    },
    background: {
      default: '#FFFFFF',
      paper: '#FFFFFF',
    },
    // ... other colors
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          borderRadius: 4,
        },
      },
    },
    // ... other component overrides
  },
}); 