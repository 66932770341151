import React from 'react';
import styles from '../../styles/modules/aboutHero.module.css';
import PublicNav from '../common/PublicNav';
import { useNavigate } from 'react-router-dom';

const AboutHero = () => {
  const navigate = useNavigate();

  const handleSignIn = () => {
    navigate('/login');
  };

  return (
    <section className={styles.heroSection}>
      <PublicNav />
      <div className={styles.mobileHeroImage}></div>
      <div className={styles.heroBackground}>
        {/* Hero Content */}
        <div className={styles.content}>
          <div className={styles.titleContainer}>
            <h1 className={styles.title}>
              About Us
              <span className={styles.titleDivider}></span>
            </h1>
          </div>
          <p className={styles.description}>
          Welcome to Something Told, a space where sentimentalists can preserve the stories behind their most cherished possessions. We are passionate about giving longevity to the memories that make heirlooms—and future heirlooms—so special. Our mission is to bridge the gap between time, truth, and treasure…
            <br></br>one story at a time.
          </p>
          <button 
            className={styles.getStartedButton}
            onClick={() => navigate('/login')}
          >
            Get Started
          </button>
        </div>
      </div>
    </section>
  );
};

export default AboutHero; 