import { useState, useRef, useEffect } from 'react';
import { styled, alpha } from '@mui/material/styles';
import {
  Box,
  Paper,
  InputBase,
  IconButton,
  Typography,
  MenuItem,
  CircularProgress
} from '@mui/material';
import {
  Search as SearchIcon,
  Clear as ClearIcon
} from '@mui/icons-material';
import PropTypes from 'prop-types';
import { useScrollPosition } from '../../contexts/ScrollPositionContext';

/**
 * Reusable search bar component with recent searches functionality
 * Features:
 * - Keyboard navigation
 * - Recent searches dropdown
 * - Clear functionality
 * - Loading state
 * - Responsive design
 */

const SearchBarWrapper = styled(Paper)(({ theme }) => ({
  padding: '4px 8px',
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.paper,
  boxShadow: 'none',
  border: `1px solid ${alpha(theme.palette.divider, 0.2)}`,
  transition: 'all 0.2s ease',
  '&:hover': {
    borderColor: alpha(theme.palette.primary.main, 0.2),
    boxShadow: `0 2px 8px ${alpha(theme.palette.common.black, 0.05)}`,
  },
  '&:focus-within': {
    borderColor: alpha(theme.palette.primary.main, 0.2),
    boxShadow: `0 3px 10px ${alpha(theme.palette.common.black, 0.07)}`,
    '& input::placeholder': {
      opacity: 0,
      transition: 'opacity 0.1s ease'
    }
  }
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  flex: 1,
  '& .MuiInputBase-input': {
    padding: '10px 12px',
    textAlign: 'center',
    transition: 'all 0.2s ease',
    fontSize: '0.95rem',
    opacity: 0.7,
    '&::placeholder': {
      opacity: 0.7,
      textAlign: 'center',
      transition: 'opacity 0.15s ease'
    },
    '&:focus': {
      textAlign: 'center',
      opacity: 0.7,
      '&::placeholder': {
        opacity: 0
      }
    }
  }
}));

const SearchIconWrapper = styled(IconButton)(({ theme }) => ({
  padding: '8px',
  color: '#C6AB70',
  opacity: 0.4,
  transition: 'transform 0.2s ease, color 0.2s ease',
  '&:hover': {
    backgroundColor: 'transparent',
    transform: 'scale(1.05)',
    color: alpha('#C6AB70', 0.8),
    opacity: 0.7,
  }
}));

const ClearIconWrapper = styled(IconButton)(({ theme }) => ({
  padding: '6px',
  color: theme.palette.text.secondary,
  transition: 'all 0.2s ease',
  opacity: 0.6,
  '&:hover': {
    backgroundColor: alpha(theme.palette.primary.main, 0.05),
    opacity: 1,
  }
}));

const RecentSearchPaper = styled(Paper)(({ theme }) => ({
  position: 'absolute',
  top: '100%',
  left: 0,
  right: 0,
  marginTop: '8px',
  borderRadius: theme.shape.borderRadius,
  zIndex: 1000,
  maxHeight: { xs: '180px', sm: '240px' },
  overflow: 'auto',
  width: '100%',
  boxShadow: '0 6px 20px rgba(0,0,0,0.1)',
  border: `1px solid ${alpha(theme.palette.divider, 0.2)}`,
  '&::-webkit-scrollbar': {
    width: '8px',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: alpha(theme.palette.primary.main, 0.2),
    borderRadius: '4px',
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.3)
    }
  }
}));

const SearchBar = ({
  placeholder = "Search...",
  onSearch,
  searching = false,
  recentSearches = [],
  onClearRecentSearches,
  onSelectRecentSearch,
  minSearchLength = 3,
  searchDelay = 1000
}) => {
  const [localSearchQuery, setLocalSearchQuery] = useState('');
  const [isSearchFocused, setIsSearchFocused] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const { setSearchBarPosition } = useScrollPosition();
  const searchBarRef = useRef(null);

  const handleSearchKeyDown = (e) => {
    if (!isSearchFocused || !recentSearches.length) return;

    switch (e.key) {
      case 'ArrowDown':
        e.preventDefault();
        setSelectedIndex(prev => 
          prev < recentSearches.length - 1 ? prev + 1 : prev
        );
        break;
      case 'ArrowUp':
        e.preventDefault();
        setSelectedIndex(prev => prev > -1 ? prev - 1 : prev);
        break;
      case 'Enter':
        e.preventDefault();
        if (selectedIndex > -1) {
          const selectedSearch = recentSearches[selectedIndex];
          setLocalSearchQuery(selectedSearch);
          onSearch(selectedSearch);
          setIsSearchFocused(false);
        }
        break;
      case 'Escape':
        setIsSearchFocused(false);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (searchBarRef.current) {
      const position = searchBarRef.current.offsetTop;
      setSearchBarPosition(position);
    }
  }, [setSearchBarPosition]);

  return (
    <Box ref={searchBarRef} sx={{ position: 'relative' }}>
      <SearchBarWrapper elevation={0}>
        <SearchIconWrapper 
          size="small"
          disabled={searching}
        >
          {searching ? (
            <CircularProgress size={20} sx={{ color: '#C6AB70' }} />
          ) : (
            <SearchIcon />
          )}
        </SearchIconWrapper>
        
        <StyledInputBase
          placeholder={placeholder}
          inputProps={{ 
            'aria-label': 'search',
            sx: { 
              '&:focus::placeholder': { 
                opacity: 0
              }
            }
          }}
          onChange={(e) => {
            const value = e.target.value;
            setLocalSearchQuery(value);
            onSearch(value);
            setSelectedIndex(-1);
          }}
          onKeyDown={handleSearchKeyDown}
          onFocus={() => {
            setIsSearchFocused(true);
          }}
          onBlur={() => {
            setTimeout(() => setIsSearchFocused(false), 200);
          }}
          value={localSearchQuery}
        />
        
        {localSearchQuery && (
          <ClearIconWrapper
            size="small"
            onClick={() => {
              setLocalSearchQuery('');
              onSearch('');
            }}
          >
            <ClearIcon fontSize="small" />
          </ClearIconWrapper>
        )}
      </SearchBarWrapper>

      {recentSearches.length > 0 && localSearchQuery === '' && isSearchFocused && (
        <RecentSearchPaper elevation={3}>
          <Box
            sx={{
              px: 2,
              py: 1.5,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              borderBottom: '1px solid',
              borderColor: theme => alpha(theme.palette.divider, 0.2)
            }}
          >
            <Typography
              variant="caption"
              sx={{
                color: 'text.secondary',
                fontWeight: 500,
                fontSize: '0.75rem',
                letterSpacing: '0.3px'
              }}
            >
              Recent Searches
            </Typography>
            <Typography
              variant="caption"
              onClick={onClearRecentSearches}
              sx={{
                color: '#C6AB70',
                cursor: 'pointer',
                fontWeight: 500,
                transition: 'all 0.2s ease',
                '&:hover': {
                  color: alpha('#C6AB70', 0.8),
                  textDecoration: 'underline'
                }
              }}
            >
              Clear All
            </Typography>
          </Box>
          {recentSearches.map((search, index) => (
            <MenuItem 
              key={index}
              onClick={() => {
                setLocalSearchQuery(search);
                onSelectRecentSearch(search);
              }}
              selected={index === selectedIndex}
              sx={{
                py: 1.5,
                px: 2,
                display: 'flex',
                alignItems: 'center',
                gap: 1.5,
                transition: 'all 0.15s ease',
                '&:hover': {
                  backgroundColor: theme => alpha(theme.palette.primary.main, 0.05)
                },
                '&.Mui-selected': {
                  backgroundColor: theme => alpha(theme.palette.primary.main, 0.08),
                  '&:hover': {
                    backgroundColor: theme => alpha(theme.palette.primary.main, 0.12)
                  }
                }
              }}
            >
              <SearchIcon 
                sx={{ 
                  opacity: 0.7, 
                  color: '#C6AB70',
                  fontSize: '1rem'
                }} 
              />
              <Typography 
                variant="body2"
                sx={{
                  fontWeight: 400,
                  color: 'text.primary'
                }}
              >
                {search}
              </Typography>
            </MenuItem>
          ))}
        </RecentSearchPaper>
      )}
    </Box>
  );
};

SearchBar.propTypes = {
  placeholder: PropTypes.string,
  onSearch: PropTypes.func.isRequired,
  searching: PropTypes.bool,
  recentSearches: PropTypes.array,
  onClearRecentSearches: PropTypes.func,
  onSelectRecentSearch: PropTypes.func,
  minSearchLength: PropTypes.number,
  searchDelay: PropTypes.number
};

export default SearchBar; 