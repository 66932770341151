import { useAuth } from '../../contexts/AuthContext';
import FirstTimeUserModal from '../FirstTimeUserModal';
import { useSubscription } from '../../hooks/useSubscription';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';

const SubscriptionChecker = ({ children }) => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const { 
    subscriptionStatus, 
    loading: checking,
    error 
  } = useSubscription();
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (!currentUser?.emailVerified) return;
    // If we have subscription status and user is authenticated
    if (subscriptionStatus && currentUser) {
      if (subscriptionStatus.isActive) {
        navigate('/treasurechest');
      } else {
        setShowModal(true);
      }
    } else if (!currentUser) {
      // If no user, don't show modal
      setShowModal(false);
    }
  }, [subscriptionStatus, currentUser?.emailVerified, navigate]);

  if (checking) {
    return null;
  }

  return (
    <>
      {children}
      <FirstTimeUserModal 
        open={showModal} 
        onClose={() => navigate('/treasurechest')}
      />
    </>
  );
};

export default SubscriptionChecker; 