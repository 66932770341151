import React, { useState, useEffect } from 'react';
import { useParams, Link as RouterLink } from 'react-router-dom';
import {
  Container,
  Typography,
  Box,
  IconButton,
  Avatar,
  Breadcrumbs,
  Link as MUILink,
  Grid,
  AppBar,
  Toolbar,
} from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import {
  ArrowBack as ArrowBackIcon,
  Facebook,
  Twitter,
  LinkedIn,
  ContentCopy,
  AccessTime as AccessTimeIcon,
} from '@mui/icons-material';
import axios from 'axios';
import DOMPurify from 'dompurify';
import ProgressiveImage from './ProgressiveImage';
import Logo, { LogoWrapper } from './common/Logo';
import { useToast } from '../contexts/ToastContext';
import Footer from './common/Footer';
import '../styles/public.css';

// Styled components
const NavBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: 'white',
  boxShadow: 'none',
  borderBottom: `1px solid ${theme.palette.divider}`,
}));

const SocialShareButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.grey[100],
  marginRight: theme.spacing(1),
  '&:hover': {
    backgroundColor: theme.palette.grey[200],
  },
}));

const BlogContent = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4),
  maxWidth: '900px',
  margin: '0 auto',
  '& img': {
    maxWidth: '600px', // Maximum width for large screens
    maxHeight: '600px', // Maximum height for any image
    width: 'auto', // Allow image to be smaller than maxWidth
    height: 'auto', // Maintain aspect ratio
    display: 'block',
    margin: '24px auto',
    borderRadius: theme.shape.borderRadius,
    boxShadow: '0 2px 12px rgba(0,0,0,0.1)',
    [theme.breakpoints.down('md')]: {
      maxWidth: '100%', // Full container width on smaller screens
      maxHeight: '500px', // Slightly smaller max height on tablets
    },
    [theme.breakpoints.down('sm')]: {
      maxHeight: '400px', // Even smaller max height on mobile
    },
    objectFit: 'contain', // Ensures entire image is visible within bounds
  },
  '& pre': {
    backgroundColor: alpha(theme.palette.primary.main, 0.04),
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    overflow: 'auto',
    margin: theme.spacing(2, 0),
    border: `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
    '& code': {
      fontFamily: 'monospace',
      whiteSpace: 'pre-wrap',
      wordBreak: 'break-word',
    },
  },
  '& code': {
    backgroundColor: alpha(theme.palette.primary.main, 0.04),
    padding: theme.spacing(0.5, 1),
    borderRadius: '4px',
    fontSize: '0.9em',
    fontFamily: 'monospace',
    color: theme.palette.primary.main,
    wordBreak: 'break-word',
  },
  '& ul, & ol': {
    paddingLeft: theme.spacing(4),
    marginBottom: theme.spacing(3),
    '& li': {
      marginBottom: theme.spacing(1),
      '&::marker': {
        color: theme.palette.primary.main,
      },
    },
  },
  '& blockquote': {
    margin: theme.spacing(3, 0),
    padding: theme.spacing(2, 3),
    borderLeft: `4px solid ${theme.palette.primary.main}`,
    backgroundColor: alpha(theme.palette.primary.main, 0.04),
    borderRadius: theme.shape.borderRadius,
    '& p': {
      margin: 0,
      fontSize: '1.1rem',
      fontStyle: 'italic',
    },
  },
  '& p, & h1, & h2, & h3, & h4, & h5, & h6': {
    maxWidth: '100%',
    wordWrap: 'break-word',
    overflowWrap: 'break-word',
  },
}));

const ArticleContainer = styled(Box)(({ theme }) => ({
  backgroundColor: '#fff',
  borderRadius: theme.shape.borderRadius * 2,
  boxShadow: '0 1px 3px rgba(0,0,0,0.02)',
  padding: theme.spacing(4),
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2),
  }
}));

const HeroSection = styled(Box)(({ theme }) => ({
  position: 'relative',
  backgroundColor: theme.palette.grey[50],
  borderRadius: theme.shape.borderRadius * 2,
  overflow: 'hidden',
  marginBottom: theme.spacing(4),
}));

const ArticleHeader = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(4),
  '& h1': {
    fontSize: '2.5rem',
    fontWeight: 700,
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      fontSize: '2rem',
    }
  }
}));

const AuthorSection = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),
  padding: theme.spacing(3),
  backgroundColor: theme.palette.grey[50],
  borderRadius: theme.shape.borderRadius,
  marginBottom: theme.spacing(4),
}));

const ArticleDetail = () => {
  const { slug } = useParams();
  const [article, setArticle] = useState(null);
  const [loading, setLoading] = useState(true);
  const { showToast } = useToast();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`/api/articles/${slug}`);
        setArticle(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error:', error);
        setLoading(false);
      }
    };

    fetchData();
  }, [slug]);

  useEffect(() => {
    document.body.classList.add('public-page');
    return () => document.body.classList.remove('public-page');
  }, []);

  const handleShare = async (platform) => {
    const url = window.location.href;
    const text = article?.title;

    const shareUrls = {
      facebook: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`,
      twitter: `https://twitter.com/intent/tweet?url=${encodeURIComponent(url)}&text=${encodeURIComponent(text)}`,
      linkedin: `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(url)}`,
    };

    if (platform === 'copy') {
      try {
        await navigator.clipboard.writeText(url);
        showToast('Link copied to clipboard!', 'success');
      } catch (err) {
        showToast('Failed to copy link', 'error');
      }
      return;
    }

    window.open(shareUrls[platform], '_blank', 'width=600,height=400');
  };

  if (loading) {
    return (
      <Box sx={{ height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Typography>Loading...</Typography>
      </Box>
    );
  }

  return (
    <div className="public-page">
      <div className="public-page-container">
        <div className="public-page-content">
          <NavBar position="static">
            <Toolbar>
              <IconButton 
                component={RouterLink} 
                to="/articles" 
                sx={{ 
                  color: 'text.primary',
                  '&:hover': {
                    backgroundColor: 'action.hover',
                  }
                }}
              >
                <ArrowBackIcon />
              </IconButton>
              <LogoWrapper>
                <Logo size="1.75rem" />
              </LogoWrapper>
            </Toolbar>
          </NavBar>

          <Container maxWidth="lg" sx={{ py: { xs: 2, sm: 4 } }}>
            <Breadcrumbs 
              sx={{ 
                mb: 3,
                '& .MuiLink-root': {
                  color: 'text.secondary',
                  '&:hover': {
                    color: 'primary.main',
                  }
                }
              }}
            >
              <MUILink component={RouterLink} to="/">Home</MUILink>
              <MUILink component={RouterLink} to="/articles">Articles</MUILink>
              <Typography color="text.primary">{article?.title}</Typography>
            </Breadcrumbs>

            {article && (
              <ArticleContainer>
                <HeroSection>
                  <ProgressiveImage
                    src={article.image_url}
                    alt={article.title}
                    style={{
                      width: '100%',
                      height: '400px',
                      objectFit: 'cover',
                    }}
                  />
                </HeroSection>

                <ArticleHeader>
                  <Typography variant="h1">{article.title}</Typography>
                  <AuthorSection>
                    <Avatar 
                      src={article.author_photo} 
                      alt={article.author}
                      sx={{ 
                        width: 56, 
                        height: 56,
                        border: '2px solid white',
                        boxShadow: '0 2px 8px rgba(0,0,0,0.1)'
                      }}
                    />
                    <Box>
                      <Typography 
                        variant="subtitle1" 
                        sx={{ 
                          fontWeight: 600,
                          color: 'text.primary'
                        }}
                      >
                        {article.author_name || article.author}
                      </Typography>
                      <Typography 
                        variant="body2" 
                        sx={{ 
                          color: 'text.secondary',
                          display: 'flex',
                          alignItems: 'center',
                          gap: 1
                        }}
                      >
                        <AccessTimeIcon sx={{ fontSize: 16 }} />
                        {article.read_time} • {new Date(article.date).toLocaleDateString()}
                      </Typography>
                    </Box>
                  </AuthorSection>

                  <BlogContent
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(article.description),
                    }}
                  />

                  <Box sx={{ 
                    mt: 6, 
                    pt: 4, 
                    borderTop: '1px solid',
                    borderColor: 'divider'
                  }}>
                    <Typography 
                      variant="subtitle2" 
                      gutterBottom
                      sx={{ 
                        color: 'text.secondary',
                        mb: 2
                      }}
                    >
                      Share this article
                    </Typography>
                    <Box sx={{ display: 'flex', gap: 1 }}>
                      <SocialShareButton onClick={() => handleShare('facebook')}>
                        <Facebook />
                      </SocialShareButton>
                      <SocialShareButton onClick={() => handleShare('twitter')}>
                        <Twitter />
                      </SocialShareButton>
                      <SocialShareButton onClick={() => handleShare('linkedin')}>
                        <LinkedIn />
                      </SocialShareButton>
                      <SocialShareButton onClick={() => handleShare('copy')}>
                        <ContentCopy />
                      </SocialShareButton>
                    </Box>
                  </Box>
                </ArticleHeader>
              </ArticleContainer>
            )}
          </Container>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default ArticleDetail;
