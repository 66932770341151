import { useState, useRef } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Grid,
  Button,
  Typography,
  Box,
  Select,
  MenuItem,
  FormControl,
  IconButton
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import TreasurePreview from './TreasurePreview';
import * as Yup from 'yup';
import { Close as CloseIcon, DeleteOutline as DeleteOutlineIcon } from '@mui/icons-material';
import ConfirmDialog from './common/ConfirmDialog';
import ImageUpload from './common/ImageUpload';
import VideoUpload from './common/VideoUpload';
import { useMediaQuery } from '@mui/material';
import LogoIcon from '../assets/ST_Logo_Gold-on-Gren.svg';

const LogoContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '2px',
  justifyContent: 'center',
}));

const SomethingText = styled(Typography)(({ theme }) => ({
  fontFamily: '"Playfair Display", serif',
  fontStyle: 'italic',
  color: theme.palette.primary.main,
  fontSize: '2rem',
  lineHeight: 1,
}));

const ToldText = styled(Typography)(({ theme }) => ({
  fontFamily: '"Roboto", sans-serif',
  color: theme.palette.secondary.main,
  fontSize: '2rem',
  fontWeight: 600,
  marginTop: '-4px',
  lineHeight: 1,
}));

const PreviewContainer = styled(Box)(({ theme }) => ({
  width: '80px',
  height: '80px',
  position: 'relative',
  borderRadius: '12px',
  overflow: 'hidden',
  backgroundColor: theme.palette.background.paper,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  border: `1px dashed ${theme.palette.divider}`,
}));

const DialogContentStyled = styled(DialogContent)(({ theme }) => ({
  padding: theme.spacing(2, 3),
  [theme.breakpoints.down('sm')]: {
    padding: 0,
    overflowY: 'visible'
  },
  '&::-webkit-scrollbar': {
    width: '8px',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: theme.palette.divider,
    borderRadius: '4px',
  }
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: '12px',
    transition: 'all 0.2s ease-in-out',
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
      transform: 'translateY(-1px)',
      boxShadow: '0 4px 12px rgba(0,0,0,0.05)',
    },
    '&.Mui-focused': {
      backgroundColor: theme.palette.background.paper,
      transform: 'translateY(-1px)',
      boxShadow: '0 4px 16px rgba(0,0,0,0.08)',
    }
  },
  '& .MuiInputBase-input': {
    color: theme.palette.text.secondary,
    fontSize: { xs: '0.875rem', sm: '0.95rem' },
    lineHeight: 1.8,
    letterSpacing: '0.015em',
  },
  '& .MuiInputLabel-root': {
    color: theme.palette.text.secondary,
    fontSize: '0.9rem',
    '&.Mui-focused': {
      color: theme.palette.text.secondary,
    }
  },
  '& .MuiInputBase-input::placeholder': {
    color: theme.palette.text.disabled,
    opacity: 0.8,
  },
  '& .MuiSelect-select': {
    color: theme.palette.text.secondary,
  }
}));

const StyledSelect = styled(Select)(({ theme }) => ({
  borderRadius: '12px',
  fontSize: '0.875rem',
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.divider,
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.text.primary,
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderWidth: '2px',
  },
  '& .MuiSelect-select.Mui-placeholder': {
    color: 'rgba(0, 0, 0, 0.6)'
  }
}));

// NEW: ContentContainer gives the modal form a clean, card-like background (Apple-inspired)
const ContentContainer = styled(Box)(({ theme }) => ({
  backgroundColor: '#ffffff',
  padding: theme.spacing(4),
  borderRadius: 16,
  height: 'auto',
  boxShadow: '0 4px 12px rgba(0,0,0,0.08)',
  margin: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2),
    margin: 0,
    borderRadius: 0,
    boxShadow: 'none',
    width: '100%'
  }
}));

const AddTreasureModal = ({ open, onClose, onAddTreasure }) => {
  const fileInputRef = useRef(null);
  const videoInputRef = useRef(null);
  const isMobile = useMediaQuery('(max-width:600px)');
  const theme = useTheme();

  const [formData, setFormData] = useState({
    name: '',
    from: '',
    category: '',
    month: '',
    day: '',
    year: '',
    story: '',
    imageUrl: '',        // Will store main content URL (image or video)
    imageBlob: null,     // Will store main content blob
    thumbnailUrl: null,  // Will store video thumbnail URL
    thumbnailBlob: null, // Will store video thumbnail blob
    isVideo: false       // Flag for content type
  });
  const [showPreview, setShowPreview] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [errors, setErrors] = useState({
    name: '',
    from: '',
    category: '',
    date: '',
    image: ''
  });
  const [goingToPreview, setGoingToPreview] = useState(false);
  const [returningFromPreview, setReturningFromPreview] = useState(false);

  const categories = [
    "All Items",
    "Jewelry & Watches",
    "Art & Collectibles",
    "Books & Manuscripts",
    "Clothing & Accessories",
    "Electronics & Gadgets",
    "Family Heirlooms",
    "Furniture & Decor",
    "Musical Instruments",
    "Photography",
    "Sports Memorabilia",
    "Toys & Games",
    "Other"
  ];
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const days = Array.from({ length: 31 }, (_, i) => i + 1);
  const years = Array.from({ length: 150 }, (_, i) => new Date().getFullYear() - i);

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required('Name is required')
      .min(2, 'Name must be at least 2 characters')
      .max(33, 'Name must be less than 33 characters')
      .matches(/^[a-zA-Z0-9\s]*$/, 'Only letters, numbers and spaces are allowed'),
    
    from: Yup.string()
      .required('From field is required')
      .max(50, 'From must be less than 50 characters')
      .matches(/^[a-zA-Z0-9\s]*$/, 'Only letters, numbers and spaces are allowed'),
    
    category: Yup.string()
      .required('Category is required')
      .oneOf([
        "Jewelry & Watches",
        "Art & Collectibles",
        "Books & Manuscripts",
        "Clothing & Accessories",
        "Electronics & Gadgets",
        "Family Heirlooms",
        "Furniture & Decor",
        "Musical Instruments",
        "Photography",
        "Sports Memorabilia",
        "Toys & Games",
        "Other"
      ], 'Please select a valid category'),
  });

  const validateField = (field, value) => {
    try {
      validationSchema.fields[field]?.validateSync(value);
      return '';
    } catch (error) {
      return error.message;
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    
    // Don't sanitize category since it's from a predefined dropdown
    if (name === 'category') {
      setFormData(prev => ({
        ...prev,
        [name]: value
      }));
      return;
    }

    // Sanitize other text inputs
    const sanitizedValue = value.replace(/[^a-zA-Z0-9\s]/g, '');
    setFormData(prev => ({
      ...prev,
      [name]: sanitizedValue
    }));
  };

  const handleDateChange = (field) => (event) => {
    setFormData(prev => ({
      ...prev,
      [field]: event.target.value
    }));
  };

  const handleInitialSubmit = async () => {
    setErrors({
      name: '',
      from: '',
      category: '',
      date: '',
      image: ''
    });

    // Validate name length first
    try {
      await validationSchema.fields.name.validate(formData.name);
    } catch (err) {
      setErrors(prev => ({ ...prev, name: err.message }));
      return;
    }

    if (!formData.name.trim()) {
      setErrors(prev => ({ ...prev, name: 'Please enter a treasure name' }));
      return;
    }

    // Validate from field
    try {
      await validationSchema.fields.from.validate(formData.from);
    } catch (err) {
      setErrors(prev => ({ ...prev, from: err.message }));
      return;
    }

    if (!formData.from.trim()) {
      setErrors(prev => ({ ...prev, from: 'Please specify who this treasure is from' }));
      return;
    }

    // Validate category
    try {
      await validationSchema.fields.category.validate(formData.category);
    } catch (err) {
      setErrors(prev => ({ ...prev, category: err.message }));
      return;
    }

    if (!formData.category) {
      setErrors(prev => ({ ...prev, category: 'Please select a category' }));
      return;
    }

    if (!formData.month || !formData.year) {
      setErrors(prev => ({ ...prev, date: 'Please select Month and Year' }));
      return;
    }

    if (!formData.imageUrl || !formData.imageBlob) {
      setErrors(prev => ({ ...prev, image: 'Please upload an image of your treasure' }));
      return;
    }

    setShowPreview(true);
  };

  const handleFinalSubmit = async (finalData) => {
    try {
      await onAddTreasure(finalData);
      onClose();
      setShowPreview(false);
      setFormData({
        name: '',
        from: '',
        category: '',
        month: '',
        day: '',
        year: '',
        story: '',
        imageUrl: '',
        imageBlob: null,
        thumbnailUrl: null,
        thumbnailBlob: null,
        isVideo: false
      });
    } catch (error) {
      console.error('Error adding treasure:', error);
    }
  };

  const cleanupForm = () => {
    if (formData.imageUrl) URL.revokeObjectURL(formData.imageUrl);
    if (formData.thumbnailUrl) URL.revokeObjectURL(formData.thumbnailUrl);
    
    setFormData({
      name: '',
      from: '',
      category: '',
      month: '',
      day: '',
      year: '',
      story: '',
      imageUrl: '',
      imageBlob: null,
      thumbnailUrl: null,
      thumbnailBlob: null,
      isVideo: false
    });
    setShowPreview(false);
  };

  const hasUnsavedData = () => {
    return formData.name || formData.from || formData.category || 
           formData.month || formData.day || formData.year || 
           formData.story || formData.imageUrl;
  };

  const handleClose = () => {
    if (hasUnsavedData()) {
      setShowConfirmDialog(true);
    } else {
      cleanupForm();
      onClose();
    }
  };

  const handleConfirmClose = () => {
    setShowConfirmDialog(false);
    cleanupForm();
    onClose();
  };

  const handleUploadComplete = (contentUrl, contentBlob, thumbnailUrl = null, thumbnailBlob = null, isVideo = false) => {
    setFormData(prev => ({
      ...prev,
      imageUrl: contentUrl,                    // Video URL or image URL
      imageBlob: contentBlob,                  // Video blob or image blob
      thumbnailUrl: isVideo ? thumbnailUrl : null,     // Store thumbnail URL for preview
      thumbnailBlob: isVideo ? thumbnailBlob : null,   // Store thumbnail blob for upload
      isVideo: isVideo
    }));
  };

  const handleUploadError = (errorMessage) => {
    // Handle error (maybe show in UI)
    console.error(errorMessage);
  };

  const handleDeleteImage = () => {
    if (formData.imageUrl) URL.revokeObjectURL(formData.imageUrl);
    if (formData.thumbnailUrl) URL.revokeObjectURL(formData.thumbnailUrl);

    setFormData(prev => ({
      ...prev,
      imageUrl: '',
      imageBlob: null,
      thumbnailUrl: null,
      thumbnailBlob: null,
      isVideo: false
    }));

    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
    if (videoInputRef.current) {
      videoInputRef.current.value = '';
    }
  };

  const handleNext = () => {
    if (!formData.month || !formData.year) {
      showToast('Please select Month and Year', 'error');
      return;
    }
    // Rest of your handleNext code...
  };

  const handlePreview = () => {
    setGoingToPreview(true);
    // ... rest of preview handler
  };

  const handleReturnFromPreview = () => {
    setReturningFromPreview(true);
    setShowPreview(false);
    // Reset after animation completes
    setTimeout(() => {
      setReturningFromPreview(false);
    }, 400);
  };

  return (
    <>
      <Dialog 
        open={open}
        onClose={handleClose}
        maxWidth="sm"
        fullWidth
        fullScreen={isMobile}
        TransitionProps={{
          timeout: {
            enter: 300,
            exit: 400
          },
          onExiting: () => {
            // Only animate out if we're moving to preview
            if (goingToPreview) {
              const dialog = document.querySelector('.MuiDialog-paper');
              if (dialog) {
                dialog.style.animation = 'slideOut 400ms ease-in forwards';
              }
            }
          },
          onEntering: () => {
            // Animate in when returning from preview
            if (returningFromPreview) {
              const dialog = document.querySelector('.MuiDialog-paper');
              if (dialog) {
                dialog.style.animation = 'slideInUp 400ms ease-out forwards';
              }
            }
          }
        }}
        PaperProps={{
          sx: {
            borderRadius: isMobile ? 0 : '16px',
            boxShadow: isMobile ? 'none' : '0 8px 32px rgba(0,0,0,0.08)',
            backgroundImage: 'none',
            position: isMobile ? 'relative' : 'fixed',
            height: 'auto',
            overflowY: isMobile ? 'visible' : 'auto',
            ...((!isMobile) && {
              maxHeight: '90vh',
            }),
            '&.MuiDialog-paper': {
              animation: isMobile ? 'none' : 'slideIn 300ms ease-out'
            }
          }
        }}
      >
        <DialogTitle 
          id="add-treasure-title" 
          sx={{ 
            p: 2,
            backgroundColor: 'rgba(255,255,255,0.8)', // Translucent white background for an Apple feel
            backdropFilter: 'blur(10px)',             // iOS-style blur effect
            textAlign: 'center',
            borderRadius: { xs: 0, sm: '8px 8px 0 0' }  // Rounded top corners on non-mobile
          }}
        >
          <Box sx={{ 
            display: 'flex', 
            flexDirection: 'column',  // Stack items vertically
            alignItems: 'center',     // Center horizontally
            width: '100%',           // Take full width
            position: 'relative',
            pb: 1,
          }}>
            {/* Logo and Title Container */}
            <Box sx={{ 
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              mb: 1
            }}>
              <img 
                src={LogoIcon} 
                alt="Something Told Logo" 
                style={{
                  width: '100px',
                  height: '100px',
                  display: 'block',
                  margin: '0 auto'
                }}
              />
              <Typography 
                variant="h5" 
                sx={{ 
                  mt: 1.5,
                  fontWeight: 600,
                  textAlign: 'center'
                }}
              >
                Add a Treasure
              </Typography>
            </Box>

            {/* Close Button - Absolute positioned */}
            <IconButton 
              onClick={handleClose}
              sx={{ 
                position: 'absolute',
                right: 0,
                top: 0,
                color: 'text.secondary',
                '&:hover': {
                  backgroundColor: 'action.hover',
                  transform: 'scale(1.1)',
                  transition: 'transform 0.2s'
                }
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>

        <DialogContentStyled>
          <ContentContainer>
            <Box component="form" sx={{ mt: 0.5 }}>
              <StyledTextField
                autoFocus
                required
                fullWidth
                id="name"
                name="name"
                label="Treasure Name"
                value={formData.name}
                onChange={handleChange}
                margin="dense"
                variant="outlined"
                InputProps={{
                  readOnly: false
                }}
                error={Boolean(errors.name)}
                helperText={errors.name}
                onBlur={(e) => validateField('name', e.target.value)}
                sx={{
                  '& .MuiInputBase-input': {
                    fontSize: '1rem',
                    padding: '12px 14px',
                  }
                }}
              />
              
              <StyledTextField
                required
                fullWidth
                id="from"
                name="from"
                label="From"
                placeholder="Person or Place"
                value={formData.from}
                onChange={handleChange}
                margin="dense"
                variant="outlined"
                InputProps={{
                  readOnly: false
                }}
                error={Boolean(errors.from)}
                helperText={errors.from}
                onBlur={(e) => validateField('from', e.target.value)}
                sx={{
                  '& .MuiInputBase-input': {
                    fontSize: '1rem',
                    padding: '12px 14px',
                  }
                }}
              />

              <FormControl 
                fullWidth 
                margin="dense"
                error={Boolean(errors.category)}
              >
                <StyledSelect
                  name="category"
                  value={formData.category}
                  onChange={handleChange}
                  displayEmpty
                  renderValue={(selected) => (
                    <span style={{ color: selected ? 'inherit' : 'rgba(0, 0, 0, 0.6)' }}>
                      {selected || "Select a category"}
                    </span>
                  )}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        maxHeight: 300
                      }
                    }
                  }}
                  error={Boolean(errors.category)}
                  sx={{ width: '100%' }}
                >
                  {categories.filter(cat => cat !== "All Items").map((category) => (
                    <MenuItem key={category} value={category}>
                      {category}
                    </MenuItem>
                  ))}
                </StyledSelect>
                {errors.category && (
                  <Typography color="error" variant="caption" sx={{ mt: 0.5, ml: 1 }}>
                    {errors.category}
                  </Typography>
                )}
              </FormControl>

              <Typography 
                variant="subtitle2" 
                sx={{ 
                  mt: 2,
                  mb: 1,
                  fontWeight: 600,
                  color: 'text.primary'
                }}
              >
                Date Received
              </Typography>

              <Grid container spacing={1.5} sx={{ mb: 2 }}>
                <Grid item xs={4}>
                  <StyledSelect
                    value={formData.month}
                    onChange={handleDateChange('month')}
                    displayEmpty
                    renderValue={(selected) => (
                      <span style={{ color: selected ? 'inherit' : 'rgba(0, 0, 0, 0.6)' }}>
                        {selected || "Month"}
                      </span>
                    )}
                    sx={{ width: '100%' }}
                  >
                    {months.map((month) => (
                      <MenuItem key={month} value={month}>{month}</MenuItem>
                    ))}
                  </StyledSelect>
                </Grid>
                <Grid item xs={4}>
                  <StyledSelect
                    value={formData.day || ''}
                    onChange={handleDateChange('day')}
                    displayEmpty
                    renderValue={(selected) => (
                      <span style={{ color: selected ? 'inherit' : 'rgba(0, 0, 0, 0.6)' }}>
                        {selected || "Day"}
                      </span>
                    )}
                    sx={{ width: '100%' }}
                  >
                    {days.map((day) => (
                      <MenuItem key={day} value={day}>{day}</MenuItem>
                    ))}
                  </StyledSelect>
                </Grid>
                <Grid item xs={4}>
                  <StyledSelect
                    value={formData.year}
                    onChange={handleDateChange('year')}
                    displayEmpty
                    renderValue={(selected) => (
                      <span style={{ color: selected ? 'inherit' : 'rgba(0, 0, 0, 0.6)' }}>
                        {selected || "Year"}
                      </span>
                    )}
                    sx={{ width: '100%' }}
                  >
                    {years.map((year) => (
                      <MenuItem key={year} value={year}>{year}</MenuItem>
                    ))}
                  </StyledSelect>
                </Grid>
              </Grid>
              {errors.date && (
                <Typography color="error" variant="caption" sx={{ mt: 0.5, ml: 1 }}>
                  {errors.date}
                </Typography>
              )}

              <Box sx={{ 
                mt: 1,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'transparent',
                transition: 'all 0.2s ease-in-out',
              }}>
                {formData.imageUrl ? (
                  <Box sx={{ 
                    position: 'relative',
                    width: 'fit-content'
                  }}>
                    <PreviewContainer>
                      <img
                        src={formData.isVideo ? formData.thumbnailUrl : formData.imageUrl}
                        alt={formData.name}
                        style={{
                          width: '100%',
                          height: '100%',
                          objectFit: 'cover'
                        }}
                      />
                    </PreviewContainer>
                    <IconButton 
                      onClick={handleDeleteImage}
                      sx={{ 
                        position: 'absolute',
                        top: -8,
                        right: -8,
                        backgroundColor: 'rgba(255, 255, 255, 0.9)',
                        boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
                        '&:hover': {
                          backgroundColor: 'rgba(255, 255, 255, 1)',
                          color: 'error.main',
                        }
                      }}
                    >
                      <DeleteOutlineIcon fontSize="small" />
                    </IconButton>
                  </Box>
                ) : (
                  <Box sx={{ 
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: 2,
                    width: '100%',
                    my: 1
                  }}>
                    <ImageUpload
                      ref={fileInputRef}
                      onUploadComplete={handleUploadComplete}
                      onUploadError={handleUploadError}
                    />
                    <VideoUpload
                      ref={videoInputRef}
                      onUploadComplete={handleUploadComplete}
                      onUploadError={handleUploadError}
                    />
                  </Box>
                )}
              </Box>

              <Button
                fullWidth
                variant="contained"
                onClick={handleInitialSubmit}
                sx={{ 
                  mt: 1,
                  py: 1.25,
                  borderRadius: '12px',
                  fontSize: '1rem',
                  fontWeight: 600,
                  textTransform: 'none',
                  bgcolor: '#11311B',
                  '&:hover': {
                    bgcolor: '#11311B',
                    opacity: 0.9,
                  },
                  boxShadow: '0 4px 16px rgba(0,0,0,0.1)',
                  transition: 'all 0.2s ease-in-out'
                }}
              >
                Add a story
              </Button>
            </Box>
          </ContentContainer>
        </DialogContentStyled>
      </Dialog>

      <TreasurePreview
        open={showPreview}
        onClose={(completeCleanup) => {
          if (completeCleanup) {
            cleanupForm();
            onClose();
          }
          setShowPreview(false);
        }}
        onSave={handleFinalSubmit}
        treasureData={formData}
      />

      <ConfirmDialog
        open={showConfirmDialog}
        onClose={() => setShowConfirmDialog(false)}
        onConfirm={handleConfirmClose}
      />
    </>
  );
};

export default AddTreasureModal; 