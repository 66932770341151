import { createContext, useContext, useState, useEffect, useCallback, useRef } from 'react';
import { auth } from '../firebase';
import { onAuthStateChanged, signOut as firebaseSignOut, sendEmailVerification } from 'firebase/auth';
import api from '../services/api';
import { debounce } from 'lodash';

export const AuthContext = createContext(null);

const SUBSCRIPTION_REFRESH_INTERVAL = 30 * 60 * 1000; // 30 minutes
const FETCH_COOLDOWN = 5000; // 5 seconds between forced refreshes
const TOKEN_REFRESH_INTERVAL = 45 * 60 * 1000; // 45 minutes (tokens expire at 1 hour)

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export const AuthProvider = ({ children }) => {
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [token, setToken] = useState(null);
  const [subscriptionStatus, setSubscriptionStatus] = useState(null);
  const [isInitialized, setIsInitialized] = useState(false);
  const fetchInProgress = useRef(false);
  const lastFetchTimestamp = useRef(0);
  const [currentUser, setCurrentUser] = useState(null);
  const timeoutRef = useRef(null);
  const isCheckingSubscription = useRef(false);
  const [userRole, setUserRole] = useState(null);

  const checkIsAuthPage = () => {
    return false;
  };

  const checkAndUpdateUserData = useCallback(async (forceRefresh = false) => {
    if (!forceRefresh && checkIsAuthPage()) return null;
    if (!auth.currentUser) return null;
    if (!auth.currentUser.emailVerified) {
      showToast("Verification required");
      signOut();
      return null;
    }

    const now = Date.now();
    
    if (isCheckingSubscription.current && !forceRefresh) {
      return userData;
    }

    try {
      isCheckingSubscription.current = true;

      if (!forceRefresh) {
        const cachedData = localStorage.getItem('userData');
        if (cachedData) {
          const { data, timestamp } = JSON.parse(cachedData);
          const cacheAge = now - timestamp;
          
          if (cacheAge < SUBSCRIPTION_REFRESH_INTERVAL) {
            setUserData(data);
            setSubscriptionStatus(data.subscription);
            setUserRole(data.profile.role);
            return data;
          }
        }
      }

      if (!forceRefresh && now - lastFetchTimestamp.current < FETCH_COOLDOWN) {
        return userData;
      }

      const currentUser = auth.currentUser;
      if (!currentUser) {
        setUserData(null);
        setCurrentUser(null);
        localStorage.removeItem('userData');
        return null;
      }

      fetchInProgress.current = true;
      lastFetchTimestamp.current = now;

      const token = await currentUser.getIdToken();
      const response = await api.get('/api/users/profile', {
        headers: { Authorization: `Bearer ${token}` }
      });

      const processedUserData = {
        ...response.data,
        profile: {
          ...response.data.profile,
          state: response.data.profile.state || '',
          birthDate: response.data.profile.birthDate || null,
          lastChecked: now
        },
        subscription: {
          ...response.data.subscription,
          lastChecked: now
        }
      };

      const cacheData = {
        data: processedUserData,
        timestamp: now
      };
      localStorage.setItem('userData', JSON.stringify(cacheData));

      setUserData(processedUserData);
      setCurrentUser(currentUser);
      setToken(token);
      setSubscriptionStatus(processedUserData.subscription);
      setUserRole(processedUserData.profile.role);

      return processedUserData;
    } finally {
      isCheckingSubscription.current = false;
      fetchInProgress.current = false;
      setLoading(false);
    }
  }, [userData]);

  useEffect(() => {
    if (checkIsAuthPage()) return;

    const checkSubscription = async () => {
      if (!auth.currentUser || !auth.currentUser.emailVerified || isCheckingSubscription.current) return;
      await checkAndUpdateUserData(false);
    };

    // Initial check
    checkSubscription();
    
    // Set up interval with cleanup
    const intervalId = setInterval(checkSubscription, SUBSCRIPTION_REFRESH_INTERVAL);
    
    return () => {
      clearInterval(intervalId);
    };
  }, []); // Empty dependency array

  // Login function
  const login = useCallback(async (initialUserData, token) => {
    const user = auth.currentUser;
    
    if (user && !user.emailVerified) {
      await sendEmailVerification(user);
      await signOut();
      throw new Error('New verification email sent');
    }
    
    setToken(token);
    return checkAndUpdateUserData(true);
  }, [checkAndUpdateUserData]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      try {
        console.log("Auth state changed:", user); // Add this debug line
        if (user && !checkIsAuthPage()) {
          setCurrentUser(user);
          await checkAndUpdateUserData();
        } else {
          setCurrentUser(null);
          setUserData(null);
          localStorage.removeItem('userData');
        }
      } catch (error) {
        console.error('Auth state change error:', error);
      } finally {
        setLoading(false);
        setIsInitialized(true);
      }
    });

    return () => unsubscribe();
  }, []);

  const signOut = async () => {
    try {
      await firebaseSignOut(auth);
      setCurrentUser(null);
      setUserData(null);
      setToken(null);
      setSubscriptionStatus(null);
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const refreshToken = async () => {
    const user = auth.currentUser;
    if (user) {
      const newToken = await user.getIdToken(true);
      setToken(newToken);
      return newToken;
    }
    return null;
  };

  const updateProfileData = (updates) => {
    setUserData(prev => ({
      ...prev,
      profile: {
        ...prev.profile,
        ...updates
      }
    }));

    const cachedData = localStorage.getItem('userData');
    if (cachedData) {
      const parsedCache = JSON.parse(cachedData);
      const updatedCache = {
        ...parsedCache,
        data: {
          ...parsedCache.data,
          profile: {
            ...parsedCache.data.profile,
            ...updates
          }
        }
      };
      localStorage.setItem('userData', JSON.stringify(updatedCache));
    }
  };

  // Add a function to resend verification email
  const resendVerificationEmail = useCallback(async () => {
    if (auth.currentUser) {
      await sendEmailVerification(auth.currentUser);
    }
  }, []);

  const refreshSubscription = useCallback(async (force = false) => {
    if (!force && Date.now() - lastFetchTimestamp.current < FETCH_COOLDOWN) {
      return userData?.subscription;
    }
    try {
      console.log('Refreshing subscription with force =', force);
      // Add timestamp to avoid caching issues
      const response = await api.get(`/api/users/subscription?cacheBust=${Date.now()}`);
      const data = response.data;
      
      console.log('Received subscription data:', data);
      
      // Get current userData to update
      const updatedSubscription = {
        ...data,
        isActive: ['active', 'trial'].includes(data.subscription_status),
        status: data.subscription_status,
        endDate: data.subscription_end_date,
        trialEndDate: data.trial_end_date,
        lastChecked: Date.now()
      };
      
      // Update state
      const updatedUserData = {
        ...userData,
        subscription: updatedSubscription
      };
      
      // Update local state
      setUserData(updatedUserData);
      setSubscriptionStatus(updatedSubscription);
      
      // Update cache
      const cacheData = {
        data: updatedUserData,
        timestamp: Date.now()
      };
      localStorage.setItem('userData', JSON.stringify(cacheData));
      
      console.log('Updated subscription status:', updatedSubscription);
      
      // Return the updated subscription data
      return updatedSubscription;
    } catch (error) {
      console.error('Error refreshing subscription:', error);
      return userData?.subscription;
    }
  }, [userData]);

  const value = {
    userData,
    token,
    loading,
    currentUser,
    signOut: async () => {
      try {
        await firebaseSignOut(auth);
        setCurrentUser(null);
        setUserData(null);
        setToken(null);
        setSubscriptionStatus(null);
      } catch (error) {
        console.error('Error signing out:', error);
      }
    },
    refreshToken,
    login,
    isAuthenticated: !!userData && !!currentUser,
    subscriptionStatus,
    refreshSubscription,
    userRole,
    updateProfileData,
    resendVerificationEmail,
    isEmailVerified: currentUser?.emailVerified || false,
  };

  useEffect(() => {
    if (!currentUser) return;
    
    const refreshTokenPeriodically = async () => {
      try {
        const newToken = await refreshToken();
        if (newToken) {
          // Update token in memory and localStorage
          setToken(newToken);
          await checkAndUpdateUserData(true); // Force refresh user data with new token
        }
      } catch (error) {
        console.error('Token refresh failed:', error);
        // If refresh fails, sign out user
        await signOut();
      }
    };

    const interval = setInterval(refreshTokenPeriodically, TOKEN_REFRESH_INTERVAL);
    
    // Initial token refresh
    refreshTokenPeriodically();

    return () => clearInterval(interval);
  }, [currentUser]);

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
}; 