import React, { useEffect } from 'react';
import styles from './styles/modules/about.module.css';
import AboutHero from './components/about/AboutHero';
import AboutContent from './components/about/AboutContent';
import Newsletter from './components/home/Newsletter';
import Footer from './components/common/Footer';
import { Divider, Box } from '@mui/material';
const AboutPage = () => {
  useEffect(() => {
    document.body.classList.add('public-page');
    return () => {
      document.body.classList.remove('public-page');
    };
  }, []);

  return (
    <main className={styles.mainContent}>
      <AboutHero />         
      <AboutContent />
      <Newsletter />
      <Box sx={{ 
            backgroundColor: 'rgba(255, 255, 255, 0.02)', // Subtle color
            height: '1px', // Very thin line
            width: '100%',
            margin: 0
          }} />
      <Footer />
    </main>
  );
};

export default AboutPage; 