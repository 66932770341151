import { useState, useEffect } from 'react';
import { Dialog, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Close as CloseIcon } from '@mui/icons-material';

// Add styled close button
const CloseButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: 16,
  right: 16,
  backgroundColor: 'rgba(0,0,0,0.4)',
  color: 'white',
  '&:hover': {
    backgroundColor: 'rgba(0,0,0,0.6)',
  },
  zIndex: 1
}));

const VideoContainer = styled('div')({
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative' // Add this for absolute positioning of close button
});

const StyledVideo = styled('video')({
  maxWidth: '100%',
  maxHeight: '100vh',
  objectFit: 'contain'
});

const VideoPlayer = ({ open, onClose, videoUrl }) => {
  const [isMuted, setIsMuted] = useState(true);

  useEffect(() => {
    // Reset muted state when modal opens
    if (open) setIsMuted(true);
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="xl"
      fullWidth
      onClick={onClose}
      PaperProps={{
        style: {
          backgroundColor: 'transparent',
          boxShadow: 'none',
          margin: 0,
          padding: 0
        }
      }}
    >
      <VideoContainer>
        <CloseButton onClick={onClose}>
          <CloseIcon />
        </CloseButton>
        <StyledVideo
          src={videoUrl}
          autoPlay
          playsInline
          loop
          muted={isMuted}
          onClick={(e) => {
            e.stopPropagation();
            setIsMuted(!isMuted);
          }}
        />
      </VideoContainer>
    </Dialog>
  );
};

export default VideoPlayer; 