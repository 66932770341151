import { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  Button,
  Grid,
  Alert,
  Link,
  Stack,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useAuth } from '../contexts/AuthContext';
import api from '../services/api';
import { useToast } from '../contexts/ToastContext';
import EditProfileModal from './EditProfileModal';
import { keyframes } from '@emotion/react';
import CircleIcon from '@mui/icons-material/Circle';
import { format } from 'date-fns';
import ConfirmDialog from './common/ConfirmDialog';

const ContentContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  maxWidth: '1200px',
  width: '100%',
  margin: '0 auto',
  flex: 1,
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2),
  }
}));

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  background: 'transparent',
  boxShadow: 'none',
  '&:before': {
    display: 'none',
  },
  '& .MuiAccordionSummary-root': {
    minHeight: '64px',
    padding: theme.spacing(0, 3),
    borderRadius: '12px',
    backgroundColor: theme.palette.background.paper,
    transition: 'all 0.2s ease-in-out',
    border: `1px solid ${theme.palette.divider}`,
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
    '&.Mui-expanded': {
      backgroundColor: '#1a4a2b',
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      '& .MuiTypography-root': {
        color: '#fff',
      },
      '& .MuiSvgIcon-root': {
        color: '#fff',
      }
    },
  },
  '& .MuiAccordionDetails-root': {
    padding: theme.spacing(3),
    backgroundColor: theme.palette.background.paper,
    borderBottomLeftRadius: '12px',
    borderBottomRightRadius: '12px',
    borderLeft: `1px solid ${theme.palette.divider}`,
    borderRight: `1px solid ${theme.palette.divider}`,
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
}));

const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  '& .MuiAccordionSummary-content': {
    margin: '12px 0',
  },
  '& .MuiTypography-root': {
    fontSize: '1.1rem',
    fontWeight: 500,
    transition: 'color 0.2s ease-in-out',
  },
  '& .MuiSvgIcon-root': {
    fontSize: '1.5rem',
    transition: 'transform 0.3s ease-in-out, color 0.2s ease-in-out',
  },
  '&.Mui-expanded': {
    '& .MuiSvgIcon-root': {
      transform: 'rotate(-180deg)',
    },
  },
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  '& .MuiOutlinedInput-root': {
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.paper,
    '&:hover': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.text.primary,
      },
    },
  },
}));

const pulse = keyframes`
  0% {
    transform: scale(0.95);
    opacity: 0.8;
  }
  50% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0.95);
    opacity: 0.8;
  }
`;

const StatusDot = styled(CircleIcon)(({ theme, status }) => ({
  fontSize: '12px',
  marginRight: theme.spacing(1),
  animation: `${pulse} 2s ease-in-out infinite`,
  color: status === 'active' ? theme.palette.success.main
       : status === 'trial' ? theme.palette.warning.main
       : status === 'expired' ? theme.palette.error.main
       : theme.palette.grey[500],
}));

const CancelButton = styled(Link)(({ theme }) => ({
  color: theme.palette.error.main,
  textDecoration: 'none',
  fontWeight: 500,
  display: 'inline-block',
  marginTop: theme.spacing(3),
  padding: theme.spacing(1, 2),
  border: `1px solid ${theme.palette.error.main}`,
  borderRadius: theme.shape.borderRadius,
  transition: 'all 0.2s ease-in-out',
  '&:hover': {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.common.white,
  },
  '&:not(:disabled)': {
    cursor: 'pointer',
  },
}));

const StatusBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2),
  backgroundColor: theme.palette.background.default,
  borderRadius: theme.shape.borderRadius,
  marginBottom: theme.spacing(2),
}));

const formatDate = (date) => {
  try {
    if (!date) return 'N/A';
    const dateObj = new Date(date);
    if (isNaN(dateObj.getTime())) return 'N/A';
    return format(dateObj, 'MMMM dd, yyyy');
  } catch (error) {
    console.error('Date formatting error:', error);
    return 'N/A';
  }
};

const SettingsHeader = styled(Typography)(({ theme }) => ({
  fontSize: '2rem',
  fontWeight: 600,
  marginBottom: theme.spacing(4),
  color: theme.palette.text.primary,
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.75rem',
    marginBottom: theme.spacing(3),
  },
}));

const Settings = () => {
  const { userData } = useAuth();
  const { showToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [isEditProfileOpen, setIsEditProfileOpen] = useState(false);
  const [expandedSection, setExpandedSection] = useState(false);
  const [formData, setFormData] = useState({
    firstName: userData?.profile?.firstName || '',
    lastName: userData?.profile?.lastName || '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    state: '',
    zipCode: '',
  });
  const [cardRequest, setCardRequest] = useState(null);
  const [showCancelConfirm, setShowCancelConfirm] = useState(false);
  const [hasPendingCancellation, setHasPendingCancellation] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      await api.post('/api/membership/request-card', formData);
      showToast('Membership card request submitted successfully! You will receive your card within a month.');
      // Clear form except names
      setFormData(prev => ({
        ...prev,
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        zipCode: '',
      }));
      // Fetch updated status after submission
      await fetchCardRequestStatus();
    } catch (error) {
      showToast('Failed to submit membership card request. Please try again.');
      console.error('Error:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleModalClose = () => {
    setIsEditProfileOpen(false);
    setExpandedSection(false);
  };

  const fetchCardRequestStatus = async () => {
    try {
      const response = await api.get('/api/membership/request-status');
      setCardRequest(response.data.request);
    } catch (error) {
      console.error('Error fetching card request:', error);
    }
  };

  useEffect(() => {
    // Fetch card request status when component mounts
    fetchCardRequestStatus();
  }, []); // Empty dependency array means it only runs once on mount

  useEffect(() => {
    const checkCancellationStatus = async () => {
      try {
        const response = await api.get('/api/users/cancellation-status');
        setHasPendingCancellation(response.data.hasPending);
      } catch (error) {
        console.error('Error checking cancellation status:', error);
      }
    };
    
    if (userData?.subscription?.status) {
      checkCancellationStatus();
    }
  }, [userData]);

  const handleCancelSubscription = async () => {
    try {
      const response = await api.post('/api/users/request-cancellation');
      if (response.data.success) {
        showToast(response.data.message, 'success');
        setHasPendingCancellation(true);
      }
    } catch (error) {
      showToast(error.response?.data?.message || 'Failed to cancel subscription', 'error');
    } finally {
      setShowCancelConfirm(false);
    }
  };

  const settingsSections = [
    {
      id: 'account',
      title: 'Account',
      onClick: () => setIsEditProfileOpen(true),
      content: null
    },
    {
      id: 'subscription',
      title: 'Membership',
      content: (
        <Box>
          <StatusBox>
            <StatusDot status={userData?.subscription?.status || 'none'} />
            <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
              Status: {' '}
              <Typography component="span" color={
                userData?.subscription?.status === 'active' ? 'success.main'
                : userData?.subscription?.status === 'trial' ? 'warning.main'
                : userData?.subscription?.status === 'expired' ? 'error.main'
                : 'text.secondary'
              }>
                {userData?.subscription?.status?.toUpperCase() || 'NONE'}
              </Typography>
            </Typography>
          </StatusBox>

          {userData?.subscription?.status === 'active' && userData?.subscription?.endDate && (
            <>
              <Box sx={{ mb: 2 }}>
                <Typography variant="body2" color="text.secondary" gutterBottom>
                  Next Payment Date
                </Typography>
                <Typography>
                  {formatDate(userData.subscription.endDate)}
                </Typography>
              </Box>

              {userData?.subscription?.status === 'trial' && userData?.subscription?.trialEndDate && (
                <Box sx={{ mb: 3 }}>
                  <Typography variant="body2" color="text.secondary" gutterBottom>
                    Trial Ends On
                  </Typography>
                  <Typography>
                    {formatDate(userData.subscription.trialEndDate)}
                  </Typography>
                </Box>
              )}

              {(userData?.subscription?.status === 'active' || userData?.subscription?.status === 'trial') && (
                <Box sx={{ borderTop: 1, borderColor: 'divider', pt: 3 }}>
                  <CancelButton 
                    variant="contained"
                    onClick={!hasPendingCancellation ? () => setShowCancelConfirm(true) : undefined}
                    disabled={hasPendingCancellation}
                    sx={{
                      '&:hover:not(:disabled)': {
                        cursor: 'pointer'
                      }
                    }}
                  >
                    {hasPendingCancellation ? 'Cancellation Requested' : 'Cancel Membership'}
                  </CancelButton>
                </Box>
              )}
            </>
          )}
        </Box>
      )
    },
    {
      id: 'physical-card',
      title: 'Exclusive Member Card',
      content: (
        <Box>
          {cardRequest ? (
            <Box component="form">
              <StatusBox>
                <StatusDot 
                  status={
                    cardRequest.status === 'pending' ? 'warning'
                    : cardRequest.status === 'processing' ? 'info'
                    : cardRequest.status === 'shipped' ? 'success'
                    : 'default'
                  } 
                />
                <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                  Status: {' '}
                  <Typography 
                    component="span" 
                    color={
                      cardRequest.status === 'pending' ? 'warning.main'
                      : cardRequest.status === 'processing' ? 'info.main'
                      : cardRequest.status === 'shipped' ? 'success.main'
                      : 'text.secondary'
                    }
                  >
                    {cardRequest.status.toUpperCase()}
                  </Typography>
                </Typography>
              </StatusBox>

              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <StyledTextField
                    fullWidth
                    label="First Name"
                    value={cardRequest.first_name}
                    InputProps={{ readOnly: true }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <StyledTextField
                    fullWidth
                    label="Last Name"
                    value={cardRequest.last_name}
                    InputProps={{ readOnly: true }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <StyledTextField
                    fullWidth
                    label="Address Line 1"
                    value={cardRequest.address_line1}
                    InputProps={{ readOnly: true }}
                  />
                </Grid>
                {cardRequest.address_line2 && (
                  <Grid item xs={12}>
                    <StyledTextField
                      fullWidth
                      label="Address Line 2"
                      value={cardRequest.address_line2}
                      InputProps={{ readOnly: true }}
                    />
                  </Grid>
                )}
                <Grid item xs={12} sm={6}>
                  <StyledTextField
                    fullWidth
                    label="City"
                    value={cardRequest.city}
                    InputProps={{ readOnly: true }}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <StyledTextField
                    fullWidth
                    label="State"
                    value={cardRequest.state}
                    InputProps={{ readOnly: true }}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <StyledTextField
                    fullWidth
                    label="ZIP Code"
                    value={cardRequest.zip_code}
                    InputProps={{ readOnly: true }}
                  />
                </Grid>
              </Grid>
            </Box>
          ) : (
            <Box component="form" onSubmit={handleSubmit}>
              <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
              Request a physical copy of your Exclusive Member Card.  This beautifully branded card represents your commitment to preserving memories, creating legacies and sharing the stories behind your cherished treasures.  Your card will be delivered within one month of your request.
              </Typography>
              
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <StyledTextField
                    fullWidth
                    label="First Name"
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleInputChange}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <StyledTextField
                    fullWidth
                    label="Last Name"
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleInputChange}
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <StyledTextField
                    fullWidth
                    label="Address Line 1"
                    name="addressLine1"
                    value={formData.addressLine1}
                    onChange={handleInputChange}
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <StyledTextField
                    fullWidth
                    label="Address Line 2 (Optional)"
                    name="addressLine2"
                    value={formData.addressLine2}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <StyledTextField
                    fullWidth
                    label="City"
                    name="city"
                    value={formData.city}
                    onChange={handleInputChange}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <StyledTextField
                    fullWidth
                    label="State"
                    name="state"
                    value={formData.state}
                    onChange={handleInputChange}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <StyledTextField
                    fullWidth
                    label="ZIP Code"
                    name="zipCode"
                    value={formData.zipCode}
                    onChange={handleInputChange}
                    required
                  />
                </Grid>
              </Grid>
              
              <Button
                type="submit"
                variant="contained"
                disabled={loading}
                sx={{ 
                  mt: 3,
                  bgcolor: '#11311B',
                  borderRadius: '8px',
                  '&:hover': {
                    bgcolor: '#1a4a2b',
                    transform: 'translateY(-1px)',
                    boxShadow: '0 4px 12px rgba(0,0,0,0.2)'
                  },
                  transition: 'all 0.2s ease-in-out',
                  textTransform: 'none',
                  fontSize: '0.95rem',
                  py: 1.5
                }}
                fullWidth
              >
                {loading ? 'Submitting...' : 'Request Physical Member Card'}
              </Button>
            </Box>
          )}
        </Box>
      )
    },
    {
      id: 'data',
      title: 'Data Protection & Privacy',
      content: (
        <Box>
          <Typography variant="body1" paragraph>
            At Something Told, we take your privacy and data protection seriously. Here's our commitment to you:
          </Typography>
          
          <Typography variant="body2" sx={{ mb: 2 }}>
            • We never sell, trade, or share your personal information with third parties
          </Typography>
          
          <Typography variant="body2" sx={{ mb: 2 }}>
            • Your stories and memories are yours - we only store what's necessary to provide our service
          </Typography>
         <Typography variant="body2" sx={{ mb: 2 }}>
            • We only collect essential information needed to operate your account and provide our services
          </Typography>
          
          <Typography variant="body2" sx={{ mb: 2 }}>
            • Your payment information is handled securely by trusted payment processors and is never stored on our servers
          </Typography>
          
          <Typography variant="body2" sx={{ mb: 3 }}>
            • You can request to delete your account and associated data at any time
          </Typography>

          <Box sx={{ mt: 3, pt: 3, borderTop: 1, borderColor: 'divider' }}>
            <CancelButton 
              component="button"
              onClick={() => {
                // TODO: Implement account deletion logic
                console.log('Delete account clicked');
              }}
            >
              Delete Account
            </CancelButton>
          </Box>
        </Box>
      )
    },
  ];

  return (
    <ContentContainer>
      <SettingsHeader>
        Settings
      </SettingsHeader>
      
      {settingsSections.map((section) => (
        <StyledAccordion 
          key={section.id} 
          disableGutters
          expanded={expandedSection === section.id}
          onChange={(e, expanded) => {
            setExpandedSection(expanded ? section.id : false);
            if (expanded && section.onClick) {
              section.onClick();
            }
          }}
        >
          <StyledAccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`${section.id}-content`}
            id={`${section.id}-header`}
          >
            <Typography>
              {section.title}
            </Typography>
          </StyledAccordionSummary>
          <AccordionDetails>
            {typeof section.content === 'string' ? (
              <Typography>{section.content}</Typography>
            ) : (
              section.content
            )}
          </AccordionDetails>
        </StyledAccordion>
      ))}

      <EditProfileModal 
        open={isEditProfileOpen}
        onClose={handleModalClose}
      />

      <ConfirmDialog
        open={showCancelConfirm}
        onClose={() => setShowCancelConfirm(false)}
        onConfirm={handleCancelSubscription}
        title="Confirm Membership Cancellation"
        message="Are you sure you want to cancel your Membership? Your access will continue until the end of your billing period."
        confirmText="Yes, Cancel Membership"
        cancelText="Keep Membership"
      />
    </ContentContainer>
  );
};

export default Settings; 