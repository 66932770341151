import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { useLocation } from 'react-router-dom';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      hasError: false,
      error: null,
      errorInfo: null,
      errorLocation: null
    };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    // Filter out Web3/Ethereum related errors
    if (error.message && (
      error.message.includes('ethereum') || 
      error.message.includes('web3')
    )) {
      console.log('Suppressed Web3/Ethereum error:', error);
      this.setState({ hasError: false });
      return;
    }

    // Get component stack trace
    const componentStack = errorInfo?.componentStack
      ?.split('\n')
      ?.filter(line => line.trim())
      ?.slice(0, 3)
      ?.join('\n');

    this.setState({
      error,
      errorInfo,
      errorLocation: componentStack
    });

    // Enhanced error logging
    console.error('Error caught by boundary:', {
      error: error.message,
      stack: error.stack,
      location: window.location.pathname,
      componentStack
    });
  }

  render() {
    if (this.state.hasError) {
      return (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh',
            p: 3,
            textAlign: 'center'
          }}
        >
          <Typography variant="h5" gutterBottom>
            Something went wrong
          </Typography>
          {process.env.NODE_ENV === 'development' && (
            <Typography 
              variant="body2" 
              color="text.secondary"
              sx={{ mb: 2, maxWidth: '80%', wordBreak: 'break-word' }}
            >
              {this.state.error?.message}
              {this.state.errorLocation && (
                <pre style={{ fontSize: '0.8em', marginTop: '1em' }}>
                  {this.state.errorLocation}
                </pre>
              )}
            </Typography>
          )}
          <Button 
            variant="contained" 
            onClick={() => window.location.reload()}
            sx={{ mt: 2 }}
          >
            Reload Page
          </Button>
        </Box>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary; 