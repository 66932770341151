import React, { createContext, useContext, useState } from 'react';

const ScrollPositionContext = createContext();

export const ScrollPositionProvider = ({ children }) => {
  const [searchBarPosition, setSearchBarPosition] = useState(0);

  return (
    <ScrollPositionContext.Provider value={{ searchBarPosition, setSearchBarPosition }}>
      {children}
    </ScrollPositionContext.Provider>
  );
};

export const useScrollPosition = () => useContext(ScrollPositionContext); 