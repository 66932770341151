import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import PublicNav from './components/common/PublicNav';
import Footer from './components/common/Footer';
import styles from './styles/modules/terms.module.css';

const TermsPage = () => {
  useEffect(() => {
    document.body.classList.add('public-page');
    return () => {
      document.body.classList.remove('public-page');
    };
  }, []);

  return (
    <div className={styles.termsPage}>
      <PublicNav />
      
      <main className={styles.mainContent}>
        <div className={styles.heroSection}>
          <h1>Terms & Privacy</h1>
          <p>Last updated: {new Date().toLocaleDateString()}</p>
        </div>

        <div className={styles.contentSection}>
          <section className={styles.section}>
            <h2>1. Introduction</h2>
            <p>Welcome to Something Told. We are committed to protecting your privacy and providing a secure experience. This document outlines our practices regarding your information and the terms of using our platform.</p>
          </section>

          <section className={styles.section}>
            <h2>2. Information Collection</h2>
            <p>We collect only essential information needed to provide our services:</p>
            <ul>
              <li>Name and email address for account creation</li>
              <li>Profile information you choose to share</li>
              <li>Content you create and share on our platform</li>
            </ul>
          </section>

          <section className={styles.section}>
            <h2>3. Data Security</h2>
            <p>Your security is our priority:</p>
            <ul>
              <li>We never store passwords in plain text</li>
              <li>We do not store credit card information</li>
              <li>All data is encrypted in transit and at rest</li>
              <li>We use industry-standard security protocols</li>
            </ul>
          </section>

          <section className={styles.section}>
            <h2>4. Information Sharing</h2>
            <p>We maintain strict privacy standards:</p>
            <ul>
              <li>We never sell your personal information</li>
              <li>We do not share data with third parties for marketing</li>
              <li>Information sharing is limited to essential service providers</li>
              <li>We comply with all applicable privacy laws and regulations</li>
            </ul>
          </section>

          <section className={styles.section}>
            <h2>5. Social Media Integration</h2>
            <p>When using our social media features:</p>
            <ul>
              <li>You control what you share on social platforms</li>
              <li>We do not automatically post to your accounts</li>
              <li>Social media connections can be removed at any time</li>
            </ul>
          </section>

          <section className={styles.section}>
            <h2>6. Your Rights</h2>
            <p>You have the right to:</p>
            <ul>
              <li>Access your personal information</li>
              <li>Request data correction or deletion</li>
              <li>Opt-out of communications</li>
              <li>Export your data</li>
            </ul>
          </section>

          <section className={styles.section}>
            <h2>7. Contact Us</h2>
            <p>If you have questions about these terms or your privacy, please contact us Support@SomethingTold.com</p>
          </section>
        </div>
      </main>

      <Footer />
    </div>
  );
};

export default TermsPage; 