import { useState, useEffect, useRef } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import {
  Dialog,
  DialogContent,
  Typography,
  Box,
  Button,
  TextField,
  Avatar,
  Select,
  MenuItem,
  CircularProgress,
} from '@mui/material';
import CloseButton from './common/CloseButton';
import { useAuth } from '../contexts/AuthContext';
import { useToast } from '../contexts/ToastContext';
import { auth } from '../firebase';
import { updatePassword, EmailAuthProvider, reauthenticateWithCredential } from 'firebase/auth';
import api from '../services/api';
import * as Yup from 'yup';
import CropPhotoModal from './common/CropPhotoModal';
import useS3Upload from '../hooks/useS3Upload';
import ImageUpload from './common/ImageUpload';
import LogoIcon from '../assets/ST_Logo_Gold-on-Gren.svg';

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: '12px',
    transition: 'all 0.2s ease-in-out',
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
    '&.Mui-focused': {
      backgroundColor: theme.palette.background.paper,
      '& .MuiOutlinedInput-notchedOutline': {
        borderWidth: '2px',
      }
    }
  },
  '& .MuiInputLabel-root': {
    fontSize: '0.875rem',
    '&.Mui-focused': {
      color: theme.palette.text.primary,
    }
  }
}));

const StyledSelect = styled(Select)(({ theme }) => ({
  borderRadius: '12px',
  fontSize: '0.875rem',
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.divider,
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.text.primary,
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderWidth: '2px',
  }
}));

// Add helper function for date handling
const getUTCDate = (dateString) => {
  if (!dateString) return null;
  const date = new Date(dateString + 'T00:00:00Z'); // Force UTC midnight
  return date;
};

const EditProfileModal = ({ open, onClose }) => {
  const { showToast } = useToast();
  const { userData, updateProfileData } = useAuth();
  const [loading, setLoading] = useState(false);
  const [profileImage, setProfileImage] = useState(null);
  const [hasChanges, setHasChanges] = useState(false);
  const [errors, setErrors] = useState({});
  const [originalData, setOriginalData] = useState({
    firstName: '',
    lastName: '',
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
    userPhoto: ''
  });
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    state: '',
    month: '',
    day: '',
    year: '',
    profileMessage: '',
    currentPassword: '',
    newPassword: '',
    confirmPassword: ''
  });
  const [tempImageUrl, setTempImageUrl] = useState(null);
  const [showCropModal, setShowCropModal] = useState(false);
  const fileInputRef = useRef(null);
  const { uploadToS3, isUploading } = useS3Upload();
  const [uploadProgress, setUploadProgress] = useState(0);
  const theme = useTheme();

  // Arrays for dropdowns - only need months for display
  const months = [
    "January", "February", "March", "April", "May", "June", 
    "July", "August", "September", "October", "November", "December"
  ];

  // Load user data
  useEffect(() => {
    if (userData?.profile && open) {
      // Parse birthDate for display only
      let monthName = '';
      let day = '';
      let year = '';
      
      if (userData.profile.birthDate) {
        // Use UTC date to prevent timezone offset issues
        const birthDate = getUTCDate(userData.profile.birthDate);
        monthName = months[birthDate.getUTCMonth()];
        day = birthDate.getUTCDate().toString();
        year = birthDate.getUTCFullYear().toString();
      }

      // Store original data for change comparison
      setOriginalData({
        firstName: userData.profile.firstName || '',
        lastName: userData.profile.lastName || '',
        userPhoto: userData.profile.userPhoto || ''
      });
      
      setFormData({
        ...formData,
        firstName: userData.profile.firstName || '',
        lastName: userData.profile.lastName || '',
        state: userData.profile.state || '',
        month: monthName,
        day: day,
        year: year,
        profileMessage: userData.profile.profileMessage || ''
      });
    }
  }, [open]);

  // Reset form when modal closes
  useEffect(() => {
    if (!open) {
      setFormData(prev => ({
        ...prev,
        currentPassword: '',
        newPassword: '',
        confirmPassword: ''
      }));
      setErrors({});
      setLoading(false);
      setHasChanges(false);
    }
  }, [open]);

  // Check for changes in form data
  useEffect(() => {
    const hasProfileChanges = 
      formData.firstName !== originalData.firstName ||
      formData.lastName !== originalData.lastName;
    
    const hasPasswordChanges = 
      formData.currentPassword || 
      formData.newPassword || 
      formData.confirmPassword;
    
    setHasChanges(hasProfileChanges || hasPasswordChanges);
  }, [formData, originalData]);

  // Profile validation
  const validateProfile = () => {
    if (!formData.firstName || !formData.lastName) {
      return 'Both first and last name are required';
    }
    if (formData.firstName.length < 2 || formData.lastName.length < 2) {
      return 'Names must be at least 2 characters long';
    }
    return '';
  };

  // Add password sanitization function (same as Auth.js)
  const sanitizePassword = (password) => {
    return password.replace(/[^\w\s@$!%*#?&]/g, '');
  };

  // Update handlePasswordChange function
  const handlePasswordChange = async () => {
    try {
      const user = auth.currentUser;
      const userEmail = user.email;
      
      // Sanitize passwords consistently
      const sanitizedCurrentPassword = sanitizePassword(formData.currentPassword);
      const sanitizedNewPassword = sanitizePassword(formData.newPassword);
      
      // Validate new password before proceeding
      const passwordError = validatePasswords();
      if (passwordError) {
        throw new Error(passwordError);
      }
      
      // Reauthenticate user with sanitized current password
      const credential = EmailAuthProvider.credential(
        userEmail,
        sanitizedCurrentPassword
      );
      
      try {
        await reauthenticateWithCredential(user, credential);
      } catch (error) {
        if (error.code === 'auth/wrong-password') {
          throw new Error('Current password is incorrect');
        }
        throw new Error('Failed to verify current password');
      }
      
      // Update password with sanitized new password
      await updatePassword(user, sanitizedNewPassword);
      
      // Clear password fields
      setFormData(prev => ({
        ...prev,
        currentPassword: '',
        newPassword: '',
        confirmPassword: ''
      }));
      
      return true;
    } catch (error) {
      throw error;
    }
  };

  // Update password validation to match Auth.js requirements
  const validatePasswords = () => {
    if (!formData.newPassword?.trim()) {
      return 'New password is required';
    }
    
    if (formData.newPassword !== formData.confirmPassword) {
      return 'Passwords do not match';
    }

    // Use the same password requirements as registration
    const schema = Yup.string()
      .required('Password is required')
      .min(8, 'Password must be at least 8 characters')
      .max(22, 'Password must not exceed 22 characters')
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,22}$/,
        'Password must contain at least one letter, one number, and one special character'
      );

    try {
      // Validate the sanitized password
      const sanitizedPassword = sanitizePassword(formData.newPassword);
      schema.validateSync(sanitizedPassword);
      return '';
    } catch (error) {
      return error.message;
    }
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      let updates = [];

      // Sanitize inputs
      const sanitizedData = {
        firstName: formData.firstName.trim(),
        lastName: formData.lastName.trim()
      };

      // Check if password needs to be updated
      if (formData.newPassword?.trim()) {
        const passwordError = validatePasswords();
        if (passwordError) {
          showToast(passwordError);
          return;
        }
        
        try {
          await handlePasswordChange();
          updates.push('password');
        } catch (error) {
          showToast(error.message || 'Failed to update password');
          return;
        }
      }

      // Check if profile needs to be updated
      if (formData.firstName !== originalData.firstName || formData.lastName !== originalData.lastName) {
        // Validate profile fields
        const profileError = validateProfile();
        if (profileError) {
          showToast(profileError);
          return;
        }

        try {
          const response = await api.put('/api/editprofile/update', {
            firstName: sanitizedData.firstName,
            lastName: sanitizedData.lastName
          });

          if (response.data.success) {
            updateProfileData({
              firstName: sanitizedData.firstName,
              lastName: sanitizedData.lastName
            });
            updates.push('profile');
          } else {
            showToast(response.data.message || 'Failed to update profile');
            return;
          }
        } catch (error) {
          if (!navigator.onLine) {
            showToast('No internet connection. Please try again.');
          } else {
            showToast(error.response?.data?.message || error.message || 'Failed to update profile');
          }
          return;
        }
      }

      // Simplified success message logic
      const successMessage = updates.length === 2
        ? 'Profile and password updated successfully'
        : updates.includes('password')
        ? 'Password updated successfully'
        : updates.includes('profile')
        ? 'Profile updated successfully'
        : '';

      if (successMessage) {
        showToast(successMessage);
        onClose();
      }
    } catch (error) {
      showToast(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handlePhotoSelect = (localUrl, webpBlob) => {
    setTempImageUrl(localUrl);
    setShowCropModal(true);
  };

  const handleCropComplete = async (croppedBlob) => {
    try {
      setLoading(true);
      setUploadProgress(0);

      // Check file size (15MB max)
      if (croppedBlob.size > 15 * 1024 * 1024) {
        showToast('Image size must be less than 15MB');
        return;
      }

      // Create a File from the Blob with proper naming
      const file = new File([croppedBlob], `profile-${Date.now()}.webp`, {
        type: 'image/webp'
      });
      
      const publicUrl = await uploadToS3(file, (progress) => {
        setUploadProgress(progress);
      });
      
      const response = await api.put('/api/editprofile/userphoto', {
        userPhoto: publicUrl
      });

      if (response.data.success) {
        updateProfileData({
          userPhoto: publicUrl
        });
        showToast('Profile photo updated successfully');
        onClose();
      }
    } catch (error) {
      if (!navigator.onLine) {
        showToast('No internet connection. Please try again.');
      } else {
        showToast(error.message || 'Failed to update profile photo');
      }
    } finally {
      setLoading(false);
      setUploadProgress(0);
    }
  };

  return (
    <>
      <Dialog 
        open={open} 
        onClose={onClose}
        maxWidth="sm"
        fullWidth
        fullScreen={false}
        sx={{
          '& .MuiDialog-paper': {
            width: '100%',
            m: { xs: 0, sm: 2 },
            maxWidth: { xs: '100%', sm: 600 },
            height: { xs: '100%', sm: 'auto' },
            borderRadius: { xs: 0, sm: 2 },
            p: { xs: 2, sm: 3 },
            position: 'relative',
            overflowY: 'auto',
            '@supports (-webkit-touch-callout: none)': {
              // iOS specific padding to handle safe area
              paddingBottom: 'env(safe-area-inset-bottom, 120px)'
            }
          },
          '@media (max-width: 600px)': {
            '& .MuiDialog-container': {
              alignItems: 'flex-start',
            },
            '& .MuiDialog-paper': {
              margin: 0,
              maxHeight: '100vh',
              minHeight: '100vh',
              paddingBottom: { 
                xs: '10px',
                sm: '0px'
              },
            }
          }
        }}
      >
        <CloseButton 
          onClose={onClose} 
          sx={{
            position: 'fixed',
            right: 8,
            top: 8,
            zIndex: 1300,
            '@media (min-width: 600px)': {
              position: 'absolute',
            }
          }}
        />
        <DialogContent sx={{ 
          p: 0,
          mt: { xs: 4, sm: 0 },
          height: '100%',
          overflowY: 'auto',
          pb: 0
        }}>
          <Box sx={{ textAlign: 'center', mb: 3 }}>
            <img 
              src={LogoIcon} 
              alt="Something Told Logo" 
              style={{
                width: '100px',
                height: '100px'
              }}
            />
          </Box>
          
          <Typography 
            variant="h4" 
            align="center" 
            sx={{ 
              mb: 4,
              fontWeight: 600
            }}
          >
            Edit Profile
          </Typography>

          <Box sx={{ 
            display: 'flex', 
            flexDirection: 'column', 
            alignItems: 'center',
            mb: 3,
            position: 'relative'
          }}>
            <Avatar
              src={userData?.profile?.userPhoto}
              sx={{
                width: 100,
                height: 100,
                bgcolor: 'grey.200',
                mb: 2
              }}
            />
            <ImageUpload
              onUploadComplete={handlePhotoSelect}
              onUploadError={(error) => showToast(error)}
              buttonProps={{
                sx: {
                  bgcolor: 'grey.800',
                  '&:hover': {
                    bgcolor: 'grey.900',
                  },
                  textTransform: 'none',
                  px: 4,
                  mb: 2
                },
                children: loading ? 'Uploading...' : 'Change Photo',
                disabled: loading
              }}
              maxSize={15 * 1024 * 1024} // 15MB in bytes
            />
            {loading && uploadProgress > 0 && (
              <Box sx={{ 
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: 1,
                bgcolor: 'rgba(255, 255, 255, 0.9)',
                p: 2,
                borderRadius: 2,
                boxShadow: 1
              }}>
                <Typography variant="body2" color="primary">
                  {Math.round(uploadProgress)}%
                </Typography>
                <CircularProgress 
                  variant="determinate" 
                  value={uploadProgress} 
                  size={24}
                />
              </Box>
            )}
          </Box>

          <Box sx={{ 
            display: 'grid', 
            gridTemplateColumns: '1fr 1fr 1fr',
            gap: 2,
            mb: 2
          }}>
            <StyledTextField
              name="firstName"
              placeholder="First Name"
              value={formData.firstName}
              onChange={handleChange}
              fullWidth
              variant="outlined"
            />
            <StyledTextField
              name="lastName"
              placeholder="Last Name"
              value={formData.lastName}
              onChange={handleChange}
              fullWidth
              variant="outlined"
            />
            <StyledSelect
              name="state"
              value={formData.state}
              disabled
              displayEmpty
              renderValue={(selected) => selected || ""}
              sx={{ 
                bgcolor: 'background.paper',
                '& .MuiSelect-icon': {
                  display: 'none'
                }
              }}
            >
              <MenuItem value={formData.state}>{formData.state}</MenuItem>
            </StyledSelect>
          </Box>

          <Typography variant="subtitle2" sx={{ mb: 1 }}>Birthday</Typography>
          <Box sx={{ 
            display: 'grid', 
            gridTemplateColumns: '1fr 1fr 1fr',
            gap: 2,
            mb: 2
          }}>
            <StyledSelect
              name="month"
              value={formData.month}
              disabled
              displayEmpty
              renderValue={(selected) => selected || ""}
              sx={{ 
                bgcolor: 'background.paper',
                '& .MuiSelect-icon': {
                  display: 'none'
                }
              }}
            >
              <MenuItem value={formData.month}>{formData.month}</MenuItem>
            </StyledSelect>

            <StyledSelect
              name="day"
              value={formData.day}
              disabled
              displayEmpty
              renderValue={(selected) => selected || ""}
              sx={{ 
                bgcolor: 'background.paper',
                '& .MuiSelect-icon': {
                  display: 'none'
                }
              }}
            >
              <MenuItem value={formData.day}>{formData.day}</MenuItem>
            </StyledSelect>

            <StyledSelect
              name="year"
              value={formData.year}
              disabled
              displayEmpty
              renderValue={(selected) => selected || ""}
              sx={{ 
                bgcolor: 'background.paper',
                '& .MuiSelect-icon': {
                  display: 'none'
                }
              }}
            >
              <MenuItem value={formData.year}>{formData.year}</MenuItem>
            </StyledSelect>
          </Box>

          {/* Comment out the profile message section
          <StyledTextField
            name="profileMessage"
            placeholder="Profile Message"
            value={formData.profileMessage}
            onChange={handleChange}
            fullWidth
            multiline
            rows={3}
            variant="outlined"
            sx={{ mb: 2 }}
          /> 
          */}

          <Box sx={{ 
            py: 2, 
            mb: 3,
            borderTop: '1px solid',
            borderColor: 'divider'
          }}>
            <Typography 
              variant="h6" 
              sx={{ 
                mb: 2,
                fontWeight: 500,
                fontSize: '1.1rem'
              }}
            >
              Change Password
            </Typography>

            <StyledTextField
              name="currentPassword"
              type="password"
              placeholder="Current Password"
              value={formData.currentPassword}
              onChange={handleChange}
              fullWidth
              variant="outlined"
              sx={{ mb: 2 }}
            />

            <StyledTextField
              name="newPassword"
              type="password"
              placeholder="New Password"
              value={formData.newPassword}
              onChange={handleChange}
              fullWidth
              variant="outlined"
              sx={{ mb: 2 }}
            />

            <StyledTextField
              name="confirmPassword"
              type="password"
              placeholder="Confirm New Password"
              value={formData.confirmPassword}
              onChange={handleChange}
              fullWidth
              variant="outlined"
            />
          </Box>
        </DialogContent>

        <Box sx={{
          position: 'sticky',
          bottom: 0,
          bgcolor: 'background.paper',
          borderTop: '1px solid',
          borderColor: 'divider',
          px: 2,
          py: 1,
          zIndex: 1
        }}>
          <Button 
            fullWidth
            variant="contained" 
            onClick={handleSubmit}
            disabled={loading || !hasChanges}
            sx={{
              bgcolor: 'grey.800',
              '&:hover': {
                bgcolor: 'grey.900',
              },
              textTransform: 'none',
              py: 1
            }}
          >
            {loading ? 'Saving...' : 'Save Changes'}
          </Button>
        </Box>
      </Dialog>

      <CropPhotoModal
        open={showCropModal}
        onClose={() => setShowCropModal(false)}
        imageUrl={tempImageUrl}
        onCropComplete={handleCropComplete}
      />
    </>
  );
};

export default EditProfileModal; 