import { Box, Skeleton } from '@mui/material';
import { styled } from '@mui/material/styles';

// Blog Card Skeleton
export const BlogCardSkeleton = () => (
  <Box sx={{ width: '100%', mb: 2 }}>
    <Skeleton variant="rectangular" height={200} sx={{ borderRadius: 1, mb: 1 }} />
    <Skeleton width="30%" height={24} sx={{ mb: 1 }} />
    <Skeleton width="70%" height={32} sx={{ mb: 1 }} />
    <Skeleton width="100%" height={20} />
    <Skeleton width="90%" height={20} />
    <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
      <Box>
        <Skeleton width={100} height={24} />
        <Skeleton width={140} height={20} />
      </Box>
      <Skeleton width={100} height={36} />
    </Box>
  </Box>
);

// Treasure Card Skeleton
export const TreasureCardSkeleton = () => (
  <Box sx={{ width: '100%', height: '100%' }}>
    <Skeleton variant="rectangular" height={200} sx={{ borderRadius: 1 }} />
    <Box sx={{ p: 2 }}>
      <Skeleton width="60%" height={24} sx={{ mb: 1 }} />
      <Skeleton width="40%" height={20} sx={{ mb: 2 }} />
      <Skeleton width="100%" height={20} />
    </Box>
  </Box>
);

// Event Card Skeleton
export const EventCardSkeleton = () => (
  <Box sx={{ 
    width: '100%',
    display: 'flex', 
    flexDirection: { xs: 'column', md: 'row' },
    mb: 3,
    border: '1px solid',
    borderColor: 'divider',
    borderRadius: 1,
    overflow: 'hidden'
  }}>
    {/* Image placeholder */}
    <Skeleton 
      variant="rectangular" 
      sx={{ 
        width: { xs: '100%', md: 300 },
        height: { xs: 200, md: '100%' }
      }} 
    />
    
    {/* Content placeholder */}
    <Box sx={{ p: 3, flex: 1 }}>
      {/* Event type badge */}
      <Skeleton width={100} height={24} sx={{ mb: 2, borderRadius: '16px' }} />
      
      {/* Title */}
      <Skeleton width="80%" height={32} sx={{ mb: 2 }} />
      
      {/* Event details (date, time, location) */}
      <Box sx={{ display: 'flex', gap: 2, mb: 2, flexWrap: 'wrap' }}>
        <Skeleton width={120} height={24} />
        <Skeleton width={120} height={24} />
        <Skeleton width={120} height={24} />
      </Box>
      
      {/* Description */}
      <Skeleton width="100%" height={20} sx={{ mb: 1 }} />
      <Skeleton width="95%" height={20} sx={{ mb: 1 }} />
      <Skeleton width="90%" height={20} sx={{ mb: 3 }} />
      
      {/* Footer - status, capacity, and button */}
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'space-between',
        alignItems: 'center'
      }}>
        <Box>
          <Skeleton width={80} height={20} sx={{ mb: 0.5 }} />
          <Skeleton width={100} height={20} />
        </Box>
        <Skeleton width={100} height={36} />
      </Box>
    </Box>
  </Box>
);

// Details Skeleton
export const TreasureDetailsSkeleton = () => (
  <Box sx={{ display: 'flex', gap: 3, flexDirection: { xs: 'column', sm: 'row' } }}>
    <Box sx={{ width: { xs: '100%', sm: '50%' } }}>
      <Skeleton variant="rectangular" height={300} sx={{ mb: 2, borderRadius: 1 }} />
      <Box sx={{ p: 2, border: '1px solid', borderColor: 'divider', borderRadius: 1 }}>
        <Skeleton width="70%" height={32} sx={{ mb: 2 }} />
        <Skeleton width="40%" height={24} sx={{ mb: 1 }} />
        <Skeleton width="60%" height={24} sx={{ mb: 2 }} />
        <Skeleton width="40%" height={24} sx={{ mb: 1 }} />
        <Skeleton width="60%" height={24} sx={{ mb: 2 }} />
      </Box>
    </Box>
    <Box sx={{ width: { xs: '100%', sm: '50%' } }}>
      <Skeleton width="30%" height={32} sx={{ mb: 2 }} />
      <Skeleton height={400} />
    </Box>
  </Box>
);

// Explore Card Skeleton
export const ExploreCardSkeleton = () => (
  <Box sx={{ 
    width: '100%',
    display: 'flex',
    flexDirection: { xs: 'column', md: 'row' },
    mb: 3,
    border: '1px solid',
    borderColor: 'divider',
    borderRadius: 1,
    overflow: 'hidden'
  }}>
    {/* Image placeholder */}
    <Skeleton 
      variant="rectangular" 
      sx={{ 
        width: { xs: '100%', md: 300 },
        height: { xs: 200, md: 250 }
      }} 
    />
    
    {/* Content placeholder */}
    <Box sx={{ p: 3, flex: 1 }}>
      {/* Category/Type badge */}
      <Skeleton width={80} height={24} sx={{ mb: 2, borderRadius: '16px' }} />
      
      {/* Title */}
      <Skeleton width="70%" height={32} sx={{ mb: 2 }} />
      
      {/* Description */}
      <Skeleton width="100%" height={20} sx={{ mb: 1 }} />
      <Skeleton width="95%" height={20} sx={{ mb: 1 }} />
      <Skeleton width="90%" height={20} sx={{ mb: 3 }} />
      
      {/* User info and date */}
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'space-between',
        alignItems: 'center'
      }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          {/* User avatar */}
          <Skeleton variant="circular" width={40} height={40} />
          <Box>
            <Skeleton width={120} height={20} sx={{ mb: 0.5 }} />
            <Skeleton width={80} height={16} />
          </Box>
        </Box>
        {/* Action buttons */}
        <Box sx={{ display: 'flex', gap: 1 }}>
          <Skeleton width={40} height={40} />
          <Skeleton width={40} height={40} />
        </Box>
      </Box>
    </Box>
  </Box>
); 