import { useState, useCallback } from 'react';
import { Dialog, DialogContent, DialogActions, Button, Box, Slider, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import Cropper from 'react-cropper';
import "cropperjs/dist/cropper.css";
import { ZoomIn, ZoomOut, Refresh } from '@mui/icons-material';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    borderRadius: 12,
    maxWidth: '95vw',
    maxHeight: '90vh'
  }
}));

const ControlButton = styled(Button)(({ theme }) => ({
  minWidth: 40,
  width: 40,
  height: 40,
  padding: 0,
  borderRadius: '50%',
  backgroundColor: theme.palette.grey[100],
  color: theme.palette.grey[800],
  '&:hover': {
    backgroundColor: theme.palette.grey[200],
  }
}));

const StyledSlider = styled(Slider)(({ theme }) => ({
  color: theme.palette.grey[800],
  '& .MuiSlider-thumb': {
    width: 14,
    height: 14,
    '&:hover, &.Mui-focusVisible': {
      boxShadow: `0 0 0 8px ${theme.palette.grey[200]}`
    }
  }
}));

const CropPhotoModal = ({ 
  open, 
  onClose, 
  imageUrl, 
  onCropComplete,
  cropShape = 'circle', // Default for profile photos
  aspectRatio = 1,      // Default 1:1 for profile
  title = 'Adjust Profile Photo',
  dialogWidth = '400px' // Default size for profile
}) => {
  const [cropper, setCropper] = useState(null);
  const [zoom, setZoom] = useState(0);
  const [rotation, setRotation] = useState(0);

  const handleZoomChange = (_, newValue) => {
    if (cropper) {
      setZoom(newValue);
      cropper.zoomTo(newValue);
    }
  };

  const handleRotate = () => {
    if (cropper) {
      const newRotation = (rotation + 90) % 360;
      setRotation(newRotation);
      cropper.rotateTo(newRotation);
    }
  };

  const handleReset = () => {
    if (cropper) {
      cropper.reset();
      setZoom(0);
      setRotation(0);
    }
  };

  const handleCrop = async () => {
    if (cropper) {
      // Larger dimensions for blog header images
      const dimensions = cropShape === 'rect' ? {
        width: 1600,    // Larger width for blog headers
        height: 800,    // Maintain aspect ratio
      } : {
        width: 400,     // Keep profile photo dimensions
        height: 400,
      };

      const croppedCanvas = cropper.getCroppedCanvas({
        ...dimensions,
        imageSmoothingEnabled: true,
        imageSmoothingQuality: 'high'
      });

      const croppedImage = await new Promise((resolve) => {
        croppedCanvas.toBlob((blob) => {
          resolve(blob);
        }, 'image/webp', cropShape === 'rect' ? 0.95 : 0.9); // Higher quality for blog images
      });

      onCropComplete(croppedImage);
      onClose();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      sx={{
        '& .MuiDialog-paper': {
          width: dialogWidth,
          margin: 2,
          height: 'auto',
          maxHeight: '80vh',
          overflow: 'hidden'
        }
      }}
    >
      <DialogContent sx={{ p: 3 }}>
        <Typography variant="h6" sx={{ mb: 2, textAlign: 'center' }}>
          {title}
        </Typography>
        
        <Box sx={{ 
          width: '100%',
          height: cropShape === 'circle' ? 400 : 'auto',
          position: 'relative',
          mb: 3
        }}>
          <Cropper
            src={imageUrl}
            style={{ height: '100%', width: '100%' }}
            aspectRatio={aspectRatio}
            guides={false}
            cropBoxResizable={cropShape === 'rect'}
            onInitialized={(instance) => setCropper(instance)}
            viewMode={1}
            dragMode="move"
            cropBoxMovable={true}
            background={false}
            autoCropArea={1}
            responsive={true}
            checkOrientation={false}
            cropBoxClass={cropShape === 'circle' ? "circular-crop" : undefined}
            ready={() => {
              if (cropShape === 'circle') {
                const cropBox = document.querySelector('.cropper-crop-box');
                if (cropBox) {
                  cropBox.style.borderRadius = '50%';
                  cropBox.style.overflow = 'hidden';
                }
              }
            }}
          />
        </Box>

        <Box sx={{ 
          display: 'flex', 
          alignItems: 'center',
          gap: 2,
          mb: 2
        }}>
          <ControlButton onClick={() => handleZoomChange(null, Math.max(0, zoom - 0.1))}>
            <ZoomOut />
          </ControlButton>
          
          <StyledSlider
            value={zoom}
            onChange={handleZoomChange}
            min={0}
            max={2}
            step={0.1}
            aria-label="Zoom"
            sx={{ flex: 1 }}
          />
          
          <ControlButton onClick={() => handleZoomChange(null, Math.min(2, zoom + 0.1))}>
            <ZoomIn />
          </ControlButton>

          <ControlButton onClick={handleRotate}>
            <Refresh />
          </ControlButton>
        </Box>

        <Typography variant="caption" color="text.secondary" align="center" display="block">
          Drag to position • Use slider to zoom • Click rotate to adjust
        </Typography>
      </DialogContent>

      <DialogActions sx={{ px: 3, pb: 2, gap: 1 }}>
        <Button 
          onClick={handleReset}
          variant="outlined"
          sx={{ borderRadius: 2 }}
        >
          Reset
        </Button>
        <Button 
          onClick={onClose}
          variant="outlined"
          sx={{ borderRadius: 2 }}
        >
          Cancel
        </Button>
        <Button 
          onClick={handleCrop}
          variant="contained"
          sx={{ 
            borderRadius: 2,
            bgcolor: 'grey.800',
            '&:hover': {
              bgcolor: 'grey.900'
            }
          }}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CropPhotoModal; 