import React, { useEffect, useRef } from 'react';
import styles from '../../styles/modules/storyBanner.module.css';
console.log('StoryBanner styles:', styles);

const StoryBanner = () => {
  const bannerRef = useRef(null);
  const contentRef = useRef(null);

  // Parallax effect on mouse movement
  useEffect(() => {
    const handleMouseMove = (e) => {
      if (!bannerRef.current || !contentRef.current) return;
      
      const moveX = (e.clientX * 0.005);
      const moveY = (e.clientY * 0.005);
      
      // Move decorative elements in parallax
      const decoratives = bannerRef.current.querySelectorAll(`.${styles.decorative}`);
      decoratives.forEach((el, i) => {
        const factor = (i % 2 === 0) ? 1 : -1;
        const speed = parseFloat(el.getAttribute('data-speed'));
        el.style.transform = `translate(${moveX * factor * speed}px, ${moveY * factor * speed}px)`;
      });
    };

    document.addEventListener('mousemove', handleMouseMove);
    return () => document.removeEventListener('mousemove', handleMouseMove);
  }, []);

  return (
    <div className={styles.bannerContainer}>
      <div className={styles.banner} ref={bannerRef}>
        {/* Decorative elements */}
        <div className={styles.decorative} data-speed="5"></div>
        <div className={styles.decorative} data-speed="3"></div>
        <div className={styles.decorative} data-speed="7"></div>
        
        <div className={styles.bannerTrack}>
          <div className={styles.bannerContent} ref={contentRef}>
            {['For Keep\'s Sake', 'For Keep\'s Sake', 'For Keep\'s Sake', 
              'For Keep\'s Sake', 'For Keep\'s Sake', 'For Keep\'s Sake'].map((text, index) => (
              <div className={styles.textGroup} key={index}>
                <span className={styles.text}>{text}</span>
                <span className={styles.dot}>•</span>
              </div>
            ))}
          </div>
        </div>
        
        {/* Edge fade effect */}
        <div className={styles.overlay}></div>
      </div>
    </div>
  );
};

export default StoryBanner; 