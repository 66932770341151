import React, { useState, useEffect } from 'react';
import { 
  Dialog, 
  DialogContent, 
  DialogTitle, 
  TextField, 
  Button, 
  Box, 
  Typography,
  CircularProgress,
  IconButton,
  Alert
} from '@mui/material';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import { sendPasswordResetEmail } from 'firebase/auth';
import { auth, appCheck } from '../../firebase';
import { getToken } from 'firebase/app-check';
import DOMPurify from 'dompurify';

// Styled components
const ModalContent = styled(DialogContent)(({ theme }) => ({
  padding: theme.spacing(3),
  minWidth: { xs: '300px', sm: '400px' },
  maxWidth: '450px',
}));

const SubmitButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  padding: theme.spacing(1.2),
  backgroundColor: '#11311B',
  '&:hover': {
    backgroundColor: '#11311B',
    opacity: 0.9,
  },
  textTransform: 'none',
  fontSize: '1rem',
  fontWeight: 500,
  borderRadius: '12px',
}));

// Styled alert with custom colors
const StyledAlert = styled(Alert)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  backgroundColor: theme.palette.error.light,
  color: theme.palette.error.contrastText
}));

/**
 * ForgotPasswordModal component for handling password reset requests
 * Protected by Firebase App Check with reCAPTCHA v3
 * 
 * @param {Object} props - Component props
 * @param {boolean} props.open - Whether the modal is open
 * @param {function} props.onClose - Function to close the modal
 */
const ForgotPasswordModal = ({ open, onClose }) => {
  const [email, setEmail] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState('');
  const [appCheckToken, setAppCheckToken] = useState(null);

  // Verify App Check on mount in production, but only if we don't have a token
  useEffect(() => {
    if (process.env.NODE_ENV === 'production' && open && !appCheckToken) {
      verifyAppCheck();
    }
  }, [open, appCheckToken]);

  // Function to verify App Check is working
  const verifyAppCheck = async () => {
    try {
      // Only get a new token if we don't have one
      if (!appCheckToken) {
        const token = await getToken(appCheck, /* forceRefresh */ false);
        setAppCheckToken(token);
        console.log('App Check verification successful');
      }
    } catch (error) {
      console.error('App Check verification failed:', error);
      setError('Unable to verify security check. Please refresh the page.');
    }
  };

  // Handle email input change
  const handleEmailChange = (e) => {
    const newValue = e.target.value;
    setEmail(newValue);
    if (error) setError('');
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Validate email
    if (!email) {
      setError('Please enter your email address');
      return;
    }

    // Validate email format with a simple regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setError('Please enter a valid email address');
      return;
    }

    setIsSubmitting(true);
    setError('');
    
    try {
      // In production, ensure we have a valid App Check token
      if (process.env.NODE_ENV === 'production') {
        if (!appCheckToken) {
          // Only request a new token if we don't have one
          try {
            const token = await getToken(appCheck, /* forceRefresh */ false);
            setAppCheckToken(token);
            console.log('Security verification successful');
          } catch (error) {
            console.error('Security verification failed:', error);
            throw new Error('security-check-failed');
          }
        }
      }

      // Sanitize email input
      const sanitizedEmail = DOMPurify.sanitize(email.trim().toLowerCase());
      
      // Send password reset email through Firebase
      await sendPasswordResetEmail(auth, sanitizedEmail);
      
      // Show success message regardless of whether email exists in Firebase
      setSubmitted(true);
    } catch (error) {
      if (error.message === 'security-check-failed') {
        setError('Security verification failed. Please refresh the page and try again.');
      } else if (error.code === 'app-check/invalid-token' || 
                 error.code === 'app-check/token-expired') {
        // Clear the token and try to get a new one next time
        setAppCheckToken(null);
        setError('Security verification failed. Please try again.');
      } else if (error.code === 'app-check/forbidden') {
        setError('Security check failed. Please ensure you are not using a VPN or private browsing.');
      } else if (error.code === 'app-check/throttled') {
        setError('Too many attempts. Please try again in a few minutes.');
      } else if (error.code === 'app-check/unknown') {
        setError('Security verification is currently unavailable. Please try again later.');
      } else {
        console.error('Password reset error:', error);
        setError('Something went wrong. Please try again later.');
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  // Handle modal close - reset state
  const handleClose = () => {
    // Only allow closing if not currently submitting
    if (!isSubmitting) {
      setEmail('');
      setError('');
      setSubmitted(false);
      onClose();
    }
  };

  return (
    <Dialog 
      open={open} 
      onClose={handleClose}
      aria-labelledby="forgot-password-title"
    >
      <DialogTitle id="forgot-password-title" sx={{ pr: 6 }}>
        {submitted ? 'Check Your Email' : 'Reset Password'}
        <IconButton
          aria-label="close"
          onClick={handleClose}
          disabled={isSubmitting}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <ModalContent>
        {submitted ? (
          // Success message
          <Box sx={{ textAlign: 'center', py: 2 }}>
            <Typography variant="body1" paragraph>
              If your email address is registered with us, we've sent a password reset link.
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Please check your inbox and spam folder. The link will expire after some time for security reasons.
            </Typography>
            <Button
              fullWidth
              variant="contained"
              onClick={handleClose}
              sx={{
                mt: 3,
                backgroundColor: '#11311B',
                '&:hover': {
                  backgroundColor: '#11311B',
                  opacity: 0.9,
                },
              }}
            >
              Close
            </Button>
          </Box>
        ) : (
          // Password reset form
          <form onSubmit={handleSubmit}>
            <Typography variant="body2" color="text.secondary" paragraph>
              Enter your email address and we'll send you a link to reset your password.
            </Typography>
            
            {error && (
              <StyledAlert severity="error" sx={{ mb: 2 }}>
                {error}
              </StyledAlert>
            )}
            
            <TextField
              fullWidth
              label="Email Address"
              type="email"
              value={email}
              onChange={handleEmailChange}
              error={!!error}
              disabled={isSubmitting}
              autoFocus
              margin="normal"
              required
            />
            
            <SubmitButton
              type="submit"
              fullWidth
              variant="contained"
              disabled={isSubmitting}
            >
              {isSubmitting ? <CircularProgress size={24} color="inherit" /> : "Send Reset Link"}
            </SubmitButton>
          </form>
        )}
      </ModalContent>
    </Dialog>
  );
};

export default ForgotPasswordModal; 