import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography
} from '@mui/material';

const ConfirmDialog = ({ 
  open, 
  onClose, 
  onConfirm, 
  title = 'Discard Changes?',
  message = 'You have unsaved changes. Are you sure you want to close this form?',
  confirmText = 'Discard',
  cancelText = 'Cancel'
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="xs"
      fullWidth
      aria-labelledby="confirm-dialog-title"
      disableEnforceFocus
      disableRestoreFocus
    >
      <DialogTitle id="confirm-dialog-title">
        <Typography variant="h6">
          {title}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography>
          {message}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button 
          onClick={onClose}
          color="primary"
        >
          {cancelText}
        </Button>
        <Button 
          onClick={onConfirm}
          color="error"
        >
          {confirmText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog; 