import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { styled, useTheme, keyframes } from '@mui/material/styles';
import { Box, Typography, Paper, Button, CardContent, IconButton } from '@mui/material';
import { EditOutlined, DeleteOutline } from '@mui/icons-material';
import ProgressiveImage from './ProgressiveImage';
import { format } from 'date-fns';
import ReadBlog from './modals/ReadBlog';
import ConfirmDialog from './common/ConfirmDialog';
import api from '../services/api';
import { useToast } from '../contexts/ToastContext';

const pulse = keyframes`
  0% { transform: scale(0.95); box-shadow: 0 0 0 0 rgba(26, 74, 43, 0.7); }
  70% { transform: scale(1); box-shadow: 0 0 0 6px rgba(26, 74, 43, 0); }
  100% { transform: scale(0.95); box-shadow: 0 0 0 0 rgba(26, 74, 43, 0); }
`;

const TimeDivider = styled('span')(({ theme }) => ({
  display: 'inline-block',
  width: '4px',
  height: '4px',
  borderRadius: '50%',
  backgroundColor: '#1a4a2b',
  margin: '0 8px',
  verticalAlign: 'middle',
  animation: `${pulse} 2s infinite`,
  transformOrigin: 'center',
}));

const BlogCardContainer = styled(Paper)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '8px',
  overflow: 'hidden',
  boxShadow: 'none',
  border: `1px solid ${theme.palette.divider}`,
  transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
  '&:hover': { 
    transform: 'translateY(-4px)', 
    boxShadow: theme.shadows[4] 
  },
  minHeight: '500px',
  width: '100%',
  cursor: 'pointer',
}));

const BlogImage = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '220px',
  position: 'relative',
  backgroundColor: theme.palette.grey[200],
  overflow: 'hidden',
  borderTopLeftRadius: '8px',
  borderTopRightRadius: '8px',
  '&::after': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.02)',
    zIndex: 1
  }
}));

const ContentWrapper = styled(CardContent)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  padding: theme.spacing(2.5),
  position: 'relative',
  overflow: 'hidden'
}));

const CategoryBadge = styled(Box)(({ theme }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '2px 8px',
  borderRadius: '20px',
  fontSize: '0.65rem',
  fontWeight: 500,
  textTransform: 'uppercase',
  backgroundColor: 'rgba(17, 49, 27, 0.06)',
  border: '1px solid rgba(17, 49, 27, 0.15)',
  color: '#11311B',
  margin: '0 auto',
  alignSelf: 'center',
  letterSpacing: '0.5px',
  boxShadow: '0 1px 3px rgba(0,0,0,0.03)',
  transition: 'all 0.2s ease',
  marginBottom: theme.spacing(1.5),
  minHeight: '24px',
  '&:hover': {
    backgroundColor: 'rgba(17, 49, 27, 0.08)',
    boxShadow: '0 2px 4px rgba(0,0,0,0.05)',
    transform: 'translateY(-1px)',
  }
}));

const stripHtmlTags = (html) => {
  if (!html) return '';
  
  // First remove script and style tags with their content
  let text = html.replace(/<(script|style)\b[^<]*(?:(?!<\/\1>)<[^<]*)*<\/\1>/gi, '');
  
  // Remove all HTML tags but keep their content
  text = text.replace(/<[^>]+>/g, ' ');
  
  // Handle special characters
  text = text.replace(/&nbsp;/g, ' ')
             .replace(/&amp;/g, '&')
             .replace(/&lt;/g, '<')
             .replace(/&gt;/g, '>')
             .replace(/&quot;/g, '"')
             .replace(/&#039;/g, "'");
  
  // Clean up whitespace
  text = text.replace(/\s+/g, ' ').trim();
  
  return text;
};

const BlogCard = ({ blog, userRole, index, onEdit, onDelete }) => {
  const { id, title, description, category, author, date, read_time, image_url, hasrights } = blog;
  const theme = useTheme();
  const { showToast } = useToast();
  
  const [isReadModalOpen, setIsReadModalOpen] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

  const handleEdit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onEdit(blog);
  };

  const handleDelete = (e) => {
    e.stopPropagation();
    setShowDeleteConfirm(true);
  };

  const handleConfirmDelete = async () => {
    try {
      await api.delete(`/api/blogs/${id}`);
      onDelete(id);
      showToast('Blog deleted successfully', 'success');
    } catch (error) {
      console.error('Failed to delete blog:', error);
      showToast('Failed to delete blog', 'error');
    } finally {
      setShowDeleteConfirm(false);
    }
  };

  const handleReadMore = (e) => {
    e.stopPropagation();
    setIsReadModalOpen(true);
  };

  const handleCardClick = () => {
    setIsReadModalOpen(true);
  };

  return (
    <>
      <BlogCardContainer onClick={handleCardClick}>
        <BlogImage>
          <ProgressiveImage
            src={image_url}
            alt={title}
            style={{ 
              width: '100%', 
              height: '100%', 
              objectFit: 'cover', 
              objectPosition: 'center center',
              position: 'absolute', 
              top: 0, 
              left: 0,
              zIndex: 0
            }}
          />
        </BlogImage>
        <ContentWrapper>
          <CategoryBadge>{category}</CategoryBadge>
          <Typography
            variant="h6"
            sx={{
              fontWeight: 600,
              fontSize: '1.1rem',
              mb: 1.5,
              color: '#11311B',
              lineHeight: 1.4,
            }}
          >
            {title}
          </Typography>
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{
              mb: 2.5,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: 3,
              WebkitBoxOrient: 'vertical',
              wordBreak: 'break-word',
              position: 'relative',
              lineHeight: 1.7,
              fontSize: '0.875rem',
              '&::after': {
                content: '""',
                position: 'absolute',
                bottom: 0,
                right: 0,
                width: '100%',
                height: '1.5em',
                background: 'linear-gradient(to bottom, transparent, white)',
                pointerEvents: 'none'
              }
            }}
          >
            {stripHtmlTags(description)}
          </Typography>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              mt: 'auto',
              pt: 1.5,
              borderTop: '1px solid',
              borderColor: 'divider',
            }}
          >
            <Box>
              <Typography variant="subtitle2">{author}</Typography>
              <Typography variant="caption" color="text.secondary">
                {format(new Date(date), 'MMMM d, yyyy')}
                <TimeDivider />
                {read_time}
              </Typography>
            </Box>
            {(userRole === 'admin' || hasrights) && (
              <Box sx={{ display: 'flex', gap: 1 }}>
                <IconButton 
                  onClick={handleEdit}
                  size="small"
                  sx={{
                    '&:hover': {
                      color: theme.palette.primary.main,
                      backgroundColor: theme.palette.action.hover,
                    }
                  }}
                >
                  <EditOutlined fontSize="small" />
                </IconButton>
                <IconButton 
                  onClick={handleDelete} 
                  size="small"
                  sx={{
                    '&:hover': {
                      color: theme.palette.error.main,
                      backgroundColor: theme.palette.action.hover,
                    }
                  }}
                >
                  <DeleteOutline fontSize="small" />
                </IconButton>
              </Box>
            )}
          </Box>
          <Button
            variant="outlined"
            size="small"
            onClick={handleReadMore}
            sx={{
              mt: 2,
              borderRadius: theme.shape.borderRadius,
              textTransform: 'none',
              borderColor: 'grey.400',
              '&:hover': { borderColor: 'grey.600' },
            }}
          >
            Read More
          </Button>
        </ContentWrapper>
      </BlogCardContainer>

      <ReadBlog
        blog={blog}
        open={isReadModalOpen}
        onClose={() => setIsReadModalOpen(false)}
        index={index}
      />

      <ConfirmDialog
        open={showDeleteConfirm}
        onClose={() => setShowDeleteConfirm(false)}
        onConfirm={handleConfirmDelete}
        title="Delete Blog Post"
        message="Are you sure you want to delete this blog post? This action cannot be undone."
        confirmText="Delete"
        confirmColor="error"
      />
    </>
  );
};

BlogCard.propTypes = {
  blog: PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    category: PropTypes.string.isRequired,
    author: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    read_time: PropTypes.string.isRequired,
    image_url: PropTypes.string.isRequired,
    hasrights: PropTypes.bool.isRequired,
  }).isRequired,
  userRole: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default BlogCard;
