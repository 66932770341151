import { useState } from 'react';
import api from '../services/api'; // For presigned URL request
import axios from 'axios'; // For S3 upload
import { getAuth } from 'firebase/auth';

const useS3Upload = () => {
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [error, setError] = useState(null);

  const uploadToS3 = async (file, onProgress) => {
    setIsUploading(true);
    setError(null);
    setUploadProgress(0);

    try {
      // 1. Get presigned URL through your backend using the api instance
      const { data } = await api.post('/api/treasures/presigned-url', {
        fileName: `${Date.now()}-${file.name}`,
        fileType: file.type
      });

      // 2. Upload to S3 directly using raw axios (NO headers except Content-Type)
      await axios.put(data.uploadUrl, file, {
        headers: {
          'Content-Type': file.type
        },
        onUploadProgress: (progressEvent) => {
          const progress = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setUploadProgress(progress);
          if (onProgress) onProgress(progress);
        }
      });

      return data.publicUrl;
    } catch (error) {
      console.error('Upload error:', error);
      setError(error.response?.data?.message || 'Failed to upload image');
      throw error;
    } finally {
      setIsUploading(false);
    }
  };

  return {
    uploadToS3,
    uploadProgress,
    isUploading,
    error,
    resetUpload: () => {
      setUploadProgress(0);
      setIsUploading(false);
      setError(null);
    }
  };
};

export default useS3Upload; 