import { useState, useRef, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import {
  Box,
  Typography,
  IconButton,
  Badge,
  Chip,
  Avatar,
  useTheme,
  useMediaQuery,
  Container,
  Divider,
  Menu,
  MenuItem,
  Fade,
  Grid,
  Button,
} from '@mui/material';
import {
  CheckCircleOutline as CheckCircleIcon,
  NotificationsNone as NotificationIcon,
  Event as EventIcon,
  Favorite as HeartIcon,
  CheckCircle as ReadIcon,
  Article as BlogIcon,
} from '@mui/icons-material';
import { useAuth } from '../contexts/AuthContext';
import api from '../services/api';
import { formatDistanceToNow } from 'date-fns';

// Styled Components
const NotificationContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  backgroundColor: 'transparent',
  borderRadius: theme.shape.borderRadius * 2,
  overflow: 'hidden',
  boxShadow: '0 2px 12px rgba(0,0,0,0.08)',
  '& > *:first-of-type': {
    borderTopLeftRadius: theme.shape.borderRadius * 2,
    borderTopRightRadius: theme.shape.borderRadius * 2,
  },
  '& > *:last-child': {
    borderBottomLeftRadius: theme.shape.borderRadius * 2,
    borderBottomRightRadius: theme.shape.borderRadius * 2,
    borderBottom: 'none',
  }
}));

const NotificationItem = styled(Box)(({ theme, unread }) => ({
  position: 'relative',
  padding: theme.spacing(2),
  display: 'flex',
  alignItems: 'flex-start',
  gap: theme.spacing(2),
  cursor: 'pointer',
  borderBottom: `1px solid ${theme.palette.divider}`,
  transform: 'translateX(0)',
  transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
  overflow: 'hidden',
  backgroundColor: '#ffffff',
  
  '&::before': unread ? {
    content: '""',
    position: 'absolute',
    left: 0,
    top: 0,
    bottom: 0,
    width: 4,
    backgroundColor: '#C6AB70'
  } : {},

  '&:hover': {
    backgroundColor: '#f8f8f8',
  },
}));

const NotificationIconWrapper = styled(Box)(({ theme, type }) => ({
  width: 48,
  height: 48,
  borderRadius: theme.shape.borderRadius,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  transition: 'transform 0.2s ease',
  overflow: 'hidden',
  backgroundColor: 'rgba(17, 49, 27, 0.08)',
  color: '#11311B',
  '& svg': {
    fontSize: 20,
  },
  [`${NotificationItem}:hover &`]: {
    transform: 'scale(1.1)',
  }
}));

const TimeChip = styled(Chip)(({ theme }) => ({
  backgroundColor: 'rgba(17, 49, 27, 0.08)',
  color: '#11311B',
  fontSize: '0.75rem',
  height: 24,
  fontWeight: 500,
  '& .MuiChip-label': {
    padding: '0 8px',
  }
}));

// Main Component
const Notifications = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [notifications, setNotifications] = useState([]);
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);
  const [swipedNotificationId, setSwipedNotificationId] = useState(null);
  const auth = useAuth();
  const [hasFetched, setHasFetched] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        setLoading(true);
        const { data } = await api.get('/api/notifications');
        setNotifications(data);
        setHasFetched(true);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching notifications:', error);
      }
    };

    if (!hasFetched) {
      fetchNotifications();
    }
  }, [auth.currentUser?.uid, hasFetched]);

  // Touch handlers for mobile swipe
  const handleTouchStart = (e) => {
    setTouchStart(e.targetTouches[0].clientX);
  };

  const handleTouchMove = (e, notificationId) => {
    setTouchEnd(e.targetTouches[0].clientX);
    
    const diff = touchStart - e.targetTouches[0].clientX;
    if (diff > 40) {
      setSwipedNotificationId(notificationId);
    } else {
      setSwipedNotificationId(null);
    }
  };

  const handleTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    setTouchStart(null);
    setTouchEnd(null);
  };

  // Notification handlers
  const handleDeleteNotification = (id) => {
    setNotifications(prev => prev.filter(notif => notif.id !== id));
  };

  const handleMarkAsRead = (id) => {
    setNotifications(prev => prev.map(notif => 
      notif.id === id ? { ...notif, has_read: true } : notif
    ));
  };

  const handleMarkAllAsRead = async () => {
    try {
      await api.put('/api/notifications/mark-all-read');
      setNotifications(prev => prev.map(n => ({ ...n, has_read: true })));
    } catch (error) {
      console.error('Error marking all notifications as read:', error);
    }
  };

  const handleNotificationClick = async (id) => {
    // Find the notification in state first
    const notification = notifications.find(n => n.id === id);
    
    // If already read, do nothing
    if (notification?.has_read) return;

    try {
      await api.put(`/api/notifications/${id}/read`);
      setNotifications(prev => prev.map(n => 
        n.id === id ? { ...n, has_read: true } : n
      ));
    } catch (error) {
      console.error('Error marking notification as read:', error);
    }
  };

  const renderNotificationIcon = (type) => {
    switch (type) {
      case 'event':
        return <EventIcon />;
      case 'system':
        return <NotificationIcon />;
      case 'like':
        return <HeartIcon />;
      case 'blog':
        return <BlogIcon />;
      default:
        return <NotificationIcon />;
    }
  };

  return (
    <Container maxWidth="lg" sx={{ py: 4, pb: { xs: '70px', sm: 4 } }}>
      <Box sx={{ 
        mb: 4, 
        display: 'flex', 
        alignItems: 'center',
        justifyContent: 'space-between'
      }}>
        <Typography 
          variant="h4" 
          sx={{ 
            fontWeight: 600,
            fontSize: { xs: '1.75rem', sm: '2rem' },
            letterSpacing: '-0.5px'
          }}
        >
          Notifications
        </Typography>
        {notifications.some(n => !n.has_read) && (
          <Button
            variant="contained"
            color="primary"
            onClick={handleMarkAllAsRead}
            startIcon={<CheckCircleIcon />}
            sx={{
              textTransform: 'none',
              borderRadius: '8px',
              px: 3,
              py: 1,
              fontSize: '0.875rem',
              fontWeight: 500,
              background: '#1a4a2b',
              color: '#ffffff',
              boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
              transition: 'all 0.2s ease',
              letterSpacing: '0.025em',
              '&:hover': {
                boxShadow: '0 4px 12px rgba(76,175,80,0.4)',
                transform: 'translateY(-1px)',
                background: '#11311B',
                color: '#fff'
              },
              '&.Mui-disabled': {
                background: theme.palette.grey[300],
                color: theme.palette.grey[500],
                boxShadow: 'none'
              }
            }}
          >
            Mark All as Read
          </Button>
        )}
      </Box>

      <NotificationContainer>
        {notifications.map((notification) => (
          <Box key={notification.id} sx={{ position: 'relative', overflow: 'hidden' }}>
            <NotificationItem
              unread={!notification.has_read}
              type={notification.type}
              swiped={swipedNotificationId === notification.id}
              onTouchStart={isMobile ? handleTouchStart : undefined}
              onTouchMove={isMobile ? (e) => handleTouchMove(e, notification.id) : undefined}
              onTouchEnd={isMobile ? handleTouchEnd : undefined}
              onClick={() => handleNotificationClick(notification.id)}
            >
              <NotificationIconWrapper type={notification.type}>
                <Avatar 
                  src={notification.is_video ? notification.video_thumbnail : notification.image_url}
                  sx={{ 
                    width: 48,
                    height: 48,
                    borderRadius: 1,
                    objectFit: 'cover' 
                  }}
                />
              </NotificationIconWrapper>

              <Box sx={{ flex: 1 }}>
                <Box sx={{ 
                  display: 'block',
                  overflow: 'hidden'
                }}>
                  <Typography 
                    variant="subtitle2" 
                    sx={{ 
                      fontSize: '0.95rem',
                      letterSpacing: '-0.3px',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis'
                    }}
                  >
                    <span style={{ fontWeight: 600 }}>{notification.sender_name}</span>
                    <span style={{ fontWeight: 400 }}> {notification.message}</span>
                  </Typography>
                </Box>

                <Typography 
                  variant="body2" 
                  color="text.secondary"
                  sx={{ 
                    lineHeight: 1.5,
                    letterSpacing: '-0.2px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'flex-end'
                  }}
                >
                  {notification.treasure_name}
                  <TimeChip 
                    label={formatDistanceToNow(new Date(notification.created_at), { 
                      addSuffix: true 
                    })} 
                    size="small" 
                    sx={{ 
                      ml: 1,
                      alignSelf: 'flex-end' 
                    }}
                  />
                </Typography>

                {notification.type === 'event' && (
                  <Box sx={{ mt: 2 }}>
                    <Chip 
                      label="View Event"
                      size="small"
                      color="primary"
                      onClick={(e) => e.stopPropagation()}
                    />
                  </Box>
                )}
              </Box>
            </NotificationItem>
          </Box>
        ))}
      </NotificationContainer>

      {notifications.length === 0 && !loading && (
        <Grid item xs={12}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              py: 8,
              px: 2,
              textAlign: 'center'
            }}
          >
            <NotificationIcon 
              sx={{ 
                fontSize: 64, 
                color: '#C6AB70',
                mb: 2,
                opacity: 0.4
              }}
            />
            <Typography variant="h6" color="text.secondary">
              No Notifications Yet
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
              Your notifications will appear here when you receive them
            </Typography>
          </Box>
        </Grid>
      )}
    </Container>
  );
};

export default Notifications; 