import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { useToast } from '../contexts/ToastContext';
import { CircularProgress, Box } from '@mui/material';
import { useState, useEffect } from 'react';
import FirstTimeUserModal from './FirstTimeUserModal';

const ProtectedRoute = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { 
    isAuthenticated, 
    loading, 
    currentUser,
    subscriptionStatus,
    refreshSubscription
  } = useAuth();
  const { closeModal } = useToast();
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (!loading && !isAuthenticated && !currentUser) {
      // Store the attempted path for redirect after login
      localStorage.setItem('redirectPath', location.pathname);
      // Redirect to login
      navigate('/login', { 
        replace: true,
        state: { from: location }
      });
    }
  }, [loading, isAuthenticated, currentUser, navigate, location]);

  useEffect(() => {
    if (!loading && isAuthenticated && subscriptionStatus?.status === 'active') {
      closeModal();
    }
  }, [loading, isAuthenticated, subscriptionStatus, closeModal]);

  useEffect(() => {
    if (!subscriptionStatus) return;

    const shouldShowModal = 
      subscriptionStatus.status === 'none' ||
      subscriptionStatus.status === 'expired' ||
      !['active', 'trial'].includes(subscriptionStatus.status);

    if (shouldShowModal) {
      setShowModal(true);
    } else {
      setShowModal(false);
    }
  }, [subscriptionStatus]);

  useEffect(() => {
    return () => setShowModal(false);
  }, []);

  useEffect(() => {
    if (location.state?.fromPayment) {
      navigate(location.pathname, { replace: true, state: {} });
      
      if (subscriptionStatus?.status !== 'active') {
        refreshSubscription(true);
      }
    }
  }, [location.state, navigate, subscriptionStatus, refreshSubscription]);

  if (loading) {
    return <Box sx={{ 
      display: 'flex', 
      justifyContent: 'center', 
      alignItems: 'center', 
      height: '100vh' 
    }}>
      <CircularProgress />
    </Box>;
  }

  // If not authenticated, don't render children
  if (!isAuthenticated || !currentUser) {
    return null;
  }

  return (
    <>
      {children}
      <FirstTimeUserModal 
        open={showModal}
        setShowModal={setShowModal}
      />
    </>
  );
};

export default ProtectedRoute; 