import React, { useState } from 'react';
import styles from '../../styles/modules/newsletter.module.css';
import { appCheck } from '../../../firebase';
import { getToken } from 'firebase/app-check';
import DOMPurify from 'dompurify';
import api from '../../../services/api';
import { Link } from 'react-router-dom';

const Newsletter = () => {
  const [email, setEmail] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError('');
    
    if (!email) {
      setError('Please enter your email address');
      setIsSubmitting(false);
      return;
    }
    
    // Validate email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setError('Please enter a valid email address');
      setIsSubmitting(false);
      return;
    }
    
    try {
      // Verify reCAPTCHA using App Check
      let token;
      if (process.env.NODE_ENV === 'production') {
        try {
          token = await getToken(appCheck, /* forceRefresh */ false);
          console.log('Newsletter security verification successful');
        } catch (error) {
          console.error('Newsletter security verification failed:', error);
          throw new Error('security-verification-failed');
        }
      }
      
      // Sanitize email
      const sanitizedEmail = DOMPurify.sanitize(email.trim().toLowerCase());
      
      // Submit to backend API
      const response = await api.post('/api/membership/newsletter-signup', { 
        email: sanitizedEmail,
        // Optional: Include token for additional server-side validation
        appCheckToken: token?.token || null
      });
      
      // Show success message
      setSuccess(true);
      // Clear the form
      setEmail('');
      
    } catch (error) {
      // Handle specific errors
      if (error.message === 'security-verification-failed') {
        setError('Security verification failed. Please try again later.');
      } else if (error.code?.includes('app-check/')) {
        setError('Security verification failed. Please try again.');
      } else if (error.response?.data?.message) {
        setError(error.response.data.message);
      } else {
        setError('Something went wrong. Please try again later.');
      }
      console.error('Newsletter submission error:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <section className={styles.newsletterSection}>
      <h3 className={styles.newsletterTitle}>
        Subscribe to our newsletter for updates, events, and featured treasures.
      </h3>
      
      {success ? (
        <div className={styles.newsletterTitle}>
          <p>Thank you for subscribing to our newsletter!</p>
       
        </div>
      ) : (
        <>
          {error && <div className={styles.errorMessage}>{error}</div>}
          
          <form onSubmit={handleSubmit} className={styles.subscribeForm}>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email"
              className={styles.emailInput}
              required
              disabled={isSubmitting}
            />
            <button 
              type="submit" 
              className={styles.submitButton}
              disabled={isSubmitting}
            >
              {isSubmitting ? 'Submitting...' : 'Submit'}
            </button>
          </form>
        </>
      )}

      <p className={styles.privacyText}>
        By subscribing, you confirm that you have read the <Link to="/terms" className={styles.privacyLink}>Privacy Policy</Link>.
      </p>
    </section>
  );
};

export default Newsletter; 