import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, IconButton, DialogContent, useMediaQuery, useTheme } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { motion } from 'framer-motion';
import CoffeeStain from '../../assets/coffee-stain.png';


const StyledDialog = styled(Dialog)(({ theme }) => ({
  fullScreen: useMediaQuery(theme.breakpoints.down('sm')),
  '& .MuiDialog-paper': {
    width: '100%',
    maxWidth: '900px',
    margin: theme.spacing(2),
    borderRadius: '16px 4px 16px 4px',
    overflow: 'hidden',
    background: `
      linear-gradient(
        to bottom right, 
        rgba(253,249,235,0.95) 0%, 
        rgba(250,245,225,0.92) 100%
      ),
      repeating-linear-gradient(
        45deg,
        transparent,
        transparent 4px,
        rgba(0,0,0,0.03) 4px,
        rgba(0,0,0,0.03) 5px,
        transparent 5px,
        transparent 10px
      )`,
    backgroundSize: 'auto, 20px 20px',
    backgroundBlendMode: 'multiply',
    backgroundPosition: 'center',
    border: '2px solid #e1d7c8',
    boxShadow: `
      15px 15px 0 rgba(0,0,0,0.1),
      0 0 12px rgba(0,0,0,0.05) inset
    `,
    transform: 'rotate(-0.5deg)',
    maxHeight: '90vh',
    position: 'relative',
    overflowX: 'hidden',
    '&::before': {
      content: '""',
      position: 'absolute',
      top: -15,
      right: -15,
      width: 60,
      height: 60,
      background: 'rgba(26, 74, 43, 0.15)',
      transform: 'rotate(45deg)',
      zIndex: 1,
    },
    '&::after': {
      content: '""',
      position: 'absolute',
      bottom: -20,
      left: -25,
      width: 80,
      height: 50,
      background: 'rgba(26, 74, 43, 0.15)',
      transform: 'rotate(-20deg)',
      zIndex: 1,
      boxShadow: '3px 3px 6px rgba(0,0,0,0.15)',
      clipPath: 'polygon(0 0, 100% 0, 85% 100%, 0 100%)'
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
      margin: 0,
      borderRadius: 0,
      maxHeight: '100vh',
      transform: 'none',
      width: '100%'
    }
  },
}));

const BlogContent = styled(motion.div)(({ theme }) => ({
  maxWidth: '750px',
  margin: '0 auto',
  backgroundColor: 'transparent',
  padding: theme.spacing(5, 4),
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(3, 2),
    maxWidth: '100%'
  },
  fontFamily: '"Caveat", cursive, sans-serif',
  position: 'relative',
  '&::after': {
    content: '""',
    position: 'absolute',
    bottom: -40,
    right: -40,
    width: 200,
    height: 200,
    background: `url(${CoffeeStain})`,
    opacity: 0.4,
    zIndex: 0,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    pointerEvents: 'none',
    transform: 'rotate(23deg) scale(1.1)',
    transformOrigin: 'bottom right'
  },
  
  // Handwritten text styles
  '& h1': {
    fontSize: '2.8rem',
    marginBottom: theme.spacing(3),
    color: '#3a2f1d',
    fontFamily: '"Caveat", cursive',
    textShadow: '1px 1px 2px rgba(0,0,0,0.1)',
    letterSpacing: '0.5px',
    borderBottom: '2px solid #8b735b',
    transform: 'rotate(-0.3deg)'
  },
  '& h2': {
    fontSize: '2rem',
    margin: theme.spacing(3, 0, 2),
    color: '#4a3a28',
    fontFamily: '"Caveat", cursive',
    textDecoration: 'underline wavy #c6ab70',
    transform: 'rotate(0.5deg)'
  },
  '& h3': {
    fontSize: '1.6rem',
    margin: theme.spacing(2, 0, 1.5),
    color: '#5a4a38',
    fontFamily: '"Caveat", cursive',
    transform: 'rotate(-0.2deg)'
  },
  '& h4': {
    fontSize: '1.4rem',
    margin: theme.spacing(2, 0, 1.5),
    color: '#5a4a38',
    fontFamily: '"Caveat", cursive',
    transform: 'rotate(-0.2deg)'
  },
  '& p': {
    marginBottom: theme.spacing(2),
    lineHeight: 1.7,
    color: '#4a3a28',
    fontSize: '1.1rem',
    fontFamily: 'Helvetica, Arial, sans-serif',
    textShadow: '0.5px 0.5px 1px rgba(0,0,0,0.05)'
  },
  // List styling for both ul and ol
  '& ul, & ol': {
    paddingLeft: theme.spacing(4),
    marginBottom: theme.spacing(3),
    '& li': {
      marginBottom: theme.spacing(1),
      fontFamily: 'Helvetica, Arial, sans-serif',
      fontSize: '1.1rem',
      '&::marker': {
        color: '#5a4a38',
      },
    },
  },
  // Additional styling for unordered lists
  '& ul': {
    listStyleType: 'disc',
  },
  // Additional styling for ordered lists
  '& ol': {
    listStyleType: 'decimal',
  },
  '& blockquote': {
    margin: theme.spacing(3, 0),
    padding: theme.spacing(2),
    borderLeft: `3px solid #c6ab70`,
    backgroundColor: 'rgba(198, 171, 112, 0.08)',
    fontFamily: 'Georgia, serif',
    fontSize: '1.1rem'
  },
  '& img': {
    border: '2px solid #d4c9b9',
    boxShadow: '3px 3px 0 rgba(0,0,0,0.1)',
    transform: 'rotate(0.5deg)'
  },
  '& code': {
    fontFamily: 'Monaco, Consolas, monospace',
    fontSize: '1rem',
    backgroundColor: 'rgba(17, 49, 27, 0.1)',
    padding: theme.spacing(0.25, 0.75),
    borderRadius: 4
  }
}));

const helpContent = `
  <h1>Hey there, fellow storyteller! 👋</h1>
  
  <p>Welcome to the Something TOLD blog creator! I'm here to help you create some amazing content. Let's make your blog shine! ✨</p>

  <h2>The Basics (But Make It Fun)</h2>
  <p>You've probably noticed this cool text editor we've got here. Think of it as your creative playground - way more exciting than a plain old text box, right?</p>

  <h3>Quick Style Guide</h3>
  <ul>
    <li>Want text to <strong>pop</strong>? Highlight it and click the B for bold</li>
    <li>Feeling fancy? Try <em>italics</em> for that extra flair</li>
    <li>Got a <u>super important point</u>? Underline it!</li>
    <li>Need to show <strike>outdated information</strike>? Use strike-through text</li>
  </ul>

  <h3>Headings & Headers</h3>
  <p>Structure your blog with different header sizes by selecting text and choosing a header option from the dropdown:</p>
  <div style="border-left: 3px solid #c6ab70; padding-left: 15px; margin: 15px 0;">
    <h1 style="font-size: 2.2rem; margin-bottom: 10px; font-family: 'Caveat', cursive;">Header 1 - The Big Statement</h1>
    <h2 style="font-size: 1.8rem; margin-bottom: 10px; font-family: 'Caveat', cursive;">Header 2 - Major Sections</h2>
    <h3 style="font-size: 1.5rem; margin-bottom: 10px; font-family: 'Caveat', cursive;">Header 3 - Subsections</h3>
    <p style="margin-top: 15px;"><strong>Tip:</strong> Well-structured headings help readers navigate your content!</p>
  </div>

  <h3>Add Some Color!</h3>
  <p>Express yourself with text and background colors - just highlight your text and click the color picker:</p>
  <p>
    <span style="color: #11311B; font-weight: bold;">Brand green text</span> | 
    <span style="color: #C6AB70; font-weight: bold;">Gold accents</span> | 
    <span style="color: #8B735B; font-weight: bold;">Earthy brown</span> | 
    <span style="color: #d32f2f; font-weight: bold;">Attention-grabbing red</span>
  </p>
  <p>
    <span style="background-color: rgba(17, 49, 27, 0.2); padding: 2px 5px;">Subtle green background</span> | 
    <span style="background-color: rgba(198, 171, 112, 0.2); padding: 2px 5px;">Gold highlight</span> | 
    <span style="background-color: rgba(211, 47, 47, 0.1); padding: 2px 5px;">Soft red highlight</span>
  </p>
  <p><strong>Pro Tip:</strong> Use colors sparingly for maximum impact!</p>

  <h3>Text Alignment Magic ✨</h3>
  <p>Make your text flow exactly how you want it with our alignment options. Simply select your text and click the alignment button in the toolbar:</p>
  
  <p><strong>Look for these buttons in your toolbar:</strong> <span style="font-family: monospace;">[ ⟵ | ≡ | ⟶ | ≡≡ ]</span> - These represent left, center, right, and justify alignment.</p>
  
  <p style="text-align: left;"><strong>Left Align</strong> - The default option, great for paragraphs. Click the left align icon to make your text start from the left margin (like this paragraph).</p>
  
  <p style="text-align: center;"><strong>Center Align</strong> - Perfect for headings or to emphasize important information. Click the center align icon to place your text in the middle (like this paragraph).</p>
  
  <p style="text-align: right;"><strong>Right Align</strong> - For a unique look or when you want to create visual interest. Click the right align icon to make your text flow from the right (like this paragraph).</p>
  
  <p style="text-align: justify;"><strong>Justify</strong> - For a clean, professional look with even margins. Click the justify icon to stretch your text to fill the entire width, creating straight edges on both sides. This is especially nice for longer paragraphs where you want that crisp, book-like appearance with all lines (except the last one) extending to both margins. Notice how this paragraph has a uniform look compared to the others!</p>

  <h4>Pro Tip: Showcase Your Treasure</h4>
  <div style="text-align: center;">
    <img 
      src="https://dki68ae31zhk1.cloudfront.net/treasures/treasure-1737603473308.webp" 
      alt="Treasured Heirloom" 
      style="
        max-width: 50%;
        height: auto;
        border-radius: 8px;
        margin: 10px auto;
        box-shadow: 0 4px 12px rgba(0,0,0,0.1);
      " 
    />
    <p style="font-style: italic; font-size: 0.9rem;">Center-aligned images draw focus to your treasure, making it the star of your blog.</p>
  </div>
  <p>Adding images like this brings your treasure to life. Consider showing multiple perspectives: the treasure alone, being held by its original owner, or in its current place in your home. Each angle adds another layer to the blog.</p>

  <h2>Weaving Your Narrative 🧵</h2>
  
  <h3>Lists That Tell a Story</h3>
  <p>For example, here's what makes Something TOLD special:</p>
  <p><strong>To create bullet points:</strong> Click where you want to add your list, then click the bullet list icon (•) in the toolbar. Each time you press Enter, a new bullet point will be created.</p>
  <ul>
    <li>We're a community that shares amazing stories</li>
    <li>Every treasure has a unique tale</li>
    <li>Your voice matters here!</li>
    <li>No specific order needed for these points</li>
  </ul>

  <h3>Numbers More Your Style?</h3>
  <p>For step-by-step instructions, use numbered lists:</p>
  <p><strong>To create numbered lists:</strong> Click where you want to add your list, then click the numbered list icon (1.) in the toolbar. The numbers will automatically increment as you press Enter for each new item.</p>
  <ol>
    <li>Start with a catchy title</li>
    <li>Add your amazing content</li>
    <li>Sprinkle in some formatting</li>
    <li>Share with the world!</li>
  </ol>

  <h2>Enriching Your Treasure's Legacy 💫</h2>

  <blockquote>
    "The things we keep aren't just objects—they're vessels of time, carriers of truth, and keepers of our most cherished memories."
  </blockquote>

  <h3>Connecting Stories Through Links</h3>
  <p>If your treasure relates to other treasures or family stories, create connections with links. Highlight text, click the link button, and paste your URL to create <a href="#">bridges between stories</a>, just as our treasures create bridges between generations.</p>

  <h3>Marking Chapters with Horizontal Rules</h3>
  <p>Create clear divisions between sections of your blog by adding a horizontal line. Just click the horizontal rule button in the toolbar:</p>

  <hr style="border: 0; height: 1px; background-image: linear-gradient(to right, rgba(139, 115, 91, 0), rgba(139, 115, 91, 0.75), rgba(139, 115, 91, 0)); margin: 25px 0;" />
  
  <p style="text-align: center; font-style: italic; color: #8B735B;">This is how a horizontal rule looks - perfect for transitions between topics!</p>
  
  <hr style="border: 0; height: 1px; background-image: linear-gradient(to right, rgba(139, 115, 91, 0), rgba(139, 115, 91, 0.75), rgba(139, 115, 91, 0)); margin: 25px 0;" />

  <h2>Quick Formatting Shortcuts 🚀</h2>
  <ul>
    <li><code>Ctrl/Cmd + B</code> - Bold</li>
    <li><code>Ctrl/Cmd + I</code> - Italic</li>
    <li><code>Ctrl/Cmd + U</code> - Underline</li>
  </ul>

  <h2>Final Tips for Blog Success 🌟</h2>
  <ul>
    <li>Break up your text with headers - nobody likes a wall of text!</li>
    <li>Use bullet points for easy scanning</li>
    <li>Add relevant images to break up content</li>
    <li>Keep paragraphs short and sweet</li>
    <li>Use formatting to emphasize key points</li>
  </ul>

  <p>Remember, the best blogs are the ones that feel personal and authentic. Don't be afraid to let your personality shine through! ✨</p>

  <h2>Need More Help?</h2>
  <p>We're always here to help! Reach out to our community moderators if you need any assistance. Happy blogging! 🎉</p>
`;

// Enhanced mobile-only content with a clearer title and intro
const mobileOnlyContent = `
  <h1>Mobile Editing Tips 📱</h1>
  
  <p>Thanks for creating content on your mobile device! Here are some tips to help you have the best possible experience.</p>
  
  <h2>Mobile Challenges</h2>
  <ul>
    <li>The text formatting toolbar offers fewer options on mobile devices</li>
    <li>Placing and resizing images can be more difficult on smaller screens</li>
    <li>Some keyboard shortcuts aren't available on mobile</li>
    <li>The rich text editor may have reduced functionality on smaller devices</li>
  </ul>
  
  <h2>Mobile-Friendly Tips</h2>
  <ul>
    <li>Write in shorter paragraphs - they're easier to edit on mobile</li>
    <li>Stick to simple formatting - bold, italic, and basic headers work best</li>
    <li>Add images at the beginning or end of paragraphs</li>
    <li>Rotate your device to landscape mode for more editing space</li>
    <li>Use text alignment buttons with single taps rather than trying to select text first</li>
    <li>Try headers to structure your content without complex formatting</li>
    <li>Use horizontal rules to separate sections when more complex layouts aren't practical</li>
    <li>If possible, finish complex edits like color formatting on a desktop computer</li>
  </ul>
  
  <h2>When to Switch to Desktop</h2>
  <p>For best results with these features, consider switching to a desktop or laptop:</p>
  <ul>
    <li>Image-rich posts with multiple photos</li>
    <li>Complex formatting with multiple colors or styles</li>
    <li>Posts requiring precise image placement</li>
    <li>Content with tables or complex layouts</li>
  </ul>
  
`;

const BlogHelpModal = ({ open, onClose, mobileOnly = false }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Add mobile-specific content
  const mobileContent = `
    <h2>Tips for Mobile Editing 📱</h2>
    <p>While our blog editor works on mobile devices, there are a few limitations to be aware of:</p>
    
    <h3>Mobile Challenges</h3>
    <ul>
      <li>The text formatting toolbar may be smaller and harder to navigate</li>
      <li>Placing and resizing images is more difficult on smaller screens</li>
      <li>Some keyboard shortcuts aren't available on mobile devices</li>
      <li>The rich text editor may have reduced functionality</li>
    </ul>
    
    <h3>Mobile-Friendly Tips</h3>
    <ul>
      <li>Write in shorter paragraphs - they're easier to edit on mobile</li>
      <li>Keep formatting simple - bold and italics work best</li>
      <li>Add images at the beginning or end of paragraphs</li>
      <li>Rotate your device to landscape mode for more editing space</li>
      <li>Use text alignment buttons with single taps rather than trying to select text first</li>
      <li>Try headers to structure your content without complex formatting</li>
      <li>Use horizontal rules to separate sections when more complex layouts aren't practical</li>
      <li>If possible, finish complex edits like color formatting on a desktop computer</li>
    </ul>
    
    <p>For the best experience with image-rich or complex formatting, we recommend using a desktop or laptop computer.</p>
  `;

  // Determine which content to show based on mobileOnly prop
  let finalHelpContent;
  
  if (mobileOnly) {
    // Show only the enhanced mobile content
    finalHelpContent = mobileOnlyContent;
  } else {
    // Show regular help content + mobile tips if on mobile
    finalHelpContent = isMobile ? helpContent + mobileContent : helpContent;
  }

  return (
    <StyledDialog 
      open={open} 
      onClose={onClose} 
      maxWidth="lg" 
      fullWidth
      fullScreen={isMobile}
    >
      <IconButton
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 16,
          top: 16,
          zIndex: 9999,
          color: '#C6AB70',
          '&:hover': { 
            backgroundColor: 'rgba(198, 171, 112, 0.1)',
            color: '#1a4a2b' 
          },
        }}
      >
        <CloseIcon />
      </IconButton>
      
      <DialogContent
        sx={{
          p: 4,
          overflowY: 'auto',
          overflowX: 'hidden',
          /* Hide scrollbar for all browsers */
          scrollbarWidth: 'none', /* Firefox */
          '&::-webkit-scrollbar': { /* Chrome, Safari, Edge */
            display: 'none',
            width: 0,
            height: 0,
            background: 'transparent',
          },
          msOverflowStyle: 'none', /* IE 10+ */
          touchAction: 'pan-y',
          overscrollBehavior: 'contain',
        }}
      >
        <BlogContent
          dangerouslySetInnerHTML={{ __html: finalHelpContent }}
        />
      </DialogContent>
    </StyledDialog>
  );
};

BlogHelpModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  mobileOnly: PropTypes.bool
};

export default BlogHelpModal;