import React from 'react';
import styles from '../../styles/modules/imageGrid.module.css';

const ImageGrid = () => {
  return (
    <section className={styles.gridSection}>
      {/* First Row */}
      <div className={styles.container}>
        <div className={styles.imageWrapper}>
          <img 
            src={require('../../images/Phonedesk.png')} 
            alt="Jewelry items" 
            className={styles.image}
          />
        </div>
        <div className={styles.textWrapper}>
          <div className={styles.textContent}>
            <h3>Deepen the Truth</h3>
            <p>Whether sparking a conversation with Dad about his special-occasion pinky ring or asking a gallery artist about the inspiration behind your newest treasure, we believe in honoring the origin stories behind the things you cherish. Our platform provides a space for these truths to live on, even as they pass through new hands and hearts.</p>
          </div>
        </div>
      </div>

      {/* Second Row - Reversed */}
      <div className={`${styles.container} ${styles.reversed}`}>
        <div className={styles.textWrapper}>
          <div className={styles.textContent}>
            <h3>Celebrate & Connect</h3>
            <p>Beyond the digital memories our platform preserves, we are a community of sentimentalists who cherish the beauty within our treasured things. We honor these connections by creating spaces—both online and in person—where like-minded (and like-hearted) individuals can share, celebrate, and engage with one another.</p>
          </div>
        </div>
        <div className={styles.imageWrapper}>
          <img 
            src={require('../../images/Event.png')} 
            alt="Person with watches" 
            className={styles.image}
          />
        </div>
      </div>
    </section>
  );
};

export default ImageGrid; 