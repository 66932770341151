// File: gridExploreNew.js

import React, { memo, useCallback, useMemo, useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { Box, Typography, IconButton } from '@mui/material';
import { LocationOn, PlayArrow } from '@mui/icons-material';
import ProgressiveImage from './ProgressiveImage'; // Import our ProgressiveImage component
import { formatDistanceToNow } from 'date-fns';
import { useInView } from 'react-intersection-observer';
import AddTreasureCard from './common/AddTreasureCard';
import PropTypes from 'prop-types';

/**
 * formatCreatedAt
 *
 * Converts a UTC timestamp (as a string) into a "time ago" format.
 */
const formatCreatedAt = (timestamp) => {
  try {
    if (!timestamp) return 'Recently';
    // Replace space with 'T' for proper ISO format
    const utcDate = new Date(timestamp.replace(' ', 'T'));
    // Adjust for local time zone
    const localDate = new Date(utcDate.getTime() - utcDate.getTimezoneOffset() * 60000);
    return formatDistanceToNow(localDate, { 
      addSuffix: true,
      includeSeconds: false,
      roundingMethod: 'floor',
    }).replace('about ', '');
  } catch (error) {
    console.error('Date formatting error:', error);
    return 'Recently';
  }
};

/*
  GridWrapper:
  A CSS grid container that lays out the treasure cards responsively.
  Each card has a minimum width of 250px.
*/
const GridWrapper = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fill, minmax(160px, 1fr))',
  gap: theme.spacing(1),
  padding: 0,
  [theme.breakpoints.up('sm')]: {
    gridTemplateColumns: 'repeat(auto-fill, minmax(280px, 1fr))',
    gap: theme.spacing(2),
    padding: theme.spacing(2),
  },
  '& > *': {
    maxWidth: {
      xs: '200px',
      sm: '320px'
    },
    justifySelf: 'center',
    width: '100%',
  }
}));

/*
  Card:
  A styled container for a treasure item. It has a hover effect.
*/
const Card = styled(Box)(({ theme, isAddCard }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  overflow: 'visible',
  backgroundColor: theme.palette.background.paper,
  cursor: 'pointer',
  aspectRatio: '4 / 5',
  width: '100%',
  boxShadow: '0 2px 8px rgba(0, 0, 0, 0.08)',
  transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
  transform: 'translateY(0)',
  ...(isAddCard ? {
    overflow: 'visible',
    '& > *': {
      height: '100%'
    }
  } : {
    '&:hover': {
      transform: 'translateY(-8px)',
      boxShadow: '0 12px 24px rgba(0, 0, 0, 0.12)',
      '& .story-overlay': {
        opacity: 1,
        visibility: 'visible',
        transform: 'translate(-50%, -50%) scale(1)',
      },
      '& .image-overlay': {
        opacity: 1,
      },
    },
  })
}));

/*
  Overlay:
  A semi-transparent overlay at the bottom of each card that displays treasure details.
*/
const Overlay = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  padding: theme.spacing(2),
  background: `linear-gradient(
    180deg, 
    rgba(255,255,255,0.01) 0%, 
    rgba(255,255,255,0.005) 25%, 
    rgba(255,255,255,0) 50%, 
    rgba(255,255,255,0.01) 75%,
    rgba(255,255,255,0.02) 100%
  )`,
  color: '#fff',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  transition: 'all 0.3s ease-in-out',
  opacity: 0.95,
  '&:hover': {
    background: `linear-gradient(
      180deg, 
      rgba(255,255,255,0.02) 0%, 
      rgba(255,255,255,0.01) 25%, 
      rgba(255,255,255,0.005) 50%, 
      rgba(255,255,255,0.01) 75%,
      rgba(255,255,255,0.03) 100%
    )`,
    opacity: 1,
  },
  zIndex: 3,
}));

/*
  CategoryLabel:
  A styled component for the category label at the top left of each card.
*/
const CategoryLabel = styled(Typography)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(2),
  left: theme.spacing(2),
  color: '#fff',
  fontSize: '0.75rem',
  fontStyle: 'italic',
  opacity: 0.9,
  textShadow: '0 2px 4px rgba(0,0,0,0.4)',
  zIndex: 4,
}));

/*
  BottomContent:
  A styled component for the bottom content of the overlay.
*/
const BottomContent = styled(Box)(({ theme }) => ({
  position: 'absolute',
  bottom: 0,
  left: 0,
  right: 0,
  padding: theme.spacing(2),
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-end',
  background: 'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0) 100%)',
  zIndex: 2,
  '& .MuiTypography-root': {
    color: '#fff',
    textShadow: '0 2px 4px rgba(0,0,0,0.4)',
  },
}));

/*
  ImageWrapper:
  A styled component for the image wrapper within the card.
*/
const ImageWrapper = styled(Box)({
  position: 'relative',
  width: '100%',
  height: '100%',
  overflow: 'hidden',
  backgroundColor: '#f5f5f5', // Light background while loading
  transition: 'all 0.3s ease-in-out',
  '& > *': {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    transition: 'all 0.3s ease-in-out',
  },
  '&:hover > *': {
    transform: 'scale(1.05)',
  },
});

/*
  StoryOverlay:
  A styled component for the story overlay.
*/
const StoryOverlay = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '85%',
  maxHeight: '70%',
  opacity: 0,
  transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
  zIndex: 10,
  textAlign: 'center',
  padding: theme.spacing(2),
  background: 'rgba(0, 0, 0, 0.6)',
  borderRadius: '16px',
  visibility: 'hidden',
  border: '1px solid rgba(255, 255, 255, 0.1)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  pointerEvents: 'none',
  '&::before': {
    content: '""',
    position: 'absolute',
    
    background: `radial-gradient(
      circle at center,
      rgba(0, 0, 0, 0.6) 0%,
      rgba(0, 0, 0, 0.6) 60%,
      rgba(0, 0, 0, 0) 100%
    )`,
    borderRadius: 'inherit',
    zIndex: -1,
  },
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(3),
  }
}));

/*
  ImageOverlay:
  A styled component for the image overlay.
*/
const ImageOverlay = styled(Box)({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  background: 'rgba(0, 0, 0, 0.15)', // Very subtle 5% black shadow
  opacity: 0,
  transition: 'opacity 0.3s ease-in-out',
  zIndex: 2,
});

const TreasureCard = memo(({ treasure, onClick, hideState }) => {
  const { ref, inView } = useInView({
    triggerOnce: true, // Keep this true to prevent reloading
    threshold: 0,      // Start loading as soon as even 0% is visible
    rootMargin: '200px 0px', // Load images 200px before they come into view
    skip: !treasure.image_url // Skip if no image
  });

  const handleClick = useCallback((e) => {
    onClick(e, treasure);
  }, [onClick, treasure]);

  // Keep image in memory with state
  const [shouldRender, setShouldRender] = useState(false);

  useEffect(() => {
    if (inView) {
      setShouldRender(true);
    }
  }, [inView]);

  const formattedDate = useMemo(() => 
    formatCreatedAt(treasure.created_at),
    [treasure.created_at]
  );

  return (
    <Card ref={ref} onClick={handleClick}>
      <ImageWrapper>
        {(shouldRender || inView) && (
          <>
            <ProgressiveImage 
              src={treasure.is_video ? treasure.video_thumbnail : treasure.image_url}
              alt={treasure.name}
              key={treasure.id}
              style={{
                transition: 'opacity 0.3s ease-in-out',
                opacity: inView ? 1 : 0
              }}
            />
            {treasure.is_video && (
              <Box
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  zIndex: 2,
                  width: '24px',
                  height: '24px',
                  borderRadius: '50%',
                  backgroundColor: 'rgba(0, 0, 0, 0.5)',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  pointerEvents: 'none'
                }}
              >
                <PlayArrow sx={{ color: 'white', fontSize: '16px' }} />
              </Box>
            )}
          </>
        )}
      </ImageWrapper>

      <ImageOverlay className="image-overlay" />
      
      {/* Story Overlay - Only show if not hidden */}
      {!hideState && (
        <StoryOverlay className="story-overlay">
          <Typography
            variant="body2"
            sx={{
              color: '#fff',
              WebkitLineClamp: 2,
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
              lineHeight: 1.6,
              letterSpacing: '0.015em',
              fontWeight: 500,
              textShadow: '0 2px 4px rgba(0,0,0,0.5)',
              opacity: 1,
              fontSize: {
                xs: '0.8rem',
                sm: '0.875rem'
              },
              px: 1,
            }}
          >
            {treasure.story || 'No story available'}
          </Typography>
        </StoryOverlay>
      )}

      {/* Category label - always visible */}
      <CategoryLabel variant="caption">
        {treasure.category}
      </CategoryLabel>

      {/* Bottom content with conditional location */}
      <BottomContent>
        {/* Treasure name - always visible */}
        <Typography 
          variant="subtitle1" 
          sx={{ 
            fontWeight: 600,
            maxWidth: hideState ? '100%' : '70%',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            color: '#fff',
          }}
        >
          {treasure.name}
        </Typography>

        {/* Location - only show if not hidden */}
        {!hideState && treasure.location && treasure.location !== 'Unknown Location' && (
          <Box sx={{ 
            display: 'flex', 
            alignItems: 'center', 
            gap: 0.5,
          }}>
            <LocationOn sx={{ fontSize: '1rem', color: '#fff' }} />
            <Typography 
              variant="caption"
              sx={{
                maxWidth: '100px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                color: '#fff',
              }}
            >
              {treasure.location}
            </Typography>
          </Box>
        )}
      </BottomContent>
    </Card>
  );
});

/**
 * GridExploreNew Component
 *
 * Props:
 * - treasures: Array of treasure objects. Each treasure is expected to have:
 *   - id, image_url, name, category, location, created_at, etc.
 * - onTreasureClick: Callback when a treasure card is clicked.
 * - onLikeClick: Callback when the like button is clicked.
 * - likedTreasures: A Set containing treasure IDs that are liked.
 * - isDashboard: Boolean indicating if the component is used in the Dashboard context.
 */
const GridExploreNew = memo(({ treasures, onTreasureClick, isDashboard, hideState = false }) => {
  if (!treasures?.length && !isDashboard) {
    return <Typography variant="h6" align="center">No treasures available</Typography>;
  }

  return (
    <GridWrapper>
      {/* Add Treasure Card for Dashboard */}
      {isDashboard && (
        <Card isAddCard>
          <AddTreasureCard 
            onClick={(e) => onTreasureClick(e, { isAddCard: true })}
          />
        </Card>
      )}

      {treasures.filter(t => !t.isAddCard).map((treasure) => (
        <TreasureCard
          key={treasure.id}
          treasure={treasure}
          onClick={onTreasureClick}
          hideState={hideState && isDashboard}
        />
      ))}
    </GridWrapper>
  );
});

GridExploreNew.displayName = 'GridExploreNew';
TreasureCard.displayName = 'TreasureCard';

GridExploreNew.propTypes = {
  treasures: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      name: PropTypes.string.isRequired,
      image_url: PropTypes.string.isRequired,
      story: PropTypes.string,
      location: PropTypes.string,
      created_at: PropTypes.string,
      category: PropTypes.string,
      isAddCard: PropTypes.bool,
    })
  ).isRequired,
  onTreasureClick: PropTypes.func.isRequired,
  isDashboard: PropTypes.bool,
};

GridExploreNew.defaultProps = {
  isDashboard: false,
};

// Also add PropTypes for the TreasureCard subcomponent
TreasureCard.propTypes = {
  treasure: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    name: PropTypes.string.isRequired,
    image_url: PropTypes.string.isRequired,
    story: PropTypes.string,
    location: PropTypes.string,
    created_at: PropTypes.string,
    category: PropTypes.string,
  }).isRequired,
  onClick: PropTypes.func.isRequired,
};

export default GridExploreNew;
