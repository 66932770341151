import { styled } from '@mui/material/styles';
import {
  Box,
  Typography,
  Button,
  Grid,
  Container,
  Link,
  AppBar,
  Toolbar,
  Paper,
  CardContent,
} from '@mui/material';
import Logo, { LogoWrapper } from './common/Logo';

const NavBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: 'white',
  boxShadow: 'none',
  borderBottom: `1px solid ${theme.palette.divider}`,
}));

const HeroSection = styled(Box)(({ theme }) => ({
  position: 'relative',
  backgroundColor: theme.palette.grey[200],
  height: '400px',
  width: '100%',
  marginBottom: theme.spacing(8),
}));

const BlogCard = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.grey[200],
  height: '450px',
  display: 'flex',
  flexDirection: 'column',
  borderRadius: 0,
  overflow: 'hidden',
  boxShadow: 'none',
}));

const BlogImage = styled(Box)(({ theme }) => ({
  height: '300px',
  width: '100%',
  backgroundColor: theme.palette.grey[200],
  marginBottom: theme.spacing(2),
}));

const PublicBlog = () => {
  const blogPosts = [
    {
      id: 1,
      title: "The Art of Preserving Family Photographs",
      description: "Learn expert techniques for preserving your precious family photos. From proper storage methods to digitization tips, discover how to keep your memories intact for generations to come.",
      category: "Preservation",
      author: "Sarah Johnson",
      date: "March 15, 2024",
      readTime: "5 min read"
    },
    {
      id: 2,
      title: "Uncovering the Stories Behind Family Jewelry",
      description: "Every piece of family jewelry tells a story. Explore how to research your family's jewelry history, understand hallmarks, and document the emotional significance behind each treasured piece.",
      category: "Family History",
      author: "Michael Chen",
      date: "March 12, 2024",
      readTime: "7 min read"
    },
    {
      id: 3,
      title: "Digital Archives: Modern Solutions for Family Treasures",
      description: "Discover how digital technology is revolutionizing the way we preserve and share family heirlooms. From 3D scanning to cloud storage, learn about the latest tools for creating lasting digital legacies.",
      category: "Technology",
      author: "Emma Williams",
      date: "March 8, 2024",
      readTime: "6 min read"
    },
    {
      id: 4,
      title: "The Emotional Value of Family Heirlooms",
      description: "Explore the psychological and emotional significance of family heirlooms. Understanding why certain objects become treasured across generations and how they strengthen family bonds.",
      category: "Psychology",
      author: "Dr. James Martinez",
      date: "March 5, 2024",
      readTime: "8 min read"
    },
    {
      id: 5,
      title: "Restoring Vintage Furniture: A Guide for Beginners",
      description: "Turn that old family furniture piece into a restored masterpiece. Learn basic restoration techniques, tools needed, and how to maintain the historical value while bringing new life to vintage pieces.",
      category: "DIY & Restoration",
      author: "Lisa Thompson",
      date: "March 1, 2024",
      readTime: "10 min read"
    },
    {
      id: 6,
      title: "Creating a Family Time Capsule",
      description: "Step-by-step guide to creating meaningful time capsules that capture your family's current moment. Learn what to include, how to preserve items, and when to open these special collections.",
      category: "Family Projects",
      author: "David Anderson",
      date: "February 28, 2024",
      readTime: "4 min read"
    }
  ];

  return (
    <Box 
      sx={{ 
        height: '100vh',
        overflowY: 'auto',
        scrollBehavior: 'smooth',
        WebkitOverflowScrolling: 'touch',
        '&::-webkit-scrollbar': {
          width: '0.4em'
        },
        '&::-webkit-scrollbar-track': {
          background: 'transparent'
        },
        '&::-webkit-scrollbar-thumb': {
          background: (theme) => theme.palette.grey[300],
          borderRadius: '4px'
        }
      }}
    >
      <NavBar position="static">
        <Toolbar sx={{ justifyContent: 'space-between' }}>
          <Box sx={{ width: 100 }} /> {/* Spacer for balance */}
          <LogoWrapper>
            <Box component={Link} href="/">
              <Logo size="1.75rem" />
            </Box>
          </LogoWrapper>
          <Box sx={{ 
            display: 'flex', 
            gap: 3,
            width: 100,
            justifyContent: 'flex-end'
          }}>
            <Link 
              href="/" 
              color="text.secondary" 
              sx={{ textDecoration: 'none' }}
            >
              Home
            </Link>
            <Link 
              href="/login" 
              color="text.secondary" 
              sx={{ textDecoration: 'none' }}
            >
              Sign In
            </Link>
          </Box>
        </Toolbar>
      </NavBar>

      <HeroSection>
        <Container maxWidth="lg">
          <Box sx={{ maxWidth: 600, pt: 8 }}>
            <Typography 
              variant="subtitle2" 
              sx={{ mb: 1, color: 'text.secondary' }}
            >
              Something Told
            </Typography>
            <Typography 
              variant="h2" 
              sx={{ 
                mb: 2,
                fontWeight: 600 
              }}
            >
              Blog
            </Typography>
            <Typography 
              variant="body1" 
              color="text.secondary" 
              paragraph
              sx={{ mb: 3 }}
            >
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna
            </Typography>
            <Button 
              variant="outlined"
              sx={{ 
                borderRadius: 0,
                textTransform: 'none',
                px: 3,
                py: 1
              }}
            >
              Read Now
            </Button>
          </Box>
        </Container>
      </HeroSection>

      <Container maxWidth="lg">
        <Grid container spacing={4}>
          {blogPosts.map((post) => (
            <Grid item xs={12} sm={6} md={4} key={post.id}>
              <BlogCard>
                <BlogImage />
                <CardContent>
                  <Typography 
                    variant="overline" 
                    color="text.secondary"
                    sx={{ mb: 1, display: 'block' }}
                  >
                    {post.category}
                  </Typography>
                  <Typography 
                    variant="h6" 
                    gutterBottom
                    sx={{ 
                      fontWeight: 600,
                      fontSize: '1.1rem',
                      mb: 2
                    }}
                  >
                    {post.title}
                  </Typography>
                  <Typography 
                    variant="body2" 
                    color="text.secondary"
                    sx={{ mb: 2 }}
                  >
                    {post.description}
                  </Typography>
                  <Box sx={{ 
                    display: 'flex', 
                    justifyContent: 'space-between',
                    alignItems: 'center'
                  }}>
                    <Box>
                      <Typography variant="subtitle2">
                        {post.author}
                      </Typography>
                      <Typography variant="caption" color="text.secondary">
                        {post.date} · {post.readTime}
                      </Typography>
                    </Box>
                    <Button 
                      variant="outlined" 
                      size="small"
                      sx={{ 
                        borderRadius: 0,
                        textTransform: 'none'
                      }}
                    >
                      Read More
                    </Button>
                  </Box>
                </CardContent>
              </BlogCard>
            </Grid>
          ))}
        </Grid>

        {/* Newsletter Section */}
        <Box sx={{ textAlign: 'center', py: 6 }}>
          <Typography variant="subtitle1" gutterBottom>
            Join the mail list
          </Typography>
          <Box sx={{ maxWidth: 400, mx: 'auto', display: 'flex', gap: 1 }}>
            <input
              type="email"
              placeholder="Email"
              style={{
                flex: 1,
                padding: '8px 12px',
                border: '1px solid #ddd',
                borderRadius: 4,
              }}
            />
            <Button
              variant="contained"
              sx={{
                bgcolor: 'grey.800',
                '&:hover': { bgcolor: 'grey.900' },
              }}
            >
              Submit
            </Button>
          </Box>
          <Typography variant="caption" color="text.secondary" sx={{ display: 'block', mt: 1 }}>
            By submitting, you confirm that you have read the Privacy Policy
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default PublicBlog; 