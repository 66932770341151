import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../contexts/AuthContext';
import styles from '../../styles/modules/hero.module.css';
import Logo from '../../images/Logo.svg';
import PublicNav from '../common/PublicNav';

const Hero = () => {
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [isScrolling, setIsScrolling] = useState(false);
  
  // Handle scroll effect for the button
  useEffect(() => {
    let scrollTimeout;
    
    const handleScroll = () => {
      setIsScrolling(true);
      
      // Clear existing timeout
      clearTimeout(scrollTimeout);
      
      // Set timeout to remove scrolling class
      scrollTimeout = setTimeout(() => {
        setIsScrolling(false);
      }, 300);
    };
    
    window.addEventListener('scroll', handleScroll);
    
    return () => {
      window.removeEventListener('scroll', handleScroll);
      clearTimeout(scrollTimeout);
    };
  }, []);

  return (
    <section className={styles.heroSection}>
      {/* Mobile Nav */}
      <div className={styles.mobileNav}>
        <PublicNav />
      </div>

      {/* Content Section */}
      <div className={styles.content}>
        <img src={Logo} alt="Something Told Logo" className={styles.logo} />
        <h1 className={styles.title}>Every Treasure Has A Tale</h1>
        <p className={styles.description}>
          Something Told provides a thoughtful space to capture, share, and preserve the precious memories behind your most cherished things—ensuring your legacy lives on through both the treasures you hold dear, and the stories that they carry.
        </p>
        <button 
          className={styles.learnMoreButton}
          onClick={() => navigate('/aboutus')}
        >
          Learn More
        </button>
      </div>

      <div className={styles.heroBackground}></div>

      <button 
        className={`${styles.signInButton} ${isScrolling ? styles.scrolling : ''}`}
        onClick={() => navigate(currentUser ? '/treasurechest' : '/login')}
        style={{ 
          minWidth: '140px',
          whiteSpace: 'nowrap',
          position: 'fixed',
          top: '24px',
          right: '24px',
          zIndex: 1000
        }}
      >
        {currentUser ? 'My Treasures' : 'Sign In'}
      </button>
    </section>
  );
};

export default Hero; 