import React from 'react';
import styles from '../../styles/modules/testimonial.module.css';

const Testimonial = () => {
  return (
    <section className={styles.testimonialSection}>
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.testimonialWrapper}>
            {/* Left side - Image */}
            <div className={styles.imageWrapper}>
              <img 
                src={require('../../images/User.png')} 
                alt="Testimonial user" 
                className={styles.imageCircle}
              />
            </div>
            
            {/* Right side - Content */}
            <div className={styles.testimonialContent}>
              <div className={styles.stars}>
                <span>★</span>
                <span>★</span>
                <span>★</span>
                <span>★</span>
                <span>★</span>
              </div>
              <p className={styles.quote}>
                "Something Told gave me the perfect space to share the story behind my engagement ring—a piece that was hand-crafted and custom-designed just for me, in secret & with so much love. Nanny Jaye's diamond has already passed through generations, and now its story will live on for those to come."
              </p>
              <div className={styles.authorWrapper}>
                <span className={styles.divider}>—</span>
                <p className={styles.author}>Tamara, Pittsburgh, PA</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Testimonial; 