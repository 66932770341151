import { Dialog, Box, styled } from '@mui/material';

// Styled components
const StyledDialog = styled(Dialog)({
  '& .MuiDialog-paper': {
    boxShadow: 'none',
    background: 'transparent',
    cursor: 'zoom-out',
    margin: 0,
    maxWidth: 'none',
    width: '100%'
  },
  '& .MuiBackdrop-root': {
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    backdropFilter: 'blur(8px)'
  }
});

const ImageContainer = styled(Box)({
  width: '100vw',
  height: '100vh',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
});

const StyledImage = styled('img')({
  maxWidth: '90%',
  maxHeight: '90vh',
  objectFit: 'contain',
  userSelect: 'none',
  WebkitUserDrag: 'none'
});

const ExpandedImageView = ({ open, onClose, imageUrl, altText }) => (
  <StyledDialog
    open={open}
    onClose={onClose}
    onClick={onClose}
    maxWidth={false}
    fullWidth
  >
    <ImageContainer>
      <StyledImage
        src={imageUrl}
        alt={altText}
        draggable={false}
      />
    </ImageContainer>
  </StyledDialog>
);

export default ExpandedImageView; 