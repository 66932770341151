import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Box,
  IconButton,
  Typography,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  FormControlLabel,
  Switch,
  FormHelperText,
  Alert,
  Collapse,
} from '@mui/material';
import { Close as CloseIcon, DeleteOutline as DeleteOutlineIcon, Fullscreen as FullscreenIcon, FullscreenExit as FullscreenExitIcon, Computer as ComputerIcon } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { alpha } from '@mui/material/styles';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import ImageUpload from '../common/ImageUpload';
import BlogHelpModal from './BlogHelpModal';
import LogoIcon from '../../assets/ST_Logo_Gold-on-Gren.svg';
import { getAuth } from 'firebase/auth';
import api from '../../services/api';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import useS3Upload from '../../hooks/useS3Upload';
import { useToast } from '../../contexts/ToastContext';
import CropPhotoModal from '../common/CropPhotoModal';
import ConfirmDialog from '../common/ConfirmDialog';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    width: '100%',
    maxWidth: 800,
    margin: theme.spacing(2),
    borderRadius: '16px',
    boxShadow: '0 10px 40px rgba(0,0,0,0.12)',
    backgroundImage: 'none',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    maxHeight: 'calc(100vh - 32px)',
    '&.MuiDialog-paper': {
      animation: 'slideIn 300ms ease-out'
    },
    [theme.breakpoints.down('sm')]: {
      margin: 0,
      maxHeight: '100vh',
      height: '100%',
      borderRadius: 0,
    }
  },
  '@keyframes slideIn': {
    '0%': {
      opacity: 0,
      transform: 'translateY(20px)'
    },
    '100%': {
      opacity: 1,
      transform: 'translateY(0)'
    }
  }
}));

const DialogContentStyled = styled(DialogContent)(({ theme }) => ({
  padding: theme.spacing(3),
  overflowY: 'auto',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  paddingBottom: theme.spacing(6),
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(5),
  },
  '&::-webkit-scrollbar': {
    width: '6px',
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: 'transparent',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: alpha(theme.palette.primary.main, 0.2),
    borderRadius: '3px',
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.3),
    }
  }
}));

const PreviewContainer = styled(Box)(({ theme }) => ({
  width: '150px',
  height: '150px',
  position: 'relative',
  borderRadius: '12px',
  overflow: 'hidden',
  backgroundColor: theme.palette.background.paper,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  border: `1px dashed ${theme.palette.divider}`,
  boxShadow: '0 4px 20px rgba(0,0,0,0.05)',
  transition: 'all 0.3s ease',
  '&:hover': {
    boxShadow: '0 8px 25px rgba(0,0,0,0.08)',
    transform: 'translateY(-2px)'
  }
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: '12px',
    minHeight: '56px',
    transition: 'all 0.2s ease-in-out',
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.text.primary,
      }
    },
    '&.Mui-focused': {
      backgroundColor: theme.palette.background.paper,
      '& .MuiOutlinedInput-notchedOutline': {
        borderWidth: '2px',
        borderColor: theme.palette.primary.main,
      }
    }
  },
  '& .MuiInputLabel-root': {
    fontSize: '0.875rem',
    transition: 'transform 0.2s ease-in-out, opacity 0.2s ease-in-out',
    '&.Mui-focused': {
      color: theme.palette.primary.main,
    }
  },
  '& .MuiInputBase-input': {
    fontSize: '0.875rem',
    padding: '15px 14px',
  },
  '& .MuiFormHelperText-root': {
    marginTop: '4px',
    fontSize: '0.7rem',
  }
}));

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  '& .MuiFormLabel-root': {
    fontSize: '0.875rem',
    transition: 'transform 0.2s ease-in-out, opacity 0.2s ease-in-out',
    '&.Mui-focused': {
      color: theme.palette.primary.main,
    }
  },
  '& .MuiInputLabel-outlined': {
    transform: 'translate(14px, 16px) scale(1)',
    '&.MuiInputLabel-shrink': {
      transform: 'translate(14px, -6px) scale(0.75)',
    }
  }
}));

const StyledSelect = styled(Select)(({ theme }) => ({
  borderRadius: '12px',
  fontSize: '0.875rem',
  backgroundColor: theme.palette.background.paper,
  height: '56px',
  minWidth: '100%',
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.divider,
    transition: 'border-color 0.2s ease-in-out',
  },
  '& .MuiSelect-select': {
    backgroundColor: 'transparent',
    padding: '15px 14px',
  },
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.text.primary,
    }
  },
  '&.Mui-focused': {
    backgroundColor: theme.palette.background.paper,
    '& .MuiOutlinedInput-notchedOutline': {
      borderWidth: '2px',
      borderColor: theme.palette.primary.main,
    }
  }
}));

const QuillWrapper = styled(Box)(({ theme }) => ({
  '& .ql-editor': {
    '& img': {
      maxWidth: '100%',  // Makes images responsive
      height: 'auto',    // Maintains aspect ratio
      maxHeight: '400px' // Maximum height for very tall images
    }
  },
  '& .ql-container': {
    height: 'calc(100% - 42px)',
  },
  '& .ql-tooltip': {
    zIndex: 9999,
    '&.ql-editing': {
      left: '0 !important',
      top: '42px !important',
    }
  }
}));

const categories = [
  'Family History',
  'Technology',
  'Culture',
  'Education',
  'Community',
  'Events',
  'Research',
  'Stories'
];

const initialFormData = {
  title: '',
  category: '',
  description: '',
  image_url: '',
  read_time: '',
  imageBlob: null
};

const extractImageUrls = (html) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, 'text/html');
  const images = doc.getElementsByTagName('img');
  return Array.from(images).map(img => ({
    src: img.src,
    isBase64: img.src.startsWith('data:')
  }));
};

const replaceImageUrl = (html, originalUrl, newUrl) => {
  // Don't use regex for large base64 URLs to avoid "regex too large" error
  if (originalUrl.length > 50000) { // If URL is very large (likely base64)
    // Use string splitting approach instead of regex
    const parts = html.split(originalUrl);
    return parts.join(newUrl);
  } else {
    // For smaller URLs, regex is fine and more precise
    const escapedUrl = originalUrl.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    const regex = new RegExp(escapedUrl, 'g');
    return html.replace(regex, newUrl);
  }
};

const fetchImageAsBlob = async (url) => {
  const response = await fetch(url);
  return response.blob();
};

const base64ToBlob = async (base64String) => {
  // Extract the base64 data (remove data:image/xxx;base64, prefix)
  const base64Data = base64String.split(',')[1];
  const mimeType = base64String.split(',')[0].split(':')[1].split(';')[0];
  
  const byteCharacters = atob(base64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += 512) {
    const slice = byteCharacters.slice(offset, offset + 512);
    const byteNumbers = new Array(slice.length);
    
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    
    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, { type: mimeType });
};

const BLOG_IMAGE_MAX_DIM = 2400; // Increase from 1920 to 2400 for better quality on high-res displays

const MobileNotice = styled(Alert)(({ theme }) => ({
  marginBottom: theme.spacing(1),
  borderRadius: '20px',
  backgroundColor: 'rgba(17, 49, 27, 0.06)',
  border: '1px solid rgba(17, 49, 27, 0.15)',
  padding: theme.spacing(0.5, 0.75),
  minHeight: '30px',
  boxShadow: '0 1px 3px rgba(0,0,0,0.03)',
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer', // Make entire notice clickable
  transition: 'all 0.2s ease',
  '&:hover': {
    backgroundColor: 'rgba(17, 49, 27, 0.08)',
    boxShadow: '0 2px 4px rgba(0,0,0,0.05)',
    transform: 'translateY(-1px)',
  },
  '& .MuiAlert-icon': {
    color: '#11311B',
    alignSelf: 'center',
    marginRight: theme.spacing(0.5),
    padding: 0,
    fontSize: '0.875rem',
  },
  '& .MuiAlert-message': {
    width: '100%',
    padding: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'nowrap',
    gap: theme.spacing(0.25),
  },
  '& .MuiAlert-action': {
    padding: 0,
    marginRight: 0,
    marginLeft: theme.spacing(0.5),
    alignSelf: 'center',
  },
}));

const StyledDialogActions = styled(DialogActions)(({ theme }) => ({
  position: 'sticky',
  bottom: 0,
  backgroundColor: theme.palette.background.paper,
  borderTop: `1px solid ${theme.palette.divider}`,
  padding: theme.spacing(2, 3),
  marginTop: 'auto',
  zIndex: 5,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexWrap: 'nowrap',
  gap: 2,
  boxShadow: '0 -4px 12px rgba(0,0,0,0.05)',
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2),
  }
}));

const CreateBlogModal = ({ open, onClose, onSuccess, editingBlog }) => {
  const [formData, setFormData] = useState(() => {
    if (editingBlog) {
      return {
        title: editingBlog.title,
        category: editingBlog.category,
        description: editingBlog.description,
        image_url: editingBlog.image_url,
        read_time: editingBlog.read_time,
        imageBlob: null
      };
    }
    return initialFormData;
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const fileInputRef = useRef(null);
  const [isHelpModalOpen, setIsHelpModalOpen] = useState(false);
  const quillRef = useRef();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { uploadToS3, isUploading } = useS3Upload();
  const { showToast } = useToast();
  const [cropModalOpen, setCropModalOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [tempImageUrl, setTempImageUrl] = useState(null);
  const [showCropModal, setShowCropModal] = useState(false);
  const [errors, setErrors] = useState({
    title: '',
    category: '',
    description: '',
    read_time: ''
  });
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [showMobileNotice, setShowMobileNotice] = useState(() => {
    // Check if user has previously dismissed the notice
    const noticeDismissed = localStorage.getItem('blogEditorMobileNoticeDismissed');
    return noticeDismissed !== 'true';
  });
  const [helpSource, setHelpSource] = useState(''); // 'mobile' or 'general'

  useEffect(() => {
    if (editingBlog) {
      setFormData({
        title: editingBlog.title,
        category: editingBlog.category,
        description: editingBlog.description,
        image_url: editingBlog.image_url,
        read_time: editingBlog.read_time,
        imageBlob: null
      });
    } else {
      setFormData(initialFormData);
    }
  }, [editingBlog]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleEditorChange = (content) => {
    setFormData(prev => ({
      ...prev,
      description: content
    }));
  };

  const handlePhotoSelect = (localUrl, webpBlob) => {
    setTempImageUrl(localUrl);
    setShowCropModal(true);
  };

  const handleCropComplete = async (croppedBlob) => {
    try {
      setLoading(true);
      
      // Determine the file extension based on the blob's type
      const fileExtension = croppedBlob.type.split('/')[1] || 'webp';
      
      // Create a file with proper MIME type
      const file = new File([croppedBlob], `blog-${Date.now()}.${fileExtension}`, {
        type: croppedBlob.type
      });
      
      // Log some diagnostic information
      console.log('Uploading image with:', {
        type: croppedBlob.type,
        size: `${(croppedBlob.size / (1024 * 1024)).toFixed(2)} MB`,
        extension: fileExtension
      });
      
      const publicUrl = await uploadToS3(file);
      
      setFormData(prev => ({
        ...prev,
        image_url: publicUrl,
        imageBlob: croppedBlob
      }));
      
    } catch (error) {
      console.error('Failed to upload image:', error);
      showToast(`Upload failed: ${error.message || 'Unknown error'}`, 'error');
    } finally {
      setLoading(false);
      setShowCropModal(false);
    }
  };

  const handleDeleteImage = () => {
    setFormData(prev => ({
      ...prev,
      image_url: '',
      imageBlob: null
    }));
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const handleClose = () => {
    setFormData(initialFormData);
    setError('');
    setShowConfirmDialog(false);
    onClose();
  };

  const handleCloseAttempt = () => {
    // Helper function to normalize description by removing HTML and whitespace
    const normalizeDescription = (desc) => {
      return desc?.replace(/<[^>]*>/g, '').trim() || '';
    };

    const hasChanges = editingBlog ? (
      formData.title !== editingBlog.title ||
      formData.category !== editingBlog.category ||
      normalizeDescription(formData.description) !== normalizeDescription(editingBlog.description) ||
      formData.image_url !== editingBlog.image_url ||
      formData.read_time !== editingBlog.read_time
    ) : (
      formData.title !== '' ||
      formData.category !== '' ||
      normalizeDescription(formData.description) !== '' ||
      formData.image_url !== '' ||
      formData.read_time !== ''
    );

    if (hasChanges) {
      setShowConfirmDialog(true);
    } else {
      handleClose();
    }
  };

  const validateForm = () => {
    const newErrors = {};
    
    // Title validation (min 2 chars)
    if (!formData.title || formData.title.trim().length < 2) {
      newErrors.title = 'Title must be at least 2 characters long';
    }

    // Category validation
    if (!formData.category) {
      newErrors.category = 'Please select a category';
    }

    // Description validation (min 10 chars)
    const strippedDescription = formData.description.replace(/<[^>]*>/g, '').trim();
    if (!strippedDescription || strippedDescription.length < 10) {
      newErrors.description = 'Description must be at least 10 characters long';
    }

    // Read time validation (min 2 chars)
    if (!formData.read_time || formData.read_time.trim().length < 2) {
      newErrors.read_time = 'Read time must be at least 2 characters long';
    }

    // Image validation
    if (!formData.image_url) {
      newErrors.image = 'Please upload a main image';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Validate form before proceeding
    if (!validateForm()) {
      showToast('Please fill in all required fields correctly', 'error');
      return;
    }

    setLoading(true);
    setError('');

    try {
      const auth = getAuth();
      const token = await auth.currentUser?.getIdToken();

      // Process all images in description FIRST before preparing the payload
      let processedContent = formData.description;
      if (formData.description) {
        const images = extractImageUrls(processedContent);
        let uploadErrors = 0;
        
        for (const { src, isBase64 } of images) {
          // Skip if already on CloudFront or not a base64 image
          if (src.includes('cloudfront.net') || !isBase64) continue;

          // Only process new images (base64) that aren't already on CloudFront
          try {
            const imageBlob = await base64ToBlob(src);
            const fileExtension = imageBlob.type.split('/')[1] || 'webp';
            const file = new File([imageBlob], `blog-${Date.now()}-${Math.random().toString(36).substring(7)}.${fileExtension}`, {
              type: imageBlob.type
            });
            
            const newImageUrl = await uploadToS3(file);
            processedContent = replaceImageUrl(processedContent, src, newImageUrl);
          } catch (error) {
            console.error('Failed to process embedded image:', error);
            uploadErrors++;
            // Continue with other images, but keep track of errors
          }
        }
        
        if (uploadErrors > 0) {
          showToast(`Failed to upload ${uploadErrors} image(s). Some images may not appear correctly.`, 'warning');
        }
      }

      // Now prepare the payload with processed content
      const changedFields = {};
      if (editingBlog) {
        Object.keys(formData).forEach(key => {
          if (key === 'description') {
            if (processedContent !== editingBlog.description) {
              changedFields.description = processedContent;
            }
          } else if (formData[key] !== editingBlog[key]) {
            changedFields[key] = formData[key];
          }
        });
        
        if (Object.keys(changedFields).length === 0) {
          showToast('No changes to save', 'info');
          handleClose();
          return;
        }
      }

      // Handle main blog image
      let finalImageUrl = formData.image_url;
      if (formData.imageBlob && 
          (!editingBlog || 
           (formData.image_url !== editingBlog.image_url && !formData.image_url.includes('cloudfront.net'))
          )
      ) {
        try {
          finalImageUrl = await uploadToS3(formData.imageBlob);
        } catch (error) {
          console.error('Failed to upload main image:', error);
          showToast('Failed to upload main image', 'error');
          setError('Failed to upload main image. Please try again.');
          setLoading(false);
          return;
        }
      }

      const endpoint = editingBlog ? `/api/blogs/${editingBlog.id}` : '/api/blogs';
      const method = editingBlog ? 'put' : 'post';

      // Prepare final payload
      const dataToSend = editingBlog ? {
        ...changedFields,
        image_url: finalImageUrl !== editingBlog.image_url ? finalImageUrl : undefined
      } : {
        ...formData,
        description: processedContent,
        image_url: finalImageUrl
      };

      // Remove any raw image data
      delete dataToSend.imageBlob;

      const response = await api[method](
        endpoint, 
        dataToSend,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        }
      );

      onSuccess(response.data, !!editingBlog);
      showToast(
        editingBlog ? 'Blog updated successfully' : 'Blog created successfully', 
        'success'
      );
      handleClose();
    } catch (error) {
      console.error('Error saving blog:', error);
      setError(error.response?.data?.message || 'Failed to save blog');
      showToast('Failed to save blog', 'error');
    } finally {
      setLoading(false);
    }
  };

  const modules = {
    toolbar: {
      container: [
        [{ 'header': [1, 2, 3, false] }],
        ['bold', 'italic', 'underline', 'strike'],
        [{ 'align': [] }],
        ['blockquote', 'code-block'],
        [{ 'list': 'ordered'}, { 'list': 'bullet' }],
        ['link', 'image'],
        [{ 'color': [] }, { 'background': [] }],
        ['hr']
      ]
    }
  };

  const toggleFullscreen = () => {
    setIsFullscreen(!isFullscreen);
  };

  const handleOpenHelpModal = (source) => {
    setHelpSource(source);
    setIsHelpModalOpen(true);
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth="md"
        fullWidth
        fullScreen={isMobile}
        sx={{
          '& .MuiDialog-paper': {
            ...(isMobile ? {
              margin: 0,
              maxHeight: '100%',
              borderRadius: 0,
              paddingTop: 'env(safe-area-inset-top)',
            } : {
              borderRadius: '8px',
              maxHeight: isFullscreen ? '100vh' : '90vh',
              width: '100%',
              margin: theme.spacing(2),
              maxWidth: isFullscreen ? '100%' : 800,
              width: isFullscreen ? '100vw' : 'auto',
              margin: isFullscreen ? 0 : theme.spacing(2),
              left: isFullscreen ? 0 : 'auto',
              right: isFullscreen ? 0 : 'auto'
            })
          }
        }}
      >
        <DialogTitle>
          <Box sx={{ 
            display: 'flex', 
            alignItems: 'center',
            justifyContent: 'center',
            position: 'relative',
            pb: isMobile ? 0.5 : 1,
          }}>
            <Box>
              <Box sx={{ 
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}>
                <img
                  src={LogoIcon}
                  alt="Something Told Logo"
                  style={{
                    width: '100px',
                    height: '100px'
                  }}
                />
                <Typography 
                  variant="h6" 
                  sx={{ 
                    mt: 1,
                    fontWeight: 600,
                    textAlign: 'center'
                  }}
                >
                  {editingBlog ? 'Edit Blog' : 'Create New Blog'}
                </Typography>
              </Box>
            </Box>
            <IconButton 
              onClick={handleCloseAttempt}
              sx={{ 
                position: 'absolute',
                right: 8,
                top: 8,
              }}
            >
              <CloseIcon />
            </IconButton>
            {!isMobile && (
              <IconButton
                onClick={toggleFullscreen}
                sx={{
                  position: 'absolute',
                  right: 48,
                  top: 8,
                  color: 'text.secondary',
                  '&:hover': {
                    color: 'text.primary'
                  }
                }}
              >
                {isFullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
              </IconButton>
            )}
          </Box>
        </DialogTitle>

        <form onSubmit={handleSubmit}>
          <DialogContentStyled>
            {/* Mobile notice with improved layout */}
            {isMobile && showMobileNotice && (
              <Collapse in={showMobileNotice}>
                <MobileNotice
                  severity="info"
                  icon={<ComputerIcon fontSize="small" />}
                  onClick={() => handleOpenHelpModal('mobile')}
                  action={
                    <Button
                      color="primary"
                      size="small"
                      onClick={() => handleOpenHelpModal('mobile')}
                      sx={{
                        fontSize: '0.65rem',
                        padding: '1px 4px',
                        textTransform: 'none',
                        minWidth: 'auto',
                        backgroundColor: 'transparent',
                        color: '#1a4a2b',
                        '&:hover': {
                          backgroundColor: 'rgba(17, 49, 27, 0.04)',
                        },
                        fontWeight: 600
                      }}
                    >
                      Why?
                    </Button>
                  }
                >
                  <Typography 
                    component="span" 
                    sx={{ 
                      fontSize: '0.7rem', 
                      fontWeight: 500,
                      color: '#11311B',
                      textAlign: 'center',
                      mx: 'auto'
                    }}
                  >
                    Blog editor works best on desktop
                  </Typography>
                </MobileNotice>
              </Collapse>
            )}

            <Box sx={{ 
              display: 'grid', 
              gridTemplateColumns: { xs: '1fr', sm: 'repeat(2, 1fr)' },
              gap: 2
            }}>
              {/* Title - Full width */}
              <StyledTextField
                autoFocus
                required
                name="title"
                label="Title"
                value={formData.title}
                onChange={handleChange}
                sx={{ gridColumn: { xs: '1', sm: '1 / 3' } }}
                error={!!errors.title}
                helperText={errors.title}
              />

              {/* Category - Half width */}
              <StyledFormControl fullWidth>
                <InputLabel id="category-label">Category</InputLabel>
                <StyledSelect
                  labelId="category-label"
                  name="category"
                  value={formData.category}
                  onChange={handleChange}
                  label="Category"
                  error={!!errors.category}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        borderRadius: '8px',
                        mt: 0.5,
                        boxShadow: '0 4px 20px rgba(0,0,0,0.15)',
                      }
                    },
                    MenuListProps: {
                      sx: {
                        p: 0.5,
                      }
                    }
                  }}
                >
                  {categories.map((category) => (
                    <MenuItem 
                      key={category} 
                      value={category}
                      sx={{
                        borderRadius: '6px',
                        mx: 0.5,
                        fontSize: '0.875rem',
                        p: '8px 12px',
                        '&:hover': {
                          backgroundColor: alpha('#11311B', 0.08),
                        },
                        '&.Mui-selected': {
                          backgroundColor: alpha('#11311B', 0.12),
                          '&:hover': {
                            backgroundColor: alpha('#11311B', 0.18),
                          }
                        }
                      }}
                    >
                      {category}
                    </MenuItem>
                  ))}
                </StyledSelect>
                {errors.category && (
                  <FormHelperText error>{errors.category}</FormHelperText>
                )}
              </StyledFormControl>

              {/* Read Time - Half width */}
              <StyledTextField
                required
                name="read_time"
                label="Read Time (e.g., '5 min read')"
                value={formData.read_time}
                onChange={handleChange}
                error={!!errors.read_time}
                helperText={errors.read_time}
              />

              {/* Quill Editor - Full width */}
              <QuillWrapper sx={{ 
                height: isFullscreen ? 'calc(100vh - 360px)' : '300px',
                mb: 2,
                gridColumn: { xs: '1', sm: '1 / 3' }
              }}>
                <ReactQuill
                  ref={quillRef}
                  theme="snow"
                  value={formData.description}
                  onChange={handleEditorChange}
                  modules={modules}
                  style={{ 
                    height: '100%',
                  }}
                />
              </QuillWrapper>

              {/* Image upload - Full width, centered */}
              <Box sx={{ 
                mt: 0,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: theme.palette.background.paper,
                transition: 'all 0.2s ease-in-out',
                position: 'relative',
                p: 1.5,
                borderRadius: '12px',
                border: `1px dashed ${theme.palette.divider}`,
                gridColumn: { xs: '1', sm: '1 / 3' },
                minHeight: '150px',
                display: isFullscreen ? 'none' : 'flex'
              }}>
                {formData.image_url ? (
                  <Box sx={{ position: 'relative', width: 'fit-content' }}>
                    <PreviewContainer>
                      <img
                        src={formData.image_url}
                        alt="Preview"
                        style={{ 
                          width: '100%', 
                          height: '100%', 
                          objectFit: 'cover',
                        }}
                      />
                    </PreviewContainer>
                    <IconButton 
                      onClick={handleDeleteImage}
                      sx={{ 
                        position: 'absolute',
                        top: -8,
                        right: -8,
                        backgroundColor: 'rgba(255, 255, 255, 0.9)',
                        boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
                        '&:hover': {
                          backgroundColor: 'rgba(255, 255, 255, 1)',
                          color: 'error.main',
                        }
                      }}
                    >
                      <DeleteOutlineIcon fontSize="small" />
                    </IconButton>
                  </Box>
                ) : (
                  <ImageUpload
                    ref={fileInputRef}
                    onUploadComplete={handlePhotoSelect}
                    maxDimension={BLOG_IMAGE_MAX_DIM}
                    previewSize={{
                      width: 150,
                      height: 150
                    }}
                    highQuality={true}
                    disableResize={true}
                    buttonLabel="Cover Photo"
                  />
                )}
              </Box>

              {error && (
                <Typography 
                  color="error" 
                  variant="caption" 
                  sx={{ 
                    mt: 1, 
                    gridColumn: { xs: '1', sm: '1 / 3' }
                  }}
                >
                  {error}
                </Typography>
              )}
            </Box>
          </DialogContentStyled>

          <StyledDialogActions>
            <Button 
              onClick={() => handleOpenHelpModal('general')}
              size="small"
              sx={{ 
                color: 'primary.main',
                '&:hover': {
                  backgroundColor: 'transparent',
                  textDecoration: 'underline'
                },
                textTransform: 'none'
              }}
            >
              Need Help?
            </Button>

            {isFullscreen && (
              <Box sx={{ 
                display: 'flex', 
                justifyContent: 'center',
                flexGrow: 1,
                mx: 2 
              }}>
                <ImageUpload
                  ref={fileInputRef}
                  onUploadComplete={handlePhotoSelect}
                  maxDimension={BLOG_IMAGE_MAX_DIM}
                  previewSize={{
                    width: 150,
                    height: 150
                  }}
                  highQuality={true}
                  disableResize={true}
                  buttonLabel="Cover Photo"
                />
              </Box>
            )}

            <Box sx={{ display: 'flex', gap: 1.5 }}>
              <Button 
                onClick={handleCloseAttempt}
                size={isMobile ? "medium" : "small"}
                sx={{ 
                  borderRadius: 1.5,
                  px: isMobile ? 2 : 3,
                  textTransform: 'none',
                  minWidth: 100,
                }}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="contained"
                disabled={loading}
                size={isMobile ? "medium" : "small"}
                sx={{ 
                  borderRadius: 1.5,
                  px: isMobile ? 2 : 3,
                  textTransform: 'none',
                  bgcolor: '#11311B',
                  '&:hover': {
                    bgcolor: '#11311B',
                    opacity: 0.9,
                  }
                }}
              >
                {loading ? 'Saving...' : editingBlog ? 'Save' : 'Publish Blog'}
              </Button>
            </Box>
          </StyledDialogActions>
        </form>
      </Dialog>

      <CropPhotoModal
        open={showCropModal}
        onClose={() => {
          setShowCropModal(false);
          setSelectedFile(null);
        }}
        imageUrl={tempImageUrl}
        onCropComplete={handleCropComplete}
        cropShape="rect"
        aspectRatio={16/9}
        title="Adjust Blog Image"
        dialogWidth="600px"
      />

      <BlogHelpModal 
        open={isHelpModalOpen}
        onClose={() => {
          setIsHelpModalOpen(false);
          setHelpSource('');
        }}
        mobileOnly={helpSource === 'mobile'}
      />

      <ConfirmDialog
        open={showConfirmDialog}
        title="Discard Changes?"
        content="Are you sure you want to discard your changes? All unsaved changes will be lost."
        confirmText="Discard Changes"
        cancelText="Continue Editing"
        onConfirm={() => {
          handleClose();
        }}
        onCancel={() => {
          setShowConfirmDialog(false);
        }}
        onClose={() => {
          setShowConfirmDialog(false);
        }}
      />
    </>
  );
};

CreateBlogModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  editingBlog: PropTypes.object,
};

export default CreateBlogModal;