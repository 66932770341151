import React from 'react';
import { Box, Stack, Link, Typography, Divider, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import YouTubeIcon from '@mui/icons-material/YouTube';
import XIcon from '@mui/icons-material/X';
import { FaTiktok } from 'react-icons/fa';

const FooterLink = styled('div')(({ theme }) => ({
  color: '#C7AE48',
  textDecoration: 'none',
  fontSize: '0.875rem',
  opacity: 0.8,
  transition: 'opacity 0.2s',
  cursor: 'pointer',
  '&:hover': {
    opacity: 1,
    textDecoration: 'none',
  },
  [`@media (max-width: 959px)`]: {
    color: '#FFFFFF',
    fontSize: '16px',
    opacity: 1,
    padding: '12px 0',
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    '&::after': {
      content: '""',
      position: 'absolute',
      top: -8,
      right: -8,
      bottom: -8,
      left: -8,
      opacity: 0
    }
  }
}));

const SocialIcon = styled(Link)(({ theme }) => ({
  color: '#C7AE48',
  opacity: 1,
  transition: 'opacity 0.2s',
  '&:hover': {
    opacity: 1,
    color: '#C7AE48',
  },
  '& svg': {
    fontSize: '1.25rem',
  },
  [theme.breakpoints.down('md')]: {
    color: '#FFFFFF',
    '&:hover': {
      color: '#FFFFFF',
    },
    '& svg': {
      fontSize: '31px',
    },
    position: 'relative',
    padding: '8px',
    '&::after': {
      content: '""',
      position: 'absolute',
      top: -12,
      right: -12,
      bottom: -12,
      left: -12,
      opacity: 0
    }
  }
}));

const MobileDivider = styled(Divider)(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.down('md')]: {
    display: 'block',
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
    width: '100%',
    margin: 0,
    position: 'relative',
  
  }
}));

const Footer = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const handleNavigation = (path) => {
    navigate(path);
    window.scrollTo(0, 0);
  };

  return (
    <Box
      component="footer"
      sx={{
        width: '100%',
        py: 2,
        px: { xs: 2, sm: 4 },
        position: { xs: 'relative', md: 'relative' },
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 2,
        backgroundColor: '#11311B',
        mt: { xs: 0, md: 0 },
        overflow: 'hidden'
      }}
    >
      <Stack
        direction={{ xs: 'column' }}
        spacing={0}
        alignItems="center"
      >
        {/* Mobile Layout (< 960px) */}
        <Stack
          sx={{
            width: '100%',
            display: { xs: 'flex', md: 'none' }
          }}
        >
          {/* Mobile Social Icons */}
          <Stack
            direction="row"
            spacing={5}
            alignItems="center"
            sx={{ 
              width: '100%',
              justifyContent: 'center',
              py: 2
            }}
          >
            <SocialIcon href="https://www.instagram.com/something_told" target="_blank" rel="noopener noreferrer">
              <InstagramIcon />
            </SocialIcon>
            <SocialIcon href="https://www.facebook.com/profile.php?id=61553045185626" target="_blank" rel="noopener noreferrer">
              <FacebookIcon />
            </SocialIcon>
            <SocialIcon href="https://www.youtube.com/@SomethingTold" target="_blank" rel="noopener noreferrer">
              <YouTubeIcon />
            </SocialIcon>
            <SocialIcon href="https://www.tiktok.com/@something_told" target="_blank" rel="noopener noreferrer">
              <FaTiktok />
            </SocialIcon>
            <SocialIcon href="https://twitter.com/something_told" target="_blank" rel="noopener noreferrer">
              <XIcon />
            </SocialIcon>
          </Stack>

          <MobileDivider />

          {/* Mobile Navigation Links */}
          <Stack
            direction="column"
            spacing={3}
            component="nav"
            sx={{
              width: '100%',
              alignItems: 'center',
              '& > div:first-of-type': {
                marginTop: '24px'
              }
            }}
          >
            <FooterLink onClick={() => handleNavigation('/')}>Home</FooterLink>
            <MobileDivider />
            <FooterLink onClick={() => handleNavigation('/blogs')}>Blog</FooterLink>
            <MobileDivider />
            <FooterLink onClick={() => handleNavigation('/aboutus')}>About</FooterLink>
            <MobileDivider />
            <FooterLink onClick={() => handleNavigation('/terms')}>Terms & Privacy</FooterLink>
            <MobileDivider />
          </Stack>

          {/* Mobile Copyright */}
          <Typography
            variant="body2"
            sx={{ 
              color: '#FFFFFF',
              textAlign: 'center',
              width: '100%',
              fontSize: '16px',
              py: 2
            }}
          >
            Something Told ® {new Date().getFullYear()}
          </Typography>
        </Stack>

        {/* Desktop Layout (≥ 960px) */}
        <Stack
          direction="row"
          sx={{
            width: '100%',
            display: { xs: 'none', md: 'flex' },
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          {/* Left: Navigation Links */}
          <Stack direction="row" spacing={3}>
            <FooterLink onClick={() => handleNavigation('/')}>Home</FooterLink>
            <FooterLink onClick={() => handleNavigation('/blogs')}>Blog</FooterLink>
            <FooterLink onClick={() => handleNavigation('/aboutus')}>About</FooterLink>
            <FooterLink onClick={() => handleNavigation('/terms')}>Terms & Privacy</FooterLink>
          </Stack>

          {/* Center: Social Icons */}
          <Stack
            direction="row"
            spacing={3}
            sx={{
              position: 'absolute',
              left: '50%',
              transform: 'translateX(-50%)'
            }}
          >
            <SocialIcon href="https://www.instagram.com/something_told" target="_blank" rel="noopener noreferrer">
              <InstagramIcon />
            </SocialIcon>
            <SocialIcon href="https://www.facebook.com/profile.php?id=61553045185626" target="_blank" rel="noopener noreferrer">
              <FacebookIcon />
            </SocialIcon>
            <SocialIcon href="https://www.youtube.com/@SomethingTold" target="_blank" rel="noopener noreferrer">
              <YouTubeIcon />
            </SocialIcon>
            <SocialIcon href="https://www.tiktok.com/@something_told" target="_blank" rel="noopener noreferrer">
              <FaTiktok />
            </SocialIcon>
            <SocialIcon href="https://twitter.com/something_told" target="_blank" rel="noopener noreferrer">
              <XIcon />
            </SocialIcon>
          </Stack>

          {/* Right: Copyright */}
          <Typography
            variant="body2"
            sx={{ 
              color: '#C7AE48',
              opacity: 0.8,
              whiteSpace: 'nowrap',
              fontSize: '14px'
            }}
          >
            Something Told ™ {new Date().getFullYear()}
          </Typography>
        </Stack>
      </Stack>
    </Box>
  );
};

export default Footer; 