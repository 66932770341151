import { Dialog, DialogContent, Typography, Box, Button, useTheme, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import CloseButton from './common/CloseButton';
import { Lock, Favorite, Share } from '@mui/icons-material';
import NightSkyBackground from './NightSkyBackground';
import TextureBg from '../assets/Texture.png';

const WelcomeContent = styled(Box)(({ theme }) => ({
  color: theme.palette.common.white,
  padding: theme.spacing(4),
  position: 'relative',
  minHeight: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(3),
    overflow: 'visible',
    display: 'grid',
    gridTemplateRows: 'auto auto auto 1fr',
    gap: theme.spacing(3),
  },
}));

const GlowingTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.white,
  textShadow: '0 0 10px rgba(255,215,0,0.3)',
  marginBottom: theme.spacing(2),
  position: 'relative',
  '&::after': {
    content: '""',
    position: 'absolute',
    bottom: -8,
    left: '50%',
    transform: 'translateX(-50%)',
    width: '60px',
    height: '2px',
    background: 'linear-gradient(90deg, rgba(255,215,0,0.1), rgba(255,215,0,0.6), rgba(255,215,0,0.1))'
  }
}));

const FeatureGrid = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(3, 1fr)',
  gap: theme.spacing(3),
  margin: theme.spacing(4, 0, 0),
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: '1fr',
    gap: theme.spacing(2),
    margin: 0,
    order: 4
  }
}));

const FeatureCard = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  background: 'linear-gradient(135deg, rgba(255,255,255,0.1) 0%, rgba(255,255,255,0.05) 100%)',
  borderRadius: theme.shape.borderRadius,
  backdropFilter: 'blur(10px)',
  border: '1px solid rgba(255,255,255,0.1)',
  transition: 'all 0.3s ease',
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: '0 8px 20px rgba(0,0,0,0.2)',
    '& .MuiSvgIcon-root': {
      transform: 'scale(1.1)',
    }
  },
  '& .MuiSvgIcon-root': {
    fontSize: 40,
    marginBottom: theme.spacing(2),
    color: theme.palette.common.white,
    filter: 'drop-shadow(0 0 8px rgba(255,255,255,0.3))',
    transition: 'transform 0.3s ease'
  }
}));

const ActionButton = styled(Button)(({ theme }) => ({
  borderRadius: 30,
  padding: theme.spacing(1.5, 4),
  fontSize: '1rem',
  textTransform: 'none',
  fontWeight: 500,
  boxShadow: '0 4px 12px rgba(0,0,0,0.15)',
  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: '0 6px 16px rgba(0,0,0,0.2)',
  },
  transition: 'all 0.3s ease'
}));

const WelcomeModal = ({ open, onClose, type = 'treasure' }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();

  // Content configurations based on type
  const content = {
    treasure: {
      title: "Discover This Treasure",
      subtitle: "Join our community to unlock the full story behind this cherished memory and create your own collection of treasured moments.",
      features: [
        {
          icon: <Lock />,
          title: "Private & Secure",
          description: "Your memories are safely stored and shared only with those you choose."
        },
        {
          icon: <Favorite />,
          title: "Create Collections",
          description: "Organize your treasures into meaningful collections that tell your story."
        },
        {
          icon: <Share />,
          title: "Share Stories",
          description: "Connect with family and friends by sharing your treasured memories."
        }
      ],
      footer: "Join thousands of families preserving their precious memories"
    },
    event: {
      title: "Join This Community Event",
      subtitle: "Be part of something special! Connect with like-minded storytellers, share experiences, and create lasting memories together.",
      features: [
        {
          icon: <Lock />,
          title: "Exclusive Access",
          description: "Join private events and connect with our vibrant community of storytellers."
        },
        {
          icon: <Favorite />,
          title: "Create Memories",
          description: "Participate in unique events that inspire and spark meaningful conversations."
        },
        {
          icon: <Share />,
          title: "Share Experiences",
          description: "Build connections through shared stories and collaborative storytelling."
        }
      ],
      footer: "Join our growing community of passionate storytellers and memory makers"
    }
  };

  const selectedContent = content[type];

  const handleSignUp = () => {
    localStorage.setItem('showRegistration', 'true');
    navigate('/login');
    onClose();
  };

  const handleSignIn = () => {
    navigate('/login');
    onClose();
  };

  return (
    <Dialog
      open={open}
      maxWidth="md"
      fullWidth
      fullScreen={isMobile}
      disableEscapeKeyDown
      onClose={(event, reason) => {
        // Only allow closing when navigating to login
        if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
          return;
        }
        onClose();
      }}
      PaperProps={{
        sx: {
          borderRadius: isMobile ? 0 : 2,
          background: 'transparent',
          m: isMobile ? 0 : 2,
          height: isMobile ? '100%' : 'auto',
          overflow: 'hidden'
        }
      }}
    >

      <DialogContent sx={{ 
        position: 'relative',
        background: `url(${TextureBg})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundColor: '#11311B',
        overflow: 'auto',
        padding: 0,
        [theme.breakpoints.down('sm')]: {
          display: 'flex',
          flexDirection: 'column'
        }
      }}>
        <WelcomeContent>
          <GlowingTitle variant="h3">
            {selectedContent.title}
          </GlowingTitle>
          
          <Typography variant="h6" sx={{ 
            opacity: 0.9, 
            mb: 3, 
            maxWidth: 600,
            [theme.breakpoints.down('sm')]: {
              fontSize: '1rem',
              mb: 0
            }
          }}>
            {selectedContent.subtitle}
          </Typography>

          <Box sx={{ 
            display: 'flex', 
            gap: 2, 
            flexWrap: 'wrap', 
            justifyContent: 'center',
            [theme.breakpoints.down('sm')]: {
              order: 3,
              width: '100%',
              mt: 0
            },
            [theme.breakpoints.up('sm')]: {
              mt: 4
            }
          }}>
            <ActionButton
              variant="outlined"
              onClick={handleSignUp}
              sx={{
                borderColor: 'rgba(255,255,255,0.3)',
                color: 'white',
                '&:hover': {
                  borderColor: 'white'
                },
                [theme.breakpoints.down('sm')]: {
                  flex: 1
                }
              }}
            >
              Sign Up Free
            </ActionButton>
            <ActionButton
              variant="outlined"
              sx={{ 
                borderColor: 'rgba(255,255,255,0.3)',
                color: 'white',
                '&:hover': {
                  borderColor: 'white'
                },
                [theme.breakpoints.down('sm')]: {
                  flex: 1
                }
              }}
              onClick={handleSignIn}
            >
              Sign In
            </ActionButton>
          </Box>

          <FeatureGrid>
            {selectedContent.features.map((feature, index) => (
              <FeatureCard key={index}>
                {feature.icon}
                <Typography variant="h6" sx={{ mb: 1 }}>
                  {feature.title}
                </Typography>
                <Typography variant="body2" sx={{ opacity: 0.8 }}>
                  {feature.description}
                </Typography>
              </FeatureCard>
            ))}
          </FeatureGrid>

          <Typography variant="body2" sx={{ 
            mt: 4, 
            opacity: 0.7,
            [theme.breakpoints.down('sm')]: {
              order: 5,
              mt: 2,
              fontSize: '0.75rem'
            }
          }}>
            {selectedContent.footer}
          </Typography>
        </WelcomeContent>
      </DialogContent>
    </Dialog>
  );
};

export default WelcomeModal; 