import { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent as MuiDialogContent,
  Typography,
  Box,
  Button,
  IconButton,
  styled,
  useTheme,
  useMediaQuery,
  CircularProgress,
  Divider,
  Avatar,
  AvatarGroup,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ShareIcon from '@mui/icons-material/Share';
import { Close, CalendarToday, AccessTime, LocationOn } from '@mui/icons-material';
import { useToast } from '../contexts/ToastContext';
import { useParams, useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import api from '../services/api';
import ErrorBoundary from './ErrorBoundary';
import Logo from './common/Logo';
import WelcomeModal from './WelcomeModal';
import { format, parseISO } from 'date-fns';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    borderRadius: theme.shape.borderRadius * 2,
    background: theme.palette.background.default,
    [theme.breakpoints.down('sm')]: {
      margin: 0,
      maxHeight: '100%',
      height: '100%',
      borderRadius: 0,
    },
  },
}));

const DialogHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(2, 3),
  borderBottom: `1px solid ${theme.palette.divider}`,
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(1.5, 2),
  },
}));

const HeroSection = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  height: '45vh',
  minHeight: 300,
  maxHeight: 480,
  overflow: 'hidden',
  
  [theme.breakpoints.down('sm')]: {
    height: '40vh',
    minHeight: 250,
  }
}));

const HeroImage = styled('img')({
  width: '100%',
  height: '100%',
  objectFit: 'cover',
});

const HeroOverlay = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  background: 'linear-gradient(180deg, rgba(0,0,0,0.2) 0%, rgba(0,0,0,0.6) 100%)',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  padding: theme.spacing(4),
  color: 'white',

  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(3, 2),
  }
}));

const EventTypeBadge = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(3),
  left: theme.spacing(3),
  padding: '6px 16px',
  borderRadius: '30px',
  backgroundColor: 'rgba(255,255,255,0.95)',
  backdropFilter: 'blur(8px)',
  boxShadow: '0 2px 12px rgba(0,0,0,0.12)',
  
  [theme.breakpoints.down('sm')]: {
    top: theme.spacing(2),
    left: theme.spacing(2),
  }
}));

const ContentSection = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4),
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(3, 2),
  }
}));

const DetailCard = styled(Box)(({ theme }) => ({
  background: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius * 2,
  padding: theme.spacing(3),
  marginBottom: theme.spacing(3),
  boxShadow: '0 2px 12px rgba(0,0,0,0.05)',
  
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
  }
}));

const InfoDivider = styled(Box)(({ theme }) => ({
  width: '3px',
  height: '3px',
  borderRadius: '50%',
  backgroundColor: theme.palette.divider,
  margin: theme.spacing(0, 1),
  flexShrink: 0,
}));

const ActionBar = styled(Box)(({ theme }) => ({
  position: 'sticky',
  bottom: 0,
  left: 0,
  right: 0,
  padding: theme.spacing(2, 4),
  background: theme.palette.background.paper,
  borderTop: `1px solid ${theme.palette.divider}`,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  zIndex: 10,
  
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2),
    flexDirection: 'column',
    gap: theme.spacing(1),
  }
}));

const EventDetails = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(4),
  marginBottom: theme.spacing(3),
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
}));

const DetailItem = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1.5),
  '& .MuiSvgIcon-root': {
    color: theme.palette.primary.main,
    opacity: 0.8,
  },
}));

const ParticipantsSection = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginTop: theme.spacing(4),
  padding: theme.spacing(2),
  backgroundColor: theme.palette.grey[50],
  borderRadius: theme.shape.borderRadius * 2,
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
}));

const ActionButtons = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    '& .MuiButton-root': {
      flex: 1,
    },
  },
}));

const formatEventTime = (timeString) => {
  if (!timeString) return '';
  try {
    // Split the time string into hours and minutes
    const [hours, minutes] = timeString.split(':');
    
    // Create a date object for today with the specified time
    const date = new Date();
    date.setHours(parseInt(hours, 10));
    date.setMinutes(parseInt(minutes, 10));
    
    return date.toLocaleTimeString('en-US', { 
      hour: 'numeric',
      minute: '2-digit',
      hour12: true,
      timeZone: 'America/New_York'
    });
  } catch (e) {
    console.error('Error formatting time:', e);
    return timeString;
  }
};

const EventStandalone = ({ open, eventId, event: initialEvent, onClose }) => {
  const { id } = useParams() || { id: eventId };
  const isDirectAccess = !eventId && !!id; // True only when accessed via URL
  const [event, setEvent] = useState(initialEvent || null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { showToast } = useToast();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { currentUser } = useAuth();
  const [showWelcome, setShowWelcome] = useState(false);
  const [dashboardLoaded, setDashboardLoaded] = useState(false);

  // For direct access, we need to force the dialog to be open
  const isOpen = isDirectAccess || open;

  const showCloseButtons = !isDirectAccess || (isDirectAccess && currentUser);
  const canClose = !isDirectAccess || (isDirectAccess && currentUser);

  useEffect(() => {
    const fetchEvent = async () => {
      try {
        setLoading(true);
        setError(null);
        
        console.log('Fetching event with ID:', id);
        const response = await api.get(`/api/events/shared/${id}`);
        console.log('Event response:', response.data);
        
        if (response.data.success) {
          setEvent(response.data.data);
        } else {
          throw new Error('Failed to fetch event data');
        }
      } catch (error) {
        console.error('Error details:', {
          message: error.message,
          response: error.response?.data,
          status: error.response?.status
        });
        setError(error.response?.data?.message || 'Failed to load event');
        showToast('Event not found', 'error');
        navigate('/events');
      } finally {
        setLoading(false);
      }
    };

    if (isDirectAccess && id) {
      fetchEvent();
    } else if (initialEvent) {
      setEvent(initialEvent);
      setLoading(false);
    }
  }, [id, isDirectAccess, initialEvent]);

  // Show welcome modal only for non-logged in users
  useEffect(() => {
    if (!currentUser && isDirectAccess && event) {
      const timer = setTimeout(() => {
        // Store redirect info only when we actually show the welcome modal
        localStorage.setItem('eventRedirect', JSON.stringify({
          id: event.id,
          type: 'event'
        }));
        setShowWelcome(true);
      }, 6000);

      return () => clearTimeout(timer);
    }
  }, [isDirectAccess, currentUser, event]);

  // Preload dashboard if direct access and user is logged in
  useEffect(() => {
    if (isDirectAccess && currentUser && !dashboardLoaded) {
      setDashboardLoaded(true);
    }
  }, [isDirectAccess, currentUser, dashboardLoaded]);

  // Update handleClose to handle direct access properly
  const handleClose = () => {
    if (isDirectAccess) {
      navigate('/events', { replace: true });
    } else if (onClose) {
      onClose();
    }
  };

  const handleShare = async () => {
    try {
      const url = `${window.location.origin}/events/${event.id}`;
      await navigator.clipboard.writeText(url);
      showToast('Link copied to clipboard!', 'success');
    } catch (error) {
      console.error('Error copying to clipboard:', error);
      showToast('Failed to copy link', 'error');
    }
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <ErrorBoundary>
      <StyledDialog
        open={isOpen}
        onClose={handleClose}
        maxWidth="md"
        fullWidth
        fullScreen={isMobile}
      >
        <IconButton 
          onClick={handleClose}
          sx={{ 
            position: 'absolute', 
            top: 16, 
            right: 16,
            zIndex: 2,
            color: 'white',
            backgroundColor: 'rgba(0,0,0,0.4)',
            backdropFilter: 'blur(4px)',
            '&:hover': {
              backgroundColor: 'rgba(0,0,0,0.6)',
            }
          }}
        >
          <Close />
        </IconButton>

        <HeroSection>
          <HeroImage
            src={event.image_url}
            alt={event.title}
            loading="eager"
          />
          <HeroOverlay>
            <EventTypeBadge>
              <Typography variant="subtitle2" color="primary.main" fontWeight={600}>
                {event.type}
              </Typography>
            </EventTypeBadge>
            <Typography variant="h3" fontWeight={700} mb={2}>
              {event.title}
            </Typography>
          </HeroOverlay>
        </HeroSection>

        <ContentSection>
          <DetailCard>
            <Box sx={{ 
              display: 'flex', 
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              fontSize: { 
                xs: '0.7rem',   // Smaller font on mobile
                sm: '0.875rem', // Medium on tablet
                md: '1rem'      // Normal on desktop
              },
              px: { xs: 1, sm: 2 }, // Less padding on mobile
            }}>
              <Box sx={{ 
                display: 'flex', 
                alignItems: 'center', 
                gap: { xs: 0.5, sm: 1 },
                minWidth: 0, // Allow content to shrink
                flexShrink: 1
              }}>
                <CalendarToday color="primary" sx={{ fontSize: 'inherit' }} />
                <Typography 
                  variant="body2" 
                  fontWeight={500}
                  noWrap // Prevent text wrapping
                  sx={{ fontSize: 'inherit' }}
                >
                  {format(parseISO(event.date), 'MMM d, yyyy')} {/* Shortened month format */}
                </Typography>
              </Box>

              <InfoDivider />

              <Box sx={{ 
                display: 'flex', 
                alignItems: 'center', 
                gap: { xs: 0.5, sm: 1 },
                minWidth: 0,
                flexShrink: 1
              }}>
                <AccessTime color="primary" sx={{ fontSize: 'inherit' }} />
                <Typography 
                  variant="body2" 
                  fontWeight={500}
                  noWrap
                  sx={{ fontSize: 'inherit' }}
                >
                  {`${formatEventTime(event.start_time)}-${formatEventTime(event.end_time)}`}
                </Typography>
              </Box>

              <InfoDivider />

              <Box sx={{ 
                display: 'flex', 
                alignItems: 'center', 
                gap: { xs: 0.5, sm: 1 },
                minWidth: 0,
                flexShrink: 1
              }}>
                <LocationOn color="primary" sx={{ fontSize: 'inherit' }} />
                <Typography 
                  variant="body2" 
                  fontWeight={500}
                  noWrap
                  sx={{ fontSize: 'inherit' }}
                >
                  {event.location || event.venue || 'Location TBA'}
                </Typography>
              </Box>
            </Box>
          </DetailCard>

          <DetailCard>
            <Typography variant="h6" gutterBottom fontWeight={600}>
              About this Event
            </Typography>
            <Typography variant="body1" color="text.secondary" sx={{ lineHeight: 1.8 }}>
              {event.description}
            </Typography>
          </DetailCard>

          <DetailCard>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 2 }}>
              <Typography variant="h6" fontWeight={600}>
                Participants
              </Typography>
              <Typography variant="subtitle1" color="primary.main" fontWeight={500}>
                {event.participants_count || 0} joined
              </Typography>
            </Box>
            <AvatarGroup 
              max={8}
              sx={{
                '& .MuiAvatar-root': {
                  width: 40,
                  height: 40,
                  fontSize: '1rem',
                  border: '2px solid white'
                }
              }}
            >
              {/* Participant avatars */}
            </AvatarGroup>
          </DetailCard>
        </ContentSection>

        <ActionBar>
          <Button
            variant="outlined"
            startIcon={<ShareIcon />}
            onClick={handleShare}
            sx={{
              borderRadius: '12px',
              textTransform: 'none',
              flex: isMobile ? 1 : 'none'
            }}
          >
            Share Event
          </Button>
          <Button
            variant="contained"
            sx={{
              borderRadius: '12px',
              textTransform: 'none',
              background: 'linear-gradient(45deg, #1a1a1a 30%, #4a4a4a 90%)',
              px: 4,
              flex: isMobile ? 1 : 'none'
            }}
          >
            RSVP Now
          </Button>
        </ActionBar>
      </StyledDialog>

      <WelcomeModal
        open={showWelcome}
        onClose={() => setShowWelcome(false)}
        type="event"
      />
    </ErrorBoundary>
  );
};

export default EventStandalone; 