import { useState, useRef, useEffect } from 'react';
import { Box, IconButton, styled } from '@mui/material';
import {
  PlayArrow,
  Pause,
  VolumeUp,
  VolumeOff,
} from '@mui/icons-material';

const VideoWrapper = styled(Box)({
  position: 'relative',
  width: '100%',
  '&:hover .video-controls': {
    opacity: 1
  }
});

const ControlButton = styled(IconButton)(({ theme }) => ({
  color: 'white',
  backgroundColor: 'rgba(0,0,0,0.2)',
  '&:hover': {
    backgroundColor: 'rgba(0,0,0,0.4)',
  },
  padding: theme.spacing(0.75),
  '& .MuiSvgIcon-root': {
    fontSize: '1.5rem'
  }
}));

// Separate controls for play and volume
const PlayControl = styled(Box)(({ theme, ishovered }) => ({
  position: 'absolute',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  opacity: ishovered === 'true' ? 1 : 0,
  transition: 'opacity 0.3s ease',
  zIndex: 10
}));

const VolumeControl = styled(Box)(({ theme, ishovered, ismuted }) => ({
  position: 'absolute',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%, 35px)',
  opacity: ismuted === 'true' || ishovered === 'true' ? 1 : 0,
  transition: 'opacity 0.3s ease',
  zIndex: 10
}));

const InstagramStyleVideoPlayer = ({ src }) => {
  const [isPlaying, setIsPlaying] = useState(true);
  const [isMuted, setIsMuted] = useState(true);
  const [isHovered, setIsHovered] = useState(false);
  const [showControls, setShowControls] = useState(false);
  const videoRef = useRef(null);
  const controlsTimeoutRef = useRef(null);
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  const hideControlsAfterDelay = () => {
    if (controlsTimeoutRef.current) {
      clearTimeout(controlsTimeoutRef.current);
    }
    // Always hide controls after delay on mobile
    if (isMobile || !isMuted) {
      controlsTimeoutRef.current = setTimeout(() => {
        setShowControls(false);
      }, 1000);
    }
  };

  const handleVideoInteraction = () => {
    setShowControls(true);
    if (isMobile) {
      hideControlsAfterDelay();
    }
  };

  const handlePlayPause = (e) => {
    e.stopPropagation();
    if (videoRef.current.paused) {
      videoRef.current.play().then(() => {
        setIsPlaying(true);
      }).catch(error => {
        console.log("Play prevented:", error);
      });
    } else {
      videoRef.current.pause();
      setIsPlaying(false);
    }
    handleVideoInteraction();
  };

  const handleVolumeToggle = (e) => {
    e.stopPropagation();  // Prevent the general video interaction
    setIsMuted(!isMuted);
    
    // Only handle delay/controls for non-muted state
    if (!isMuted) {
      if (isMobile) {
        setShowControls(false);  // Immediately hide on mobile when unmuting
      } else {
        handleVideoInteraction();
      }
    }
  };

  return (
    <VideoWrapper
      onMouseEnter={() => {
        if (!isMobile) {
          setIsHovered(true);
          setShowControls(true);
        }
      }}
      onMouseLeave={() => {
        if (!isMobile) {
          setIsHovered(false);
          if (!isMuted) {
            setShowControls(false);
          }
        }
      }}
      onClick={handleVideoInteraction}
      onTouchStart={(e) => {
        // Don't trigger video interaction if clicking volume button
        if (!e.target.closest('button')) {
          handleVideoInteraction();
        }
      }}
    >
      <video
        ref={videoRef}
        src={src}
        style={{
          width: '100%',
          height: 'auto',
          display: 'block'
        }}
        muted={isMuted}
        autoPlay
        playsInline
        loop
      />
      {/* Only show play control on hover/tap */}
      <PlayControl ishovered={(isHovered || showControls).toString()}>
        <ControlButton size="small" onClick={handlePlayPause}>
          {isPlaying ? <Pause /> : <PlayArrow />}
        </ControlButton>
      </PlayControl>
      {/* Show volume control when muted or on hover/tap */}
      <VolumeControl 
        ishovered={(isHovered || showControls).toString()}
        ismuted={isMuted.toString()}
      >
        <ControlButton 
          size="small" 
          onClick={handleVolumeToggle}
        >
          {isMuted ? <VolumeOff /> : <VolumeUp />}
        </ControlButton>
      </VolumeControl>
    </VideoWrapper>
  );
};

export default InstagramStyleVideoPlayer; 