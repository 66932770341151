import React, { useRef, useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogContent,
  IconButton,
  Typography,
  Box,
  Chip,
  Avatar,
  Divider,
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { Close as CloseIcon, KeyboardArrowUp } from '@mui/icons-material';
import { format } from 'date-fns';
import ProgressiveImage from '../ProgressiveImage';
import { alpha } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import { motion, AnimatePresence } from 'framer-motion';
import { useScrollManagement } from '../../contexts/ScrollManagementContext';

// Update CategoryBadge styling
const CategoryBadge = styled(Box)(({ theme }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '3px 10px',
  borderRadius: '16px',
  fontSize: '0.65rem',
  fontWeight: 500,
  textTransform: 'uppercase',
  backgroundColor: 'rgba(0, 0, 0, 0.3)', // Match close button background
  color: 'rgba(255, 255, 255, 0.9)',
  letterSpacing: '0.5px',
  backdropFilter: 'blur(8px)', // Match close button blur
  border: '1px solid rgba(255, 255, 255, 0.2)', // Match close button border
  boxShadow: '0 2px 8px rgba(0, 0, 0, 0.2)', // Same shadow as close button
  height: '24px',
  transition: 'all 0.2s ease-in-out',
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    transform: 'scale(1.05)',
  }
}));

export const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    width: '100%',
    maxWidth: '900px',
    margin: theme.spacing(2),
    borderRadius: theme.shape.borderRadius * 2,
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
    maxHeight: '90vh',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('md')]: {
      margin: 0,
      maxWidth: '100%',
      height: '100vh',
      maxHeight: '100vh',
      borderRadius: 0,
      paddingTop: 'env(safe-area-inset-top)',
    },
  },
}));

const HeaderImage = styled(motion.div)(({ theme, isCollapsed }) => ({
  position: 'relative',
  width: '100%',
  height: isCollapsed ? '200px' : '50vh',
  overflow: 'hidden',
  transition: 'height 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
  '&::after': {
    content: '""',
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    height: '100%',
    background: `linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.2) 50%,
      rgba(0, 0, 0, 0.8) 90%,
      rgba(0, 0, 0, 0.9) 100%
    )`,
    zIndex: 1,
  },
  [theme.breakpoints.down('md')]: {
    height: isCollapsed ? '180px' : '300px',
  },
  [theme.breakpoints.down('sm')]: {
    height: isCollapsed ? '180px' : '250px',
  },
}));

const ContentWrapper = styled(Box)(({ theme, isCollapsed }) => ({
  position: 'relative',
  height: isCollapsed ? 'calc(100vh - 225px)' : '400px',
  backgroundColor: alpha(theme.palette.primary.main, 0.03),
  transition: 'all 0.5s cubic-bezier(0.4, 0, 0.2, 1)',
  [theme.breakpoints.down('md')]: {
    height: isCollapsed ? 'calc(100vh - 180px)' : 'calc(100vh - 300px)',
  },
  [theme.breakpoints.down('sm')]: {
    height: isCollapsed ? 'calc(100vh - 160px)' : 'calc(100vh - 250px)',
  },
}));

const ScrollableContent = styled(Box)(({ theme }) => ({
  height: '100%',
  overflowY: 'auto',
  overflowX: 'hidden',
  WebkitOverflowScrolling: 'touch',
  scrollBehavior: 'smooth',
  '-webkit-transform': 'translateZ(0)',
  transform: 'translateZ(0)',
  willChange: 'transform',
  padding: theme.spacing(4),
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(3),
  },
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2),
  },
  '-webkit-overflow-scrolling': 'touch',
  overscrollBehavior: 'auto',
  
  // Hide scrollbar for Chrome, Safari and Opera
  '&::-webkit-scrollbar': {
    display: 'none'
  },
  
  // Hide scrollbar for IE, Edge and Firefox
  '-ms-overflow-style': 'none',  // IE and Edge
  'scrollbarWidth': 'none',      // Firefox
}));

const HeaderContent = styled(Box)(({ theme, isCollapsed }) => ({
  position: 'absolute',
  bottom: 0,
  left: 0,
  right: 0,
  padding: theme.spacing(3),
  color: '#fff',
  zIndex: 2,
  transition: 'all 0.3s ease',
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(2),
  },
  transform: isCollapsed ? 'translateY(0)' : 'translateY(0)',
  opacity: 1, // Keep content always visible
}));

export const BlogContent = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4),
  maxWidth: '900px',
  margin: '0 auto',
  '& img': {
    maxWidth: '600px', // Maximum width for large screens
    maxHeight: '600px', // Maximum height for any image
    width: 'auto', // Allow image to be smaller than maxWidth
    height: 'auto', // Maintain aspect ratio
    display: 'block',
    margin: '24px auto',
    borderRadius: theme.shape.borderRadius,
    boxShadow: '0 2px 12px rgba(0,0,0,0.1)',
    [theme.breakpoints.down('md')]: {
      maxWidth: '100%', // Full container width on smaller screens
      maxHeight: '500px', // Slightly smaller max height on tablets
    },
    [theme.breakpoints.down('sm')]: {
      maxHeight: '400px', // Even smaller max height on mobile
    },
    objectFit: 'contain', // Ensures entire image is visible within bounds
  },
  // Code blocks
  '& pre': {
    backgroundColor: alpha(theme.palette.primary.main, 0.04),
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    overflow: 'auto',
    maxWidth: '100%',
    margin: theme.spacing(2, 0),
    border: `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
    '& code': {
      fontFamily: 'monospace',
      whiteSpace: 'pre-wrap',
      wordBreak: 'break-word',
    }
  },

  // Inline code
  '& code': {
    backgroundColor: alpha(theme.palette.primary.main, 0.04),
    padding: theme.spacing(0.5, 1),
    borderRadius: '4px',
    fontSize: '0.9em',
    fontFamily: 'monospace',
    color: theme.palette.primary.main,
    wordBreak: 'break-word',
  },

  // Lists
  '& ul, & ol': {
    paddingLeft: theme.spacing(4),
    marginBottom: theme.spacing(3),
    '& li': {
      marginBottom: theme.spacing(1),
      '&::marker': {
        color: theme.palette.primary.main,
      },
    },
  },

  // Blockquotes
  '& blockquote': {
    margin: theme.spacing(3, 0),
    padding: theme.spacing(2, 3),
    borderLeft: `4px solid ${theme.palette.primary.main}`,
    backgroundColor: alpha(theme.palette.primary.main, 0.04),
    borderRadius: theme.shape.borderRadius,
    '& p': {
      margin: 0,
      fontSize: '1.1rem',
      fontStyle: 'italic',
    },
  },

  // General text content
  '& p, & h1, & h2, & h3, & h4, & h5, & h6': {
    maxWidth: '100%',
    wordWrap: 'break-word',
    overflowWrap: 'break-word',
  },

  // Tables
  '& table': {
    width: '100%',
    borderCollapse: 'collapse',
    marginBottom: theme.spacing(3),
    '& th, & td': {
      padding: theme.spacing(1, 2),
      border: `1px solid ${theme.palette.divider}`,
      textAlign: 'left',
    },
    '& th': {
      backgroundColor: alpha(theme.palette.primary.main, 0.04),
    },
  },

  // Ensure all content stays within bounds
  '& *': {
    maxWidth: '100%',
  },

  // Style the horizontal rule
  '& hr': {
    margin: theme.spacing(4, 0),
    border: 'none',
    height: '2px',
    background: `linear-gradient(
      90deg, 
      rgba(0,0,0,0) 0%, 
      ${alpha(theme.palette.primary.main, 0.2)} 50%, 
      rgba(0,0,0,0) 100%
    )`,
    opacity: 0.8,
  },

  // Fancy divider option
  '& .fancy-divider': {
    margin: theme.spacing(4, 0),
    height: '5px',
    border: 'none',
    background: `linear-gradient(
      90deg, 
      rgba(0,0,0,0) 0%,
      ${alpha(theme.palette.primary.main, 0.1)} 25%,
      ${alpha(theme.palette.primary.main, 0.2)} 50%,
      ${alpha(theme.palette.primary.main, 0.1)} 75%,
      rgba(0,0,0,0) 100%
    )`,
    position: 'relative',
    '&::before': {
      content: '""',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '30px',
      height: '30px',
      background: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='${encodeURIComponent(
        theme.palette.primary.main
      )}' d='M12 2l2.4 7.4h7.6l-6.2 4.5 2.4 7.4-6.2-4.5-6.2 4.5 2.4-7.4-6.2-4.5h7.6z'/%3E%3C/svg%3E") center/contain no-repeat`,
      opacity: 0.2,
    }
  },

  // Space break
  '& .space-break': {
    margin: theme.spacing(3, 0),
    textAlign: 'center',
    '&::before': {
      content: '"• • •"',
      color: alpha(theme.palette.text.primary, 0.3),
      letterSpacing: '8px',
    }
  },

  // Text alignment styles for Quill
  '& .ql-align-center': {
    textAlign: 'center',
  },
  '& .ql-align-left': {
    textAlign: 'left',
  },
  '& .ql-align-right': {
    textAlign: 'right',
  },
  '& .ql-align-justify': {
    textAlign: 'justify',
  },

  // Make sure paragraphs maintain alignment
  '& p': {
    marginBottom: theme.spacing(2),
    lineHeight: 1.7,
    '&.ql-align-center': {
      textAlign: 'center',
    },
    '&.ql-align-left': {
      textAlign: 'left',
    },
    '&.ql-align-right': {
      textAlign: 'right',
    },
    '&.ql-align-justify': {
      textAlign: 'justify',
    },
  },
}));

// Add this styled component for the arrow button
const ScrollArrowButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  bottom: 12,
  right: 12,
  zIndex: 3,
  padding: 4,
  backgroundColor: 'transparent',
  color: 'rgba(255, 255, 255, 0.6)',
  '&:hover': {
    backgroundColor: 'transparent',
    color: 'rgba(255, 255, 255, 0.9)',
  },
  transition: 'all 0.2s ease',
  boxShadow: 'none',
}));

// Add this for the avatar animation
const AvatarWrapper = styled(motion.div)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),
}));

// Update ScrollArrowButton container to include Logo
const ScrollControls = styled(Box)(({ theme }) => ({
  position: 'absolute',
  bottom: 12,
  right: 12,
  zIndex: 3,
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1.5),
}));

const ReadBlog = ({ blog, open, onClose, index }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const contentRef = useRef(null);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [elementsVisible, setElementsVisible] = useState(true);
  const { setPreventScroll } = useScrollManagement();
  const lastScrollTop = useRef(0);
  const scrollTimeout = useRef(null);
  const [isAnimating, setIsAnimating] = useState(false);
  const animationTimeout = useRef(null);

  // Handle scroll events with animation lock and better edge detection
  const handleScroll = useCallback(() => {
    if (!contentRef.current || isAnimating) return;
    
    const scrollTop = contentRef.current.scrollTop;
    const scrollSpeed = Math.abs(scrollTop - (lastScrollTop.current || 0));
    const isAtTop = scrollTop < 20;
    const wasAtTop = lastScrollTop.current < 20;
    
    // Prevent unnecessary animations when already at correct state
    if (isAtTop && !isCollapsed && wasAtTop) return; // Already expanded at top
    if (!isAtTop && isCollapsed) return; // Already collapsed while scrolling
    
    // Collapse on fast scroll down (but only if not at top)
    if ((scrollSpeed > 10 || scrollTop > 50) && !isCollapsed && !isAtTop) {
      setIsAnimating(true);
      setElementsVisible(false);
      
      setTimeout(() => {
        setIsCollapsed(true);
        
        animationTimeout.current = setTimeout(() => {
          setElementsVisible(true);
          setIsAnimating(false);
        }, 300);
      }, 150);
    } 
    // Expand only when deliberately scrolling to top
    else if (isAtTop && isCollapsed) {
      // Only expand if user has deliberately stopped at top
      // (prevents expand during fast scroll-bounce)
      setIsAnimating(true);
      setElementsVisible(false);
      
      setTimeout(() => {
        setIsCollapsed(false);
        
        animationTimeout.current = setTimeout(() => {
          setElementsVisible(true);
          setIsAnimating(false);
        }, 300);
      }, 150);
    }

    lastScrollTop.current = scrollTop;
  }, [isCollapsed, isAnimating]);

  // Handle manual collapse toggle
  const handleHeaderCollapse = useCallback(() => {
    setIsCollapsed(prev => !prev);
    setElementsVisible(prev => !prev);
    
    if (!isCollapsed) {
      contentRef.current?.scrollTo({
        top: 150,
        behavior: 'smooth'
      });
    } else {
      contentRef.current?.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }
  }, [isCollapsed]);

  // Reset state when modal closes
  useEffect(() => {
    if (!open) {
      setIsCollapsed(false);
      setElementsVisible(true);
      if (contentRef.current) {
        contentRef.current.scrollTop = 0;
      }
      lastScrollTop.current = 0;
    }
  }, [open]);

  // Clean up timeouts
  useEffect(() => {
    return () => {
      if (animationTimeout.current) {
        clearTimeout(animationTimeout.current);
      }
    };
  }, []);

  return (
    <StyledDialog
      open={open}
      onClose={onClose}
      maxWidth={false}
      TransitionProps={{
        onExited: () => {
          setIsCollapsed(false);
          setElementsVisible(true);
        }
      }}
    >
      {/* Add a container for the close button and category */}
      <Box
        sx={{
          position: 'absolute',
          top: 'calc(env(safe-area-inset-top) + 16px)',
          right: 16,
          display: 'flex',
          alignItems: 'center',
          gap: 2,
          zIndex: 9999,
        }}
      >
        <CategoryBadge>
          {blog.category}
        </CategoryBadge>
        
        <IconButton
          onClick={onClose}
          sx={{
            backgroundColor: 'rgba(0, 0, 0, 0.3)',
            backdropFilter: 'blur(8px)',
            color: 'rgba(255, 255, 255, 0.85)',
            width: 36,
            height: 36,
            transition: 'all 0.2s ease-in-out',
            border: '1px solid rgba(255, 255, 255, 0.2)',
            boxShadow: '0 2px 8px rgba(0, 0, 0, 0.2)',
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              transform: 'scale(1.05)',
              color: 'rgba(255, 255, 255, 1)',
              boxShadow: '0 3px 10px rgba(0, 0, 0, 0.3)',
            },
            [theme.breakpoints.down('md')]: {
              width: 32,
              height: 32,
            },
          }}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      </Box>

      <HeaderImage isCollapsed={isCollapsed}>
        <ProgressiveImage
          src={blog.image_url}
          alt={blog.title}
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
          }}
        />
        <HeaderContent isCollapsed={isCollapsed}>
          <motion.div
            layout
            animate={{
              opacity: elementsVisible ? 1 : 0,
              scale: isCollapsed ? 0.95 : 1,
              y: isCollapsed ? 0 : 0
            }}
            transition={{ 
              duration: 0.15,
              ease: 'easeInOut'
            }}
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: theme.spacing(2),
              visibility: elementsVisible ? 'visible' : 'hidden'
            }}
          >
            <Box>
              <Typography 
                variant={isCollapsed ? "h6" : "h4"}
                component="h1"
                sx={{ 
                  transition: 'all 0.3s ease',
                  fontSize: isCollapsed 
                    ? '1.1rem'
                    : { xs: '1.5rem', sm: '2rem', md: '2.5rem' },
                  mb: 0.4,
                  ...(isCollapsed && {
                    lineHeight: 1.2,
                  })
                }}
              >
                {blog.title}
              </Typography>

              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: theme.spacing(1.5),
                  ...(isCollapsed && {
                    mt: 0.5,
                  })
                }}
              >
                <Box
                  sx={{
                    position: 'relative',
                    transition: 'transform 0.2s ease-in-out',
                    '&:hover': {
                      transform: 'scale(1.1)',
                      zIndex: 1
                    }
                  }}
                >
                  <Avatar 
                    alt={blog.author}
                    src={blog.author_photo || '/default-avatar.png'}
                    sx={{ 
                      width: { 
                        xs: isCollapsed ? 28 : 36,
                        sm: isCollapsed ? 32 : 40,
                        md: isCollapsed ? 36 : 44 
                      },
                      height: { 
                        xs: isCollapsed ? 28 : 36,
                        sm: isCollapsed ? 32 : 40,
                        md: isCollapsed ? 36 : 44
                      },
                      border: '2px solid rgba(255,255,255,0.8)',
                      boxShadow: '0 2px 8px rgba(0,0,0,0.2)',
                      transition: 'all 0.3s ease-in-out',
                      backgroundColor: 'rgba(255,255,255,0.1)', // Fallback background
                      '& img': {
                        objectFit: 'cover'
                      }
                    }}
                  />
                </Box>
                <Typography 
                  variant="body2" 
                  sx={{ 
                    color: 'rgba(255, 255, 255, 0.9)',
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                    fontSize: {
                      xs: isCollapsed ? '0.8rem' : '0.875rem',
                      sm: isCollapsed ? '0.875rem' : '0.9rem',
                      md: '0.9rem'
                    },
                    '&::after': {
                      content: '""',
                      display: 'inline-block',
                      width: '1px',
                      height: '12px',
                      backgroundColor: 'rgba(255, 255, 255, 0.3)',
                      marginLeft: 1,
                      marginRight: 1,
                    }
                  }}
                >
                  {blog.author}
                </Typography>
                <Typography 
                  variant="body2" 
                  sx={{ 
                    color: 'rgba(255, 255, 255, 0.9)',
                    fontSize: {
                      xs: isCollapsed ? '0.8rem' : '0.875rem',
                      sm: isCollapsed ? '0.875rem' : '0.9rem',
                      md: '0.9rem'
                    }
                  }}
                >
                  {blog.read_time}
                </Typography>
              </Box>
            </Box>
          </motion.div>
        </HeaderContent>

        <ScrollControls>
       
          {/* removed arrow button */}
          {/*     <ScrollArrowButton 
            onClick={handleHeaderCollapse}
            size="small"
            sx={{
              transform: isCollapsed ? 'rotate(180deg)' : 'none',
            }}
          >
            <KeyboardArrowUp fontSize="small" />
          </ScrollArrowButton> */} 
        </ScrollControls>
      </HeaderImage>

      <ContentWrapper isCollapsed={isCollapsed}>
        <ScrollableContent 
          ref={contentRef}
          onScroll={handleScroll}
        >
          <BlogContent>
            <motion.div
              initial={false}
              animate={{
                opacity: 1,
                scale: isCollapsed ? 0.98 : 1,
                y: isCollapsed ? -20 : 0,
              }}
              transition={{
                duration: 0.5,
                ease: [0.4, 0, 0.2, 1],
                opacity: { duration: 0.4 },
                layout: { duration: 0.5 }
              }}
              layout
              dangerouslySetInnerHTML={{ __html: blog.description }}
            />
          </BlogContent>
        </ScrollableContent>
      </ContentWrapper>
    </StyledDialog>
  );
};

ReadBlog.propTypes = {
  blog: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    category: PropTypes.string.isRequired,
    author: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    read_time: PropTypes.string.isRequired,
    image_url: PropTypes.string.isRequired,
    author_photo: PropTypes.string,
  }),
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
};

export default ReadBlog; 