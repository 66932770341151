import axios from 'axios';
import { getAuth } from 'firebase/auth';
import app from '../firebase'; // Ensure default firebase app is imported

// Should read from environment variables
const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL || 'https://legacyvault.herokuapp.com',
  timeout: 15000,
  headers: {
    'Content-Type': 'application/json'
  }
});

// Request interceptor - adds auth token
api.interceptors.request.use(async (config) => {
  const auth = getAuth();
  const user = auth.currentUser;
  
  if (user) {
    const token = await user.getIdToken();
    config.headers.Authorization = `Bearer ${token}`;
  }
  
  return config;
}, error => {
  return Promise.reject(error);
});

// Response interceptor - handle errors
api.interceptors.response.use(
  response => response,
  async (error) => {
    const originalRequest = error.config;
    
    // Handle network errors
    if (!error.response) {
      console.error('Network Error:', error);
      return Promise.reject(new Error('Network error occurred'));
    }

    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        const user = auth.currentUser;
        if (user) {
          // Force token refresh
          await auth.currentUser.getIdToken(true);
          // Retry the original request
          return api(originalRequest);
        } else {
          // If no user, redirect to login
          window.location.href = '/login';
        }
      } catch (refreshError) {
        console.error('Token refresh failed:', refreshError);
        window.location.href = '/login';
      }
    }
    // Handle other error status codes
    if (error.response?.status === 403) {
      console.error('Permission denied');
      return Promise.reject(new Error('Permission denied'));
    }

    if (error.response?.status === 429) {
      console.error('Rate limit exceeded');
      return Promise.reject(new Error('Too many requests'));
    }

    return Promise.reject(error);
  }
);

// Export the api instance directly
export default api; 
