// imageupload.js
// This component handles uploading an image file, converts HEIC to JPEG (client-side),
// then resizes the image with Pica, and finally uses either JPEG (on iOS) or WebP (elsewhere).
// The extensive logs remain, but now sizes are shown in MB for clarity.

import { forwardRef, useState } from 'react';
import { Button, CircularProgress, useMediaQuery } from '@mui/material';
import { styled } from '@mui/material/styles';
import heic2any from 'heic2any';
import pica from 'pica';
import PropTypes from 'prop-types';
import { PhotoCamera } from '@mui/icons-material';

const Input = styled('input')({
  display: 'none'
});

const MAX_DIM = 640; // We'll resize images so neither width nor height exceeds 640
const WEBP_QUALITY = 0.85; 
const JPEG_QUALITY = 0.85; 
const MAX_FILE_SIZE = 20 * 1024 * 1024; // 20 MB

const ImageUpload = forwardRef(({ onUploadComplete, onUploadError, maxDimension = 640, previewSize = { width: 80, height: 80 }, highQuality = false, disableResize = false, buttonLabel = 'Upload Photo' }, ref) => {
  const [isSelecting, setIsSelecting] = useState(false);
  const isMobile = useMediaQuery('(max-width:600px)');

  // Utility function to resize the image using pica
  // Returns a canvas with the resized image
  const resizeImageWithPica = async (img) => {
    let targetWidth = img.width;
    let targetHeight = img.height;

    // If width is bigger, limit by width; otherwise, limit by height
    if (targetWidth > targetHeight) {
      if (targetWidth > maxDimension) {
        targetHeight = (targetHeight * maxDimension) / targetWidth;
        targetWidth = maxDimension;
      }
    } else {
      if (targetHeight > maxDimension) {
        targetWidth = (targetWidth * maxDimension) / targetHeight;
        targetHeight = maxDimension;
      }
    }

    // Create a target canvas
    const offScreenCanvas = document.createElement('canvas');
    offScreenCanvas.width = Math.round(targetWidth);
    offScreenCanvas.height = Math.round(targetHeight);

    console.log('[resizeImageWithPica] Original dims:', `${img.width}x${img.height}`);
    console.log('[resizeImageWithPica] Target dims:', `${offScreenCanvas.width}x${offScreenCanvas.height}`);

    // Source canvas (full size)
    const srcCanvas = document.createElement('canvas');
    srcCanvas.width = img.width;
    srcCanvas.height = img.height;
    const srcCtx = srcCanvas.getContext('2d');
    srcCtx.drawImage(img, 0, 0);

    // Use pica to do the resizing
    const resultCanvas = await pica().resize(srcCanvas, offScreenCanvas, {
      quality: 3,
      alpha: false
    });

    console.log('[resizeImageWithPica] Pica resizing done.');
    return resultCanvas;
  };

  // Chooses whether to output JPEG or WebP (depending on iOS or not)
  // and converts the image (either resized via pica or kept at original dimensions) to a Blob
  const convertImage = async (file, options = {}) => {
    // Extract the skipResize flag and optional maxDimension override
    const { skipResize = false, maxDimension: optMaxDimension = 1200 } = options;

    console.log('[convertImage] Starting conversion.');
    const fileSizeMB = (file.size / (1024 * 1024)).toFixed(2);
    console.log(`[convertImage] Original file type: ${file.type}`);
    console.log(`[convertImage] Original file size: ${fileSizeMB} MB`);

    // Read file as dataURL for easy <img> creation
    const dataUrl = await new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = (err) => reject(err);
      reader.readAsDataURL(file);
    });

    // Create <img> from dataURL
    const img = await new Promise((resolve, reject) => {
      const tempImg = new Image();
      tempImg.onload = () => resolve(tempImg);
      tempImg.onerror = (err) => reject(err);
      tempImg.src = dataUrl;
    });

    console.log('[convertImage] Image loaded with dimensions:', `${img.width}x${img.height}`);

    let finalCanvas;
    if (skipResize) {
      // If resizing is disabled, draw onto a canvas maintaining original dimensions
      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0);
      finalCanvas = canvas;
    } else {
      // Use pica to do the resizing
      finalCanvas = await resizeImageWithPica(img);
    }

    // Decide output format based on iOS detection
    const isIOS = /iPhone|iPad|iPod/.test(navigator.userAgent);
    const mimeType = isIOS ? 'image/jpeg' : 'image/webp';
    // Use higher quality for blog images
    const outputQuality = highQuality ? 0.95 : (isIOS ? JPEG_QUALITY : WEBP_QUALITY);

    console.log('[convertImage] High quality mode:', highQuality);
    console.log(`[convertImage] Using mimeType: ${mimeType} at quality: ${outputQuality}`);

    // Convert the final canvas to a blob
    const blob = await new Promise((resolve, reject) => {
      finalCanvas.toBlob((b) => {
        if (!b) {
          return reject(new Error('[convertImage] toBlob returned null.'));
        }
        resolve(b);
      }, mimeType, outputQuality);
    });

    const blobSizeMB = (blob.size / (1024 * 1024)).toFixed(2);
    console.log('[convertImage] Blob created.');
    console.log(`[convertImage] Resulting blob type: ${blob.type}`);
    console.log(`[convertImage] Resulting blob size: ${blobSizeMB} MB`);

    // Create a local URL for preview
    const localUrl = URL.createObjectURL(blob);
    return { blob, localUrl };
  };

  // Handler for the <input /> change event
  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const startTime = performance.now();
    console.log(`[handleFileChange] Starting process at: ${(startTime/1000).toFixed(2)}s`);
    console.log(`[handleFileChange] File selected: ${file.name}`);

    setIsSelecting(true);

    try {
      // Log environment data
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      console.log('[handleFileChange] User Agent is:', userAgent);
      console.log('[handleFileChange] File selected:', file.name);
      console.log('[handleFileChange] File type:', file.type);
      console.log(`[handleFileChange] File size: ${(file.size / (1024 * 1024)).toFixed(2)} MB`);

      // Check file size
      if (file.size > MAX_FILE_SIZE) {
        onUploadError(`File size must be less than ${MAX_FILE_SIZE / (1024 * 1024)}MB`);
        console.error('[handleFileChange] File too large. Size:', file.size);
        return;
      }

      let processedFile = file;

      // If HEIC, convert to JPEG first
      if (file.type.startsWith('image/')) {
        if (file.type === 'image/heic') {
          console.log('[handleFileChange] Detected HEIC file. Converting to JPEG...');
          const heicConvertedBlob = await heic2any({
            blob: file,
            toType: 'image/jpeg',
            quality: 0.85
          });
          console.log('[handleFileChange] heic2any conversion done. Blob type:', heicConvertedBlob.type);
          console.log(`[handleFileChange] heic2any conversion done. Blob size: ${(heicConvertedBlob.size / (1024 * 1024)).toFixed(2)} MB`);

          processedFile = new File([heicConvertedBlob], file.name.replace('.heic', '.jpg'), {
            type: 'image/jpeg'
          });
          console.log('[handleFileChange] New processed file type:', processedFile.type);
          console.log(`[handleFileChange] New processed file size: ${(processedFile.size / (1024 * 1024)).toFixed(2)} MB`);
        }
      } else {
        onUploadError('Only image files are allowed');
        console.warn('[handleFileChange] Non-image file encountered. Aborting.');
        return;
      }

      // Convert (resize + correct format) => final blob
      const { blob, localUrl } = await convertImage(processedFile, { skipResize: disableResize, maxDimension });

      // Callback with the new blob & local URL
      onUploadComplete(localUrl, blob);
      console.log('[handleFileChange] onUploadComplete callback executed successfully.');
      const endTime = performance.now();
      console.log(`[handleFileChange] Total processing time: ${((endTime - startTime)/1000).toFixed(2)}s`);
    } catch (error) {
      console.error('Error processing image:', error);
      onUploadError('Failed to process image. Please try another file.');
    } finally {
      setIsSelecting(false);
    }
  };

  return (
    <>
      <Input
        ref={ref}
        accept="image/*"
        type="file"
        onChange={handleFileChange}
        id="image-upload-input"
      />
      <label htmlFor="image-upload-input">
        <Button
          variant="contained"
          component="span"
          startIcon={!isMobile && <PhotoCamera />}
          sx={{
            bgcolor: '#11311B',
            '&:hover': {
              bgcolor: '#11311B',
              opacity: 0.9,
            }
          }}
        >
          {isMobile ? <PhotoCamera sx={{ mr: 1 }} /> : null}
          {isMobile ? (buttonLabel === 'Upload Photo' ? 'Photo' : buttonLabel) : buttonLabel}
        </Button>
      </label>
    </>
  );
});

ImageUpload.propTypes = {
  onUploadComplete: PropTypes.func.isRequired,
  onUploadError: PropTypes.func.isRequired,
  maxDimension: PropTypes.number,
  previewSize: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number
  }),
  highQuality: PropTypes.bool,
  disableResize: PropTypes.bool,
  buttonLabel: PropTypes.string
};

export default ImageUpload;
