import { useState, useEffect, useCallback, useRef } from 'react';
import { styled } from '@mui/material/styles';
import {
  Box,
  Drawer,
  AppBar,
  Toolbar,
  List,
  Typography,
  Divider,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  Avatar,
  InputBase,
  Paper,
  Menu,
  MenuItem,
  Grid,
  Card,
  useTheme,
  CircularProgress,
  Button,
  Tooltip,
  CardMedia,
  Alert,
  Collapse
} from '@mui/material';
import {
  Menu as MenuIcon,
  Dashboard as DashboardIcon,
  Assignment as AssignmentIcon,
  Message as MessageIcon,
  Settings as SettingsIcon,
  Search as SearchIcon,
  Logout as LogoutIcon,
  Add as AddIcon,
  AddCircleOutline,
  Edit as EditIcon,
  HelpOutline as HelpOutlineIcon,
  Refresh as RefreshIcon,
  Clear as ClearIcon,
  ViewModule,
  ViewQuilt,
  PlayArrow
} from '@mui/icons-material';
import { useAuth } from '../contexts/AuthContext';
import { useNavigate, useLocation } from 'react-router-dom';
import { signOut } from 'firebase/auth';
import { auth } from '../firebase';
import Footer from './common/Footer';
import AddTreasureModal from './AddTreasureModal';
import TreasureDetails from './TreasureDetails';
import { TreasureCardSkeleton } from './skeletons/ContentSkeletons';
import TreasureVaultModal from './TreasureVaultModal';
import api from '../services/api';
import { debounce } from 'lodash';
import SearchBar from './common/SearchBar';
import useMediaQuery from '@mui/material/useMediaQuery';
import TreasureStandalone from './TreasureStandalone';
import { useToast } from '../contexts/ToastContext';
import ProgressiveImage from './ProgressiveImage';
import GridExploreNew from './GridExploreNew';
import AddTreasureCard from './common/AddTreasureCard';
import Diversity1Icon from '@mui/icons-material/Diversity1';
import { getAuth } from 'firebase/auth';
import EditProfileModal from './EditProfileModal';

const drawerWidth = 240;
const miniDrawerWidth = 65;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: miniDrawerWidth,
    ...(open && {
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: drawerWidth,
    }),
  }),
);

const ContentContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  maxWidth: theme.breakpoints.values.lg + 'px',
  width: '100%',
  margin: '0 auto',
  flex: 1,
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2),
    paddingBottom: '70px',
  }
}));

const TreasureCard = styled(Paper)(({ theme }) => ({
  height: '300px',
  position: 'relative',
  overflow: 'hidden',
  cursor: 'pointer',
  transition: 'all 0.3s',
  borderRadius: theme.shape.borderRadius,
  border: `1px solid ${theme.palette.divider}`,
  '&:hover': {
    transform: 'translateY(-4px)',
    boxShadow: theme.shadows[4],
    '& .treasure-info': {
      transform: 'translateY(0)',
      opacity: 1
    }
  }
}));

const TreasureInfo = styled(Box)(({ theme }) => ({
  position: 'absolute',
  bottom: 0,
  left: 0,
  right: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.7)',
  color: 'white',
  padding: theme.spacing(2),
  transform: 'translateY(100%)',
  opacity: 0,
  transition: 'all 0.3s',
}));

const TreasureImage = styled('img')({
  width: '100%',
  height: '100%',
  objectFit: 'cover',
});

const TreasureVault = styled(Card)(({ theme }) => ({
  background: `linear-gradient(135deg, 
    ${theme.palette.grey[900]} 0%,
    ${theme.palette.grey[800]} 100%)`,
  color: theme.palette.common.white,
  padding: theme.spacing(4),
  position: 'relative',
  overflow: 'hidden',
  marginBottom: theme.spacing(4),
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: 'url(/images/gold-texture.png)', // You'll need to add this texture
    opacity: 0.1,
    mixBlendMode: 'overlay',
  }
}));

const TreasureGrid = styled(Grid)(({ theme }) => ({
  position: 'relative',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: `linear-gradient(45deg, 
      ${theme.palette.background.paper} 0%,
      ${theme.palette.grey[50]} 100%)`,
    opacity: 0.7,
    zIndex: -1,
  }
}));

const HeaderSection = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(4),
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  '& h4': {
    background: `linear-gradient(135deg, 
      ${theme.palette.grey[900]} 0%,
      ${theme.palette.grey[700]} 100%)`,
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    backgroundClip: 'text',
    textFillColor: 'transparent',
    fontWeight: 600,
  }
}));

const VIEW_MODE_KEY = 'treasureChest_dashboard_viewMode';
const DEFAULT_VIEW = 'card';

// Add styled component for the notice
const ProfileNotice = styled(Alert)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  marginTop: theme.spacing(1),
  borderRadius: '20px',
  backgroundColor: 'rgba(17, 49, 27, 0.06)',
  border: '1px solid rgba(17, 49, 27, 0.15)',
  boxShadow: '0 1px 3px rgba(0,0,0,0.03)',
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  transition: 'all 0.2s ease',
  '&:hover': {
    backgroundColor: 'rgba(17, 49, 27, 0.08)',
    boxShadow: '0 2px 4px rgba(0,0,0,0.05)',
    transform: 'translateY(-1px)',
  },
  '& .MuiAlert-icon': {
    color: '#11311B',
    alignSelf: 'center',
    padding: 0,
    fontSize: '0.875rem',
    [theme.breakpoints.up('md')]: {
      fontSize: '1rem',
    }
  },
  '& .MuiAlert-message': {
    width: '100%',
    padding: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'nowrap',
    gap: theme.spacing(0.25),
  },
  '& .MuiAlert-action': {
    padding: 0,
    marginRight: 0,
    marginLeft: theme.spacing(0.5),
    alignSelf: 'center',
  },
  // Mobile styles
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(0.5, 0.75),
    minHeight: '30px',
  },
  // Desktop styles
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(1, 2),
    minHeight: '48px',
  }
}));

const Dashboard = () => {
  // Viewport detection (keep this at top)
  const isTablet = useMediaQuery('(min-width: 768px) and (max-width: 1024px)');
  const isMobile = useMediaQuery('(max-width: 767px)');
  
  // Set viewport once when component mounts
  const [viewport] = useState(() => {
    if (isTablet) return 'tablet';
    if (isMobile) return 'mobile';
    return 'desktop';
  });

  const [open, setOpen] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const { currentUser, userData } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [isAddTreasureOpen, setIsAddTreasureOpen] = useState(false);
  const [treasures, setTreasures] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [searching, setSearching] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [selectedTreasure, setSelectedTreasure] = useState(null);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const theme = useTheme();
  const [vaultModalOpen, setVaultModalOpen] = useState(false);
  const [localSearchQuery, setLocalSearchQuery] = useState('');
  const observerTarget = useRef(null);
  const [recentSearches, setRecentSearches] = useState([]);
  const [isSearchFocused, setIsSearchFocused] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const { showToast } = useToast();
  const [redirectTreasure, setRedirectTreasure] = useState(null);
  const [redirectEvent, setRedirectEvent] = useState(null);
  const [viewMode, setViewMode] = useState(() => {
    return localStorage.getItem(VIEW_MODE_KEY) || DEFAULT_VIEW;
  });
  const [editProfileOpen, setEditProfileOpen] = useState(false);

  // Add check for profile photo
  const hasProfilePhoto = userData?.profile?.userPhoto && userData.profile.userPhoto.includes('cloudfront.net');

  // Debounced fetch function
  const debouncedFetch = useCallback(
    debounce(async (pageNum, search) => {
      try {
        const endpoint = search 
          ? `/api/treasures/search`
          : '/api/treasures/user-treasures';

        const response = await api.get(endpoint, {
          params: {
            page: pageNum,
            query: search,
            viewport
          }
        });

        const newTreasures = Array.isArray(response.data.treasures) 
          ? response.data.treasures 
          : [];
        
        // Update treasures based on page number
        setTreasures(prev => 
          pageNum === 1 ? newTreasures : [...prev, ...newTreasures]
        );
        
        setHasMore(response.data.hasMore || false);
        setError(null);
        
      } catch (error) {
        if (error.response?.status === 429) {
          setError('Too many requests. Please wait a moment before trying again.');
        } else {
          setError('Failed to fetch treasures');
        }
        console.error('Error fetching treasures:', error);
      } finally {
        setLoading(false);
        setLoadingMore(false);
        setSearching(false);
      }
    }, 300),
    [viewport]
  );

  // Search handler - restore original behavior
  const handleSearch = useCallback(
    debounce((query) => {
      setLoading(true);
      
      // Clear search case
      if (!query || query.length === 0) {
        setSearchQuery('');
        setPage(1);
        debouncedFetch(1, '');
        setSearching(false);
        return;
      }
      
      // Don't search for very short queries
      if (query.length < 3) {
        setLoading(false);
        return;
      }
      
      // Update search state
      setSearchQuery(query);
      setPage(1);
      setSearching(true);
      
      // Fetch with search query
      debouncedFetch(1, query);
      
      // Update recent searches
      if (query.length >= 3) {
        setRecentSearches(prev => {
          const updated = [query, ...prev.filter(q => q !== query)];
          return updated.slice(0, 5);
        });
      }
    }, 300),
    [debouncedFetch]
  );

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/login');
    } catch (error) {
      console.error('Error logging out:', error);
    }
    handleMenuClose();
  };

  const handleAddTreasureClick = () => {
    setIsAddTreasureOpen(true);
  };

  const handleCloseAddTreasureModal = () => {
    setIsAddTreasureOpen(false);
  };

  const handleAddTreasure = async (newTreasure) => {
    try {
      // The treasure is already saved to database in AddTreasureModal
      // Immediately update UI by adding the new treasure at the beginning
      setTreasures(prev => [newTreasure, ...prev]);
      handleCloseAddTreasureModal();
    } catch (error) {
      console.error('Error adding treasure:', error);
    }
  };

  const handleTreasureClick = (treasure) => {
    setSelectedTreasure(treasure);
  };

  const handleDeleteTreasure = (deletedId) => {
    setTreasures(prev => prev.filter(t => t.id !== deletedId));
    setSelectedTreasure(null);
  };

  const handleMenuItemClick = (path) => {
    console.log('Navigating to:', path);
    navigate(path);
  };

  const menuItems = [
    { text: 'Dashboard', icon: <DashboardIcon />, path: '/dashboard' },
    { text: 'Explore', icon: <AssignmentIcon />, path: '/explore' },
    { text: 'Events', icon: <MessageIcon />, path: '/events' },
    { text: 'Blog', icon: <SettingsIcon />, path: '/blog' },
  ];

  const menuOptions = [
    { text: 'Edit Profile', icon: <EditIcon />, onClick: () => setEditProfileOpen(true) },
    { text: 'Something Told', icon: <Diversity1Icon />, onClick: () => setVaultModalOpen(true) },
    { text: 'Logout', icon: <LogoutIcon />, onClick: handleLogout },
  ];

  // Add error retry mechanism
  const handleRetry = useCallback(async () => {
    setError(null);
    setLoading(true);
    try {
      await debouncedFetch(1, searchQuery);
    } catch (err) {
      setError('Failed to reload treasures');
    }
  }, [debouncedFetch, searchQuery]);

  const handleSearchKeyDown = (e) => {
    if (!isSearchFocused || !recentSearches.length) return;

    switch (e.key) {
      case 'ArrowDown':
        e.preventDefault();
        setSelectedIndex(prev => 
          prev < recentSearches.length - 1 ? prev + 1 : prev
        );
        break;
      case 'ArrowUp':
        e.preventDefault();
        setSelectedIndex(prev => prev > -1 ? prev - 1 : prev);
        break;
      case 'Enter':
        e.preventDefault();
        if (selectedIndex > -1) {
          const selectedSearch = recentSearches[selectedIndex];
          setLocalSearchQuery(selectedSearch);
          handleSearch(selectedSearch);
          setIsSearchFocused(false);
        }
        break;
      case 'Escape':
        setIsSearchFocused(false);
        break;
      default:
        break;
    }
  };

  // Add handleLoadMore function
  const handleLoadMore = useCallback(() => {
    if (!hasMore || loadingMore || loading) return;
    
    setLoadingMore(true);
    setPage(prev => prev + 1);
    debouncedFetch(page + 1, searchQuery);
  }, [hasMore, loadingMore, loading, page, searchQuery, debouncedFetch]);

  // Initial data fetch
  useEffect(() => {
    setLoading(true);
    debouncedFetch(1, searchQuery);
  }, [debouncedFetch, searchQuery]);

  // Infinite scroll observer
  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        if (entries[0].isIntersecting && hasMore && !loading && !loadingMore) {
          handleLoadMore();
        }
      },
      { threshold: 0.1 }
    );

    const currentTarget = observerTarget.current;
    if (currentTarget) {
      observer.observe(currentTarget);
    }

    return () => {
      if (currentTarget) {
        observer.unobserve(currentTarget);
      }
    };
  }, [hasMore, loading, loadingMore, handleLoadMore]);

  // Add network state detection
  useEffect(() => {
    const handleOnline = () => {
      if (error) handleRetry();
    };

    window.addEventListener('online', handleOnline);
    return () => window.removeEventListener('online', handleOnline);
  }, [error, handleRetry]);

  // Future edit handler
  const handleEditTreasure = async (editedTreasure) => {
    try {
      // Update UI immediately with edited treasure
      setTreasures(prev => 
        prev.map(t => t.id === editedTreasure.id ? editedTreasure : t)
      );
    } catch (error) {
      console.error('Error updating treasure:', error);
    }
  };

  // Update the redirect check useEffect
  useEffect(() => {
    if (!loading) {
      // Check for treasure redirect
      const treasureRedirectData = localStorage.getItem('treasureRedirect');
      if (treasureRedirectData) {
        try {
          const { id } = JSON.parse(treasureRedirectData);
          const fetchRedirectTreasure = async () => {
            try {
              const response = await api.get(`/api/treasures/shared/${id}`);
              setRedirectTreasure({
                data: response.data,
                id: id
              });
            } catch (error) {
              console.error('Error fetching redirect treasure:', error);
              showToast('Failed to load shared treasure', 'error');
            }
          };
          fetchRedirectTreasure();
          localStorage.removeItem('treasureRedirect');
        } catch (error) {
          console.error('Error parsing treasure redirect data:', error);
        }
      }

      // Check for event redirect
      const eventRedirectData = localStorage.getItem('eventRedirect');
      if (eventRedirectData) {
        try {
          const { id } = JSON.parse(eventRedirectData);
          const fetchRedirectEvent = async () => {
            try {
              const response = await api.get(`/api/events/shared/${id}`);
              setRedirectEvent({
                data: response.data,
                id: id
              });
              navigate(`/events/${id}`);
            } catch (error) {
              console.error('Error fetching redirect event:', error);
              showToast('Failed to load shared event', 'error');
            }
          };
          fetchRedirectEvent();
          localStorage.removeItem('eventRedirect');
        } catch (error) {
          console.error('Error parsing event redirect data:', error);
        }
      }
    }
  }, [loading, currentUser, showToast, navigate]);

  const handleViewModeChange = (mode) => {
    setViewMode(mode);
    localStorage.setItem(VIEW_MODE_KEY, mode);
  };

  const handleUpdateTreasure = (updatedTreasure) => {
    // Update the treasures array with the updated treasure
    setTreasures(prev => 
      prev.map(t => t.id === updatedTreasure.id ? updatedTreasure : t)
    );
    // Also update the selected treasure if it's open
    setSelectedTreasure(updatedTreasure);
  };

  // Add this useEffect near other useEffect hooks
  useEffect(() => {
    const checkEmailVerification = async () => {
      if (currentUser && !currentUser.emailVerified) {
        try {
          await signOut(auth);
          navigate('/login');
          showToast("Please verify your email before accessing the dashboard", "warning");
        } catch (error) {
          console.error('Error during email verification check:', error);
          showToast("Session validation failed", "error");
        }
      }
    };

    checkEmailVerification();
  }, [currentUser, navigate, showToast]);

  const renderContent = () => {
    if (error) {
      return (
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Card
            sx={{
              height: '300px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer',
              border: '1px solid',
              borderColor: 'divider'
            }}
            onClick={handleRetry}  // Use handleRetry instead of window.reload()
          >
            <RefreshIcon sx={{ fontSize: 48, color: 'text.secondary', mb: 2 }} />
            <Typography variant="h6" color="text.secondary">
              Click to retry
            </Typography>
          </Card>
        </Grid>
      );
    }

    if (loading) {
      return [...Array(7)].map((_, index) => (
        <Grid item xs={12} sm={6} md={4} lg={3} key={`skeleton-${index}`}>
          <Box sx={{ 
            height: '100%',
            border: '1px solid',
            borderColor: 'divider',
            borderRadius: 2,
            overflow: 'hidden'
          }}>
            <TreasureCardSkeleton />
          </Box>
        </Grid>
      ));
    }

    // Ensure treasures is always an array
    const treasureArray = Array.isArray(treasures) ? treasures : [];

    if (treasureArray.length === 0 && searchQuery) {
      return (
        <Grid item xs={12}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              py: 8,
              px: 2,
              textAlign: 'center'
            }}
          >
            <SearchIcon 
              sx={{ 
                fontSize: 64, 
                color: '#C6AB70',
                mb: 2,
                opacity: 0.4
              }} 
            />
            <Typography variant="h6" color="text.secondary">
              No treasures found
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
              Try different keywords or check your spelling
            </Typography>
          </Box>
        </Grid>
      );
    }

    // For card view (default)
    if (viewMode === 'card') {
      return (
        <>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <AddTreasureCard onClick={handleAddTreasureClick} />
          </Grid>
          {treasureArray.map((treasure) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={treasure.id}>
              <TreasureCard 
                onClick={() => handleTreasureClick(treasure)}
                elevation={0}
              >
                <Box
                  sx={{
                    position: 'relative',
                    width: '100%',
                    height: '100%'
                  }}
                >
                  <ProgressiveImage
                    src={treasure.is_video ? treasure.video_thumbnail : treasure.image_url}
                    alt={treasure.name}
                    onContextMenu={(e) => e.preventDefault()}
                    style={{
                      cursor: 'pointer',
                      WebkitUserDrag: 'none',
                    }}
                  />
                  {treasure.is_video && (
                    <Box
                      sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        zIndex: 2,
                        width: '24px',
                        height: '24px',
                        borderRadius: '50%',
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}
                    >
                      <PlayArrow sx={{ color: 'white', fontSize: '16px' }} />
                    </Box>
                  )}
                </Box>
                <TreasureInfo className="treasure-info">
                  <Typography variant="subtitle1" sx={{ fontWeight: 600, mb: 1 }}>
                    {treasure.name}
                  </Typography>
                  <Typography variant="body2">
                    From: {treasure.from_person}
                  </Typography>
                  <Typography variant="caption" sx={{ opacity: 0.7 }}>
                    <Tooltip 
                      title={treasure.date_acquired ? new Date(treasure.date_acquired + 'T00:00:00').toLocaleDateString('en-US', {
                        month: 'long',
                        day: 'numeric',
                        year: 'numeric',
                        timeZone: 'UTC'
                      }) : 'Not specified'}
                      arrow
                    >
                      <span>
                        Received: {new Date(treasure.date_acquired + 'T00:00:00').toLocaleDateString('en-US', {
                          month: 'short',
                          day: 'numeric',
                          year: 'numeric',
                          timeZone: 'UTC'
                        })}
                      </span>
                    </Tooltip>
                  </Typography>
                </TreasureInfo>
              </TreasureCard>
            </Grid>
          ))}
        </>
      );
    }

    // For progressive view only
    return (
      <>
        <Grid item xs={12}>
          <GridExploreNew
            treasures={[{ isAddCard: true }, ...treasureArray]}
            onTreasureClick={(e, treasure) => {
              if (treasure.isAddCard) {
                handleAddTreasureClick();
              } else {
                handleTreasureClick(treasure);
              }
            }}
            isDashboard={true}
            hideState={true}
          />
        </Grid>
      </>
    );
  };

  return (
    <ContentContainer maxWidth="lg" sx={{ mt: 1 }}>
      <Typography variant="h4" sx={{ fontWeight: 600, color: theme.palette.text.primary, mb: 3 }}>
        My Treasures
      </Typography>

      <Box sx={{ mb: 4 }}>
        <SearchBar
          placeholder="Search through your treasured memories..."
          onSearch={handleSearch}
          searching={searching}
          recentSearches={recentSearches}
          onClearRecentSearches={() => setRecentSearches([])}
          onSelectRecentSearch={(search) => {
            setLocalSearchQuery(search);
            handleSearch(search);
          }}
          value={localSearchQuery}
          onChange={(e) => setLocalSearchQuery(e.target.value)}
          onFocus={() => setIsSearchFocused(true)}
          onBlur={() => setTimeout(() => setIsSearchFocused(false), 200)}
          onKeyDown={handleSearchKeyDown}
        />

        {!hasProfilePhoto && (
          <Collapse in={!hasProfilePhoto}>
            <ProfileNotice 
              severity="info"
              onClick={() => setEditProfileOpen(true)}
              action={
                <Button
                  color="primary"
                  size="small"
                  onClick={(e) => {
                    e.stopPropagation();
                    setEditProfileOpen(true);
                  }}
                  sx={{
                    fontSize: { xs: '0.65rem', sm: '0.75rem' },
                    padding: { xs: '1px 4px', sm: '4px 12px' },
                    textTransform: 'none',
                    minWidth: 'auto',
                    backgroundColor: 'transparent',
                    color: '#1a4a2b',
                    '&:hover': {
                      backgroundColor: 'rgba(17, 49, 27, 0.04)',
                    },
                    fontWeight: 600
                  }}
                >
                  Add Photo
                </Button>
              }
            >
              <Box sx={{ 
                display: 'flex', 
                alignItems: 'center', 
                gap: { xs: 1, sm: 2 }
              }}>
                <Avatar sx={{ 
                  width: { xs: 20, sm: 32 }, 
                  height: { xs: 20, sm: 32 },
                  fontSize: { xs: '0.75rem', sm: '1rem' },
                  bgcolor: 'rgba(17, 49, 27, 0.12)',
                  color: '#11311B'
                }}>
                  {currentUser?.email?.[0]?.toUpperCase()}
                </Avatar>
                <Typography 
                  component="span" 
                  sx={{ 
                    fontSize: { xs: '0.7rem', sm: '0.875rem' }, 
                    fontWeight: 500,
                    color: '#11311B'
                  }}
                >
                  Personalize your profile by adding a photo
                </Typography>
              </Box>
            </ProfileNotice>
          </Collapse>
        )}
      </Box>

      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'flex-end',
        mb: 3,
        mt: -1
      }}>
        <Paper 
          elevation={0}
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 0.5,
            p: 0.5,
            border: '1px solid',
            borderColor: 'divider',
            borderRadius: '16px',
          }}
        >
          <IconButton
            onClick={() => handleViewModeChange('card')}
            color={viewMode === 'card' ? 'primary' : 'default'}
            sx={{
              bgcolor: viewMode === 'card' ? 'action.selected' : 'transparent',
              borderRadius: '16px',
              mr: 0.5,
            }}
          >
            <ViewModule />
          </IconButton>
          <IconButton
            onClick={() => handleViewModeChange('progressive')}
            color={viewMode === 'progressive' ? 'primary' : 'default'}
            sx={{
              bgcolor: viewMode === 'progressive' ? 'action.selected' : 'transparent',
              borderRadius: '16px',
            }}
          >
            <ViewQuilt />
          </IconButton>
        </Paper>
      </Box>

      <TreasureGrid container spacing={2}>
        {renderContent()}
        
        {!loading && !error && hasMore && (
          <Grid item xs={12}>
            <Box 
              ref={observerTarget}
              sx={{ 
                display: 'flex', 
                justifyContent: 'center', 
                py: 4 
              }}
            >
              <CircularProgress size={30} />
            </Box>
          </Grid>
        )}
      </TreasureGrid>

      <AddTreasureModal 
        open={isAddTreasureOpen}
        onClose={handleCloseAddTreasureModal}
        onAddTreasure={handleAddTreasure}
      />

      <TreasureDetails
        open={!!selectedTreasure}
        onClose={() => setSelectedTreasure(null)}
        treasure={selectedTreasure}
        onDelete={handleDeleteTreasure}
        onUpdate={handleUpdateTreasure}
      />

      <TreasureVaultModal 
        open={vaultModalOpen}
        onClose={() => setVaultModalOpen(false)}
      />

      {redirectTreasure && (
        <TreasureStandalone
          open={!!redirectTreasure}
          treasure={redirectTreasure.data}
          treasureId={redirectTreasure.id}
          onClose={() => {
            console.log('Closing TreasureStandalone');
            setRedirectTreasure(null);
          }}
        />
      )}

      {redirectEvent && (
        <TreasureStandalone
          open={!!redirectEvent}
          treasure={redirectEvent.data}
          treasureId={redirectEvent.id}
          onClose={() => {
            console.log('Closing TreasureStandalone');
            setRedirectEvent(null);
          }}
        />
      )}

      <EditProfileModal 
        open={editProfileOpen}
        onClose={() => setEditProfileOpen(false)}
      />
    </ContentContainer>
  );
};

export default Dashboard; 