import { useEffect, useState, useCallback } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { CircularProgress, Box, Typography, Paper } from '@mui/material';
import api from '../services/api';
import { useAuth } from '../contexts/AuthContext';
import { useToast } from '../contexts/ToastContext';
import TextureBackground from '../assets/Texture.png';

/**
 * Component that handles the subscription success flow.
 * It waits for backend confirmation before navigating to the dashboard.
 */
const SubscriptionSuccess = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { refreshSubscription, userData } = useAuth();
  const { closeModal, showToast } = useToast();
  const sessionId = searchParams.get('session_id');
  const [isProcessing, setIsProcessing] = useState(false);
  const [processingStatus, setProcessingStatus] = useState('Initializing');
  const [pollCount, setPollCount] = useState(0);
  const [readyToNavigate, setReadyToNavigate] = useState(false);

  // Function to check if subscription is truly active in the backend
  const verifySubscriptionActive = useCallback(async () => {
    try {
      // Call the subscription endpoint directly to check status - avoid caching
      const response = await api.get(`/api/users/subscription?t=${Date.now()}`);
      const subData = response.data;
      
      console.log('Subscription status from backend:', subData);
      
      // If subscription is active, we can proceed
      if (subData && subData.subscription_status === 'active') {
        return true;
      }
      return false;
    } catch (error) {
      // Check if this is a rate limit error (429)
      if (error.response && error.response.status === 429) {
        console.error('Rate limit reached during subscription verification');
        // We'll handle this in the polling loop by waiting longer
      } else {
        console.error('Error checking subscription status:', error);
      }
      return false;
    }
  }, []);

  // Add a helper function to handle rate limit scenarios
  const handleRateLimitScenario = useCallback(() => {
    showToast('Your payment was successful, but we need to wait a moment before confirming your subscription. Please be patient.', 'info');
    setProcessingStatus('Waiting for confirmation...');
    
    // We'll wait a bit longer than usual
    return new Promise(resolve => setTimeout(resolve, 10000));
  }, [showToast]);

  // Memoize the confirmation logic
  const confirmSubscription = useCallback(async () => {
    if (!sessionId || isProcessing) return;
    
    setIsProcessing(true);
    let rateLimitEncountered = false;
    
    try {
      // Update status for user feedback
      setProcessingStatus('Verifying payment...');
      
      // First confirm the subscription on the backend
      try {
        await api.get(`/api/users/confirm-subscription?session_id=${sessionId}`);
      } catch (error) {
        if (error.response && error.response.status === 429) {
          rateLimitEncountered = true;
          await handleRateLimitScenario();
        } else {
          throw error; // Re-throw if it's not a rate limit error
        }
      }
      
      // Update status for user feedback
      setProcessingStatus('Activating subscription...');
      
      // Force refresh subscription - be careful about rate limits here too
      try {
        await refreshSubscription(true);
      } catch (error) {
        console.warn('Error refreshing subscription data:', error);
        // Continue anyway - we'll try to verify through polling
      }
      
      // Initial delay to give backend time to process - increased to 3 seconds
      console.log('Initial delay before starting verification...');
      setProcessingStatus('Processing payment...');
      await new Promise(resolve => setTimeout(resolve, 3000));
      
      // Add longer delay if we've already hit rate limits
      if (rateLimitEncountered) {
        setProcessingStatus('Waiting for systems to catch up...');
        await new Promise(resolve => setTimeout(resolve, 8000));
      }
      
      // Start polling to verify subscription is truly active with exponential backoff
      let isActive = false;
      let attempts = 0;
      const maxAttempts = 8; // Reduced from 15 to 8 attempts
      let currentDelay = 2000; // Start with 2 second delay
      let consecutiveRateLimits = 0;
      
      while (!isActive && attempts < maxAttempts) {
        setProcessingStatus(`Confirming subscription... (${attempts + 1}/${maxAttempts})`);
        console.log(`Verification attempt ${attempts + 1} with delay ${currentDelay/1000}s`);
        
        try {
          isActive = await verifySubscriptionActive();
          
          if (isActive) {
            console.log('Subscription verified as active! Breaking out of polling loop.');
            break;
          }
          // Reset consecutive rate limits counter on successful (though negative) check
          consecutiveRateLimits = 0;
        } catch (error) {
          console.warn(`Verification attempt ${attempts + 1} failed:`, error);
          // Check for rate limit errors
          if (error.response && error.response.status === 429) {
            consecutiveRateLimits++;
            setProcessingStatus('Rate limit reached, waiting longer...');
            
            // If we've hit rate limits multiple times in a row, wait much longer
            if (consecutiveRateLimits > 1) {
              await handleRateLimitScenario();
            }
          }
          // Continue with polling even if an attempt fails
        }
        
        // Wait with exponential backoff (multiply by 1.5 each time)
        await new Promise(resolve => setTimeout(resolve, currentDelay));
        currentDelay = Math.min(currentDelay * 1.5, 25000); // Cap at 25 seconds max delay
        attempts++;
        setPollCount(attempts);
      }
      
      // After polling loop completes
      if (!isActive) {
        // Even after multiple attempts, subscription not showing as active
        setProcessingStatus('Subscription pending activation');
        
        // Inform the user about what's happening
        showToast(
          'Your payment was successful! It may take a moment for your subscription to activate fully. Proceed to the dashboard and refresh if needed.', 
          'info',
          8000 // Longer display time
        );
        
        // Wait longer before navigating
        await new Promise(resolve => setTimeout(resolve, 5000));
        
        // Try one more refresh before giving up
        try {
          await refreshSubscription(true);
          
          // Check once more before navigating
          const finalCheck = await verifySubscriptionActive();
          if (finalCheck) {
            setProcessingStatus('Subscription activated!');
          } else {
            console.log('Final check still shows subscription as not active');
          }
        } catch (error) {
          console.warn('Final subscription refresh failed:', error);
        }
      } else {
        // Final status update
        setProcessingStatus('Subscription activated!');
        
        // Close any modals that might be open
        closeModal();
        
        // Show success toast
        showToast(`Subscription activated. Welcome to Something Told`, 'success');
        
        // Brief delay to see the success message
        await new Promise(resolve => setTimeout(resolve, 1000));
      }
      
      // Mark as ready to navigate - this will trigger the navigation effect
      setReadyToNavigate(true);
      
    } catch (error) {
      console.error('Subscription confirmation failed:', error);
      setProcessingStatus('Verification failed');
      showToast('Payment verification failed. Please contact support support@somethingtold.com', 'error');
      
      // On failure, wait 3 seconds before redirecting
      setTimeout(() => {
        setReadyToNavigate(true);
      }, 3000);
    } finally {
      setIsProcessing(false);
    }
  }, [sessionId, refreshSubscription, verifySubscriptionActive, closeModal, showToast, handleRateLimitScenario]);

  // Effect for initial confirmation
  useEffect(() => {
    if (sessionId) {
      confirmSubscription();
    } else {
      navigate('/treasurechest');
    }
  }, [sessionId, confirmSubscription, navigate]);
  
  // Separate effect for navigation - only triggers after confirmation is complete
  useEffect(() => {
    if (readyToNavigate) {
      // Double check with a final refresh to make extra sure
      refreshSubscription(true).then(() => {
        // Now navigate - the subscription should be active in the backend
        navigate('/treasurechest', { replace: true });
      });
    }
  }, [readyToNavigate, navigate, refreshSubscription]);

  return (
    <Box sx={{ 
      display: 'flex', 
      flexDirection: 'column', 
      alignItems: 'center', 
      justifyContent: 'center', 
      minHeight: '100vh',
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 9999,
      backgroundImage: `url(${TextureBackground})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      color: 'white',
    }}>
      <Box 
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '100%',
          maxWidth: { xs: '90%', sm: '500px' },
          padding: 0,
        }}
      >
        <Paper 
          elevation={0}
          sx={{
            backgroundColor: 'rgba(255, 255, 255, 0.15)',
            backdropFilter: 'blur(10px)',
            borderRadius: '24px',
            p: { xs: 3, sm: 5 },
            textAlign: 'center',
            border: '1px solid rgba(255, 255, 255, 0.1)',
          }}
        >
          <CircularProgress 
            size={70} 
            sx={{ 
              mb: 4, 
              color: 'white',
              opacity: 0.9
            }} 
          />
          
          <Typography 
            variant="h4" 
            component="h1" 
            gutterBottom 
            sx={{ 
              fontWeight: 600, 
              fontSize: { xs: '1.75rem', sm: '2.25rem' },
              color: 'white',
              mb: 2.5
            }}
          >
            {processingStatus}
          </Typography>
          
          <Typography 
            variant="body1" 
            sx={{ 
              color: 'rgba(255, 255, 255, 0.9)',
              maxWidth: '450px', 
              mx: 'auto',
              lineHeight: 1.6,
              fontSize: { xs: '0.95rem', sm: '1.1rem' }
            }}
          >
            Please wait while we confirm your payment and activate your premium access. This may take a moment...
          </Typography>
          
          {pollCount > 3 && (
            <Typography 
              variant="body2" 
              sx={{ 
                mt: 4, 
                maxWidth: '450px', 
                mx: 'auto', 
                opacity: 0.7,
                color: 'white',
                fontSize: '0.9rem'
              }}
            >
              Stripe payments sometimes take a few moments to fully process. We're checking the status of your subscription...
            </Typography>
          )}
        </Paper>
      </Box>
    </Box>
  );
};

export default SubscriptionSuccess; 