import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../contexts/AuthContext';
import styles from '../../styles/modules/publicNav.module.css';
import LogoIcon from '../../images/Logo_Icon.svg';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';

const PublicNav = () => {
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { currentUser } = useAuth();

  useEffect(() => {
    // Function to close menu
    const closeMenu = () => {
      if (isMenuOpen) {
        setIsMenuOpen(false);
      }
    };

    // Handle clicks outside the menu
    const handleClickOutside = (event) => {
      const menu = document.querySelector(`.${styles.mobileMenu}`);
      const menuButton = document.querySelector(`.${styles.menuButton}`);
      
      if (menu && !menu.contains(event.target) && !menuButton.contains(event.target)) {
        closeMenu();
      }
    };

    // Handle scroll events
    const handleScroll = () => {
      closeMenu();
    };

    // Add event listeners when menu is open
    if (isMenuOpen) {
      document.addEventListener('mousedown', handleClickOutside);
      document.addEventListener('scroll', handleScroll, true);
    }

    // Cleanup event listeners
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('scroll', handleScroll, true);
    };
  }, [isMenuOpen]); // Only re-run effect when isMenuOpen changes

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className={styles.navWrapper}>
      <nav className={styles.nav}>
        <div className={styles.leftSection}>
          {/* Logo */}
          <img src={LogoIcon} alt="Something Told Logo" className={styles.logo} />

          {/* Desktop Links - hidden on mobile */}
          <div className={styles.navLinks}>
            <a href="/">Home</a>
            <a href="/blogs">Blog</a>
            <a href="/aboutus">About</a>
          </div>
        </div>

        {/* Right Section */}
        <div className={styles.rightSection}>
          {/* Desktop Sign In */}
          <button 
            className={styles.signInButton}
            onClick={() => navigate(currentUser ? '/treasurechest' : '/login')}
          >
            {currentUser ? 'My Treasures' : 'Sign In'}
          </button>

          {/* Mobile Menu Button */}
          <button 
            className={styles.menuButton}
            onClick={toggleMenu}
            aria-label="Menu"
          >
            {isMenuOpen ? <CloseIcon /> : <MenuIcon />}
          </button>
        </div>
      </nav>

      {/* Mobile Menu Dropdown */}
      {isMenuOpen && (
        <div className={styles.mobileMenu}>
          <a href="/">
            <span className={styles.menuItem}>
              Home
            </span>
          </a>
          <a href="/blogs">
            <span className={styles.menuItem}>
              Blog
            </span>
          </a>
          <a href="/aboutus">
            <span className={styles.menuItem}>
              About
            </span>
          </a>
          <a 
            href={currentUser ? '/treasurechest' : '/login'} 
            className={styles.mobileSignIn}
          >
            <span className={styles.menuItem}>
              {currentUser ? 'My Treasures' : 'Sign In'}
            </span>
          </a>
        </div>
      )}
    </div>
  );
};

export default PublicNav; 