import React, { useEffect } from 'react';

import Hero from './components/home/Hero';
import StoryBanner from './components/home/StoryBanner';
import ProcessSteps from './components/home/ProcessSteps';
import ContentBlock from './components/home/ContentBlock';
import Testimonial from './components/home/Testimonial';
import ImageGrid from './components/home/ImageGrid';
import Newsletter from './components/home/Newsletter';
import Footer from './components/common/Footer';
import styles from './styles/modules/home.module.css';
import AboutFounder from './components/home/AboutFounder';
import { Divider, Box } from '@mui/material';

const HomePage = () => {
  useEffect(() => {
    // Add public-page class when mounting
    document.body.classList.add('public-page');
    
    // Remove it when unmounting
    return () => {
      document.body.classList.remove('public-page');
    };
  }, []);

  console.log('HomePage component rendering');
  return (
    <div>
      
      <main className={styles.mainContent}>
        <div className={styles.sections}>
          <Hero />
          <StoryBanner />
          <ProcessSteps />
          <ContentBlock />
          <Testimonial />
          <ImageGrid />
          <AboutFounder />
          <Box sx={{ 
            backgroundColor: 'rgba(255, 255, 255, 0.02)', // Subtle color
            height: '1px', // Very thin line
            width: '100%',
            margin: 0
          }} />
          <Newsletter />
          <Box sx={{ 
            backgroundColor: 'rgba(255, 255, 255, 0.02)', // Subtle color
            height: '1px', // Very thin line
            width: '100%',
            margin: 0
          }} />
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default HomePage; 