import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { styled, keyframes, useTheme } from '@mui/material/styles';
import { Box, Typography, Card, CardContent, Button, IconButton, Avatar, AvatarGroup, Tooltip, CircularProgress, Modal, List, ListItem, ListItemText, Divider, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import { CalendarTodayOutlined, AccessTimeOutlined, LocationOnOutlined, ExpandLess, ExpandMore } from '@mui/icons-material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditOutlined from '@mui/icons-material/EditOutlined';
import { format, parseISO } from 'date-fns';
import ProgressiveImage from './ProgressiveImage';
import ShareIcon from '@mui/icons-material/Share';
import { useToast } from '../contexts/ToastContext';
import { useMediaQuery } from '@mui/material';
import { useAuth } from '../contexts/AuthContext';
import api from '../services/api';

const EventCardContainer = styled(Card)(({ theme, expanded }) => ({
  display: 'flex',
  marginBottom: theme.spacing(3),
  borderRadius: theme.shape.borderRadius * 2,
  overflow: 'hidden',
  boxShadow: 'none',
  border: `1px solid ${theme.palette.divider}`,
  flexDirection: 'column',
  transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
  backgroundColor: theme.palette.background.paper,
  width: '100%',
  maxWidth: '100%',
  '&:hover': {
    boxShadow: theme.shadows[4],
    transform: 'translateY(-2px)',
    borderColor: 'transparent',
  },

  [theme.breakpoints.down('md')]: {
    borderRadius: theme.shape.borderRadius,
    margin: '0 0 16px 0',
  },

  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
    height: expanded ? 'auto' : '400px',
    minHeight: '400px',
  }
}));

const EventImage = styled(Box)(({ theme, expanded }) => ({
  position: 'relative',
  overflow: 'hidden',
  backgroundColor: theme.palette.grey[200],
  width: '100%',
  
  [theme.breakpoints.down('md')]: {
    height: '200px',
  },

  [theme.breakpoints.up('md')]: {
    width: '340px',
    minWidth: '340px',
    height: expanded ? '340px' : '100%',
    alignSelf: expanded ? 'center' : 'flex-start',
  }
}));

const ContentWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  width: '100%',
  
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(2),
    '& .MuiCardContent-root': {
      padding: 0
    }
  },

  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(2.5),
  }
}));

const EventBadge = styled(Box)(({ theme }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '2px 8px',
  borderRadius: '20px',
  fontSize: '0.65rem',
  fontWeight: 500,
  textTransform: 'uppercase',
  backgroundColor: 'rgba(17, 49, 27, 0.06)',
  border: '1px solid rgba(17, 49, 27, 0.15)',
  color: '#11311B',
  margin: 0,
  letterSpacing: '0.5px',
  boxShadow: '0 1px 3px rgba(0,0,0,0.03)',
  transition: 'all 0.2s ease',
  marginBottom: theme.spacing(2),
  minHeight: '24px',
  '&:hover': {
    backgroundColor: 'rgba(17, 49, 27, 0.08)',
    boxShadow: '0 2px 4px rgba(0,0,0,0.05)',
    transform: 'translateY(-1px)',
  }
}));

const EventDetails = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(3),
  marginBottom: theme.spacing(2),
  flexWrap: 'wrap',
  '& > div': {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  }
}));

// Add keyframes for the pulsating animation
const pulse = keyframes`
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(26, 74, 43, 0.7);
  }
  
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 6px rgba(26, 74, 43, 0);
  }
  
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(26, 74, 43, 0);
  }
`;

const RegistrationStatus = styled(Typography)(({ theme }) => ({
  display: 'inline-block',
  color: theme.palette.success.main,
  fontWeight: 500,
  fontSize: '0.875rem',
  '&::before': {
    content: '""',
    display: 'inline-block',
    width: '8px',
    height: '8px',
    borderRadius: '50%',
    backgroundColor: 'currentColor',
    marginRight: theme.spacing(1),
    animation: `${pulse} 2s infinite`,
    transformOrigin: 'center',
    position: 'relative',
    top: '-1px',
  },
}));

const formatEventTime = (timeString) => {
  if (!timeString) return '';
  try {
    // Split the time string into hours and minutes
    const [hours, minutes] = timeString.split(':');
    
    // Create a date object for today with the specified time
    const date = new Date();
    date.setHours(parseInt(hours, 10));
    date.setMinutes(parseInt(minutes, 10));
    
    return date.toLocaleTimeString('en-US', { 
      hour: 'numeric',
      minute: '2-digit',
      hour12: true,
      timeZone: 'America/New_York'
    });
  } catch (e) {
    console.error('Error formatting time:', e);
    return timeString;
  }
};

// Update the EventExpandButton styling
const EventExpandButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  bottom: -25, // Move button lower
  right: 4,
  backgroundColor: theme.palette.background.paper,
  boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
  padding: 4,
  '& .MuiSvgIcon-root': {
    fontSize: '1.2rem',
  },
  '&:hover': {
    backgroundColor: theme.palette.grey[100],
  }
}));

// Styled components for avatar effects
const StyledAvatarGroup = styled(AvatarGroup)(({ theme }) => ({
  '& .MuiAvatar-root': {
    width: 24,
    height: 24,
    fontSize: '0.75rem',
    borderColor: theme.palette.background.paper,
    transition: 'all 0.3s ease',
    '&:hover': {
      transform: 'scale(1.2)',
      zIndex: 10,
    }
  },
  '& .MuiAvatarGroup-avatar': {
    fontSize: '0.75rem',
    backgroundColor: theme.palette.primary.main,
  },

  [theme.breakpoints.up('sm')]: {
    '& .MuiAvatar-root': {
      width: 28,
      height: 28
    }
  }
}));

// Update ParticipantsSection for better stacking on smaller screens
const ParticipantsSection = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column-reverse',
  gap: theme.spacing(2),
  marginTop: theme.spacing(2),
  width: '100%',

  // Container for buttons
  '.button-group': {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    width: '100%',
    '& .MuiButton-root': {
      width: '100%',
      minHeight: '40px',
      whiteSpace: 'nowrap',  // Prevent text wrapping
      overflow: 'hidden',    // Prevent overflow
      textOverflow: 'ellipsis' // Show ellipsis if needed
    }
  },

  // Container for participants info
  '.participants-info': {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    width: '100%',
    flexWrap: 'wrap',
    '& .MuiAvatarGroup-root': {
      flexShrink: 0
    }
  },

  // Tablet and Desktop layout
  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    
    '.participants-info': {
      order: 1,
      width: 'auto',
      gap: theme.spacing(2)
    },
    
    '.button-group': {
      order: 2,
      width: 'auto',
      flexDirection: 'row',
      '& .MuiButton-root': {
        width: 'auto'
      }
    }
  }
}));

// Pulsating dot component
const PulsatingDot = styled('span')(({ theme }) => ({
  display: 'inline-block',
  width: 8,
  height: 8,
  backgroundColor: '#1a4a2b', // Changed from #4CAF50 to #1a4a2b
  borderRadius: '50%',
  marginRight: theme.spacing(1),
  position: 'relative',
  animation: `${pulse} 2s infinite`,
}));

const EventCard = ({ event, isAdmin, hasRights, onEdit, onDelete }) => {
  const [expanded, setExpanded] = useState(false);
  const [isTextTruncated, setIsTextTruncated] = useState(false);
  const [hasRsvped, setHasRsvped] = useState(false);
  const [participantCount, setParticipantCount] = useState(
    Number(event.participants_count) || 0
  );
  const [isRsvpLoading, setIsRsvpLoading] = useState(false);
  const descriptionRef = useRef(null);
  const { title, date, location, start_time, end_time, description, type, status, capacity, image_url } = event;
  const theme = useTheme();
  const { showToast } = useToast();
  const auth = useAuth();
  const [showParticipantsModal, setShowParticipantsModal] = useState(false);
  const [participantsList, setParticipantsList] = useState([]);
  const [isLoadingParticipants, setIsLoadingParticipants] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  // Check if text is truncated
  useEffect(() => {
    const checkTextTruncation = () => {
      if (descriptionRef.current) {
        const element = descriptionRef.current;
        const actualHeight = element.scrollHeight;
        const visibleHeight = element.clientHeight;
        const lineHeight = parseInt(window.getComputedStyle(element).lineHeight);
        const maxLines = 3; // Adjust based on WebkitLineClamp value
        const maxHeight = lineHeight * maxLines;

        setIsTextTruncated(actualHeight > maxHeight);
      }
    };

    const timer = setTimeout(checkTextTruncation, 100);
    window.addEventListener('resize', checkTextTruncation);
    
    return () => {
      clearTimeout(timer);
      window.removeEventListener('resize', checkTextTruncation);
    };
  }, [description]);

  // Check if user has RSVP'd
  useEffect(() => {
    const checkRsvpStatus = async () => {
      try {
        const response = await api.get(`/api/events/${event.id}/rsvp`);
        setHasRsvped(response.data.hasRsvped);
      } catch (error) {
        console.error('Error checking RSVP status:', error);
      }
    };
    
    checkRsvpStatus();
  }, [event.id, auth.currentUser?.uid]);

  const handleEdit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onEdit(event);
  };

  const handleDelete = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDeleteDialogOpen(true);
  };

  const handleShare = async () => {
    try {
      const url = `${window.location.origin}/events/${event.id}`;
      await navigator.clipboard.writeText(url);
      showToast('Link copied to clipboard!', 'success');
    } catch (error) {
      console.error('Error copying to clipboard:', error);
      showToast('Failed to copy link', 'error');
    }
  };

  const handleRSVP = async () => {
    if (isRsvpLoading) return;
    setIsRsvpLoading(true);
    
    try {
      const response = await api({
        method: hasRsvped ? 'delete' : 'post',
        url: `/api/events/${event.id}/rsvp`,
      });
      
      setHasRsvped(!hasRsvped);
      setParticipantCount(Number(response.data.participantCount) || 0);
      showToast(response.data.message, 'success');
    } catch (error) {
      console.error('RSVP error:', error);
      showToast(
        error.response?.data?.message || 'Failed to process RSVP', 
        'error'
      );
    } finally {
      setIsRsvpLoading(false);
    }
  };

  const fetchParticipants = async () => {
    if (!isAdmin && !hasRights) return;
    setIsLoadingParticipants(true);
    try {
      const response = await api.get(`/api/events/${event.id}/participants`);
      setParticipantsList(response.data.participants);
    } catch (error) {
      console.error('Error fetching participants:', error);
      showToast('Failed to load participants', 'error');
    } finally {
      setIsLoadingParticipants(false);
    }
  };

  return (
    <EventCardContainer expanded={expanded}>
      <EventImage expanded={expanded}>
        <ProgressiveImage
          src={image_url}
          alt={title}
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            position: 'absolute',
            top: 0,
            left: 0,
          }}
        />
      </EventImage>
      <ContentWrapper>
        <CardContent sx={{ 
          flex: '1 0 auto', 
          p: 0,
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}>
          <Box sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            mb: 1.5,
          }}>
            <EventBadge>{type}</EventBadge>
            {(isAdmin || hasRights) && (
              <Box sx={{ display: 'flex', gap: 1 }}>
                <IconButton
                  onClick={handleEdit}
                  size="small"
                  sx={{
                    color: 'text.secondary',
                    '&:hover': {
                      color: 'primary.main',
                      backgroundColor: 'rgba(0, 0, 0, 0.04)',
                    },
                  }}
                >
                  <EditOutlined fontSize="small" />
                </IconButton>
                <IconButton
                  onClick={handleDelete}
                  size="small"
                  sx={{
                    color: 'text.secondary',
                    '&:hover': {
                      color: 'error.main',
                      backgroundColor: 'rgba(211, 47, 47, 0.04)',
                    },
                  }}
                >
                  <DeleteOutlineIcon fontSize="small" />
                </IconButton>
              </Box>
            )}
          </Box>
          
          <Box sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: { xs: 1, sm: 1.5 },
          }}>
            <Typography 
              variant="h6" 
              sx={{ 
                fontSize: { xs: '1rem', sm: '1.2rem' },
                fontWeight: 600,
                lineHeight: 1.3,
              }}
            >
              {title}
            </Typography>
            
            {(isAdmin || hasRights) && (
              <Button
                variant="text"
                size="small"
                onClick={() => {
                  setShowParticipantsModal(true);
                  fetchParticipants();
                }}
                sx={{
                  textTransform: 'none',
                  fontSize: '0.75rem',
                  color: 'text.secondary',
                  '&:hover': {
                    color: 'primary.main',
                    backgroundColor: 'transparent'
                  }
                }}
              >
                View List
              </Button>
            )}
          </Box>

          <EventDetails sx={{ 
            mb: { xs: 1, sm: 1.5 },
            flexDirection: { xs: 'column', sm: 'row' },
            gap: { xs: 1, sm: 1, md: 1.5, lg: 3 },
            flexWrap: { sm: 'wrap', lg: 'nowrap' },
          }}>
            <Box>
              <CalendarTodayOutlined 
                sx={{ 
                  color: 'primary.main',
                  fontSize: { xs: '1.1rem', sm: '1rem', md: '1.1rem' },
                  opacity: 0.8 
                }} 
              />
              <Typography 
                variant="body2" 
                color="text.secondary"
                sx={{
                  fontSize: { xs: '0.875rem', sm: '0.8rem', md: '0.875rem' },
                }}
              >
                {format(parseISO(date), 'MMMM d, yyyy')}
              </Typography>
            </Box>
            <Box>
              <AccessTimeOutlined sx={{ color: 'text.secondary', fontSize: '1.1rem' }} />
              <Typography variant="body2" color="text.secondary">
                {`${formatEventTime(start_time)} - ${formatEventTime(end_time)} EST`}
              </Typography>
            </Box>
            <Box>
              <LocationOnOutlined sx={{ color: 'text.secondary', fontSize: '1.1rem' }} />
              <Typography variant="body2" color="text.secondary">
                {location}
              </Typography>
            </Box>
          </EventDetails>

          <Box sx={{ position: 'relative', mb: 2 }}>
            <Typography
              ref={descriptionRef}
              variant="body2"
              sx={{
                display: '-webkit-box',
                WebkitLineClamp: expanded ? 'unset' : 4,
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                lineHeight: '1.5em',
                maxHeight: expanded ? 'none' : '4.5em',
                position: 'relative',
                paddingBottom: isTextTruncated ? 2 : 0,
                '&::after': !expanded && isTextTruncated ? {
                  content: '""',
                  position: 'absolute',
                  bottom: 0,
                  right: 0,
                  width: '100%',
                  height: '1.5em',
                  background: 'linear-gradient(to bottom, transparent, white)',
                } : {}
              }}
            >
              {description}
            </Typography>
            
            {isTextTruncated && (
              <EventExpandButton
                onClick={() => setExpanded(!expanded)}
                size="small"
                aria-label={expanded ? "show less" : "show more"}
              >
                {expanded ? <ExpandLess /> : <ExpandMore />}
              </EventExpandButton>
            )}
          </Box>

          <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <ParticipantsSection>
              <Box className="button-group">
                <Button
                  variant="outlined"
                  startIcon={<ShareIcon />}
                  onClick={handleShare}
                  sx={{ 
                    borderRadius: '20px',
                    textTransform: 'none',
                    boxShadow: 1,
                    '&:hover': {
                      transform: 'translateY(-2px)',
                      boxShadow: 2
                    }
                  }}
                >
                  Share
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleRSVP}
                  disabled={isRsvpLoading}
                  sx={{
                    borderRadius: '20px',
                    textTransform: 'none',
                    boxShadow: 1,
                    bgcolor: hasRsvped ? theme.palette.error.main : '#11311B',
                    '&:hover': {
                      transform: 'translateY(-2px)',
                      boxShadow: 2,
                      bgcolor: hasRsvped ? theme.palette.error.dark : '#11311B',
                      opacity: hasRsvped ? 1 : 0.9,
                    }
                  }}
                >
                  {hasRsvped ? 'Withdraw RSVP' : 'RSVP Now'}
                  {isRsvpLoading && <CircularProgress size={20} sx={{ ml: 1 }} />}
                </Button>
              </Box>

              <Box className="participants-info">
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <PulsatingDot />
                  <Typography 
                    variant="caption" 
                    sx={{ 
                      color: 'grey.800',
                      fontWeight: 500,
                      fontSize: '0.875rem'
                    }}
                  >
                    {(participantCount || 0).toLocaleString()} participants
                  </Typography>
                </Box>
              </Box>
            </ParticipantsSection>
          </Box>
        </CardContent>
      </ContentWrapper>
      
      {(isAdmin || hasRights) && (
        <Modal
          open={showParticipantsModal}
          onClose={() => setShowParticipantsModal(false)}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backdropFilter: 'blur(3px)'
          }}
        >
          <Box sx={{
            width: '90%',
            maxWidth: 600,
            maxHeight: '80vh',
            bgcolor: 'background.paper',
            borderRadius: 2,
            boxShadow: 24,
            p: 3,
            overflow: 'auto'
          }}>
            <Typography variant="h6" sx={{ mb: 2 }}>
              Event Participants ({participantCount})
            </Typography>
            {isLoadingParticipants ? (
              <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
                <CircularProgress size={24} />
              </Box>
            ) : (
              <List dense sx={{ width: '100%' }}>
                {participantsList.map((participant, index) => (
                  <React.Fragment key={participant.email}>
                    <ListItem alignItems="flex-start">
                      <ListItemText
                        primary={participant.name}
                        secondary={participant.email}
                        primaryTypographyProps={{ 
                          variant: 'body2',
                          sx: { fontWeight: 500 }
                        }}
                        secondaryTypographyProps={{ 
                          variant: 'caption',
                          sx: { 
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap'
                          }
                        }}
                      />
                      <Typography variant="caption" color="text.secondary">
                        {format(parseISO(participant.created_at), 'MMM d, yyyy')}
                      </Typography>
                    </ListItem>
                    {index < participantsList.length - 1 && <Divider component="li" />}
                  </React.Fragment>
                ))}
              </List>
            )}
          </Box>
        </Modal>
      )}

      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Confirm Delete
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this event? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={() => setDeleteDialogOpen(false)}
            color="primary"
          >
            Cancel
          </Button>
          <Button 
            onClick={() => {
              setDeleteDialogOpen(false);
              onDelete(event);
            }}
            color="error"
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </EventCardContainer>
  );
};

EventCard.propTypes = {
  event: PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    location: PropTypes.string.isRequired,
    start_time: PropTypes.string.isRequired,
    end_time: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    capacity: PropTypes.string.isRequired,
    image_url: PropTypes.string.isRequired,
    participants_count: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string
    ]),
    hasrights: PropTypes.bool,
  }).isRequired,
  isAdmin: PropTypes.bool.isRequired,
  hasRights: PropTypes.bool.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default EventCard;
