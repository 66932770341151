import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Box,
  IconButton,
  Typography,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  CircularProgress,
} from '@mui/material';
import { Close as CloseIcon, DeleteOutline as DeleteOutlineIcon } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import ImageUpload from '../common/ImageUpload';
import { getAuth } from 'firebase/auth';
import api from '../../services/api';
import LogoIcon from '../../assets/ST_Logo_Gold-on-Gren.svg';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import { useToast } from '../../contexts/ToastContext';
import useS3Upload from '../../hooks/useS3Upload';
import Skeleton from '@mui/material/Skeleton';
import { alpha } from '@mui/material/styles';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    width: '100%',
    maxWidth: 700,
    margin: theme.spacing(2),
    borderRadius: '16px',
    boxShadow: '0 10px 40px rgba(0,0,0,0.12)',
    backgroundImage: 'none',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    maxHeight: 'calc(100vh - 32px)',
    '&.MuiDialog-paper': {
      animation: 'slideIn 300ms ease-out'
    },
    [theme.breakpoints.down('sm')]: {
      margin: 0,
      maxHeight: '100vh',
      height: '100%',
      borderRadius: 0,
    }
  },
  '@keyframes slideIn': {
    '0%': {
      opacity: 0,
      transform: 'translateY(20px)'
    },
    '100%': {
      opacity: 1,
      transform: 'translateY(0)'
    }
  }
}));

const DialogContentStyled = styled(DialogContent)(({ theme }) => ({
  padding: theme.spacing(3),
  overflowY: 'auto',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  paddingBottom: theme.spacing(6),
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(5),
  },
  '&::-webkit-scrollbar': {
    width: '6px',
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: 'transparent',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: alpha(theme.palette.primary.main, 0.2),
    borderRadius: '3px',
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.3),
    }
  }
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: '12px',
    minHeight: '56px',
    transition: 'all 0.2s ease-in-out',
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.text.primary,
      }
    },
    '&.Mui-focused': {
      backgroundColor: theme.palette.background.paper,
      '& .MuiOutlinedInput-notchedOutline': {
        borderWidth: '2px',
        borderColor: theme.palette.primary.main,
      }
    }
  },
  '& .MuiInputLabel-root': {
    fontSize: '0.875rem',
    transition: 'transform 0.2s ease-in-out, opacity 0.2s ease-in-out',
    '&.Mui-focused': {
      color: theme.palette.primary.main,
    }
  },
  '& .MuiInputBase-input': {
    fontSize: '0.875rem',
    padding: '15px 14px',
  },
  '& .MuiFormHelperText-root': {
    marginTop: '4px',
    fontSize: '0.7rem',
  }
}));

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  '& .MuiFormLabel-root': {
    fontSize: '0.875rem',
    transition: 'transform 0.2s ease-in-out, opacity 0.2s ease-in-out',
    '&.Mui-focused': {
      color: theme.palette.primary.main,
    }
  },
  '& .MuiInputLabel-outlined': {
    transform: 'translate(14px, 16px) scale(1)',
    '&.MuiInputLabel-shrink': {
      transform: 'translate(14px, -6px) scale(0.75)',
    }
  }
}));

const StyledSelect = styled(Select)(({ theme }) => ({
  borderRadius: '12px',
  fontSize: '0.875rem',
  backgroundColor: theme.palette.background.paper,
  height: '56px',
  minWidth: '100%',
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.divider,
    transition: 'border-color 0.2s ease-in-out',
  },
  '& .MuiSelect-select': {
    backgroundColor: 'transparent',
    padding: '15px 14px',
  },
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.text.primary,
    }
  },
  '&.Mui-focused': {
    backgroundColor: theme.palette.background.paper,
    '& .MuiOutlinedInput-notchedOutline': {
      borderWidth: '2px',
      borderColor: theme.palette.primary.main,
    }
  }
}));

const PreviewContainer = styled(Box)(({ theme }) => ({
  width: '150px',
  height: '150px',
  position: 'relative',
  borderRadius: '12px',
  overflow: 'hidden',
  backgroundColor: theme.palette.background.paper,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  border: `1px dashed ${theme.palette.divider}`,
  boxShadow: '0 4px 20px rgba(0,0,0,0.05)',
  transition: 'all 0.3s ease',
  '&:hover': {
    boxShadow: '0 8px 25px rgba(0,0,0,0.08)',
    transform: 'translateY(-2px)'
  }
}));

const eventTypes = [
  'Workshop',
  'Conference',
  'Virtual Event',
  'Online Workshop',
  'Meetup',
  'Social Gathering'
];

const convertTo24Hour = (timeStr) => {
  if (!timeStr) return '';
  try {
    // If already in 24-hour format
    if (timeStr.match(/^\d{2}:\d{2}$/)) return timeStr;

    // Parse 12-hour format
    const [time, modifier] = timeStr.split(' ');
    let [hours, minutes] = time.split(':');
    hours = parseInt(hours, 10);
    
    if (hours === 12) {
      hours = modifier === 'PM' ? 12 : 0;
    } else {
      hours = modifier === 'PM' ? hours + 12 : hours;
    }

    return `${hours.toString().padStart(2, '0')}:${minutes}`;
  } catch (e) {
    console.error('Time conversion error:', e);
    return timeStr;
  }
};

const formatTimeForInput = (timeString) => {
  if (!timeString) return '';
  return convertTo24Hour(timeString);
};

const formatTimeForDisplay = (timeString) => {
  if (!timeString) return '';
  try {
    // If time is in 12-hour format already, return as is
    if (timeString.includes('AM') || timeString.includes('PM')) {
      return timeString;
    }
    
    // Convert 24-hour format to 12-hour format
    const [hours, minutes] = timeString.split(':').map(Number);
    const date = new Date(2000, 0, 1, hours, minutes);
    return date.toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: '2-digit',
      hour12: true
    });
  } catch (e) {
    return timeString;
  }
};

const initialFormData = {
  title: '',
  description: '',
  date: '',
  location: '',
  type: '',
  capacity: '',
  image_url: '',
  start_time: '',
  end_time: '',
  timezone: 'America/New_York'
};

const ConfirmDialog = ({ open, onClose, onConfirm }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        <Typography variant="h6">Discard Changes?</Typography>
      </DialogTitle>
      <DialogContent>
        <Typography>
          You have unsaved changes. Are you sure you want to close without saving?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button 
          onClick={onConfirm} 
          color="error"
          variant="contained"
        >
          Discard Changes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const StyledDialogActions = styled(DialogActions)(({ theme }) => ({
  position: 'sticky',
  bottom: 0,
  backgroundColor: theme.palette.background.paper,
  borderTop: `1px solid ${theme.palette.divider}`,
  padding: theme.spacing(2, 3),
  marginTop: 'auto',
  zIndex: 5,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexWrap: 'nowrap',
  gap: 2,
  boxShadow: '0 -4px 12px rgba(0,0,0,0.05)',
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2),
  }
}));

const CreateEventModal = ({ open, onClose, onSuccess, editingEvent }) => {
  const { showToast } = useToast();
  const { uploadToS3, isUploading } = useS3Upload();
  const [formData, setFormData] = useState(() => {
    if (editingEvent) {
      return {
        title: editingEvent.title || '',
        description: editingEvent.description || '',
        date: editingEvent.date || '',
        location: editingEvent.location || '',
        type: editingEvent.type || '',
        capacity: editingEvent.capacity || '',
        image_url: editingEvent.image_url || '',
        start_time: editingEvent.start_time || '',
        end_time: editingEvent.end_time || '',
        timezone: editingEvent.timezone || 'America/New_York'
      };
    }
    return initialFormData;
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const fileInputRef = useRef(null);
  const [originalData, setOriginalData] = useState(null);
  const [hasChanges, setHasChanges] = useState(false);
  const [imageBlob, setImageBlob] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(!!editingEvent);

  useEffect(() => {
    if (editingEvent) {
      setIsLoading(true);
      const initialData = {
        title: editingEvent.title || '',
        description: editingEvent.description || '',
        date: editingEvent.date || '',
        location: editingEvent.location || '',
        type: editingEvent.type || '',
        capacity: editingEvent.capacity || '',
        image_url: editingEvent.image_url || '',
        start_time: formatTimeForInput(editingEvent.start_time) || '',
        end_time: formatTimeForInput(editingEvent.end_time) || '',
        timezone: editingEvent.timezone || 'America/New_York'
      };
      setFormData(initialData);
      setOriginalData(initialData);
      setIsLoading(false);
    } else {
      setFormData(initialFormData);
      setOriginalData(null);
    }
  }, [editingEvent]);

  useEffect(() => {
    if (originalData) {
      const hasChanges = Object.keys(formData).some(key => {
        const originalValue = originalData[key]?.toString() || '';
        const newValue = formData[key]?.toString() || '';
        return originalValue !== newValue;
      });
      setHasChanges(hasChanges);
    }
  }, [formData, originalData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'start_time' || name === 'end_time') {
      setFormData(prev => ({
        ...prev,
        [name]: convertTo24Hour(value)
      }));
    } else {
      setFormData(prev => ({
        ...prev,
        [name]: value
      }));
    }
  };

  const handlePhotoSelect = (localUrl, webpBlob) => {
    setImageBlob(webpBlob);
    setFormData(prev => ({
      ...prev,
      image_url: localUrl
    }));
  };

  const handleDeleteImage = () => {
    setImageBlob(null);
    setFormData(prev => ({
      ...prev,
      image_url: ''
    }));
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const resetForm = () => {
    setFormData(initialFormData);
    setError('');
  };

  // Separate closeModal function for actual closing
  const closeModal = () => {
    setShowConfirmDialog(false);
    setFormData(initialFormData);
    setError('');
    onClose();
  };

  // Check for changes before closing
  const handleClose = () => {
    const hasUnsavedChanges = editingEvent ? (
      Object.keys(formData).some(key => {
        const originalValue = originalData[key]?.toString() || '';
        const newValue = formData[key]?.toString() || '';
        return originalValue !== newValue;
      })
    ) : (
      Object.keys(formData).some(key => 
        formData[key] !== initialFormData[key]
      )
    );

    if (hasUnsavedChanges) {
      setShowConfirmDialog(true);
    } else {
      closeModal();
    }
  };

  const validateTimes = (start, end) => {
    if (!start || !end) return true;
    
    try {
      // Convert both times to 24-hour format for comparison
      const start24 = convertTo24Hour(start);
      const end24 = convertTo24Hour(end);
      
      const [startHour, startMinute] = start24.split(':').map(Number);
      const [endHour, endMinute] = end24.split(':').map(Number);
      
      const startMinutes = startHour * 60 + startMinute;
      const endMinutes = endHour * 60 + endMinute;
      
      return endMinutes > startMinutes;
    } catch (e) {
      console.error('Time validation error:', e);
      return true;
    }
  };

  const validateForm = () => {
    // If creating new event, validate all required fields
    if (!editingEvent) {
      if (!formData.title.trim()) return 'Title is required';
      if (!formData.description.trim()) return 'Description is required';
      if (!formData.date) return 'Date is required';
      if (!formData.location.trim()) return 'Location is required';
      if (!formData.type) return 'Event type is required';
      if (!formData.capacity) return 'Capacity is required';
      if (!formData.start_time) return 'Start time is required';
      if (!formData.end_time) return 'End time is required';
      if (!formData.image_url) return 'Image is required';
      if (!validateTimes(formData.start_time, formData.end_time)) {
        return 'End time must be after start time';
      }
    } else {
      // If editing, only validate fields that have changed
      const changedFields = Object.keys(formData).reduce((acc, key) => {
        const originalValue = originalData[key]?.toString() || '';
        const newValue = formData[key]?.toString() || '';
        if (originalValue !== newValue) {
          acc[key] = formData[key];
        }
        return acc;
      }, {});

      // Add debug logging
      console.log('Original Data:', originalData);
      console.log('Form Data:', formData);
      console.log('Changed Fields:', changedFields);

      // Validate only changed fields
      if ('title' in changedFields && !changedFields.title.trim()) {
        return 'Title is required';
      }
      if ('description' in changedFields && !changedFields.description.trim()) {
        return 'Description is required';
      }
      if ('date' in changedFields && !changedFields.date) {
        return 'Date is required';
      }
      if ('location' in changedFields && !changedFields.location.trim()) {
        return 'Location is required';
      }
      if ('type' in changedFields && !changedFields.type) {
        return 'Event type is required';
      }
      if ('capacity' in changedFields && !changedFields.capacity) {
        return 'Capacity is required';
      }
      if ('start_time' in changedFields || 'end_time' in changedFields) {
        if (!validateTimes(formData.start_time, formData.end_time)) {
          return 'End time must be after start time';
        }
      }
    }
    return null;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationError = validateForm();
    if (validationError) {
      setError(validationError);
      showToast(validationError, 'error');
      return;
    }

    setLoading(true);
    setError('');
    setUploadProgress(0);

    try {
      let finalImageUrl = formData.image_url;
      
      // Only upload to S3 if:
      // 1. It's a new event (no editingEvent) OR
      // 2. We're editing AND we have a new image blob AND the URL has changed
      if (imageBlob && (!editingEvent || formData.image_url !== editingEvent.image_url)) {
        try {
          const file = new File([imageBlob], `event-${Date.now()}.webp`, {
            type: 'image/webp'
          });
          
          finalImageUrl = await uploadToS3(file, (progress) => {
            setUploadProgress(progress);
          });
        } catch (error) {
          showToast('Failed to upload image', 'error');
          setError('Failed to upload image');
          return;
        }
      }

      const auth = getAuth();
      const token = await auth.currentUser?.getIdToken();

      // Only include image_url in dataToSend if it has changed
      const dataToSend = {
        ...formData,
        ...(finalImageUrl !== editingEvent?.image_url ? { image_url: finalImageUrl } : {})
      };

      // If editing, only send changed fields
      const finalData = editingEvent 
        ? Object.keys(dataToSend).reduce((acc, key) => {
            const originalValue = originalData[key]?.toString() || '';
            const newValue = dataToSend[key]?.toString() || '';
            if (originalValue !== newValue) {
              acc[key] = dataToSend[key];
            }
            return acc;
          }, {})
        : dataToSend;

      // If no changes in edit mode, show info toast
      if (editingEvent && Object.keys(finalData).length === 0) {
        showToast('No changes to save', 'info');
        return;
      }

      const endpoint = editingEvent 
        ? `/api/events/${editingEvent.id}`
        : '/api/events';
      
      const method = editingEvent ? 'put' : 'post';
      
      const response = await api[method](endpoint, finalData);

      onSuccess(response.data, !!editingEvent);
      showToast(
        editingEvent ? 'Event updated successfully' : 'Event created successfully',
        'success'
      );
      closeModal();
    } catch (error) {
      console.error('Error saving event:', error);
      const errorMessage = error.response?.data?.message || 'Failed to save event';
      setError(errorMessage);
      showToast(errorMessage, 'error');
    } finally {
      setLoading(false);
      setUploadProgress(0);
    }
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="md"
        fullWidth
        fullScreen={isMobile}
        sx={{
          '& .MuiDialog-paper': {
            ...(isMobile ? {
              margin: 0,
              maxHeight: '100%',
              borderRadius: 0,
              paddingTop: 'env(safe-area-inset-top)',
            } : {
              borderRadius: '8px',
              maxHeight: '90vh',
            })
          }
        }}
      >
        <DialogTitle>
          <Box sx={{ 
            display: 'flex', 
            alignItems: 'center',
            justifyContent: 'center',
            position: 'relative',
            pb: isMobile ? 0.5 : 1,
          }}>
            <Box>
              <Box sx={{ 
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}>
                <img
                  src={LogoIcon}
                  alt="Something Told Logo"
                  style={{
                    width: '100px',
                    height: '100px'
                  }}
                />
                <Typography 
                  variant="h6" 
                  sx={{ 
                    mt: 1,
                    fontWeight: 600,
                    textAlign: 'center'
                  }}
                >
                  {editingEvent ? 'Edit Event' : 'Create New Event'}
                </Typography>
              </Box>
            </Box>
            <IconButton 
              onClick={handleClose}
              sx={{ 
                position: 'absolute',
                right: 8,
                top: 8,
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>

        <form onSubmit={handleSubmit}>
          <DialogContentStyled>
            {isLoading ? (
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                <Skeleton height={56} />
                <Box sx={{ display: 'flex', gap: 2 }}>
                  <Skeleton height={56} width="50%" />
                  <Skeleton height={56} width="50%" />
                </Box>
                <Box sx={{ display: 'flex', gap: 2 }}>
                  <Skeleton height={56} width="50%" />
                  <Skeleton height={56} width="50%" />
                </Box>
                <Skeleton height={56} />
                <Skeleton height={120} />
                <Skeleton height={200} variant="rectangular" />
              </Box>
            ) : (
              <Box sx={{ 
                display: 'grid', 
                gridTemplateColumns: { xs: '1fr', sm: 'repeat(2, 1fr)' },
                gap: 2
              }}>
                {/* Title - Full width */}
                <StyledTextField
                  autoFocus
                  required
                  fullWidth
                  name="title"
                  label="Event Title"
                  value={formData.title}
                  onChange={handleChange}
                  sx={{ gridColumn: { xs: '1', sm: '1 / 3' } }}
                />

                {/* Event Type - Half width */}
                <StyledFormControl fullWidth>
                  <InputLabel id="event-type-label">Event Type</InputLabel>
                  <StyledSelect
                    labelId="event-type-label"
                    name="type"
                    value={formData.type}
                    onChange={handleChange}
                    label="Event Type"
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          borderRadius: '8px',
                          mt: 0.5,
                          boxShadow: '0 4px 20px rgba(0,0,0,0.15)',
                        }
                      },
                      MenuListProps: {
                        sx: {
                          p: 0.5,
                        }
                      }
                    }}
                  >
                    {eventTypes.map((type) => (
                      <MenuItem 
                        key={type} 
                        value={type}
                        sx={{
                          borderRadius: '6px',
                          mx: 0.5,
                          fontSize: '0.875rem',
                          p: '8px 12px',
                          '&:hover': {
                            backgroundColor: alpha(theme.palette.primary.main, 0.08),
                          },
                          '&.Mui-selected': {
                            backgroundColor: alpha(theme.palette.primary.main, 0.12),
                            '&:hover': {
                              backgroundColor: alpha(theme.palette.primary.main, 0.18),
                            }
                          }
                        }}
                      >
                        {type}
                      </MenuItem>
                    ))}
                  </StyledSelect>
                </StyledFormControl>

                {/* Capacity - Half width */}
                <StyledTextField
                  name="capacity"
                  label="Capacity"
                  type="number"
                  value={formData.capacity}
                  onChange={handleChange}
                  required
                  inputProps={{ min: 1 }}
                />
                
                {/* Date field */}
                <StyledTextField
                  name="date"
                  label="Date"
                  type="date"
                  value={formData.date}
                  onChange={handleChange}
                  required
                  InputLabelProps={{ shrink: true }}
                />

                {/* Location field */}
                <StyledTextField
                  name="location"
                  label="Location"
                  value={formData.location}
                  onChange={handleChange}
                  required
                  sx={{ gridColumn: { xs: '1', sm: '2 / 3' } }}
                />

                {/* Time fields */}
                <Box sx={{ 
                  display: 'flex', 
                  gap: 2,
                  gridColumn: { xs: '1', sm: '1 / 3' }
                }}>
                  <StyledTextField
                    name="start_time"
                    label="Start Time"
                    type="time"
                    value={formatTimeForInput(formData.start_time)}
                    onChange={handleChange}
                    required
                    sx={{ width: '50%' }}
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ step: 300 }}
                    helperText={formatTimeForDisplay(formData.start_time)}
                  />
                  <StyledTextField
                    name="end_time"
                    label="End Time"
                    type="time"
                    value={formatTimeForInput(formData.end_time)}
                    onChange={handleChange}
                    required
                    sx={{ width: '50%' }}
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ step: 300 }}
                    helperText={formatTimeForDisplay(formData.end_time)}
                    error={formData.start_time && formData.end_time && !validateTimes(formData.start_time, formData.end_time)}
                    FormHelperTextProps={{
                      error: formData.start_time && formData.end_time && !validateTimes(formData.start_time, formData.end_time)
                    }}
                  />
                </Box>

                {/* Description - Full width */}
                <StyledTextField
                  name="description"
                  label="Description"
                  value={formData.description}
                  onChange={handleChange}
                  required
                  multiline
                  rows={4}
                  sx={{ 
                    gridColumn: { xs: '1', sm: '1 / 3' },
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '12px'
                    },
                    mb: 0.5
                  }}
                />

                {/* Image upload - Full width, centered */}
                <Box sx={{ 
                  mt: 0,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: theme.palette.background.paper,
                  transition: 'all 0.2s ease-in-out',
                  position: 'relative',
                  p: 1.5,
                  borderRadius: '12px',
                  border: `1px dashed ${theme.palette.divider}`,
                  gridColumn: { xs: '1', sm: '1 / 3' },
                  minHeight: '150px',
                }}>
                  {formData.image_url ? (
                    <Box sx={{ 
                      position: 'relative',
                      width: 'fit-content'
                    }}>
                      <Box sx={{
                        width: '150px',
                        height: '150px',
                        position: 'relative',
                        borderRadius: '8px',
                        overflow: 'hidden',
                        boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
                      }}>
                        <img
                          src={formData.image_url}
                          alt="Event preview"
                          style={{ 
                            width: '100%', 
                            height: '100%', 
                            objectFit: 'cover',
                          }}
                        />
                      </Box>
                      <IconButton 
                        onClick={handleDeleteImage}
                        sx={{ 
                          position: 'absolute',
                          top: -8,
                          right: -8,
                          backgroundColor: 'rgba(255, 255, 255, 0.9)',
                          boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
                          '&:hover': {
                            backgroundColor: 'rgba(255, 255, 255, 1)',
                            color: 'error.main',
                          }
                        }}
                      >
                        <DeleteOutlineIcon fontSize="small" />
                      </IconButton>
                    </Box>
                  ) : (
                    <ImageUpload
                      ref={fileInputRef}
                      onUploadComplete={handlePhotoSelect}
                      onUploadError={(error) => showToast(error, 'error')}
                      maxSize={15 * 1024 * 1024} // 15MB
                    />
                  )}
                  
                  {loading && uploadProgress > 0 && (
                    <Box sx={{ 
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      gap: 1,
                      bgcolor: 'rgba(255, 255, 255, 0.9)',
                      p: 2,
                      borderRadius: 2,
                      boxShadow: 1
                    }}>
                      <Typography variant="body2" color="primary">
                        {Math.round(uploadProgress)}%
                      </Typography>
                      <CircularProgress 
                        variant="determinate" 
                        value={uploadProgress} 
                        size={24}
                      />
                    </Box>
                  )}
                </Box>
              </Box>
            )}

            {error && (
              <Typography color="error" variant="caption" sx={{ mt: 1, display: 'block' }}>
                {error}
              </Typography>
            )}
          </DialogContentStyled>

          <StyledDialogActions>
            <Typography 
              variant="caption" 
              color="text.secondary"
              sx={{
                fontSize: '0.75rem'
              }}
            >
              All times in EST
            </Typography>
            
            <Box sx={{ display: 'flex', gap: 1.5 }}>
              <Button 
                onClick={handleClose}
                size={isMobile ? "medium" : "small"}
                sx={{ 
                  borderRadius: 1.5,
                  px: isMobile ? 2 : 3,
                  textTransform: 'none',
                }}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="contained"
                disabled={loading || (editingEvent && !hasChanges)}
                size={isMobile ? "medium" : "small"}
                sx={{ 
                  borderRadius: 1.5,
                  px: isMobile ? 2 : 3,
                  textTransform: 'none',
                  bgcolor: '#11311B',
                  '&:hover': {
                    bgcolor: '#11311B',
                    opacity: 0.9,
                  }
                }}
              >
                {loading ? 'Saving...' : editingEvent ? 'Save Changes' : 'Create Event'}
              </Button>
            </Box>
          </StyledDialogActions>
        </form>
      </Dialog>

      <ConfirmDialog 
        open={showConfirmDialog}
        onClose={() => setShowConfirmDialog(false)}
        onConfirm={closeModal}
      />
    </>
  );
};

CreateEventModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  editingEvent: PropTypes.object,
};

export default CreateEventModal; 