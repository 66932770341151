import { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../services/api';
import { useAuth } from '../contexts/AuthContext';

const SUBSCRIPTION_CHECK_INTERVAL = 5 * 60 * 1000; // 5 minutes

export const useSubscription = () => {
  const [subscriptionStatus, setSubscriptionStatus] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const intervalRef = useRef();

  const checkSubscription = useCallback(async () => {
    if (!currentUser) {
      setLoading(false);
      return;
    }
    
    try {
      const response = await api.get('/api/users/subscription');
      setSubscriptionStatus(response.data);
      setError(null);
    } catch (error) {
      if (!error.response && error.message === 'Network error occurred') {
        return;
      }
      console.error('Subscription check error:', error);
      setError(error);
      if (error.response?.status === 401) {
        setSubscriptionStatus(null);
      }
    } finally {
      setLoading(false);
    }
  }, [currentUser]);

  useEffect(() => {
    let mounted = true;

    if (currentUser) {
      checkSubscription();
      
      const interval = setInterval(() => {
        if (mounted) {
          checkSubscription();
        }
      }, SUBSCRIPTION_CHECK_INTERVAL);

      return () => {
        mounted = false;
        clearInterval(interval);
      };
    }
  }, [checkSubscription, currentUser]);

  return {
    subscriptionStatus,
    loading,
    error,
    refreshSubscription: checkSubscription
  };
}; 