import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { getAuth, applyActionCode } from "firebase/auth";
import { Box, Typography, CircularProgress } from '@mui/material';

const HandleEmailVerification = ({ onSuccess, onError }) => {
  const [searchParams] = useSearchParams();
  const [status, setStatus] = useState('verifying');
  const oobCode = searchParams.get('oobCode');

  useEffect(() => {
    const verifyEmail = async () => {
      try {
        const auth = getAuth();
        await applyActionCode(auth, oobCode);
        setStatus('success');
        onSuccess?.();
      } catch (error) {
        setStatus('error');
        onError?.(error);
      }
    };

    if (oobCode) {
      verifyEmail();
    } else {
      setStatus('error');
    }
  }, [oobCode, onSuccess, onError]);

  return (
    <Box sx={{ textAlign: 'center', py: 4 }}>
      {status === 'verifying' && (
        <>
          <CircularProgress />
          <Typography sx={{ mt: 2 }}>
            Verifying your email...
          </Typography>
        </>
      )}
      {status === 'success' && (
        <Typography>
          Email verified successfully! You can now sign in.
        </Typography>
      )}
      {status === 'error' && (
        <Typography color="error">
          Failed to verify email. The link may be expired or invalid.
        </Typography>
      )}
    </Box>
  );
};

export default HandleEmailVerification; 