import React, { useState, useEffect } from "react";
import { auth, appCheck } from "../firebase";
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut, updateProfile, sendEmailVerification, fetchSignInMethodsForEmail } from "firebase/auth";
import { getToken } from 'firebase/app-check';
import {
  Box,
  Button,
  TextField,
  Typography,
  Container,
  Paper,
  CircularProgress,
  Grid,
  Stack,
  Link,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Dialog,
  DialogContent,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import AuthLayout from './auth/AuthLayout';
import FirstTimeUserModal from './FirstTimeUserModal';
import api from '../services/api';
import SubscriptionChecker from './auth/SubscriptionChecker';
import { useAuth } from '../contexts/AuthContext';
import { useToast } from '../contexts/ToastContext';
import * as Yup from 'yup';
import styled from '@emotion/styled';
import DOMPurify from 'dompurify';
import { useTheme } from '@mui/material/styles';
import LoginLogo from '../assets/loginlogo.svg';
import ForgotPasswordModal from './auth/ForgotPasswordModal';


const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: '12px',
    transition: 'all 0.2s ease-in-out',
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
    '&.Mui-focused': {
      backgroundColor: theme.palette.background.paper,
      '& .MuiOutlinedInput-notchedOutline': {
        borderWidth: '2px',
      }
    }
  },
  '& .MuiInputLabel-root': {
    fontSize: '0.875rem',
    '&.Mui-focused': {
      color: theme.palette.text.primary,
    }
  },
  '& .MuiInputBase-input': {
    fontSize: '0.875rem',
    padding: '12px 14px',
  }
}));

const StyledSelect = styled(Select)(({ theme }) => ({
  borderRadius: '12px',
  fontSize: '0.875rem',
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.divider,
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.text.primary,
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderWidth: '2px',
  },
  '& .MuiSelect-select': {
    padding: '12px 14px',
  }
}));

const Auth = () => {
  const theme = useTheme();
  const { login, currentUser, loading: authLoading } = useAuth();
  const { showToast, showVerificationModal } = useToast();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [month, setMonth] = useState("");
  const [day, setDay] = useState("");
  const [year, setYear] = useState("");
  const [isRegistering, setIsRegistering] = useState(() => {
    const showRegistration = localStorage.getItem('showRegistration');
    if (showRegistration) {
      localStorage.removeItem('showRegistration');
      return true;
    }
    return false;
  });
  const [loading, setLoading] = useState(false);
  const [confirmTouched, setConfirmTouched] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [emailTouched, setEmailTouched] = useState(false);
  const [showFirstTimeModal, setShowFirstTimeModal] = useState(false);
  const [showSubscriptionChecker, setShowSubscriptionChecker] = useState(false);
  const [state, setState] = useState("");
  const [lastResend, setLastResend] = useState(0);
  const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);
  const [appCheckToken, setAppCheckToken] = useState(null);

  // Generate arrays for date selection
  const months = ["January", "February", "March", "April", "May", "June", 
                 "July", "August", "September", "October", "November", "December"];
  const days = Array.from({ length: 31 }, (_, i) => i + 1);
  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: 100 }, (_, i) => currentYear - i);

  // US States array (full names)
  const US_STATES = [
    'Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California',
    'Colorado', 'Connecticut', 'Delaware', 'Florida', 'Georgia',
    'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa',
    'Kansas', 'Kentucky', 'Louisiana', 'Maine', 'Maryland',
    'Massachusetts', 'Michigan', 'Minnesota', 'Mississippi', 'Missouri',
    'Montana', 'Nebraska', 'Nevada', 'New Hampshire', 'New Jersey',
    'New Mexico', 'New York', 'North Carolina', 'North Dakota', 'Ohio',
    'Oklahoma', 'Oregon', 'Pennsylvania', 'Rhode Island', 'South Carolina',
    'South Dakota', 'Tennessee', 'Texas', 'Utah', 'Vermont',
    'Virginia', 'Washington', 'West Virginia', 'Wisconsin', 'Wyoming'
  ];

  // Update validation schema
  const signupSchema = Yup.object().shape({
    firstName: Yup.string()
      .required('First name is required')
      .min(2, 'Too short')
      .max(50, 'Too long')
      .matches(/^[a-zA-Z\s]*$/, 'Only letters allowed'),
    lastName: Yup.string()
      .required('Last name is required')
      .min(2, 'Too short')
      .max(50, 'Too long')
      .matches(/^[a-zA-Z\s]*$/, 'Only letters allowed'),
    email: Yup.string()
      .required('Email is required')
      .email('Invalid email'),
    password: Yup.string()
      .required('Password is required')
      .min(8, 'Password must be at least 8 characters')
      .max(22, 'Password must not exceed 22 characters')
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,22}$/,
        'Password must contain at least one letter, one number, and one special character (e.g., Password1! or TreasureHunt2@)'
      ),
    birthDate: Yup.date()
      .required('Birth date is required')
      .max(new Date(new Date().setFullYear(new Date().getFullYear() - 13)), 'Must be at least 13 years old'),
    state: Yup.string()
      .nullable()
  });

  // Live password validation
  const validatePasswords = (pass, confirm) => {
    if (!confirm) return "";

    // Check if each character typed so far matches
    for (let i = 0; i < confirm.length; i++) {
      if (confirm[i] !== pass[i]) {
        return "Passwords do not match";
      }
    }

    // If we've completed typing and lengths match, check minimum length
    if (confirm.length === pass.length) {
      if (pass.length < 6) {
        return "Password must be at least 6 characters long";
      }
    }

    return "";
  };

  // Update password handlers without trim()
  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    if (confirmPassword) {
      setPasswordError(validatePasswords(newPassword, confirmPassword));
    }
  };

  const handleConfirmPasswordChange = (e) => {
    const confirmPass = e.target.value;
    setConfirmPassword(confirmPass);
    setPasswordError(validatePasswords(password, confirmPass));
  };

  // Add email validation function
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email && emailTouched) {
      return "Email is required";
    }
    if (email && !emailRegex.test(email)) {
      return "Please enter a valid email address";
    }
    return "";
  };

  // Handle email change
  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    if (emailTouched) {
      setEmailError(validateEmail(newEmail));
    }
  };

  // Handle email blur (when user clicks out)
  const handleEmailBlur = () => {
    setEmailTouched(true);
    setEmailError(validateEmail(email));
  };

  // Add debounce for auth actions
  const [authInProgress, setAuthInProgress] = useState(false);
  
  // Add this function to sanitize the password input while preserving special characters
  const sanitizePassword = (password) => {
    // Remove any potentially harmful characters while keeping allowed special chars
    return password.replace(/[^\w\s@$!%*#?&]/g, '');
  };

  const handleEmailVerification = async (user) => {
    try {
      await sendEmailVerification(user);
      showToast("Verification email sent. Please check your inbox and verify your email.");
    } catch (error) {
      console.error("Error sending verification email:", error);
      showToast("Error sending verification email. Please try again.");
    }
  };

  const resetFormState = () => {
    setFirstName("");
    setLastName("");
    setEmail("");
    setPassword("");
    setConfirmPassword("");
    setMonth("");
    setDay("");
    setYear("");
    setState("");
    setEmailTouched(false);
    setPasswordError("");
  };

  const handleResendVerification = async () => {
    console.log('handleResendVerification called, email:', email);
    
    if (Date.now() - lastResend < 45000) { // 45s cooldown
      showToast("Please wait before resending");
      return;
    }
    
    try {
      // First check if methods exist for this email
      const methods = await fetchSignInMethodsForEmail(auth, email.trim().toLowerCase());
      if (methods.length === 0) {
        showToast("No account found with this email address");
        return;
      }
      
      // Try to get the current user
      const currentUser = auth.currentUser;
      
      if (currentUser) {
        // If user is already signed in, use their account
        await handleEmailVerification(currentUser);
      } else {
        // For the verification modal flow, we should already have verified the account exists
        // but we don't have the password, so we'll just inform the user to check their email
        showToast("Please check your email for the verification link we just sent");
        // We can't automatically send a new one without the password, but we can at least
        // let them know to check their email
      }
      
      showToast("Verification email has been sent. Please check your inbox and spam folders.");
      setLastResend(Date.now());
      
      // Use the global verification modal from the toast context
      showVerificationModal(email);
    } catch (error) {
      console.error("Error resending verification:", error);
      showToast("Error sending verification email. Please try again.");
    }
  };

  // Add App Check verification function
  const verifyAppCheck = async (forceRefresh = false) => {
    try {
      // Only get a new token if we don't have one or if force refresh is requested
      if (!appCheckToken || forceRefresh) {
        const token = await getToken(appCheck, forceRefresh);
        setAppCheckToken(token);
        console.log('Auth security verification successful');
        return token;
      }
      return appCheckToken;
    } catch (error) {
      console.error('Auth security verification failed:', error);
      showToast('Security verification failed. Please refresh the page.');
      throw new Error('security-verification-failed');
    }
  };

  const handleAuth = async (e) => {
    e.preventDefault();
    setLoading(true);
    setAuthInProgress(true);

    try {
      // First verify App Check for security
      if (process.env.NODE_ENV === 'production') {
        try {
          await verifyAppCheck();
        } catch (error) {
          throw error; // This will be caught by the outer catch
        }
      }

      const sanitizedEmail = DOMPurify.sanitize(email.trim().toLowerCase());
      const sanitizedPassword = sanitizePassword(password);

      if (isRegistering) {
        // First check if email exists in Firebase
        try {
          const methods = await fetchSignInMethodsForEmail(auth, sanitizedEmail);
          if (methods.length > 0) {
            showToast("Email is already in use");
            setLoading(false);
            setAuthInProgress(false);
            return;
          }
        } catch (error) {
          if (error.code !== 'auth/invalid-email') {
            throw error;
          }
        }

        // Validate all fields before proceeding
        if (!firstName || !lastName || !email || !password || !month || !day || !year) {
          showToast("Please fill in all required fields");
          setLoading(false);
          setAuthInProgress(false);
          return;
        }

        const birthDate = `${year}-${String(months.indexOf(month) + 1).padStart(2, '0')}-${String(day).padStart(2, '0')}`;
        
        // Create Firebase user first
        const userCredential = await createUserWithEmailAndPassword(auth, sanitizedEmail, sanitizedPassword);
        const user = userCredential.user;
        
        try {
          // Send verification email before backend registration
          await handleEmailVerification(user);
          
          // Then register with backend
          const response = await api.post('/api/users/register', {
            firstName,
            lastName,
            email: sanitizedEmail,
            birthDate,
            state: state || null,
            firebase_uid: user.uid
          });
          
          // Update display name
          await updateProfile(user, {
            displayName: `${firstName} ${lastName}`
          });

          // Store email for verification
          const userEmailForVerification = sanitizedEmail;
          // Show toast
          showToast("Account created! Please verify your email before signing in.");
          
          // Use the global verification modal from the toast context
          showVerificationModal(userEmailForVerification);

          setIsRegistering(false);
          
          return; // Don't proceed with auto-login
        } catch (error) {
          // If backend registration fails, delete the Firebase user
          await user.delete();
          throw error;
        }
      } else {
        // Login logic with App Check verification
        const userCredential = await signInWithEmailAndPassword(auth, sanitizedEmail, sanitizedPassword);
        const user = userCredential.user;
        
        // Add email verification check
        if (!user.emailVerified) {
          await handleEmailVerification(user);
          await signOut(auth);
          showToast("New verification email sent. Please check your inbox before signing in.");
          showVerificationModal(email);
          throw new Error("New verification email sent. Please check your inbox before signing in.");
        }

        const token = await userCredential.user.getIdToken();
        
        const userData = await login(null, token);
        if (userData) {
          // Check for redirect path
          const redirectPath = localStorage.getItem('redirectPath');
          if (redirectPath) {
            localStorage.removeItem('redirectPath'); // Clean up
            navigate(redirectPath);
          } else {
            navigate('/treasurechest');
          }
        } else {
          throw new Error('Failed to fetch user profile');
        }
      }
    } catch (error) {
      console.error('Auth error:', error);
      
      // Handle App Check specific errors
      if (error.message === 'security-verification-failed') {
        showToast('Security verification failed. Please refresh the page and try again.');
      } else if (error.code?.includes('app-check/')) {
        // Handle App Check errors
        if (error.code === 'app-check/throttled') {
          showToast('Too many attempts. Please try again in a few minutes.');
        } else {
          showToast('Security verification failed. Please try again.');
        }
        // Clear token on error to force refresh next time
        setAppCheckToken(null);
      } else {
        // Handle regular Firebase auth errors
        let errorMessage = "An error occurred";
        switch (error.code) {
          case 'auth/email-already-in-use':
            errorMessage = "Email is already in use";
            break;
          case 'auth/invalid-email':
            errorMessage = "Invalid email address";
            break;
          case 'auth/user-not-found':
          case 'auth/wrong-password':
          case 'auth/invalid-credential':
            errorMessage = "Invalid email or password";
            break;
          case 'auth/too-many-requests':
            errorMessage = "Too many attempts. Please try again later.";
            break;
          default:
            errorMessage = error.message || "Authentication failed. Please try again.";
        }
        showToast(errorMessage);
      }
    } finally {
      setAuthInProgress(false);
      setLoading(false);
    }
  };

  const handleKeyPress = (event) => {
    if (event && event.key === 'Enter') {
      event.preventDefault();
      handleAuth(event); // Pass the event object
    }
  };

  // Update the email TextField in both forms
  const emailTextField = (
    <StyledTextField
      margin="normal"
      required
      fullWidth
      label="Email"
      value={email}
      onChange={handleEmailChange}
      onBlur={handleEmailBlur}
      error={!!emailError}
      helperText={emailError}
      sx={{ mb: emailError ? 0.5 : 2 }}
    />
  );

  const renderRegistrationForm = () => (
    <>
      <Typography 
        variant="overline" 
        sx={{ 
          mb: 1, 
          color: 'primary.main',
          fontWeight: 600,
          letterSpacing: '1px'
        }}
      >
        SOMETHING TOLD
      </Typography>
      <Typography 
        variant="h5" 
        sx={{ 
          mb: 4,
          fontWeight: 700,
          background: 'linear-gradient(45deg, #1a1a1a 30%, #4a4a4a 90%)',
          backgroundClip: 'text',
          WebkitBackgroundClip: 'text',
          color: 'transparent'
        }}
      >
        Create an account
      </Typography>
      
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <StyledTextField
            required
            fullWidth
            label="First Name"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <StyledTextField
            required
            fullWidth
            label="Last Name"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
        </Grid>
      </Grid>

      <Typography 
        variant="subtitle2" 
        sx={{ 
          mt: 2,
          mb: 1,
          fontWeight: 600,
          color: 'text.primary'
        }}
      >
        Birthday *
      </Typography>
      
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <FormControl fullWidth>
            <StyledSelect
              value={month}
              onChange={(e) => setMonth(e.target.value)}
              displayEmpty
              renderValue={(selected) => (
                <span style={{ color: selected ? 'inherit' : 'rgba(0, 0, 0, 0.6)' }}>
                  {selected || "Month"}
                </span>
              )}
            >
              {months.map((m, i) => (
                <MenuItem key={i} value={m}>{m}</MenuItem>
              ))}
            </StyledSelect>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormControl fullWidth>
            <StyledSelect
              value={day}
              displayEmpty
              renderValue={(selected) => (
                <span style={{ color: selected ? 'inherit' : 'rgba(0, 0, 0, 0.6)' }}>
                  {selected || "Day"}
                </span>
              )}
              onChange={(e) => setDay(e.target.value)}
            >
              {days.map((d) => (
                <MenuItem key={d} value={d}>{d}</MenuItem>
              ))}
            </StyledSelect>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormControl fullWidth>
            <StyledSelect
              value={year}
              displayEmpty
              renderValue={(selected) => (
                <span style={{ color: selected ? 'inherit' : 'rgba(0, 0, 0, 0.6)' }}>
                  {selected || "Year"}
                </span>
              )}
              onChange={(e) => setYear(e.target.value)}
            >
              {years.map((y) => (
                <MenuItem key={y} value={y}>{y}</MenuItem>
              ))}
            </StyledSelect>
          </FormControl>
        </Grid>
      </Grid>

      <Grid container spacing={2} sx={{ mt: 2 }}>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <StyledSelect
              value={state}
              displayEmpty
              renderValue={(selected) => (
                <span style={{ color: selected ? 'inherit' : 'rgba(0, 0, 0, 0.6)' }}>
                  {selected || "State"}
                </span>
              )}
              onChange={(e) => setState(e.target.value)}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {US_STATES.map((stateName) => (
                <MenuItem key={stateName} value={stateName}>
                  {stateName}
                </MenuItem>
              ))}
            </StyledSelect>
          </FormControl>
        </Grid>
      </Grid>

      {emailTextField}
      
      <StyledTextField
        margin="normal"
        required
        fullWidth
        label="Password"
        type="password"
        value={password}
        onChange={handlePasswordChange}
        error={!!passwordError}
        sx={{ mt: 2 }}
      />
      
      <StyledTextField
        margin="normal"
        required
        fullWidth
        label="Confirm Password"
        type="password"
        value={confirmPassword}
        onChange={handleConfirmPasswordChange}
        onKeyPress={handleKeyPress}
        error={!!passwordError}
        helperText={passwordError}
        sx={{ mt: 2 }}
      />

      <Button
        fullWidth
        variant="contained"
        onClick={handleAuth}
        disabled={loading}
        sx={{
          mt: 2,
          mb: 2,
          py: 1.5,
          bgcolor: '#11311B',
          '&:hover': {
            bgcolor: '#11311B',
            opacity: 0.9,
          },
          textTransform: 'none',
          fontSize: '1rem',
          fontWeight: 500,
          borderRadius: '12px',
        }}
      >
        {loading ? <CircularProgress size={24} color="inherit" /> : "Sign In"}
      </Button>

      <Button
        fullWidth
        variant="text"
        onClick={() => setIsRegistering(false)}
        sx={{ color: 'text.secondary' }}
      >
        I already have an account
      </Button>
    </>
  );

  // Handle opening the forgot password modal
  const handleForgotPasswordClick = () => {
    setShowForgotPasswordModal(true);
  };

  // Clear existing auth state on mount
  useEffect(() => {
    // Check if we should show registration form
    const showRegistration = localStorage.getItem('showRegistration');
    if (showRegistration === 'true') {
      setIsRegistering(true);
      // Clear the flag
      localStorage.removeItem('showRegistration');
    }
  }, []);

  useEffect(() => {
    console.log("Current User:", currentUser); 

    // Don't redirect if user is authenticated
    if (currentUser) {
      navigate('/treasurechest');
    }
  }, [currentUser, navigate]);

  // Add loading handling
  if (authLoading) {
    return (
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        height: '100vh' 
      }}>
        <CircularProgress />
      </Box>
    );
  }

  const handleLogoClick = () => {
    navigate('/');
  };

  return (
    <AuthLayout>
      <Container maxWidth="lg" sx={{ height: '100%', display: 'flex', alignItems: 'center' }}>
        {isRegistering ? (
          // Registration form - mobile friendly
          <Box sx={{ 
            maxWidth: 500, 
            mx: 'auto',
            width: '100%',
            my: 4
          }}>
            <Paper 
              elevation={3} 
              sx={{ 
                p: { xs: 2, sm: 4 },
                bgcolor: 'white',
                width: '100%'
              }}
            >
              {renderRegistrationForm()}
            </Paper>
          </Box>
        ) : (
          // Login layout - responsive grid
          <Grid 
            container 
            spacing={{ xs: 0, md: 2 }}
            sx={{ 
              flexDirection: { xs: 'column', md: 'row' },
              alignItems: 'center',
              minHeight: { xs: 'auto', md: '100vh' },
              pt: { xs: -20, md: 0 }
            }}
          >
            {/* Left Section - Logo */}
            <Grid item xs={12} md={6} sx={{ 
              display: 'flex', 
              alignItems: 'center', 
              justifyContent: 'center',
              py: { xs: 0, md: 0 },
              mb: { xs: 1, md: 0 }
            }}>
              <Box sx={{ 
                width: '100%',
                maxWidth: { xs: '150px', sm: '200px', md: '236.25px' },
                textAlign: 'center',
                cursor: 'pointer'
              }}
              onClick={handleLogoClick}
              >
                <img 
                  src={LoginLogo} 
                  alt="Something Told Logo" 
                  style={{ 
                    width: '100%',
                    height: { xs: '100px', sm: '150px', md: '243.08px' },
                    objectFit: 'contain'
                  }}
                />
              </Box>
            </Grid>

            {/* Right Section - Auth Form */}
            <Grid item xs={12} md={6} sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%'
            }}>
              <Paper 
                elevation={3}
                sx={{
                  p: { xs: 2, sm: 4 },
                  maxWidth: { xs: '100%', sm: 400 },
                  width: '100%',
                  bgcolor: 'white',
                  my: { xs: 0, md: 0 }
                }}
              >
                {emailTextField}
                <StyledTextField
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  sx={{ mb: 3 }}
                  onKeyPress={handleKeyPress}
                />
                <Button
                  fullWidth
                  variant="contained"
                  onClick={handleAuth}
                  disabled={loading || authInProgress}
                  sx={{
                    mt: 2,
                    mb: 2,
                    py: 1.5,
                    bgcolor: '#11311B',
                    '&:hover': {
                      bgcolor: '#11311B',
                      opacity: 0.9,
                    },
                    textTransform: 'none',
                    fontSize: '1rem',
                    fontWeight: 500,
                    borderRadius: '12px',
                  }}
                >
                  {loading ? <CircularProgress size={24} color="inherit" /> : "Sign In"}
                </Button>
                <Box sx={{ textAlign: 'center', mb: 2 }}>
                  <Button 
                    variant="text" 
                    onClick={handleForgotPasswordClick}
                    sx={{ 
                      color: 'text.secondary',
                      textTransform: 'none',
                      fontSize: '0.875rem'
                    }}
                  >
                    Forgot Password?
                  </Button>
                </Box>
                <Button
                  fullWidth
                  variant="contained"
                  onClick={() => setIsRegistering(true)}
                  sx={{
                    py: 1.5,
                    bgcolor: '#11311B',
                    '&:hover': {
                      bgcolor: '#11311B',
                      opacity: 0.9,
                    },
                    textTransform: 'none',
                    fontSize: '1rem',
                    fontWeight: 500,
                    borderRadius: '12px',
                  }}
                >
                  Create new account
                </Button>
              </Paper>
            </Grid>
          </Grid>
        )}
      </Container>
      {showSubscriptionChecker && (
        <SubscriptionChecker 
          onError={(message) => alert(message)}
        />
      )}
      <FirstTimeUserModal 
        open={showFirstTimeModal}
      />

      {/* Forgot Password Modal */}
      <ForgotPasswordModal 
        open={showForgotPasswordModal}
        onClose={() => setShowForgotPasswordModal(false)}
      />
    </AuthLayout>
  );
};

export default Auth;
