import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Typography, Box, Container, Button, TextField, InputAdornment } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import SearchBar from './common/SearchBar';
import { EventCardSkeleton } from './skeletons/ContentSkeletons';
import EventCard from './EventCard';
import api from '../services/api';
import { getAuth } from 'firebase/auth';
import { debounce } from 'lodash';
import useMediaQuery from '@mui/material/useMediaQuery';
import CreateEventModal from './modals/CreateEventModal';
import { Search as SearchIcon } from '@mui/icons-material';
import EventIcon from '@mui/icons-material/Event';
import { useToast } from '../contexts/ToastContext';
import ConfirmDialog from './common/ConfirmDialog';

const Events = () => {
  const [loading, setLoading] = useState(true);
  const [events, setEvents] = useState([]);
  const [userRole, setUserRole] = useState('');
  const [hasMore, setHasMore] = useState(false);
  const [page, setPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');
  const observerTarget = useRef(null);
  const theme = useTheme();
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [editingEvent, setEditingEvent] = useState(null);
  const [recentSearches, setRecentSearches] = useState([]);
  const { showToast } = useToast();
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [eventToDelete, setEventToDelete] = useState(null);

  // Viewport detection
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const viewport = isMobile ? 'mobile' : isTablet ? 'tablet' : 'desktop';

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem('userData'));
    const role = userData?.data?.profile?.role || 'user';
    setUserRole(role);
  }, []);

  const fetchEvents = useCallback(async (pageNum = 1, append = false) => {
    try {
      setLoading(true);
      const auth = getAuth();
      const token = await auth.currentUser?.getIdToken();

      const response = await api.get('/api/events', {
        params: {
          page: pageNum,
          viewport,
          query: searchQuery
        }
      });

      // Ensure we have arrays even if response is undefined
      const newEvents = Array.isArray(response.data?.events) ? response.data.events : [];
      const hasMoreEvents = !!response.data?.hasMore;

      setEvents(prev => append ? [...(Array.isArray(prev) ? prev : []), ...newEvents] : newEvents);
      setHasMore(hasMoreEvents);
      setLoading(false);
    } catch (error) {
      console.error('Failed to fetch events:', error);
      setEvents([]);
      setLoading(false);
    }
  }, [viewport, searchQuery]);

  // Update handleSearch to match Explore.js
  const handleSearch = useCallback(
    debounce((query) => {
      setSearchQuery(query);
      setPage(1);
      
      if (query && query.length >= 3) {
        setRecentSearches(prev => {
          const updated = [query, ...prev.filter(q => q !== query)];
          return updated.slice(0, 5);
        });
      }
    }, 300),
    []
  );

  // Intersection Observer for infinite scroll
  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        if (entries[0].isIntersecting && hasMore && !loading) {
          setPage(prev => prev + 1);
        }
      },
      { threshold: 1.0 }
    );

    if (observerTarget.current) {
      observer.observe(observerTarget.current);
    }

    return () => observer.disconnect();
  }, [hasMore, loading]);

  // Fetch events when page, viewport, or search changes
  useEffect(() => {
    fetchEvents(page, page > 1);
  }, [fetchEvents, page]);

  // Add handleEdit function
  const handleEdit = async (event) => {
    setEditingEvent(event);
    setIsCreateModalOpen(true);
  };

  // Modify handleCreateSuccess to handle both create and edit
  const handleCreateSuccess = useCallback((updatedEvent, isEdit = false) => {
    if (isEdit) {
      setEvents(prevEvents => 
        prevEvents.map(event => 
          event.id === updatedEvent.id ? updatedEvent : event
        )
      );
    } else {
      setEvents(prevEvents => [updatedEvent, ...prevEvents]);
    }
    setIsCreateModalOpen(false);
    setEditingEvent(null);
    // Refresh to ensure consistency
    fetchEvents(1, false);
  }, [fetchEvents]);

  // Add delete handler
  const handleDelete = (event) => {
    setEventToDelete(event);
    setDeleteConfirmOpen(true);
  };

  // Add confirm delete function
  const handleConfirmDelete = async () => {
    try {
      await api.delete(`/api/events/${eventToDelete.id}`);

      // Remove event from state
      setEvents(prevEvents => 
        prevEvents.filter(event => event.id !== eventToDelete.id)
      );

      showToast('Event deleted successfully', 'success');
    } catch (error) {
      console.error('Failed to delete event:', error);
      showToast(
        error.response?.data?.message || 'Failed to delete event',
        'error'
      );
    } finally {
      setDeleteConfirmOpen(false);
      setEventToDelete(null);
    }
  };

  return (
    <Container 
      maxWidth="lg" 
      sx={{ 
        mt: 4,
        px: { xs: 2, sm: 3 }, // Adjust padding for mobile
        width: '100%',
        [theme.breakpoints.down('sm')]: {
          maxWidth: '100%',
        }
      }}
    >
      {/* Title Section */}
      <Typography
        variant="h4"
        sx={{
          fontWeight: 600,
          color: theme.palette.text.primary,
          mb: 3,
        }}
      >
        Events
      </Typography>

      {/* SearchBar Section */}
      <Box sx={{ 
        display: 'flex', 
        gap: 2, 
        width: '100%',
        mb: 4,
        flexDirection: { xs: 'column', sm: 'row' }, // Stack on mobile, row on tablet/desktop
        '& > *': { // Target both children (TextField and Button)
          flex: { xs: '1 1 auto', sm: '0 0 auto' } // Full width on mobile, auto on tablet/desktop
        },
        '& > .MuiTextField-root': {
          flex: { sm: '1 1 auto' } // Let TextField grow on tablet/desktop
        },
        '& > .MuiButton-root': {
          minWidth: { xs: '100%', sm: 'auto' }, // Full width on mobile, auto on tablet/desktop
          height: '40px'
        }
      }}>
        <Box sx={{ flex: { sm: '1 1 auto' } }}>
          <SearchBar
            placeholder="Search events..."
            onSearch={handleSearch}
            recentSearches={recentSearches}
            onClearRecentSearches={() => setRecentSearches([])}
            onSelectRecentSearch={(search) => handleSearch(search)}
          />
        </Box>
        {(userRole === 'admin' || userRole === 'ambassador') && (
          <Button
            variant="contained"
            onClick={() => setIsCreateModalOpen(true)}
            sx={{
              borderRadius: '12px',
              bgcolor: '#11311B',
              color: 'white',
              textTransform: 'none',
              fontSize: '0.875rem',
              '&:hover': {
                bgcolor: '#11311B',
                opacity: 0.9,
              }
            }}
          >
            Create Event
          </Button>
        )}
      </Box>

      {/* Event List */}
      <Box>
        {loading && page === 1 ? (
          [...Array(5)].map((_, index) => (
            <EventCardSkeleton key={`skeleton-${index}`} />
          ))
        ) : events.length > 0 ? (
          events.map((event) => (
            <EventCard
              key={event.id}
              event={event}
              isAdmin={userRole === 'admin'}
              hasRights={event.hasrights}
              onEdit={() => handleEdit(event)}
              onDelete={() => handleDelete(event)}
            />
          ))
        ) : (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              py: 8,
              px: 2,
              textAlign: 'center',
              width: '100%'
            }}
          >
            {searchQuery ? (
              // Search with no results
              <>
                <SearchIcon 
                  sx={{
                    fontSize: 64, 
                    color: '#C6AB70',
                    mb: 2,
                    opacity: 0.4
                  }} 
                />
                <Typography variant="h6" color="text.secondary">
                  No events found
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                  Try different keywords or check your spelling
                </Typography>
              </>
            ) : (
              // No events yet
              <>
                <EventIcon 
                  sx={{ 
                    fontSize: 64, 
                    color: 'text.secondary',
                    mb: 2,
                    opacity: 0.6
                  }}
                />
                <Typography variant="h6" color="text.secondary">
                  No Events Scheduled Yet
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                  Be the first to create an event for the community
                </Typography>
              </>
            )}
          </Box>
        )}

        {hasMore && !loading && (
          <Box ref={observerTarget} sx={{ height: 20, mt: 2 }} />
        )}
      </Box>

      {/* Create Event Modal */}
      <CreateEventModal 
        open={isCreateModalOpen}
        onClose={() => {
          setIsCreateModalOpen(false);
          setEditingEvent(null);
        }}
        onSuccess={handleCreateSuccess}
        editingEvent={editingEvent}
      />

      {/* Add ConfirmDialog */}
      <ConfirmDialog
        open={deleteConfirmOpen}
        title="Delete Event"
        content="Are you sure you want to delete this event? This action cannot be undone."
        confirmText="Delete"
        cancelText="Cancel"
        onConfirm={handleConfirmDelete}
        onCancel={() => {
          setDeleteConfirmOpen(false);
          setEventToDelete(null);
        }}
      />
    </Container>
  );
};

export default Events;
