import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Box,
  IconButton,
  Typography,
  MenuItem,
  Switch,
  FormControlLabel,
  Grid,
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { Close as CloseIcon } from '@mui/icons-material';
import LogoIcon from '../../assets/ST_Logo_Gold-on-Gren.svg';
import { useToast } from '../../contexts/ToastContext';
import api from '../../services/api';
import { useMediaQuery } from '@mui/material';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    width: '100%',
    maxWidth: 600,
    borderRadius: { xs: 0, sm: '16px' },
    boxShadow: '0 8px 32px rgba(0,0,0,0.08)',
    [theme.breakpoints.down('sm')]: {
      height: '100%',
    },
    [theme.breakpoints.up('sm')]: {
      height: 'auto'
    },
    margin: { xs: 0, sm: theme.spacing(2) },
  }
}));

const DialogContentStyled = styled(DialogContent)(({ theme }) => ({
  padding: theme.spacing(3),
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2),
  },
  overflow: 'auto',
  '&::-webkit-scrollbar': {
    width: '6px',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'rgba(0,0,0,0.1)',
    borderRadius: '10px',
  }
}));

const MediaPreview = styled(Box)(({ theme }) => ({
  width: '100%',
  height: 200,
  borderRadius: '8px',
  overflow: 'hidden',
  backgroundColor: theme.palette.grey[50],
  marginBottom: theme.spacing(3),
  position: 'relative',
  boxShadow: '0 8px 32px rgba(0,0,0,0.1)',
  transition: 'transform 0.3s ease, box-shadow 0.3s ease',
  '& img': {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    transition: 'transform 0.3s ease',
  },
  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: '0 12px 40px rgba(0,0,0,0.15)',
    '& img': {
      transform: 'scale(1.03)',
    },
    '&::after': {
      opacity: 1,
    }
  },
  '&::after': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: 'linear-gradient(180deg, rgba(0,0,0,0.03) 0%, rgba(0,0,0,0.1) 100%)',
    opacity: 0,
    transition: 'opacity 0.3s ease',
    zIndex: 1,
  }
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: '12px',
    transition: 'all 0.2s ease-in-out',
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
      transform: 'translateY(-1px)',
      boxShadow: '0 4px 12px rgba(0,0,0,0.05)',
    },
    '&.Mui-focused': {
      backgroundColor: theme.palette.background.paper,
      transform: 'translateY(-1px)',
      boxShadow: '0 4px 16px rgba(0,0,0,0.08)',
    }
  },
  '& .MuiInputBase-input': {
    color: theme.palette.text.secondary,
    fontSize: { xs: '0.875rem', sm: '0.95rem' },
    lineHeight: 1.8,
    letterSpacing: '0.015em',
  },
  '& .MuiInputLabel-root': {
    color: theme.palette.text.secondary,
    fontSize: '0.9rem',
    '&.Mui-focused': {
      color: theme.palette.text.secondary,
    }
  },
  '& .MuiInputBase-input::placeholder': {
    color: theme.palette.text.disabled,
    opacity: 0.8,
  },
  '& .MuiSelect-select': {
    color: theme.palette.text.secondary,
  }
}));

const StyledSwitch = styled(Switch)(({ theme }) => ({
  width: 32,
  height: 16,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.grey[400],
        opacity: 0.5,
        border: 0,
      },
    }
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 12,
    height: 12,
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    backgroundColor: theme.palette.grey[400],
    opacity: 0.3,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    })
  }
}));

const categories = [
  "Jewelry & Watches",
  "Art & Collectibles", 
  "Books & Manuscripts",
  "Clothing & Accessories",
  "Electronics & Gadgets",
  "Family Heirlooms",
  "Furniture & Decor",
  "Musical Instruments", 
  "Photography",
  "Sports Memorabilia",
  "Toys & Games",
  "Other"
];

const EditTreasureModal = ({ open, onClose, treasure, onUpdate = () => {} }) => {
  const { showToast } = useToast();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [formData, setFormData] = useState({
    name: '',
    from_person: '',
    category: '',
    date_acquired: '',
    story: '',
    is_public: false
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const fileInputRef = useRef(null);
  const [originalData, setOriginalData] = useState({
    name: '',
    from_person: '',
    category: '',
    date_acquired: '',
    story: '',
    is_public: false
  });
  const [hasChanges, setHasChanges] = useState(false);

  useEffect(() => {
    if (treasure) {
      const initialData = {
        name: treasure.name || '',
        from_person: treasure.from_person || '',
        category: treasure.category || '',
        date_acquired: treasure.date_acquired || '',
        story: treasure.story || '',
        is_public: Boolean(treasure.is_public)
      };
      
      setOriginalData(initialData);
      setFormData(initialData);
    }
  }, [treasure]);

  useEffect(() => {
    const hasChanges = Object.keys(formData).some(key => {
      const originalValue = originalData[key]?.toString() || '';
      const newValue = formData[key]?.toString() || '';
      return originalValue !== newValue;
    });
    setHasChanges(hasChanges);
  }, [formData, originalData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    
    // Special handling for different field types
    switch(name) {
      case 'category':
      case 'date_acquired':
        // Don't sanitize these fields
        setFormData(prev => ({ ...prev, [name]: value }));
        break;
      
      case 'story':
        // Allow more characters in story, but prevent XSS
        const sanitizedStory = value.replace(/[<>]/g, '');
        setFormData(prev => ({ ...prev, [name]: sanitizedStory }));
        break;
      
      default:
        // Basic sanitization for name and from_person
        const sanitizedValue = value.replace(/[^a-zA-Z0-9\s'-]/g, '');
        setFormData(prev => ({ ...prev, [name]: sanitizedValue }));
    }
  };

  const handleSave = async () => {
    try {
      setLoading(true);
      setError('');
      
      // Only include fields that have actually changed
      const changedFields = Object.keys(formData).reduce((acc, key) => {
        const originalValue = originalData[key]?.toString() || '';
        const newValue = formData[key]?.toString() || '';
        
        if (originalValue !== newValue) {
          acc[key] = formData[key];
        }
        return acc;
      }, {});

      // If no changes, don't make the request
      if (Object.keys(changedFields).length === 0) {
        showToast('No changes to save', 'info');
        return;
      }

      if (!treasure?.id) {
        throw new Error('Treasure ID is missing');
      }

      const response = await api.put(
        `/api/treasures/${treasure.id}`,
        changedFields
      );

      if (response.data) {
        // Try to call onUpdate if it exists
        if (typeof onUpdate === 'function') {
          onUpdate(response.data);
        }
        onClose();
        showToast('Treasure updated successfully', 'success');
      }

    } catch (error) {
      console.error('Error updating treasure:', error);
      // Extract validation error message from the response
      const errorMessage = error.response?.data?.message || 'Failed to update treasure';
      setError(errorMessage);
      showToast(errorMessage, 'error');
    } finally {
      setLoading(false);
    }
  };

  return (
    <StyledDialog 
      open={open} 
      onClose={onClose} 
      maxWidth="sm" 
      fullWidth
      fullScreen={isMobile}
    >
      <DialogContentStyled>
        <Box sx={{ 
          ...(isMobile && {
            pb: '80px'
          }),
          position: 'relative'
        }}>
          <Box sx={{ 
            textAlign: 'center', 
            pt: 3,
            mb: 4,
            position: 'relative'
          }}>
            <IconButton
              onClick={onClose}
              size="medium"
              sx={{
                position: 'absolute',
                right: 0,
                top: 0,
                color: 'text.secondary',
                '&:hover': {
                  backgroundColor: 'rgba(0,0,0,0.05)'
                }
              }}
            >
              <CloseIcon fontSize="medium" />
            </IconButton>
            <img 
              src={LogoIcon} 
              alt="Something Told Logo" 
              style={{ 
                width: '100px',
                height: '100px',
              }}
            />
            <Typography variant="h5" component="div" sx={{ mt: 2 }}>
              Edit Your Treasure
            </Typography>
          </Box>

          <MediaPreview>
            <img
              src={treasure?.is_video ? treasure?.video_thumbnail : treasure?.image_url}
              alt="Treasure"
            />
          </MediaPreview>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <StyledTextField
                fullWidth
                label="Treasure Name"
                name="name"
                value={formData.name}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <StyledTextField
                fullWidth
                label="From"
                name="from_person"
                value={formData.from_person}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <StyledTextField
                select
                fullWidth
                label="Category"
                name="category"
                value={formData.category}
                onChange={handleChange}
                SelectProps={{
                  displayEmpty: true,
                  renderValue: (selected) => selected || "Select a category"
                }}
              >
                {categories.map(category => (
                  <MenuItem key={category} value={category}>{category}</MenuItem>
                ))}
              </StyledTextField>
            </Grid>
            <Grid item xs={12}>
              <StyledTextField
                fullWidth
                type="date"
                label="Date Received"
                name="date_acquired"
                value={formData.date_acquired}
                onChange={handleChange}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={12}>
              <StyledTextField
                fullWidth
                multiline
                rows={4}
                label="Story"
                name="story"
                value={formData.story}
                onChange={handleChange}
                placeholder="Share the story behind this treasure..."
              />
            </Grid>
          </Grid>
        </Box>
      </DialogContentStyled>

      <DialogActions sx={{ 
        p: { xs: 2, sm: 3 },
        borderTop: '1px solid',
        borderColor: 'divider',
        position: isMobile ? 'fixed' : 'sticky',
        bottom: 0,
        left: isMobile ? 0 : 'auto',
        right: isMobile ? 0 : 'auto',
        width: isMobile ? '100%' : 'auto',
        bgcolor: 'background.paper',
        zIndex: 1,
        display: 'flex',
        justifyContent: 'space-between',
        ...(isMobile && {
          boxShadow: '0 -4px 12px rgba(0,0,0,0.05)'
        })
      }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
          <Typography 
            variant="caption" 
            sx={{ 
              color: 'text.secondary',
              opacity: formData.is_public ? 0.5 : 0.9,
              fontSize: '0.75rem',
              transition: 'opacity 0.2s ease-in-out'
            }}
          >
            Private
          </Typography>
          <StyledSwitch
            checked={formData.is_public}
            onChange={(e) => setFormData(prev => ({ 
              ...prev, 
              is_public: e.target.checked 
            }))}
            size="small"
          />
          <Typography 
            variant="caption" 
            sx={{ 
              color: 'text.secondary',
              opacity: formData.is_public ? 0.9 : 0.5,
              fontSize: '0.75rem',
              transition: 'opacity 0.2s ease-in-out'
            }}
          >
            Public
          </Typography>
        </Box>
        
        <Box sx={{ display: 'flex', gap: 1 }}>
          <Button 
            onClick={onClose}
            size="small"
            sx={{ 
              borderRadius: '8px',
              textTransform: 'none',
              px: 3,
              color: 'text.secondary',
              fontSize: { xs: '0.875rem', sm: '0.95rem' },
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleSave}
            disabled={loading || !hasChanges}
            size="small"
            sx={{
              borderRadius: '8px',
              textTransform: 'none',
              px: 3,
              fontSize: { xs: '0.875rem', sm: '0.95rem' },
              background: 'linear-gradient(45deg, #1a1a1a 30%, #4a4a4a 90%)',
              boxShadow: '0 4px 16px rgba(0,0,0,0.1)',
              '&:hover': {
                transform: 'translateY(-1px)',
                boxShadow: '0 6px 20px rgba(0,0,0,0.15)',
              },
              transition: 'all 0.2s ease-in-out',
              color: '#fff',
              opacity: (!hasChanges || loading) ? 0.6 : 1
            }}
          >
            {loading ? 'Saving...' : 'Save Changes'}
          </Button>
        </Box>
      </DialogActions>
    </StyledDialog>
  );
};

EditTreasureModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  treasure: PropTypes.object,
  onUpdate: PropTypes.func,
};

export default EditTreasureModal; 