import {
  Dialog,
  DialogContent,
  Typography,
  Box,
  Button,
  Paper,
  useTheme,
  useMediaQuery,
  CircularProgress,
  Link
} from '@mui/material';
import { styled } from '@mui/material/styles';
import api from '../services/api';
import { useNavigate } from 'react-router-dom';
import { useToast } from '../contexts/ToastContext';
import { useState } from 'react';
import { useAuth } from '../contexts/AuthContext';
import NightCard from './NightCard';
import { useSubscription } from '../hooks/useSubscription';
import Logo from '../assets/ST_Logo_Gold-on-Gren.svg';
import { motion, AnimatePresence } from 'framer-motion';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useTheme as useMuiTheme, alpha } from '@mui/material/styles';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    maxWidth: '1200px',
    width: '100%',
    margin: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    animation: 'modalFadeIn 0.3s ease-out',
    [theme.breakpoints.down('sm')]: {
      margin: 0,
      maxHeight: '100%',
      height: '100%',
      borderRadius: 0,
    }
  },
  '& .MuiBackdrop-root': {
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    backdropFilter: 'blur(4px)',
  },
  '@keyframes modalFadeIn': {
    from: {
      opacity: 0,
      transform: 'translateY(-20px)'
    },
    to: {
      opacity: 1,
      transform: 'translateY(0)'
    }
  }
}));

const PlanCard = styled(Box)(({ theme, isPopular }) => ({
  position: 'relative',
  padding: theme.spacing(3),
  borderRadius: '24px',
  border: '1px solid',
  borderColor: isPopular 
    ? theme.palette.primary.main 
    : alpha(theme.palette.divider, 0.8),
  boxShadow: isPopular 
    ? `0 12px 30px ${alpha(theme.palette.primary.main, 0.15)}` 
    : `0 8px 24px ${alpha(theme.palette.divider, 0.1)}`,
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  transition: 'all 0.3s ease',
  background: alpha(theme.palette.background.paper, 0.8),
  backdropFilter: 'blur(10px)',
  '&:hover': {
    transform: 'translateY(-8px)',
    boxShadow: `0 16px 40px ${alpha(theme.palette.divider, 0.15)}`,
    borderColor: alpha(theme.palette.text.secondary, 0.3),
  },
  '& .feature-item': {
    position: 'relative',
    paddingLeft: theme.spacing(4),
    marginBottom: theme.spacing(1.5),
    transition: 'transform 0.2s ease',
    '&:hover': {
      transform: 'translateX(3px)',
    }
  },
  '& .feature-icon': {
    position: 'absolute',
    left: 0,
    top: '2px',
    color: theme.palette.success.main,
  }
}));

const DiscountLabel = styled(Box)(({ theme }) => ({
  background: 'linear-gradient(135deg,rgb(26, 186, 66) 0%,rgb(26, 186, 66) 100%)',
  padding: theme.spacing(0.75, 2),
  borderRadius: '8px',
  maxWidth: 'fit-content',
  margin: '12px auto 0',
  position: 'relative',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    borderRadius: '8px',
    padding: '1.5px',
    background: 'linear-gradient(135deg,rgb(26, 186, 66) 0%,rgb(42, 198, 33) 100%)',
    WebkitMask: 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
    WebkitMaskComposite: 'destination-out',
    maskComposite: 'exclude',
  },
  animation: 'pulse 5s infinite'
}));

const pulseKeyframes = `
  @keyframes pulse {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    50% {
      transform: scale(1.05);
      opacity: 0.8;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
`;

const style = document.createElement('style');
style.innerHTML = pulseKeyframes;
document.head.appendChild(style);

const FirstTimeUserModal = ({ open, setShowModal }) => {
  const navigate = useNavigate();
  const { showToast, closeModal } = useToast();
  const { refreshSubscription } = useAuth();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { subscriptionStatus } = useSubscription();
  const [showDiscount, setShowDiscount] = useState(false);
  const [loadingPlan, setLoadingPlan] = useState(null);

  const plans = [
    {
      title: '3-Day Trial',
      price: 'Free',
      description: 'Experience all premium features for 3 days',
      features: [
        'Full access to all features'
      ],
      buttonText: 'Start 3 Day Free Trial',
      isPopular: false
    },
    {
      title: 'Annual Plan',
      price: '$99',
      period: '/year',
      description: '',
      features: [
        'Preserve Unlimited Treasure Stories',
        'Upload One Photo or Video Per Story',
        'Explore Blog & Member Stories',
        'Exclusive Access to Live & Virtual Events',
        
      ],
      buttonText: 'Choose Annual',
      isPopular: true
    },
    {
      title: 'Monthly Plan',
      price: '$9.99',
      period: '/month',
      description: '',
      features: [
        'Preserve Unlimited Treasure Stories',
        'Upload One Photo or Video Per Story',
      
        'Explore Blog & Member Stories',
        'Exclusive Access to Live & Virtual Events',
      ],
      buttonText: 'Choose Monthly',
      isPopular: false
    }
  ];

  const handleSubscriptionSelect = async (plan) => {
    try {
      setLoadingPlan(plan.title);
      
      if (plan.title === '3-Day Trial') {
        // Start trial request
        const response = await api.post('/api/users/start-trial', { 
          plan: 'trial' 
        });

        if (response.data.status === 'success') {
          // Navigate first, then handle background tasks
          navigate('/treasurechest');
          
          // Run these in parallel using Promise.all
          await Promise.all([
            refreshSubscription(true),
            new Promise(resolve => {
              showToast('Trial started successfully!', 'success');
              resolve();
            })
          ]);
          
          // Close modal last
          closeModal();
        }
      } else {
        // Handle paid subscriptions
        const response = await api.post('/api/users/subscribe', { 
          plan: plan.title === 'Annual Plan' ? 'annual' : 'monthly'
        });
        
        if (response.data.redirectUrl) {
          window.location.href = response.data.redirectUrl;
        }
      }
    } catch (error) {
      if (error.response?.status === 400) {
        showToast(
          error.response.data.message || 
          (plan.title === '3-Day Trial' 
            ? 'Trial period has already been used. Please select a subscription plan.'
            : 'Failed to process subscription. Please try again.'),
          'error'
        );
      } else {
        showToast(error.response?.data?.message || 'Failed to update subscription', 'error');
      }
      console.error('Subscription error:', error);
    } finally {
      setLoadingPlan(null);
    }
  };

  return (
    <StyledDialog 
      open={open}
      maxWidth={false}
      fullScreen={isMobile}
      disableEscapeKeyDown
      hideBackdrop={false}
      PaperProps={{
        sx: {
          mx: { xs: 2, sm: 3, md: 4 },
          minWidth: { xs: '100%', md: '1000px' },
          [theme.breakpoints.down('sm')]: {
            '& .night-card .moons .first-moon': {
              filter: 'none !important'
            },
            '& .night-card .moons::before, & .night-card .moons::after': {
              boxShadow: 'none !important'
            }
          }
        }
      }}
    >
      <DialogContent sx={{ p: { xs: 2, sm: 4 } }}>
        <Box sx={{ textAlign: 'center', mb: 4 }}>
          <Box 
            component="img"
            src={Logo}
            alt="Something Told Logo"
            sx={{ 
              width: '140px',
              height: 'auto',
              mb: 2
            }}
          />
          <motion.div
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <Typography 
              variant="h3" 
              sx={{ 
                fontSize: '2.2rem',
                fontWeight: 800,
                mb: 2,
                background: 'linear-gradient(135deg, #11311B 0%, #2A5A3C 100%)',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                position: 'relative',
                display: 'inline-block',
              }}
            >
              Choose your plan
            </Typography>
          </motion.div>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.2 }}
          >
            <Typography 
              variant="subtitle1" 
              color="text.secondary"
              sx={{ 
                maxWidth: '600px',
                mx: 'auto',
                lineHeight: 1.6,
                fontWeight: 500,
                fontSize: '1.1rem',
                letterSpacing: '0.01em'
              }}
            >
              Choose your plan to start preserving your treasures & their stories
            </Typography>
          </motion.div>

          {showDiscount && (
            <DiscountLabel>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.75 }}>
                <Typography
                  variant="body2"
                  sx={{
                    color: '#fff',
                    fontWeight: 500,
                    display: 'flex',
                    alignItems: 'center',
                    gap: 0.75,
                    fontSize: '0.875rem'
                  }}
                >
                  <Box
                    component="span"
                    sx={{
                      background: 'rgba(255,255,255,0.2)',
                      padding: '2px 6px',
                      borderRadius: '6px',
                      fontSize: '0.75rem',
                      fontWeight: 700
                    }}
                  >
                    EARLY ACCESS
                  </Box>
                  Save 40% with code
                  <Box
                    component="span"
                    sx={{
                      background: 'rgba(255,255,255,0.15)',
                      padding: '3px 6px',
                      borderRadius: '4px',
                      fontFamily: 'monospace',
                      letterSpacing: '0.5px',
                      fontSize: '0.8rem'
                    }}
                  >
                    SomethingTold2025
                  </Box>
                </Typography>
              </Box>
            </DiscountLabel>
          )}
        </Box>

        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'center', 
          mb: 4,
          gap: 3,
          position: 'relative',
          zIndex: 1
        }}>
          <Box
            sx={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              left: 0,
              top: '50%',
              transform: 'translateY(-50%)',
              background: 'radial-gradient(circle at center, rgba(17, 49, 27, 0.05) 0%, rgba(17, 49, 27, 0) 70%)',
              zIndex: -1,
              borderRadius: '50%',
              pointerEvents: 'none',
            }}
          />
          <Box sx={{
            display: 'grid',
            gridTemplateColumns: { 
              xs: '1fr',
              md: 'repeat(3, minmax(300px, 1fr))'
            },
            gap: 3,
            width: '100%',
            maxWidth: '1200px'
          }}>
            {/* 3-Day Trial - second on mobile */}
            <Box sx={{ order: { xs: 2, md: 1 } }}>
              <motion.div
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.5 }}
              >
                <PlanCard isPopular={false}>
                  <Typography variant="h5" sx={{ mb: 1, fontWeight: 600 }}>
                    {plans[0].title}
                  </Typography>
                  <Box sx={{ display: 'flex', alignItems: 'baseline', mb: 2 }}>
                    <Typography variant="h4" sx={{ fontWeight: 600 }}>
                      {plans[0].price}
                    </Typography>
                    {plans[0].period && (
                      <Typography variant="body1" color="text.secondary">
                        {plans[0].period}
                      </Typography>
                    )}
                  </Box>
                  <Typography 
                    variant="body2" 
                    color="text.secondary" 
                    sx={{ 
                      mb: 3,
                      fontWeight: 500
                    }}
                  >
                    {plans[0].description}
                  </Typography>
                  <Box sx={{ flex: 1 }}>
                    {plans[0].features.map((feature) => (
                      <Typography 
                        key={feature} 
                        variant="body2"
                        className="feature-item"
                        sx={{ 
                          mb: 1.5,
                          fontWeight: 500,
                          color: 'text.primary'
                        }}
                      >
                        <CheckCircleOutlineIcon 
                          className="feature-icon" 
                          sx={{ fontSize: 18 }} 
                        />
                        {feature}
                      </Typography>
                    ))}
                  </Box>
                  <Button
                    variant="contained"
                    onClick={() => handleSubscriptionSelect(plans[0])}
                    sx={{
                      mt: '24px',
                      textTransform: 'none',
                      fontSize: '0.875rem',
                      fontWeight: 600,
                      borderRadius: 1,
                      boxShadow: 'none',
                      padding: '6px 20px',
                      height: '36.5px',
                      minHeight: '36.5px',
                      width: 'auto',
                      maxWidth: '100%',
                      lineHeight: 1.75,
                      position: 'relative',
                      bottom: 0,
                      marginBottom: 0,
                      bgcolor: 'grey.800',
                      '&:hover': {
                        bgcolor: 'grey.900',
                      },
                      mx: 'auto',
                      display: 'block',
                    }}
                  >
                    {loadingPlan === plans[0].title ? (
                      <CircularProgress size={24} color="inherit" />
                    ) : plans[0].buttonText}
                  </Button>
                </PlanCard>
              </motion.div>
            </Box>

            {/* Annual Plan - first on mobile */}
            <Box sx={{ order: { xs: 1, md: 2 } }}>
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6, delay: 0.1 }}
              >
                <NightCard 
                  plan={plans[1]} 
                  loading={loadingPlan === plans[1].title}
                  onSubscribe={() => handleSubscriptionSelect(plans[1])}
                />
              </motion.div>
            </Box>

            {/* Monthly Plan - last on both mobile and desktop */}
            <Box sx={{ order: { xs: 3, md: 3 } }}>
              <motion.div
                initial={{ opacity: 0, x: 20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.5, delay: 0.2 }}
              >
                <NightCard 
                  plan={plans[2]} 
                  loading={loadingPlan === plans[2].title}
                  onSubscribe={() => handleSubscriptionSelect(plans[2])}
                />
              </motion.div>
            </Box>
          </Box>
        </Box>

        <Typography 
          variant="caption" 
          align="center" 
          sx={{ 
            display: 'block',
            maxWidth: '400px',
            mx: 'auto',
            mt: 3,
            pt: 2,
            borderTop: '1px solid',
            borderColor: 'divider',
            color: 'text.secondary',
            opacity: 0.8
          }}
        >
          By continuing, you agree to our{' '}
          <Link
            href="/terms"
            target="_blank"
            rel="noopener noreferrer"
            sx={{
              color: 'primary.main',
              textDecoration: 'none',
              '&:hover': {
                textDecoration: 'underline'
              }
            }}
          >
            Terms of Service & Privacy Policy
          </Link>
          
        </Typography>
      </DialogContent>
    </StyledDialog>
  );
};

export default FirstTimeUserModal; 