import { Dialog, DialogContent, Typography, Box, Button, useTheme, useMediaQuery } from '@mui/material';
import { styled } from '@mui/material/styles';
import CloseButton from './common/CloseButton';
import { AutoStoriesOutlined, Timeline } from '@mui/icons-material';
import Diversity1Icon from '@mui/icons-material/Diversity1';
import TextureBg from '../assets/Texture.png';

const VaultContent = styled(Box)(({ theme }) => ({
  color: theme.palette.common.white,
  padding: theme.spacing(4),
  position: 'relative',
  minHeight: '100%',
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(3, 0),
    overflow: 'visible',
  },
  /* Hide scrollbar for Chrome, Safari and Opera */
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  /* Hide scrollbar for IE, Edge and Firefox */
  '-ms-overflow-style': 'none',  /* IE and Edge */
  'scrollbar-width': 'none',  /* Firefox */
}));

const GlowingText = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.white,
  textShadow: '0 0 10px rgba(198, 171, 112, 0.4)',
  position: 'relative',
  marginBottom: theme.spacing(2),
  textAlign: 'center',
  fontFamily: 'Roboto',
  letterSpacing: '0.05em',
  fontSize: '1.75rem',
  '&::before, &::after': {
    content: '""',
    position: 'absolute',
    bottom: -8,
    left: '50%',
    transform: 'translateX(-50%)',
    height: '1px',
  },
  '&::before': {
    width: '140px',
    background: 'linear-gradient(90deg, transparent, rgba(198, 171, 112, 0.6), transparent)',
  },
  '&::after': {
    width: '90px',
    bottom: -12,
    background: 'linear-gradient(90deg, transparent, rgba(198, 171, 112, 0.3), transparent)',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.5rem',
    marginBottom: theme.spacing(2),
  },
}));

const StorySection = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(2),
  padding: theme.spacing(2),
  backgroundColor: 'rgba(17, 49, 27, 0.3)',
  borderRadius: '4px',
  border: '1px solid rgba(198, 171, 112, 0.15)',
  boxShadow: 'inset 0 0 30px rgba(0, 0, 0, 0.2)',
  position: 'relative',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: -1,
    left: -1,
    right: -1,
    bottom: -1,
    border: '1px solid rgba(198, 171, 112, 0.1)',
    borderRadius: '5px',
    pointerEvents: 'none'
  },
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2, 1),
    marginTop: theme.spacing(2),
    borderRadius: 0,
    border: 'none',
    borderTop: '1px solid rgba(198, 171, 112, 0.15)',
    borderBottom: '1px solid rgba(198, 171, 112, 0.15)',
  },
}));

const FeatureBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  gap: theme.spacing(2),
  marginBottom: theme.spacing(1.5),
  padding: theme.spacing(1.5),
  backgroundColor: 'rgba(0, 0, 0, 0.15)',
  border: '1px solid rgba(198, 171, 112, 0.15)',
  borderRadius: '4px',
  position: 'relative',
  transition: 'all 0.3s ease-in-out',
  cursor: 'pointer',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: 'linear-gradient(45deg, rgba(198, 171, 112, 0.03), transparent)',
    pointerEvents: 'none'
  },
  '& .MuiSvgIcon-root': {
    color: '#C6AB70',
    fontSize: 20,
    transition: 'all 0.4s ease-in-out',
    filter: 'drop-shadow(0 0 4px rgba(198, 171, 112, 0.3))',
  },
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.25)',
    borderColor: 'rgba(198, 171, 112, 0.25)',
    '& .MuiSvgIcon-root': {
      transform: 'scale(1.1)',
      filter: 'drop-shadow(0 0 8px rgba(198, 171, 112, 0.5))',
    }
  },
  '&:last-child': {
    marginBottom: 0,
  },
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.spacing(2),
    '& .MuiSvgIcon-root': {
      fontSize: 24,
    },
  },
}));

const QuoteSection = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(4),
  padding: theme.spacing(3, 4),
  position: 'relative',
  textAlign: 'center',
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(3, 1),
    marginTop: theme.spacing(3),
  }
}));

const FooterText = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(1),
  textAlign: 'center',
  color: '#C6AB70',
  opacity: 0.9,
  fontStyle: 'italic',
  fontSize: '0.85rem',
  letterSpacing: '0.02em',
  padding: theme.spacing(1.5, 0),
  borderTop: '1px solid rgba(198, 171, 112, 0.2)',
}));

const ContentAnimation = styled(Box)(({ theme }) => ({
  animation: 'fadeInUp 0.6s ease-out',
  '@keyframes fadeInUp': {
    from: {
      opacity: 0,
      transform: 'translateY(20px)'
    },
    to: {
      opacity: 1,
      transform: 'translateY(0)'
    }
  }
}));

const TreasureVaultModal = ({ open, onClose }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      maxWidth="md"
      fullWidth
      fullScreen={isMobile}
      PaperProps={{
        sx: {
          borderRadius: isMobile ? 0 : 2,
          background: `url(${TextureBg})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          m: isMobile ? 0 : 2,
          height: isMobile ? '100%' : 'auto'
        }
      }}
    >
      <CloseButton 
        onClose={onClose} 
        sx={{
          position: 'absolute',
          color: '#C6AB70',
          top: isMobile ? 8 : 16,
          right: isMobile ? 8 : 16,
          zIndex: 3,
          cursor: 'pointer',
          transition: 'all 0.3s ease',
          '&:hover': {
            color: '#fff',
            transform: 'scale(1.1)'
          }
        }}
      />
      <DialogContent 
        sx={{ 
          position: 'relative',
          backgroundColor: 'transparent',
          overflow: 'auto',
          height: '100%',
          WebkitOverflowScrolling: 'touch',
          /* Hide scrollbar for all browsers */
          scrollbarWidth: 'none', /* Firefox */
          '&::-webkit-scrollbar': { /* Chrome, Safari, Edge */
            display: 'none',
            width: 0,
            height: 0,
            background: 'transparent',
          },
          msOverflowStyle: 'none', /* IE 10+ */
          '& .MuiBox-root': {
            position: 'relative',
            zIndex: 2,
            [theme.breakpoints.down('sm')]: {
              minHeight: '101%',
            }
          }
        }}
      >
        <ContentAnimation sx={{ 
          position: 'relative', 
          zIndex: 2,
          backgroundColor: 'transparent',
          [theme.breakpoints.down('sm')]: {
            paddingBottom: '44px',
          }
        }}>
          <VaultContent>
            <GlowingText variant="h4" sx={{ mb: 2 }}>
            Welcome to Something Told
            </GlowingText>
            
            <Typography 
              variant="h6" 
              sx={{ 
                mb: 2,
                opacity: 0.9,
                textAlign: isMobile ? 'center' : 'left',
                fontSize: isMobile ? '1.1rem' : '1.25rem',
                color: '#C6AB70',
                letterSpacing: '0.02em',
              }}
            >
              Every Treasure Has a Tale
            </Typography>

            <Typography 
              variant="body1" 
              sx={{ 
                opacity: 0.8, 
                lineHeight: 1.8,
                textAlign: isMobile ? 'center' : 'left',
                fontSize: isMobile ? '0.95rem' : '1rem'
              }}
            >
The objects we cherish hold more than memories—they hold the stories of our lives. A grandfather's pocket watch that marked history, a mother's recipe book filled with love, a handwritten letter that changed everything. These treasures deserve a home where their stories can live on.
            </Typography>

            <StorySection>
              <FeatureBox>
                <Box sx={{ 
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: 32,
                  height: 32,
                  borderRadius: '2px',
                  backgroundColor: 'rgba(198, 171, 112, 0.1)',
                  border: '1px solid rgba(198, 171, 112, 0.2)',
                  flexShrink: 0,
                }}>
                  <AutoStoriesOutlined />
                </Box>
                <Box sx={{ flex: 1 }}>
                  <Typography variant="h6" sx={{ 
                    mb: 0.5,
                    color: '#C6AB70',
                    textShadow: '0 0 8px rgba(198, 171, 112, 0.2)',
                    letterSpacing: '0.03em',
                    fontWeight: 500,
                    fontSize: '0.95rem',
                  }}>
                    Preserve Your Legacy
                  </Typography>
                  <Typography variant="body2" sx={{ 
                    opacity: 1,
                    lineHeight: 1.5,
                    fontSize: '0.85rem',
                    color: 'rgba(255,255,255,0.9)',
                    letterSpacing: '0.02em',
                  }}>
                    Transform cherished items into lasting digital keepsakes. Each story you share becomes a chapter in your personal or family's ongoing narrative, preserved for generations to come.
                  </Typography>
                </Box>
              </FeatureBox>

              <FeatureBox>
                <Box sx={{ 
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: 32,
                  height: 32,
                  borderRadius: '2px',
                  backgroundColor: 'rgba(198, 171, 112, 0.1)',
                  border: '1px solid rgba(198, 171, 112, 0.2)',
                  flexShrink: 0,
                }}>
                  <Diversity1Icon />
                </Box>
                <Box sx={{ flex: 1 }}>
                  <Typography variant="h6" sx={{ 
                    mb: 0.5,
                    color: '#C6AB70',
                    textShadow: '0 0 8px rgba(198, 171, 112, 0.2)',
                    letterSpacing: '0.03em',
                    fontWeight: 500,
                    fontSize: '0.95rem',
                  }}>
                    Connect Through Community
                  </Typography>
                  <Typography variant="body2" sx={{ 
                    opacity: 1,
                    lineHeight: 1.5,
                    fontSize: '0.85rem',
                    color: 'rgba(255,255,255,0.9)',
                    letterSpacing: '0.02em',
                  }}>
                    Join a like-hearted community of Sentimentalists that values the beauty in personal histories. Through shared stories, events, and meaningful connections, we celebrate the significance behind the objects we hold dear.
                  </Typography>
                </Box>
              </FeatureBox>

              <FeatureBox>
                <Box sx={{ 
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: 32,
                  height: 32,
                  borderRadius: '2px',
                  backgroundColor: 'rgba(198, 171, 112, 0.1)',
                  border: '1px solid rgba(198, 171, 112, 0.2)',
                  flexShrink: 0,
                }}>
                  <Timeline />
                </Box>
                <Box sx={{ flex: 1 }}>
                  <Typography variant="h6" sx={{ 
                    mb: 0.5,
                    color: '#C6AB70',
                    textShadow: '0 0 8px rgba(198, 171, 112, 0.2)',
                    letterSpacing: '0.03em',
                    fontWeight: 500,
                    fontSize: '0.95rem',
                  }}>
                    Build Your Treasure Chest
                  </Typography>
                  <Typography variant="body2" sx={{ 
                    opacity: 1,
                    lineHeight: 1.5,
                    fontSize: '0.85rem',
                    color: 'rgba(255,255,255,0.9)',
                    letterSpacing: '0.02em',
                  }}>
                    Our intuitive platform makes it easy to attach narratives, photos, and reflections to the things that matter most—keeping their meaning intact, even as they change hands.
                  </Typography>
                </Box>
              </FeatureBox>
            </StorySection>

            <QuoteSection>
              <Typography 
                variant="body1" 
                sx={{ 
                  fontStyle: 'italic', 
                  color: 'rgba(255, 255, 255, 0.95)',
                  maxWidth: '800px',
                  margin: '0 auto',
                  fontSize: { xs: '0.9rem', sm: '0.95rem', md: '1rem' },
                  lineHeight: 1.8,
                  letterSpacing: '0.02em',
                }}
              >
                Unlike traditional keepsake boxes or digital albums, Something Told is designed to 
                deepen the emotional connection to your treasures, making sure every cherished item serves as a 
                gateway to its past. With Something Told, your most meaningful possessions don't 
                just sit on a shelf—they come to life through the stories they tell. Watch as memories weave 
                together, shaping a rich tapestry of your history, family, and life's journey.
              </Typography>
            </QuoteSection>

            <FooterText
              variant="body2" 
              sx={{ 
                fontSize: isMobile ? '0.85rem' : '0.875rem'
              }}
            >
              Beyond Storage, It's Storytelling
            </FooterText>
          </VaultContent>
        </ContentAnimation>
      </DialogContent>
    </Dialog>
  );
};

export default TreasureVaultModal; 