import { useState, useEffect, useRef } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent as MuiDialogContent,
  Typography,
  Box,
  Button,
  IconButton,
  styled,
  useTheme,
  useMediaQuery,
  CircularProgress,
  Tooltip,
  Avatar,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ShareIcon from '@mui/icons-material/Share';
import { Close } from '@mui/icons-material';
import { useToast } from '../contexts/ToastContext';
import { useParams, useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import api from '../services/api';
import ErrorBoundary from './ErrorBoundary';
import WelcomeModal from './WelcomeModal';
import Logo from './common/Logo';
import InstagramStyleVideoPlayer from './common/InstagramStyleVideoPlayer';
import LogoIcon from '../assets/ST_Logo_Gold-on-Green.svg';
import texture from '../assets/Texture.png';
import StoryDisplayBox from './common/StoryDisplayBox';

// Reuse styled components from TreasureDetails
const ContentWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(3),
  padding: theme.spacing(0, 1),
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    gap: theme.spacing(2),
    padding: 0,
  }
}));

const Column = styled(Box)(({ theme }) => ({
  width: '50%',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  }
}));

const ImageContainer = styled(Box)(({ theme, isVideo }) => ({
  position: 'relative',
  width: '100%',
  paddingTop: isVideo ? 'auto' : '100%', // Only square for images
  borderRadius: theme.shape.borderRadius,
  overflow: 'hidden',
  backgroundColor: theme.palette.grey[50],
  border: `1px solid ${theme.palette.divider}`,
  marginBottom: theme.spacing(2),
  userSelect: 'none',
  WebkitTouchCallout: 'none',
  transition: 'transform 0.2s ease-in-out',
  ...(isVideo && {
    height: 'auto',
    maxHeight: '500px',  // Changed from 420px to 600px
    paddingTop: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }),
  ...(!isVideo && {
    '&:hover': {
      '& img': {
        transform: 'scale(1.05)',
        transition: 'transform 0.3s ease'
      },
      '&::after': {
        opacity: 1
      }
    },
    '&::after': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      background: 'linear-gradient(180deg, rgba(0,0,0,0.02) 0%, rgba(0,0,0,0.1) 100%)',
      opacity: 0,
      transition: 'opacity 0.3s ease',
      zIndex: 2,
      pointerEvents: 'none'
    }
  })
}));

const DetailBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  border: `1px solid ${theme.palette.divider}`
}));

// Same mobile-friendly dialog content styling from TreasureDetails
const DialogContentMobile = styled(MuiDialogContent)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2),
  }
}));

const AuthorContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  margin: '0 auto 12px', // Center horizontally
  width: 'fit-content', // Prevent full width
  transition: 'transform 0.2s ease-in-out',
  '&:hover': {
    transform: 'scale(1.01)'
  }
});

const AuthorAvatar = styled(Avatar)(({ theme }) => ({
  width: 36,
  height: 36,
  [theme.breakpoints.up('sm')]: {
    width: 40,
    height: 40
  },
  border: '2px solid white'
}));

const TreasureStandalone = ({ open, treasureId, treasure: initialTreasure, onClose }) => {
  const { id } = useParams() || { id: treasureId };
  const isDirectAccess = !treasureId; // If no treasureId prop, it's direct URL access
  const [treasure, setTreasure] = useState(initialTreasure || null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { showToast } = useToast();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { currentUser } = useAuth();
  const [showWelcome, setShowWelcome] = useState(false);
  const [dashboardLoaded, setDashboardLoaded] = useState(false);

  // Determine if we should show close buttons
  const showCloseButtons = !isDirectAccess || (isDirectAccess && currentUser);
  
  // Determine if dialog can be closed by escape/backdrop
  const canClose = !isDirectAccess || (isDirectAccess && currentUser);

  // Preload dashboard if direct access and user is logged in
  useEffect(() => {
    if (isDirectAccess && currentUser && !dashboardLoaded) {
      setDashboardLoaded(true);
    }
  }, [isDirectAccess, currentUser, dashboardLoaded]);

  const handleClose = () => {
    if (!canClose) {
      return; // Do nothing if we can't close
    } else if (isDirectAccess && currentUser) {
      // If dashboard is preloaded, just remove the standalone
      if (currentUser && dashboardLoaded) {
        navigate('/treasurechest', { replace: true });
      } else {
        navigate('/treasurechest');
      }
    } else if (!isDirectAccess) {
      onClose();
    }
  };

  // Fetch or set up the treasure
  useEffect(() => {
    if (initialTreasure) {
      setTreasure(initialTreasure);
      setLoading(false);
      return;
    }
    
    const fetchTreasure = async () => {
      try {
        setLoading(true);
        setError(null);
        
        console.log('TreasureStandalone: Starting fetch for treasure:', id);
        console.log('Current location:', window.location.pathname);
        console.log('Auth state:', !!currentUser);
        
        const response = await api.get(`/api/treasures/shared/${id}`);

        console.log('TreasureStandalone: Response received:', response.data);
        setTreasure(response.data);
      } catch (error) {
        console.log('TreasureStandalone: Error details:', {
          message: error.message,
          status: error.response?.status,
          data: error.response?.data,
          stack: error.stack
        });
        setError(error.response?.data?.message || 'Failed to load treasure');
        if (error.response?.status !== 401) {
          showToast('Treasure not found', 'error');
          navigate('/treasurechest');
        }
      } finally {
        setLoading(false);
      }
    };

    if (id) {
      fetchTreasure();
    }
  }, [id, navigate, showToast, currentUser, initialTreasure]);

  // Show a welcome modal after a delay if user is not logged in
  useEffect(() => {
    if (!currentUser) {
      const timer = setTimeout(() => {
        // Store redirect info before showing welcome modal
        localStorage.setItem('treasureRedirect', JSON.stringify({
          id,
          url: window.location.pathname,
          timestamp: Date.now()
        }));
        setShowWelcome(true);
      }, 2000);
      
      return () => clearTimeout(timer);
    }
  }, [currentUser, id]);

  const handleShare = async () => {
    if (!treasure?.id) return;
    const shareUrl = `${window.location.origin}/treasurechest/treasure/${treasure.id}`;
    
    try {
      if (navigator.clipboard && typeof navigator.clipboard.writeText === 'function') {
        await navigator.clipboard.writeText(shareUrl);
        showToast('Link copied to clipboard!', 'success', 3000);
      } else {
        const textArea = document.createElement('textarea');
        textArea.value = shareUrl;
        document.body.appendChild(textArea);
        textArea.select();
        try {
          document.execCommand('copy');
          showToast('Link copied to clipboard!', 'success', 3000);
        } catch (err) {
          showToast('Failed to copy link. Please try again.', 'error');
        }
        document.body.removeChild(textArea);
      }
    } catch (error) {
      console.error('Error copying to clipboard:', error);
      showToast('Failed to copy link. Please try again.', 'error');
    }
  };

  // Calculate dynamic sizes based on content and viewport
  const calculateSizes = () => {
    const viewportWidth = window.innerWidth;
    const titleLength = treasure?.name?.length || 0;
    
    if (viewportWidth < 600) { // mobile
      const isVideoLandscape = treasure?.is_video && viewportWidth > 400;

      if (isVideoLandscape) {
        if (titleLength > 15) return { logo: '1rem', text: '0.8rem' };
        if (titleLength > 10) return { logo: '1.2rem', text: '0.9rem' };
        return { logo: '2.2rem', text: '1rem' };
      }

      // Longer title = smaller sizes
      if (titleLength > 30) {
        return { logo: '2.2rem', text: '0.8rem' };
      } else if (titleLength > 20) {
        return { logo: '2.2rem', text: '0.9rem' };
      }
      return { logo: '2.2rem', text: '1rem' };
    }
    
    return { logo: '2.2rem', text: '1.25rem' }; // desktop defaults
  };

  const sizes = calculateSizes();

  if (loading) {
    return (
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        height: '100vh',
        flexDirection: 'column',
        gap: 2
      }}>
        <CircularProgress />
        <Typography color="text.secondary">Loading treasure...</Typography>
      </Box>
    );
  }

  if (error && !treasure) {
    return (
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        height: '100vh',
        flexDirection: 'column',
        gap: 2,
        p: 3,
        textAlign: 'center'
      }}>
        <Typography variant="h6" color="error">
          {error}
        </Typography>
        <Button 
          variant="contained" 
          onClick={() => navigate('/treasurechest')}
        >
          Go to Dashboard
        </Button>
      </Box>
    );
  }

  if (!treasure) {
    return (
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        height: '100vh',
        p: 3,
        textAlign: 'center'
      }}>
        <Typography>No treasure found</Typography>
      </Box>
    );
  }

  return (
    <ErrorBoundary>
      <Box sx={{ 
        minHeight: '100vh',
        bgcolor: 'transparent',
        p: isDirectAccess ? 3 : 0,
        position: 'relative',
        zIndex: 1
      }}>
        <Dialog
          open={true}
          onClose={handleClose}
          maxWidth="md"
          fullWidth
          fullScreen={isMobile}
          PaperProps={{
            sx: {
              maxHeight: { sm: '90vh' },
              height: isMobile ? '100%' : 'auto'
            }
          }}
          onBackdropClick={canClose ? handleClose : undefined}
        >
          <DialogTitle 
            sx={{ 
              p: { xs: 2, sm: 3 }, 
              borderBottom: '1px solid', 
              borderColor: 'divider',
              position: 'sticky',
              top: 0,
              bgcolor: 'background.paper',
              zIndex: 1
            }}
          >
            <Box sx={{ 
              display: 'flex', 
              flexDirection: { xs: 'column', sm: 'row' },
              alignItems: { xs: 'flex-start', sm: 'center' }, 
              justifyContent: 'space-between',
            }}>
              <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                {showCloseButtons && (
                  <IconButton
                    edge="start"
                    onClick={handleClose}
                    aria-label="back"
                    sx={{ 
                      mr: 1,
                      display: { xs: 'flex', sm: 'none' }
                    }}
                  >
                    <ArrowBackIcon />
                  </IconButton>
                )}
                <Box sx={{ 
                  display: 'flex', 
                  alignItems: 'center',
                  flex: 1,
                  width: '100%',
                  justifyContent: { xs: 'flex-start', sm: 'flex-start' }
                }}>
                  <img
                    src={LogoIcon}
                    alt="Something Told Logo"
                    style={{
                      width: sizes.logo,
                      height: sizes.logo,
                    }}
                  />
                  <Box 
                    sx={{ 
                      width: '1px', 
                      height: 24, 
                      bgcolor: 'divider', 
                      mx: 1,
                      opacity: 0.2 
                    }} 
                  />
                  <Typography 
                    variant="h6" 
                    component="div" 
                    noWrap
                    sx={{ 
                      fontWeight: 400,
                      fontSize: sizes.text,
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      flex: 1,
                      minWidth: 0
                    }}
                  >
                    {treasure?.name
                    ? treasure.name.charAt(0).toUpperCase() + treasure.name.slice(1)
                    : ''}
                  </Typography>
                </Box>
                {showCloseButtons && (
                  <Box sx={{ display: { xs: 'none', sm: 'flex' } }}>
                    <IconButton 
                      onClick={handleClose}
                      aria-label="close"
                      sx={{ ml: 1 }}
                    >
                      <Close />
                    </IconButton>
                  </Box>
                )}
              </Box>
            </Box>
          </DialogTitle>

          <DialogContentMobile 
            sx={{ 
              p: { 
                xs: 2, 
                sm: 3 
              },
              '&.MuiDialogContent-root': {
                overflowY: 'auto',
                display: 'flex',
                flexDirection: 'column'
              }
            }}
          >
            <ContentWrapper>
              <Column>
                <ImageContainer isVideo={treasure?.is_video}>
                  <Box
                    sx={{
                      position: 'absolute',
                      top: 8,
                      right: 8,
                      display: 'inline-flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      padding: '2px 8px',
                      borderRadius: '16px',
                      fontSize: '0.6rem',
                      fontWeight: 500,
                      textTransform: 'uppercase',
                      backgroundColor: 'rgba(0, 0, 0, 0.3)',
                      color: 'rgba(255, 255, 255, 0.9)',
                      letterSpacing: '0.5px',
                      backdropFilter: 'blur(8px)',
                      border: '1px solid rgba(255, 255, 255, 0.2)',
                      boxShadow: '0 2px 8px rgba(0, 0, 0, 0.2)',
                      height: '22px',
                      zIndex: 3
                    }}
                  >
                    {treasure?.category}
                  </Box>
                  {treasure?.is_video ? (
                    <InstagramStyleVideoPlayer src={treasure.image_url} />
                  ) : (
                    <Box
                      component="img"
                      src={treasure?.image_url}
                      alt={treasure?.name}
                      draggable="false"
                      style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        objectPosition: 'center',
                        transition: 'transform 0.3s ease',
                        zIndex: 1,
                        userSelect: 'none',
                        WebkitUserDrag: 'none',
                        WebkitTouchCallout: 'none',
                        pointerEvents: 'none'
                      }}
                      onContextMenu={(e) => e.preventDefault()}
                    />
                  )}
                </ImageContainer>

                <DetailBox>
                  <Box 
                    sx={{ 
                      backgroundImage: `url(${texture})`,
                      backgroundSize: 'cover',
                      backgroundPosition: 'center',
                      borderRadius: '8px',
                      boxShadow: '0 1px 20px rgba(122, 118, 118, 0.39)',
                      p: 2,
                      mb: 0,
                      color: 'common.white'
                    }}
                  >
                    <AuthorContainer>
                      <AuthorAvatar 
                        src={treasure?.author_photo} 
                        alt={treasure?.author_first_name}
                        sx={{
                          width: 48,
                          height: 48,
                          border: '0px solid white',
                          [theme.breakpoints.up('sm')]: {
                            width: 48,
                            height: 48
                          }
                        }}
                      />
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography 
                          variant="body2" 
                          color="common.white"
                          sx={{ 
                            opacity: 0.6,
                            fontStyle: 'italic',
                            fontSize: '0.75rem',
                            mr: 0.5
                          }}
                        >
                          By:
                        </Typography>
                        <Typography 
                          variant="body1" 
                          sx={{ 
                            fontWeight: 500,
                            fontSize: '0.875rem',
                            color: 'common.white'
                          }}
                        >
                          {treasure?.author_first_name 
                            ? treasure.author_first_name.charAt(0).toUpperCase() + treasure.author_first_name.slice(1)
                            : ''}
                        </Typography>
                      </Box>
                    </AuthorContainer>

                    <Box sx={{ 
                      display: 'flex', 
                      justifyContent: 'center',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                      gap: 1,
                      flexDirection: { xs: 'column', sm: 'row' }
                    }}>
                      <Box sx={{ 
                        display: 'flex', 
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}>
                        <Typography 
                          variant="body2" 
                          color="common.white"
                          sx={{ 
                            opacity: 0.6,
                            fontStyle: 'italic',
                            fontSize: '0.875rem',
                            mr: 0.5
                          }}
                        >
                          From:
                        </Typography>
                        <Typography 
                          variant="body1" 
                          sx={{ 
                            fontWeight: 500,
                            fontSize: '0.875rem',
                            color: 'common.white'
                          }}
                        >
                          {treasure?.from_person
                          ? treasure.from_person.charAt(0).toUpperCase() + treasure.from_person.slice(1)
                          : ''}
                        </Typography>
                      </Box>
                      
                      <Box 
                        sx={{ 
                          height: '16px',
                          opacity: 0.3,
                          width: '1px',
                          bgcolor: 'rgba(255, 255, 255, 0.3)',
                          mx: 2,
                          display: { xs: 'none', sm: 'block' }
                        }}
                      />
                      
                      <Box sx={{ 
                        display: 'flex', 
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}>
                        <Typography 
                          variant="body2" 
                          color="common.white"
                          sx={{ 
                            opacity: 0.6,
                            fontStyle: 'italic',
                            fontSize: '0.875rem',
                            mr: 0.5
                          }}
                        >
                          Received:
                        </Typography>
                        <Typography 
                          variant="body1" 
                          sx={{ 
                            fontWeight: 500,
                            fontSize: '0.875rem',
                            color: 'common.white'
                          }}
                        >
                          {treasure?.date_acquired ? new Date(treasure.date_acquired + 'T00:00:00').toLocaleDateString('en-US', {
                            month: 'long',
                            day: 'numeric',
                            year: 'numeric',
                            timeZone: 'UTC'
                          }) : 'Not specified'}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Box sx={{ 
                    display: 'flex', 
                    gap: 1, 
                    mt: 2,
                    borderTop: '1px solid',
                    borderColor: 'divider',
                    pt: 2
                  }}>
                    <Button
                      variant="outlined"
                      startIcon={<ShareIcon />}
                      onClick={handleShare}
                      fullWidth
                      sx={{ 
                        flex: { xs: '1 1 100%', sm: 1 }
                      }}
                    >
                      Share
                    </Button>
                  </Box>
                </DetailBox>
              </Column>

              {/* Right Column - Story */}
              <Column>
                <StoryDisplayBox story={treasure?.story} />
              </Column>
            </ContentWrapper>
          </DialogContentMobile>
        </Dialog>
        <WelcomeModal 
          open={showWelcome} 
          onClose={() => setShowWelcome(false)}
        />
      </Box>
    </ErrorBoundary>
  );
};

export default TreasureStandalone;