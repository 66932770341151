import React from 'react';
import {
  Box,
  Link,
  Stack,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import SomethingToldTexture from '../../assets/SomethingTold_Texture.png';
import Footer from '../../publicPages/components/common/Footer';

const FooterLink = styled(Link)(({ theme }) => ({
  color: '#CCAB62',
  textDecoration: 'none',
  fontSize: '0.875rem',
  opacity: 0.8,
  transition: 'opacity 0.2s',
  '&:hover': {
    opacity: 1,
    textDecoration: 'none',
  },
}));

const SocialIcon = styled(Link)(({ theme }) => ({
  color: '#CCAB62',
  opacity: 0.8,
  transition: 'opacity 0.2s',
  '&:hover': {
    opacity: 1,
    color: '#CCAB62',
  },
  '& svg': {
    fontSize: '1.25rem',
  },
}));

const AuthLayout = ({ children }) => {
  return (
    <Box
      sx={{
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        backgroundImage: `url(${SomethingToldTexture})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <Box 
        sx={{
          flex: '1 0 auto',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          position: 'relative',
          zIndex: 1,
        }}
      >
        {children}
      </Box>

      {/* Replace hardcoded footer with Footer component */}
      <Box sx={{ 
        backgroundColor: 'transparent',
        position: { xs: 'relative', md: 'absolute' },
        bottom: 0,
        width: '100%',
        mt: { xs: 4, md: 0 }
      }}>
        <Footer />
      </Box>
    </Box>
  );
};

export default AuthLayout; 