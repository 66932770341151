import React, { useState, useEffect, useCallback, useRef } from 'react';
import { styled } from '@mui/material/styles';
import {
  Box,
  Typography,
  InputBase,
  Paper,
  IconButton,
  Grid,
  Chip,
  Container,
  Card,
  CardContent,
  CardMedia,
  Menu,
  MenuItem,
  Button,
  useMediaQuery,
  useTheme,
  Skeleton,
} from '@mui/material';
import {
  Search as SearchIcon,
  TuneRounded as FilterIcon,
  Favorite,
  LocationOn,
  AccessTime,
  Inventory as TreasureIcon,
  ViewModule,
  ViewQuilt,
  ViewCompact,
  PlayArrow,
} from '@mui/icons-material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { TreasureCardSkeleton } from './skeletons/ContentSkeletons';
import api from '../services/api';
import { getAuth } from 'firebase/auth';
import { formatDistanceToNow } from 'date-fns';
import SearchBar from './common/SearchBar';
import { debounce } from 'lodash';
import TreasureStandalone from './TreasureStandalone';
import GridExploreNew from './GridExploreNew';
import ProgressiveImage from './ProgressiveImage';
import { alpha } from '@mui/material/styles';
import GridExplore from './GridExplore';

// Add these constants back
const VIEW_MODE_KEY = 'treasureChest_viewMode';
const DEFAULT_VIEW = 'card';

const SearchWrapper = styled(Paper)(({ theme }) => ({
  padding: '2px 4px',
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.paper,
  boxShadow: 'none',
  border: `1px solid ${theme.palette.divider}`,
}));

const CategoryChip = styled(Chip)(({ theme }) => ({
  borderRadius: '16px',
  '&.MuiChip-outlined': {
    borderColor: theme.palette.divider,
  },
  '&.MuiChip-clickable:hover': {
    backgroundColor: theme.palette.grey[100],
  },
  '&.Mui-selected, &.MuiChip-colorPrimary': {
    backgroundColor: theme.palette.action.selected,
    color: theme.palette.text.primary,
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    }
  }
}));

const TreasureCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  borderRadius: theme.shape.borderRadius,
  overflow: 'hidden',
  boxShadow: 'none',
  border: `1px solid ${theme.palette.divider}`,
  '& .card-content': {
    transform: 'translateY(0)',
    transition: 'transform 0.2s ease-in-out',
  },
  '&:hover .card-content': {
    transform: 'translateY(-4px)',
  },
  '& .card-footer': {
    transform: 'translateY(0)', // Footer stays in place
  }
}));

// Remove the duplicate TreasureCardSkeleton and add ImageSkeleton instead
const ImageSkeleton = styled(Skeleton)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  transform: 'none',
  transformOrigin: '0 0',
  '&::after': {
    background: `linear-gradient(
      90deg,
      transparent,
      ${alpha(theme.palette.background.paper, 0.4)},
      transparent
    )`,
  },
}));

const CategoryDropdown = styled(Button)(({ theme }) => ({
  width: '100%',
  justifyContent: 'space-between',
  padding: theme.spacing(1.5),
  color: theme.palette.text.primary,
  backgroundColor: theme.palette.background.paper,
  border: `1px solid ${theme.palette.divider}`,
  textTransform: 'none',
  '&:hover': {
    backgroundColor: theme.palette.background.paper,
    borderColor: theme.palette.text.primary,
  },
}));

// Add this styled component for the heart animation
const AnimatedHeart = styled(Favorite)(({ theme, isLiked }) => ({
  fontSize: '1.2rem',
  color: isLiked ? theme.palette.error.main : theme.palette.text.secondary,
  transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
  transform: 'scale(1)',
  '&:hover': {
    transform: 'scale(1.1)',
  },
  '&.like-animation': {
    animation: 'likeAnimation 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
  },
  '@keyframes likeAnimation': {
    '0%': {
      transform: 'scale(1)',
    },
    '50%': {
      transform: 'scale(1.3)',
    },
    '100%': {
      transform: 'scale(1)',
    },
  },
}));

// Update the TouchTargetWrapper styling
const TouchTargetWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: 0.5,
  cursor: 'pointer',
  position: 'relative',
  '&:active': {
    transform: 'scale(0.95)',
  }
}));

const categories = [
  "All Items",
  "Jewelry & Watches",
  "Art & Collectibles",
  "Books & Manuscripts",
  "Clothing & Accessories",
  "Electronics & Gadgets",
  "Family Heirlooms",
  "Furniture & Decor",
  "Musical Instruments",
  "Photography",
  "Sports Memorabilia",
  "Toys & Games",
  "Other"
];

const treasureItems = [
  {
    id: 1,
    title: "Victorian Pearl Brooch",
    category: "Jewelry & Watches",
    description: "Exquisite Victorian-era brooch featuring natural pearls and rose-cut diamonds in a floral design.",
    location: "Boston, MA",
    timeAgo: "2 days ago",
    likes: 156,
    imageUrl: "https://images.unsplash.com/photo-1602173574767-37ac01994b2a"
  },
  {
    id: 2,
    title: "Vintage Pocket Watch",
    category: "Jewelry & Watches",
    description: "1890s Swiss-made pocket watch with intricate gold engraving and original chain. Features a rare moonphase complication.",
    location: "Chicago, IL",
    timeAgo: "5 days ago",
    likes: 89,
    imageUrl: "https://images.unsplash.com/photo-1509048191080-d2984bad6ae5"
  },
  {
    id: 3,
    title: "Sapphire and Diamond Bracelet",
    category: "Jewelry & Watches",
    description: "Edwardian-era bracelet featuring Ceylon sapphires and old-mine cut diamonds set in platinum, circa 1910.",
    location: "New York, NY",
    timeAgo: "1 week ago",
    likes: 234,
    imageUrl: "https://images.unsplash.com/photo-1599643477877-530eb83abc8e"
  },
  {
    id: 4,
    title: "Art Deco Diamond Ring",
    category: "Jewelry & Watches",
    description: "Stunning 1920s Art Deco engagement ring with original certification and family documentation.",
    location: "San Francisco, CA",
    timeAgo: "3 days ago",
    likes: 312,
    imageUrl: "https://images.unsplash.com/photo-1605100804763-247f67b3557e"
  },
  {
    id: 5,
    title: "Art Nouveau Vase",
    category: "Art & Collectibles",
    description: "Stunning Émile Gallé cameo glass vase from 1900, featuring delicate floral motifs in purple and green overlays.",
    location: "Seattle, WA",
    timeAgo: "1 day ago",
    likes: 178,
    imageUrl: "https://images.unsplash.com/photo-1578500494198-246f612d3b3d"
  },
  {
    id: 6,
    title: "Emerald Family Ring",
    category: "Jewelry & Watches",
    description: "Victorian emerald and diamond cluster ring passed down through five generations, with original documentation from 1875.",
    location: "Los Angeles, CA",
    timeAgo: "4 days ago",
    likes: 245,
    imageUrl: "https://images.unsplash.com/photo-1603561591411-07134e71a2a9"
  },
  {
    id: 7,
    title: "Antique Cameo Collection",
    category: "Jewelry & Watches",
    description: "Set of three Italian carved shell cameos in original gilt frames, featuring classical profiles and mythological scenes.",
    location: "Philadelphia, PA",
    timeAgo: "6 days ago",
    likes: 167,
    imageUrl: "https://images.unsplash.com/photo-1611085583191-a3b181a88401"
  },
  {
    id: 8,
    title: "Ruby and Pearl Choker",
    category: "Jewelry & Watches",
    description: "Belle Époque ruby and natural pearl choker necklace, formerly owned by European nobility, circa 1895.",
    location: "Miami, FL",
    timeAgo: "2 weeks ago",
    likes: 289,
    imageUrl: "https://images.unsplash.com/photo-1515562141207-7a88fb7ce338"
  }
];

const formatCreatedAt = (timestamp) => {
  try {
    if (!timestamp) return 'Recently';
    
    // Convert UTC timestamp to local time
    const utcDate = new Date(timestamp.replace(' ', 'T'));
    // Adjust for timezone offset
    const localDate = new Date(utcDate.getTime() - (utcDate.getTimezoneOffset() * 60000));
    
    return formatDistanceToNow(localDate, { 
      addSuffix: true,
      includeSeconds: false,
      roundingMethod: 'floor'
    }).replace('about ', '');
  } catch (error) {
    console.error('Date formatting error:', error);
    return 'Recently';
  }
};

const Explore = () => {
  const [treasures, setTreasures] = useState([]);
  const [loading, setLoading] = useState(true);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState("All Items");
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [prefetchedData, setPrefetchedData] = useState(null);
  const [prefetchController, setPrefetchController] = useState(null);
  const observerTarget = useRef(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [searching, setSearching] = useState(false);
  const [recentSearches, setRecentSearches] = useState([]);
  const [isSearchFocused, setIsSearchFocused] = useState(false);
  const [selectedTreasure, setSelectedTreasure] = useState(null);
  const [viewport] = useState(() => {
    const width = window.innerWidth;
    if (width < 600) return 'mobile';
    if (width < 960) return 'tablet';
    return 'desktop';
  });
  const [viewMode, setViewMode] = useState(() => {
    const savedMode = localStorage.getItem(VIEW_MODE_KEY);
    // Convert old 'grid' mode to 'progressive'
    return savedMode === 'grid' ? 'progressive' : (savedMode || DEFAULT_VIEW);
  });
  
  // Handler for view mode changes
  const handleViewModeChange = (mode) => {
    setViewMode(mode);
    localStorage.setItem(VIEW_MODE_KEY, mode);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (category) => {
    if (typeof category === 'string') {
      setSelectedCategory(category);
      setSearchQuery('');
      setRecentSearches([]);
      fetchTreasures(1, category, '');
    }
    setAnchorEl(null);
  };

  // Function to prefetch next page
  const prefetchNextPage = useCallback(async (pageNum) => {
    try {
      // Cancel any existing prefetch request
      if (prefetchController) {
        prefetchController.abort();
      }
      
      // Don't prefetch if we know there's no more data
      if (!hasMore) return;

      const controller = new AbortController();
      setPrefetchController(controller);

      const auth = getAuth();
      const token = await auth.currentUser.getIdToken();

      const categoryParam = selectedCategory && selectedCategory !== "All Items" 
        ? `&category=${encodeURIComponent(selectedCategory)}` 
        : '';
      const searchParam = searchQuery ? `&query=${encodeURIComponent(searchQuery)}` : '';
      
      const url = `/api/treasures/public-treasures?page=${pageNum}${categoryParam}${searchParam}`;
      
      const { data } = await api.get(url);

      setPrefetchedData(data);
    } catch (err) {
      if (!api.isCancel(err)) {
        console.error('Error prefetching treasures:', err);
      }
    }
  }, [hasMore, selectedCategory, searchQuery]);

  const fetchTreasures = useCallback(async (pageNum = 1, category = selectedCategory, query = searchQuery) => {
    try {
      // If we're fetching the next page and have prefetched data, use it
      if (pageNum > 1 && prefetchedData && !category && !query) {
        setTreasures(prev => [...prev, ...prefetchedData.treasures]);
        setHasMore(prefetchedData.hasMore);
        setPrefetchedData(null);
        
        // Start prefetching the next page if there's more
        if (prefetchedData.hasMore) {
          prefetchNextPage(pageNum + 1);
        }
        return;
      }

      const auth = getAuth();
      const token = await auth.currentUser.getIdToken();
      
      const categoryParam = category && category !== "All Items" 
        ? `&category=${encodeURIComponent(category)}` 
        : '';
      const searchParam = query ? `&query=${encodeURIComponent(query)}` : '';
      
      const url = `/api/treasures/public-treasures?page=${pageNum}${categoryParam}${searchParam}`;
      
      const { data } = await api.get(url);

      // Ensure we have an array even if response is empty or undefined
      const newTreasures = Array.isArray(data?.treasures) ? data.treasures : [];
      
      if (pageNum === 1) {
        setTreasures(newTreasures);
      } else {
        setTreasures(prev => [...(Array.isArray(prev) ? prev : []), ...newTreasures]);
      }
      
      setHasMore(data.hasMore);
      setError(null);

      // Start prefetching the next page if there's more
      if (data.hasMore) {
        prefetchNextPage(pageNum + 1);
      }
    } catch (err) {
      console.error('Error fetching public treasures:', err);
      setError('Failed to load treasures');
      setTreasures([]); // Ensure we have an empty array on error
    } finally {
      setLoading(false);
      setLoadingMore(false);
      setSearching(false);
    }
  }, [selectedCategory, searchQuery, prefetchedData, prefetchNextPage]);

  const handleSearch = useCallback(
    debounce((query) => {
      if (query.length === 0) {
        setSearchQuery('');
        setPage(1);
        fetchTreasures(1, selectedCategory, '');
        return;
      }
      
      if (query.length < 3) return;
      
      setSearchQuery(query);
      setPage(1);
      setSearching(true);
      fetchTreasures(1, selectedCategory, query);
      
      if (query.length >= 3) {
        setRecentSearches(prev => {
          const updated = [query, ...prev.filter(q => q !== query)];
          return updated.slice(0, 5);
        });
      }
    }, 1000),
    [fetchTreasures, selectedCategory]
  );

  // Clean up prefetch controller on unmount or category/search changes
  useEffect(() => {
    return () => {
      if (prefetchController) {
        prefetchController.abort();
      }
    };
  }, [selectedCategory, searchQuery]);

  // Initial load
  useEffect(() => {
    if (!loading) return;
    fetchTreasures(1);
  }, [fetchTreasures, loading]);

  // Infinite scroll
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && hasMore && !loading && !loadingMore) {
          setPage(prev => prev + 1);
          setLoadingMore(true);
          fetchTreasures(page + 1);
        }
      },
      { threshold: 0.1 }
    );

    const currentTarget = observerTarget.current;
    if (currentTarget) observer.observe(currentTarget);
    return () => {
      if (currentTarget) observer.unobserve(currentTarget);
    };
  }, [hasMore, loading, loadingMore, page, fetchTreasures]);

  const handleLikeClick = async (e, treasureId) => {
    e.preventDefault();
    e.stopPropagation();
    
    try {
      const { data } = await api.post(`/api/likes/${treasureId}/toggle`);

      // Update UI state
      setTreasures(prev => prev.map(t => 
        t.id === treasureId ? { 
          ...t, 
          like_count: data.likeCount,
          has_liked: data.hasLiked 
        } : t
      ));
    } catch (error) {
      console.error('Error toggling like:', error);
    }
  };

  const handleTreasureView = (e, treasure) => {
    e.preventDefault();
    e.stopPropagation();
    setSelectedTreasure(treasure);
  };

  const renderTreasures = () => {
    return treasures && treasures.map((treasure) => (
      <Grid item xs={12} sm={6} md={4} lg={3} key={treasure.id}>
        <TreasureCard>
          <Box
            sx={{
              position: 'relative',
              paddingTop: '100%',
              WebkitTouchCallout: 'none',
              overflow: 'hidden',
            }}
            onContextMenu={(e) => e.preventDefault()}
            onClick={(e) => handleTreasureView(e, treasure)}
          >
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                cursor: 'pointer',
                overflow: 'hidden',
              }}
            >
              <Box
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  cursor: 'pointer',
                  '&::after': {
                    content: '""',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    background: 'rgba(0, 0, 0, 0)',
                    transition: 'background 0.3s ease-in-out',
                  },
                  '&:hover::after': {
                    background: 'rgba(0, 0, 0, 0.1)',
                  }
                }}
              >
                <Box
                  component="img"
                  src={treasure.is_video ? treasure.video_thumbnail : treasure.image_url}
                  alt={treasure.name}
                  loading="lazy"
                  sx={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    cursor: 'pointer',
                  }}
                />
                {treasure.is_video && (
                  <Box
                    sx={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                      zIndex: 2,
                      width: '24px',
                      height: '24px',
                      borderRadius: '50%',
                      backgroundColor: 'rgba(0, 0, 0, 0.5)',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                  >
                    <PlayArrow sx={{ color: 'white', fontSize: '16px' }} />
                  </Box>
                )}
                <ProgressiveImage
                  src={treasure.is_video ? treasure.video_thumbnail : treasure.image_url}
                  alt={treasure.name}
                  style={{
                    WebkitUserDrag: 'none',
                    WebkitTouchCallout: 'none',
                    cursor: 'pointer',
                  }}
                  LoadingComponent={ImageSkeleton}
                />
              </Box>
            </Box>
          </Box>
          <CardContent 
            sx={{ 
              height: '200px',
              display: 'flex',
              flexDirection: 'column',
              p: '16px 16px 4px'
            }}
          >
            <Box 
              className="card-content"
              sx={{ 
                flex: 1,
                mb: 1.5
              }}
            >
              <Box
                sx={{ 
                  display: 'inline-flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  padding: '2px 8px',
                  borderRadius: '20px',
                  fontSize: '0.65rem',
                  fontWeight: 500,
                  textTransform: 'uppercase',
                  backgroundColor: 'rgba(17, 49, 27, 0.06)',
                  border: '1px solid rgba(17, 49, 27, 0.15)',
                  color: '#11311B',
                  margin: 0,
                  letterSpacing: '0.5px',
                  boxShadow: '0 1px 3px rgba(0,0,0,0.03)',
                  transition: 'all 0.2s ease',
                  marginBottom: theme.spacing(2),
                  minHeight: '24px',
                  width: 'fit-content',
                  '&:hover': {
                    backgroundColor: 'rgba(17, 49, 27, 0.08)',
                    boxShadow: '0 2px 4px rgba(0,0,0,0.05)',
                    transform: 'translateY(-1px)',
                  }
                }}
              >
                {treasure.category}
              </Box>
              <Typography 
                variant="h6"
                sx={{ 
                  mb: 1,
                  fontSize: '1.1rem',
                  fontWeight: 600,
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis'
                }}
              >
                {treasure.name}
              </Typography>
              <Typography 
                variant="body2" 
                color="text.secondary"
                sx={{ 
                  display: '-webkit-box',
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: 'vertical',
                  overflow: 'hidden',
                  minHeight: '40px'
                }}
              >
                {treasure.story || '\u00A0\n\u00A0'}
              </Typography>
            </Box>

            {/* Footer section - stays fixed */}
            <Box 
              className="card-footer"
              sx={{ 
                pt: 1.5,
                borderTop: '1px solid',
                borderColor: 'divider',
                display: 'flex', 
                alignItems: 'center',
                justifyContent: 'space-between',
                mt: 'auto',
              }}
            >
              {/* Location */}
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                {treasure.location !== 'Unknown Location' ? (
                  <>
                    <LocationOn sx={{ fontSize: '1rem', opacity: 0.7 }} />
                    <Typography 
                      variant="caption" 
                      color="text.secondary"
                      sx={{
                        maxWidth: '80px',  // Limit width
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        display: 'block'
                      }}
                    >
                      {treasure.location}
                    </Typography>
                  </>
                ) : (
                  <Box sx={{ width: '20px' }} />  // Placeholder when no location
                )}
              </Box>

              {/* Time */}
              <Box sx={{ 
                display: 'flex',
                alignItems: 'center',
                gap: 0.5
              }}>
                <AccessTime sx={{ fontSize: '1rem', opacity: 0.6 }} />
                <Typography 
                  variant="caption" 
                  color="text.secondary"
                  sx={{ 
                    opacity: 0.6,
                    fontStyle: 'italic',
                    fontSize: '0.75rem',
                    maxWidth: '90px',  // Limit width
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: 'block'
                  }}
                >
                  {formatCreatedAt(treasure.created_at)}
                </Typography>
              </Box>

              {/* Likes */}
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                <TouchTargetWrapper 
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent card click
                    handleLikeClick(e, treasure.id);
                  }}
                >
                  <AnimatedHeart 
                    isLiked={treasure.has_liked}
                    className={treasure.has_liked ? 'like-animation' : ''}
                  />
                  {Number(treasure.like_count) > 0 && (
                    <Typography 
                      variant="caption" 
                      color="text.secondary"
                      sx={{ ml: 0.5 }}
                    >
                      {treasure.like_count}
                    </Typography>
                  )}
                </TouchTargetWrapper>
              </Box>
            </Box>
          </CardContent>
        </TreasureCard>
      </Grid>
    ));
  };

  // In the render section, add array safety check
  const treasureArray = Array.isArray(treasures) ? treasures : [];
  
  return (
    <Container 
      maxWidth="lg" 
      sx={{ 
        py: 4, 
        pb: { xs: '70px', sm: 4 },
        px: { xs: '4px', sm: 3 }, // Minimal padding on mobile, normal on desktop
        '& .MuiContainer-root': {
          maxWidth: { xs: '100%', sm: 'lg' }
        }
      }}
    >
<Typography variant="h4" sx={{ fontWeight: 600, color: theme.palette.text.primary, mb: 3 }}>
        Explore Treasures
      </Typography>

      <Box sx={{ mb: 4 }}>
        <SearchBar
          placeholder="Search through all treasures..."
          onSearch={handleSearch}
          searching={searching}
          recentSearches={recentSearches}
          onClearRecentSearches={() => setRecentSearches([])}
          onSelectRecentSearch={(search) => {
            handleSearch(search);
          }}
          value={searchQuery}
          key={selectedCategory}
        />
      </Box>

      <Box>
        {isMobile || isTablet ? (
          <Box 
            sx={{ 
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              mb: 4,
              gap: 2
            }}
          >
            <Box sx={{ flex: 1 }}>
              <CategoryDropdown
                onClick={handleClick}
                endIcon={<KeyboardArrowDownIcon />}
                fullWidth
                sx={{
                  borderRadius: '16px',
                  border: '1px solid',
                  borderColor: 'divider',
                  p: '4px 12px',
                  height: '40px',
                  backgroundColor: 'background.paper',
                }}
              >
                {selectedCategory}
              </CategoryDropdown>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => handleClose()}
                PaperProps={{
                  sx: {
                    width: anchorEl?.offsetWidth || 'auto',
                    maxHeight: '60vh',
                    mt: 1,
                    borderRadius: '16px',
                    '& .MuiMenuItem-root': {
                      py: 1.5,
                    }
                  }
                }}
              >
                {categories.map((category) => (
                  <MenuItem 
                    key={category}
                    onClick={() => handleClose(category)}
                    selected={category === selectedCategory}
                    sx={{
                      '&.Mui-selected': {
                        backgroundColor: theme.palette.action.selected,
                        color: theme.palette.text.primary,
                        '&:hover': {
                          backgroundColor: theme.palette.action.hover,
                        }
                      }
                    }}
                  >
                    {category}
                  </MenuItem>
                ))}
              </Menu>
            </Box>

            <Paper 
              elevation={0}
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 0.5,
                p: 0.5,
                border: '1px solid',
                borderColor: 'divider',
                borderRadius: '16px',
              }}
            >
              <IconButton
                onClick={() => handleViewModeChange('card')}
                color={viewMode === 'card' ? 'primary' : 'default'}
                sx={{
                  bgcolor: viewMode === 'card' ? 'action.selected' : 'transparent',
                  borderRadius: '16px',
                  mr: 0.5,
                }}
              >
                <ViewModule />
              </IconButton>
              <IconButton
                onClick={() => handleViewModeChange('progressive')}
                color={viewMode === 'progressive' ? 'primary' : 'default'}
                sx={{
                  bgcolor: viewMode === 'progressive' ? 'action.selected' : 'transparent',
                  borderRadius: '16px',
                  mr: 0.5,
                }}
              >
                <ViewQuilt />
              </IconButton>
              <IconButton
                onClick={() => handleViewModeChange('masonry')}
                color={viewMode === 'masonry' ? 'primary' : 'default'}
                sx={{
                  bgcolor: viewMode === 'masonry' ? 'action.selected' : 'transparent',
                  borderRadius: '16px',
                }}
              >
                <ViewCompact />
              </IconButton>
            </Paper>
          </Box>
        ) : (
          <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap', mb: -4 }}>
            {categories.map((category) => (
              <CategoryChip
                key={category}
                label={category}
                variant={category === selectedCategory ? "filled" : "outlined"}
                clickable
                onClick={() => handleClose(category)}
                color={category === selectedCategory ? "primary" : "default"}
                className={category === selectedCategory ? "Mui-selected" : ""}
              />
            ))}
          </Box>
        )}

        {!isMobile && !isTablet && (
          <Box 
            sx={{ 
              display: 'flex', 
              justifyContent: 'flex-end',
              mb: 3,
           
              mt: {
                md: 5,
                lg: -3,
                xl: -1   // Desktop
              }
            }}
          >
            <Paper 
              elevation={0}
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 0.5,
                p: 0.5,
                border: '1px solid',
                borderColor: 'divider',
                borderRadius: '16px',
              }}
            >
              <IconButton
                onClick={() => handleViewModeChange('card')}
                color={viewMode === 'card' ? 'primary' : 'default'}
                sx={{
                  bgcolor: viewMode === 'card' ? 'action.selected' : 'transparent',
                  borderRadius: '16px',
                  mr: 0.5,
                }}
              >
                <ViewModule />
              </IconButton>
              <IconButton
                onClick={() => handleViewModeChange('progressive')}
                color={viewMode === 'progressive' ? 'primary' : 'default'}
                sx={{
                  bgcolor: viewMode === 'progressive' ? 'action.selected' : 'transparent',
                  borderRadius: '16px',
                  mr: 0.5,
                }}
              >
                <ViewQuilt />
              </IconButton>
              <IconButton
                onClick={() => handleViewModeChange('masonry')}
                color={viewMode === 'masonry' ? 'primary' : 'default'}
                sx={{
                  bgcolor: viewMode === 'masonry' ? 'action.selected' : 'transparent',
                  borderRadius: '16px',
                }}
              >
                <ViewCompact />
              </IconButton>
            </Paper>
          </Box>
        )}
      </Box>

      <Grid container spacing={3}>
        {loading ? (
          [...Array(12)].map((_, index) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={`skeleton-${index}`}>
              <Box sx={{ 
                height: '100%',
                border: '1px solid',
                borderColor: 'divider',
                borderRadius: 1,
                overflow: 'hidden'
              }}>
                <TreasureCardSkeleton />
              </Box>
            </Grid>
          ))
        ) : treasureArray.length === 0 ? (
          <Grid item xs={12}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                py: 8,
                px: 2,
                textAlign: 'center'
              }}
            >
              {searchQuery ? (
                // Search with no results
                <>
                  <SearchIcon 
                  sx={{
                    fontSize: 64, 
                    color: '#C6AB70',
                    mb: 2,
                    opacity: 0.4
                    }} 
                  />
                  <Typography variant="h6" color="text.secondary">
                    No treasures found
                  </Typography>
                  <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                    Try different keywords or check your spelling
                  </Typography>
                </>
              ) : (
                // No public treasures
                <>
                  <TreasureIcon 
                    sx={{ 
                      fontSize: 64, 
                      color: 'text.secondary',
                      mb: 2,
                      opacity: 0.6
                    }}
                  />
                  <Typography variant="h6" color="text.secondary">
                    No Public Treasures Yet
                  </Typography>
                  <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                    Be the first to share your treasured memories with the community
                        </Typography>
                </>
              )}
                  </Box>
            </Grid>
        ) : (
          <>
            {viewMode === 'card' && renderTreasures()}
            {viewMode === 'progressive' && (
              <Grid item xs={12}>
                <GridExploreNew
                  treasures={treasureArray}
                  onTreasureClick={handleTreasureView}
                  onLikeClick={handleLikeClick}
                />
              </Grid>
            )}
            {viewMode === 'masonry' && (
              <Grid item xs={12}>
                <GridExplore
                  treasures={treasureArray}
                  onTreasureClick={handleTreasureView}
                  onLikeClick={handleLikeClick}
                />
              </Grid>
            )}
          </>
        )}
      </Grid>

      {hasMore && !loading && (
        <Box ref={observerTarget} sx={{ height: 20, mt: 2 }} />
      )}

      {selectedTreasure && (
        <TreasureStandalone
          open={!!selectedTreasure}
          treasure={selectedTreasure}
          treasureId={selectedTreasure.id}
          onClose={() => setSelectedTreasure(null)}
        />
      )}
    </Container>
  );
};

export default Explore; 