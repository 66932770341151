// Home.js

// NOTE: This file uses MUI for styling and React Router for navigation.
// If you have a global CSS that sets overflow to hidden, you must remove or override it.

import { styled } from '@mui/material/styles';
import {
  Box,
  Typography,
  Button,
  Grid,
  Container,
  Link,
  AppBar,
  Toolbar,
} from '@mui/material';
import { useAuth } from '../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import Logo, { LogoWrapper } from './common/Logo';
import Footer from './common/Footer';
import '../styles/public.css';
import { useEffect } from 'react';

// -----------------------------------
// NAVBAR STYLING
// -----------------------------------
const NavBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: 'white',
  boxShadow: 'none',
  borderBottom: `1px solid ${theme.palette.divider}`,
}));

// -----------------------------------
// HERO SECTION STYLING
// -----------------------------------
const HeroSection = styled(Box)(({ theme }) => ({
  // Use auto height so it can grow, ensuring scrolling is possible.
  backgroundColor: theme.palette.grey[200],
  width: '100%',
  marginBottom: theme.spacing(8),
  padding: theme.spacing(4),
}));

const HeroContent = styled(Box)(({ theme }) => ({
  // Keep content in normal flow. Let it expand naturally.
  width: '100%',
  maxWidth: theme.breakpoints.values.lg,
  '& > *': {
    maxWidth: '500px',
  },
}));

// -----------------------------------
// CONTENT SECTION STYLING
// -----------------------------------
const ContentSection = styled(Box)(({ theme }) => ({
  // Also use auto height, letting content push the page length.
  backgroundColor: theme.palette.grey[200],
  marginBottom: theme.spacing(4),
  padding: theme.spacing(4),
  display: 'flex',
  alignItems: 'center',
}));

const ContentText = styled(Box)(({ theme }) => ({
  maxWidth: '400px',
}));

// -----------------------------------
// GRID SECTION STYLING
// -----------------------------------
const GridSection = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(4),
}));

const GridItem = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.grey[200],
  padding: theme.spacing(3),
  marginBottom: theme.spacing(2),
}));

// -----------------------------------
// MAIN COMPONENT
// -----------------------------------
const Home = () => {
  // Access AuthContext to determine if user is signed in
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  // Navigate to correct page upon Sign In / My Treasure Chest click
  const handleSignInClick = () => {
    if (currentUser) {
      navigate('/treasurechest');
    } else {
      navigate('/login');
    }
  };

  useEffect(() => {
    document.body.classList.add('public-page');
    return () => document.body.classList.remove('public-page');
  }, []);

  return (
    <div className="public-page">
      <div className="public-page-container">
        <div className="public-page-content">
          {/* Navbar */}
          <NavBar position="static">
            <Toolbar sx={{ justifyContent: 'space-between' }}>
              <Box sx={{ width: 100 }} /> {/* Spacer for left/right balance */}
              <LogoWrapper>
                <Box component={Link} href="/" sx={{ textDecoration: 'none' }}>
                  <Logo size="1.75rem" />
                </Box>
              </LogoWrapper>
              <Box
                sx={{
                  display: 'flex',
                  gap: 3,
                  width: 100, // Match left spacer
                  justifyContent: 'flex-end',
                }}
              >
                <Link
                  href="/articles"
                  color="text.secondary"
                  sx={{ textDecoration: 'none' }}
                >
                  Articles
                </Link>
                <Link
                  onClick={handleSignInClick}
                  color="text.secondary"
                  sx={{
                    textDecoration: 'none',
                    cursor: 'pointer',
                  }}
                >
                  {currentUser ? 'My Treasure Chest' : 'Sign In'}
                </Link>
              </Box>
            </Toolbar>
          </NavBar>

          {/* Hero Section */}
          <HeroSection>
            <HeroContent>
              <Typography
                variant="subtitle2"
                gutterBottom
                sx={{
                  textTransform: 'uppercase',
                  letterSpacing: 1,
                  mb: 2,
                }}
              >
                Something Told
              </Typography>
              <Typography
                variant="h2"
                gutterBottom
                sx={{
                  fontWeight: 700,
                  mb: 3,
                }}
              >
                About Us
              </Typography>
              <Typography
                variant="body1"
                paragraph
                sx={{
                  mb: 4,
                  color: 'text.secondary',
                }}
              >
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
                nonumy eirmod tempor invidunt ut labore et dolore magna.
              </Typography>
              <Button
                variant="outlined"
                sx={{
                  borderRadius: 0,
                  borderColor: 'text.primary',
                  color: 'text.primary',
                  px: 4,
                  py: 1.5,
                  '&:hover': {
                    borderColor: 'text.primary',
                    backgroundColor: 'rgba(0, 0, 0, 0.05)',
                  },
                }}
              >
                Learn More
              </Button>
            </HeroContent>
          </HeroSection>

          {/* Main Content Container */}
          <Container maxWidth="lg" sx={{ py: 4 }}>
            {/* Content Section 1 */}
            <ContentSection>
              <ContentText>
                <Typography variant="h4" gutterBottom>
                  Headline
                </Typography>
                <Typography variant="body1" paragraph>
                  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
                  nonumy eirmod tempor invidunt ut labore et dolore magna.
                </Typography>
                <Button variant="outlined" sx={{ borderRadius: 0 }}>
                  Learn More
                </Button>
              </ContentText>
            </ContentSection>

            {/* Grid Section */}
            <GridSection>
              <Grid container spacing={3}>
                {[1, 2, 3].map((item) => (
                  <Grid item xs={12} md={4} key={item}>
                    <GridItem>
                      <Typography variant="h5" gutterBottom>
                        Headline {item}
                      </Typography>
                      <Typography variant="body2" paragraph>
                        Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                        diam nonumy eirmod tempor invidunt ut labore et dolore magna.
                      </Typography>
                      <Button variant="outlined" sx={{ borderRadius: 0 }}>
                        Learn More
                      </Button>
                    </GridItem>
                  </Grid>
                ))}
              </Grid>
            </GridSection>

            {/* Add extra content to demonstrate scrolling */}
            <Box sx={{ mb: 4 }}>
              <Typography variant="h5" gutterBottom>
                Extra Content to Force Scrolling
              </Typography>
              <Typography variant="body2" paragraph>
                {
                  // Repeat text multiple times to push the footer off-screen
                  Array(20)
                    .fill(
                      'This is additional text repeated many times to ensure the page overflows vertically and demonstrates scrolling. '
                    )
                    .join('')
                }
              </Typography>
            </Box>
          </Container>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default Home;
