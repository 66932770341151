import React, { useState, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { Link as RouterLink } from 'react-router-dom';
import styles from '../../styles/modules/blogList.module.css';
import PublicNav from '../common/PublicNav';
import ProgressiveImage from '../../../components/ProgressiveImage';
import Footer from '../common/Footer';
import Newsletter from '../home/Newsletter';
import { Divider, Box } from '@mui/material';

const BlogList = () => {
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(true);
  const { ref, inView } = useInView({
    threshold: 0,
    triggerOnce: true
  });

  // Helper function to strip HTML tags
  const stripHtmlTags = (html) => {
    const temp = document.createElement('div');
    temp.innerHTML = html;
    return temp.textContent || temp.innerText || '';
  };

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/articles`);
        const data = await response.json();
        console.log('Articles data:', data);
        setArticles(data);
        setLoading(false);
      } catch (error) {
        console.error('Full error:', error);
        console.error('Error fetching articles:', error);
        setLoading(false);
      }
    };

    fetchArticles();
  }, []);

  const handleCardClick = (slug) => {
    window.location.href = `/blogs/${slug}`;
  };

  return (
    <div className={styles.blogPage}>
      <PublicNav />
      
      <main className={styles.mainContent}>
        <div className={styles.heroSection}>
          <h1>Hello, Sentimentalists!</h1>
          <p>Welcome to our Blog, centered around storytelling, treasures, heirloom preservation and legacy planning. Come curious. Leave inspired!</p>
        </div>

        <div className={styles.articlesGrid}>
          {articles.map((article, index) => (
            <article 
              key={article.id} 
              className={styles.articleCard} 
              ref={ref}
              onClick={() => handleCardClick(article.slug)}
              role="button"
              tabIndex={0}
              onKeyDown={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                  handleCardClick(article.slug);
                }
              }}
            >
              <div className={styles.imageWrapper}>
                <ProgressiveImage
                  src={article.image_url}
                  alt={article.title}
                  className={styles.articleImage}
                />
                <div className={styles.imageOverlay}>
                  <span className={styles.readMoreOverlay}>Read Article</span>
                </div>
              </div>

              <div className={styles.cardContent}>
                <div className={styles.categoryWrapper}>
                  <div className={styles.category}>{article.category}</div>
                  <div className={styles.date}>
                    {new Date(article.date).toLocaleDateString()}
                  </div>
                </div>
                
                <h2 className={styles.articleTitle}>
                  <RouterLink to={`/blogs/${article.slug}`}>
                    {article.title}
                  </RouterLink>
                </h2>

                <p className={styles.description}>
                  {stripHtmlTags(article.description)}
                </p>

                <div className={styles.articleMeta}>
                  <div className={styles.authorInfo}>
                    <img 
                      src={article.author_photo || 'https://ui-avatars.com/api/?name=' + encodeURIComponent(article.author) + '&background=11311B&color=fff'}
                      alt={article.author}
                      className={styles.authorImage}
                    />
                    <div className={styles.authorDetails}>
                      <span className={styles.author}>{article.author}</span>
                      {article.read_time && (
                        <span className={styles.readTime}>
                          {article.read_time}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </article>
          ))}
        </div>
      </main>
      <Newsletter />
      <Box sx={{ 
        backgroundColor: 'rgba(255, 255, 255, 0.02)',
        height: '1px',
        width: '100%',
        margin: 0
      }} />
      <Footer />
    </div>
  );
};

export default BlogList; 