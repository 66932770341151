import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Typography, Box, Container, Button, Grid, TextField, InputAdornment } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { debounce } from 'lodash';
import { BlogCardSkeleton } from './skeletons/ContentSkeletons';
import BlogCard from './BlogCard';
import SearchBar from './common/SearchBar';
import CreateBlogModal from './modals/CreateBlogModal';
import api from '../services/api';
import { getAuth } from 'firebase/auth';
import { Search as SearchIcon } from '@mui/icons-material';
import ArticleIcon from '@mui/icons-material/Article';
import { useToast } from '../contexts/ToastContext';

const Blog = () => {
  const { showToast } = useToast();
  const [loading, setLoading] = useState(true);
  const [blogs, setBlogs] = useState([]);
  const [userRole, setUserRole] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [editingBlog, setEditingBlog] = useState(null);
  const observerTarget = useRef(null);
  const theme = useTheme();
  const [recentSearches, setRecentSearches] = useState([]);

  const getViewport = () => {
    const width = window.innerWidth;
    if (width < 600) return 'mobile';      // xs: 0-600px
    if (width < 900) return 'tablet';      // sm: 600-900px
    if (width < 1200) return 'laptop';     // md: 900-1200px
    if (width < 1536) return 'desktop';    // lg: 1200-1536px
    return 'large';                        // xl: 1536px+
  };

  const fetchBlogs = useCallback(async (pageNum = 1, append = false) => {
    try {
      setLoading(true);
      const auth = getAuth();
      const token = await auth.currentUser?.getIdToken();

      const response = await api.get('/api/blogs', {
        params: {
          page: pageNum,
          query: searchQuery,
          viewport: getViewport(),
        },
      });

      // Ensure we have arrays even if response is undefined
      const newBlogs = Array.isArray(response.data?.blogs) ? response.data.blogs : [];
      const hasMoreBlogs = !!response.data?.hasMore;

      setBlogs((prev) => (append ? [...(Array.isArray(prev) ? prev : []), ...newBlogs] : newBlogs));
      setHasMore(hasMoreBlogs);
      setLoading(false);
    } catch (error) {
      console.error('Failed to fetch blogs:', error);
      setBlogs([]);
      setLoading(false);
    }
  }, [searchQuery]);

  const handleEdit = async (blog) => {
    const canEdit = userRole === 'admin' || 
                   userRole === 'ambassador' || 
                   blog.hasrights;
                   
    if (!canEdit) {
      return;
    }
    
    setEditingBlog(blog);
    setIsCreateModalOpen(true);
  };

  const handleCreateSuccess = useCallback((updatedBlog, isEdit = false) => {
    if (isEdit) {
      setBlogs(prevBlogs => 
        prevBlogs.map(blog => 
          blog.id === updatedBlog.id ? updatedBlog : blog
        )
      );
    } else {
      setBlogs(prevBlogs => [updatedBlog, ...prevBlogs]);
    }
    setIsCreateModalOpen(false);
    setEditingBlog(null);
    fetchBlogs(1, false);
  }, [fetchBlogs]);

  const handleSearch = useCallback(
    debounce((query) => {
      setSearchQuery(query);
      setPage(1);
      
      if (query && query.length >= 3) {
        setRecentSearches(prev => {
          const updated = [query, ...prev.filter(q => q !== query)];
          return updated.slice(0, 5);
        });
      }
    }, 300),
    []
  );

  const handleDeleteBlog = useCallback((blogId) => {
    setBlogs(prevBlogs => prevBlogs.filter(blog => blog.id !== blogId));
    showToast('Blog post deleted successfully', 'success');
  }, [showToast]);

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem('userData'));
    const role = userData?.data?.profile?.role || 'user';
    setUserRole(role);
  }, []);

  useEffect(() => {
    fetchBlogs(page, page > 1);
  }, [fetchBlogs, page]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && hasMore && !loading) {
          setPage((prevPage) => prevPage + 1);
        }
      },
      { threshold: 1.0 }
    );

    if (observerTarget.current) {
      observer.observe(observerTarget.current);
    }

    return () => observer.disconnect();
  }, [hasMore, loading]);

  return (
    <Container maxWidth="lg" sx={{ mt: 4 }}>
      <Typography variant="h4" sx={{ fontWeight: 600, color: theme.palette.text.primary, mb: 3 }}>
        Blog
      </Typography>
      <Box sx={{ 
        display: 'flex', 
        gap: 2, 
        width: '100%',
        mb: 4,
        flexDirection: { xs: 'column', sm: 'row' },
        '& > *': {
          flex: { xs: '1 1 auto', sm: '0 0 auto' }
        },
        '& > .MuiTextField-root': {
          flex: { sm: '1 1 auto' }
        },
        '& > .MuiButton-root': {
          minWidth: { xs: '100%', sm: 'auto' },
          height: '40px'
        }
      }}>
        <Box sx={{ 
          flex: { sm: '1 1 auto' },
          width: '100%'
        }}>
          <SearchBar
            placeholder="Search blogs..."
            onSearch={handleSearch}
            recentSearches={recentSearches}
            onClearRecentSearches={() => setRecentSearches([])}
            onSelectRecentSearch={(search) => handleSearch(search)}
          />
        </Box>
        {(userRole === 'admin' || userRole === 'ambassador') && (
          <Button
            variant="contained"
            onClick={() => setIsCreateModalOpen(true)}
            sx={{
              height: '40px',
              borderRadius: '12px',
              bgcolor: '#11311B',
              color: 'white',
              textTransform: 'none',
              fontSize: '0.875rem',
              '&:hover': {
                bgcolor: '#11311B',
                opacity: 0.9,
              }
            }}
          >
            Create Blog
          </Button>
        )}
      </Box>
      <Grid container spacing={4}>
        {loading ? (
          [...Array(6)].map((_, index) => (
            <Grid item xs={12} sm={6} md={4} key={`skeleton-${index}`}>
              <BlogCardSkeleton />
            </Grid>
          ))
        ) : blogs.length > 0 ? (
          blogs.map((blog, index) => (
            <Grid item xs={12} sm={6} md={4} key={blog.id}>
              <BlogCard 
                blog={blog} 
                userRole={userRole} 
                index={index}
                onEdit={handleEdit}
                onDelete={handleDeleteBlog}
              />
            </Grid>
          ))
        ) : (
          <Grid item xs={12}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                py: 8,
                px: 2,
                textAlign: 'center'
              }}
            >
              {searchQuery ? (
                <>
                  <SearchIcon 
                    sx={{
                      fontSize: 64, 
                      color: '#C6AB70',
                      mb: 2,
                      opacity: 0.4
                    }} 
                  />
                  <Typography variant="h6" color="text.secondary">
                    No blogs found
                  </Typography>
                  <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                    Try different keywords or check your spelling
                  </Typography>
                </>
              ) : (
                <>
                  <ArticleIcon 
                    sx={{ 
                      fontSize: 64, 
                      color: 'text.secondary',
                      mb: 2,
                      opacity: 0.6
                    }}
                  />
                  <Typography variant="h6" color="text.secondary">
                    No Blogs Published Yet
                  </Typography>
                  <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                    Be the first to share your thoughts with the community
                  </Typography>
                </>
              )}
            </Box>
          </Grid>
        )}
      </Grid>
      {hasMore && !loading && <Box ref={observerTarget} sx={{ height: 20, mt: 2 }} />}

      <CreateBlogModal 
        open={isCreateModalOpen}
        onClose={() => {
          setIsCreateModalOpen(false);
          setEditingBlog(null);
        }}
        onSuccess={handleCreateSuccess}
        editingBlog={editingBlog}
      />
    </Container>
  );
};

export default Blog;