import { Card, Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { AddCircleOutline } from '@mui/icons-material';

const StyledAddTreasureCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: theme.spacing(3),
  background: `linear-gradient(135deg, 
    ${theme.palette.background.paper} 0%, 
    ${theme.palette.grey[50]} 100%)`,
  border: '2px dashed',
  borderColor: theme.palette.divider,
  borderRadius: theme.shape.borderRadius,
  cursor: 'pointer',
  transition: 'all 0.3s ease-in-out',
  position: 'relative',
  zIndex: 1,
  '&:hover': {
    borderColor: theme.palette.primary.main,
    transform: 'translateY(-4px)',
    boxShadow: `0 0 20px ${theme.palette.primary.main}15`,
    '& .add-icon': {
      transform: 'scale(1.1) rotate(180deg)',
      color: theme.palette.primary.main,
    }
  }
}));

const AddTreasureCard = ({ onClick }) => (
  <StyledAddTreasureCard onClick={onClick}>
    <Box sx={{ 
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: 1,
      py: 2,
    }}>
      <AddCircleOutline
        className="add-icon"
        sx={{
          fontSize: 48,
          color: 'text.secondary',
          transition: 'all 0.6s cubic-bezier(0.4, 0, 0.2, 1)',
        }}
      />
      <Box sx={{ textAlign: 'center' }}>
        <Typography 
          variant="h6"
          sx={{ 
            color: 'text.primary',
            fontWeight: 500,
            mb: 0.5
          }}
        >
          Preserve a Memory
        </Typography>
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{ maxWidth: 200 }}
        >
          Share the story behind the treasure you hold dear
        </Typography>
      </Box>
    </Box>
  </StyledAddTreasureCard>
);

export default AddTreasureCard; 