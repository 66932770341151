import React, { useEffect, useState } from 'react';
import { 
  Dialog, 
  DialogContent, 
  DialogActions, 
  Typography, 
  Box, 
  Button, 
  useTheme, 
  useMediaQuery,
  LinearProgress
} from '@mui/material';
import { styled } from '@mui/material/styles';
import CloseButton from './common/CloseButton';
import {
  CardGiftcard,
  Celebration,
  LocationOn,
  History,
  Favorite,
  Diamond,
  FamilyRestroom
} from '@mui/icons-material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
// Import the Texture asset from your assets folder
import TextureBg from '../assets/Texture.png';

// Replicate the VaultContent style from TreasureVaultModal.js exactly
const VaultContent = styled(Box)(({ theme }) => ({
  color: theme.palette.common.white,         
  padding: theme.spacing(4, 4, 0), // Remove bottom padding
  position: 'relative',
  minHeight: '100%',
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(3, 0, 0), // Remove horizontal padding on mobile
    overflow: 'visible',
  },
  /* Hide scrollbar for Chrome, Safari and Opera */
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  /* Hide scrollbar for IE, Edge and Firefox */
  '-ms-overflow-style': 'none',  /* IE and Edge */
  'scrollbar-width': 'none',  /* Firefox */
}));

// Replicate ContentAnimation from TreasureVaultModal.js
const ContentAnimation = styled(Box)(({ theme }) => ({
  animation: 'fadeInUp 0.6s ease-out',
  '@keyframes fadeInUp': {
    from: {
      opacity: 0,
      transform: 'translateY(20px)',
    },
    to: {
      opacity: 1,
      transform: 'translateY(0)',
    },
  },
}));

// Update GlowingText to be more compact
const GlowingText = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.white,
  textShadow: '0 0 10px rgba(198, 171, 112, 0.4)',
  position: 'relative',
  marginBottom: theme.spacing(2), // Reduced from 4
  textAlign: 'center',
  fontFamily: 'Roboto',
  letterSpacing: '0.05em',
  fontSize: '1.75rem', // Slightly smaller font
  '&::before, &::after': {
    content: '""',
    position: 'absolute',
    bottom: -8, // Reduced from -12
    left: '50%',
    transform: 'translateX(-50%)',
    height: '1px', // Thinner lines
  },
  '&::before': {
    width: '140px', // Shorter line
    background: 'linear-gradient(90deg, transparent, rgba(198, 171, 112, 0.6), transparent)',
  },
  '&::after': {
    width: '90px', // Shorter line
    bottom: -12, // Reduced from -18
    background: 'linear-gradient(90deg, transparent, rgba(198, 171, 112, 0.3), transparent)',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.5rem',
    marginBottom: theme.spacing(2),
  },
}));

// Update the StorySection styling to be more compact
const StorySection = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(2),
  padding: theme.spacing(2),
  backgroundColor: 'rgba(17, 49, 27, 0.3)',
  borderRadius: '4px',
  border: '1px solid rgba(198, 171, 112, 0.15)',
  boxShadow: 'inset 0 0 30px rgba(0, 0, 0, 0.2)',
  position: 'relative',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: -1,
    left: -1,
    right: -1,
    bottom: -1,
    border: '1px solid rgba(198, 171, 112, 0.1)',
    borderRadius: '5px',
    pointerEvents: 'none'
  },
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2, 1), // Reduce horizontal padding on mobile
    marginTop: theme.spacing(2),
    borderRadius: 0, // Remove border radius on mobile
    border: 'none', // Remove border on mobile
    borderTop: '1px solid rgba(198, 171, 112, 0.15)',
    borderBottom: '1px solid rgba(198, 171, 112, 0.15)',
  },
}));

// Add the ProgressIndicator styled component after other styled components
const ProgressIndicator = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  padding: theme.spacing(1.5),
  backgroundColor: 'rgba(0, 0, 0, 0.2)',
  borderRadius: '4px',
  border: '1px solid rgba(198, 171, 112, 0.15)',
  transition: 'all 0.3s ease',
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.25)',
    border: '1px solid rgba(198, 171, 112, 0.25)',
  }
}));

// Update the FeatureBox to include isAnswered prop
const FeatureBox = styled(Box, {
  shouldComponentUpdate: true,
  props: ['isAnswered']
})(({ theme, isAnswered }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  gap: theme.spacing(2),
  marginBottom: theme.spacing(1.5),
  padding: theme.spacing(1.5),
  backgroundColor: isAnswered ? 'rgba(26, 74, 43, 0.25)' : 'rgba(0, 0, 0, 0.15)',
  border: `1px solid ${isAnswered ? 'rgba(198, 171, 112, 0.25)' : 'rgba(198, 171, 112, 0.15)'}`,
  borderRadius: '4px',
  position: 'relative',
  transition: 'all 0.3s ease-in-out',
  cursor: 'pointer',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: 'linear-gradient(45deg, rgba(198, 171, 112, 0.03), transparent)',
    pointerEvents: 'none'
  },
  '& .MuiSvgIcon-root': {
    color: '#C6AB70',
    fontSize: 20,
    transition: 'all 0.4s ease-in-out',
    filter: 'drop-shadow(0 0 4px rgba(198, 171, 112, 0.3))',
  },
  '&:hover': {
    backgroundColor: isAnswered ? 'rgba(26, 74, 43, 0.3)' : 'rgba(0, 0, 0, 0.25)',
    borderColor: 'rgba(198, 171, 112, 0.25)',
    '& .MuiSvgIcon-root': {
      transform: 'scale(1.1)',
      filter: 'drop-shadow(0 0 8px rgba(198, 171, 112, 0.5))',
    }
  },
  '&:last-child': {
    marginBottom: 0,
  },
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.spacing(2),
    '& .MuiSvgIcon-root': {
      fontSize: 24,
    },
  },
}));

// Update FooterText to be more compact
const FooterText = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(1),
  textAlign: 'center',
  color: '#C6AB70',
  opacity: 0.9,
  fontStyle: 'italic',
  fontSize: '0.85rem',
  letterSpacing: '0.02em',
  padding: theme.spacing(1.5, 0),
  borderTop: '1px solid rgba(198, 171, 112, 0.2)',
}));

// StoryInspirationModal mimics TreasureVaultModal exactly with updated content
const StoryInspirationModal = ({ open, onClose }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [answeredPrompts, setAnsweredPrompts] = useState(new Set());

  useEffect(() => {
    if (isMobile && open) {
      document.body.classList.add('modal-open');
    }
    return () => {
      document.body.classList.remove('modal-open');
    };
  }, [open, isMobile]);

  // Array of prompts with icons
  const storyPrompts = [
    {
      question: "Origin",
      prompt: "Was this treasure self-gifted or gifted/passed onto you?",
      icon: <CardGiftcard />
    },
    {
      question: "Special Moment",
      prompt: "Was there a special moment or reason for purchasing/receiving this treasure?",
      icon: <Celebration />
    },
    {
      question: "Context",
      prompt: "Where were you and who were you with when you received this treasure?",
      icon: <LocationOn />
    },
    {
      question: "History",
      prompt: "If someone treasured it before you, do you know where they received it and what it meant to them?",
      icon: <History />
    },
    {
      question: "Emotions",
      prompt: "What feelings does this treasure evoke in you (i.e. - happiness, love, nostalgia, pride, etc.)?",
      icon: <Favorite />
    },
    {
      question: "Material",
      prompt: "Do you know what your treasure is physically made of (i.e.- 14k Gold, Saffiano leather, Acrylic paint, etc.)?",
      icon: <Diamond />
    },
    {
      question: "Future",
      prompt: "Is there someone you imagine passing this onto one day and if so, who would that be?",
      icon: <FamilyRestroom />
    }
  ];

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      fullScreen={isMobile}
      PaperProps={{
        sx: {
          borderRadius: isMobile ? 0 : 2,
          background: `url(${TextureBg})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundColor: '#11311B',
          m: isMobile ? 0 : 2,
          height: isMobile ? '100%' : 'auto',
        },
      }}
    >
      <CloseButton
        onClose={onClose}
        sx={{
          position: 'absolute',
          color: '#C6AB70',
          top: isMobile ? 8 : 16,
          right: isMobile ? 8 : 16,
          zIndex: 3,
          cursor: 'pointer',
          transition: 'all 0.3s ease',
          '&:hover': {
            color: '#C6AB70',
            transform: 'scale(1.1)',
            filter: 'drop-shadow(0 0 6px rgba(198, 171, 112, 0.5))'
          }
        }}
      />
      <DialogContent
        sx={{
          position: 'relative',
          backgroundColor: 'transparent',
          overflow: 'auto',
          height: '100%',
          WebkitOverflowScrolling: 'touch',
          /* Hide scrollbar for all browsers */
          scrollbarWidth: 'none', /* Firefox */
          '&::-webkit-scrollbar': { /* Chrome, Safari, Edge */
            display: 'none',
            width: 0,
            height: 0,
            background: 'transparent',
          },
          msOverflowStyle: 'none', /* IE 10+ */
          '& .MuiBox-root': {
            position: 'relative',
            zIndex: 2,
            [theme.breakpoints.down('sm')]: {
              minHeight: '101%',
            },
          },
        }}
      >
        <ContentAnimation
          sx={{
            position: 'relative',
            zIndex: 2,
            backgroundColor: 'transparent',
            [theme.breakpoints.down('sm')]: {
              paddingBottom: theme.spacing(2),
            },
          }}
        >
          <VaultContent>
            <GlowingText variant="h4">
              Story Writing Guide
            </GlowingText>
            <Typography
              variant="body1"
              sx={{
                opacity: 1,
                lineHeight: 1.6,
                textAlign: 'center',
                fontSize: '0.9rem',
                mb: 2,
                color: '#C6AB70',
                fontStyle: 'italic',
                letterSpacing: '0.02em',
                maxWidth: '600px',
                margin: '0 auto 1rem',
              }}
            >
              Use these prompts to help guide your story
            </Typography>

            <ProgressIndicator>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                {answeredPrompts.size === storyPrompts.length ? (
                  <Typography 
                    variant="body2" 
                    sx={{ 
                      color: '#C6AB70',
                      fontWeight: 600,
                      fontSize: '1rem',
                      textAlign: 'center',
                      width: '100%',
                      animation: 'celebrateText 0.5s ease-out',
                      '@keyframes celebrateText': {
                        '0%': { transform: 'scale(0.95)', opacity: 0 },
                        '100%': { transform: 'scale(1)', opacity: 1 }
                      }
                    }}
                  >
                      You're Ready to Craft Your Story!
                  </Typography>
                ) : (
                  <>
                    <Typography variant="body2" sx={{ color: '#C6AB70', mr: 1 }}>
                      Story Progress
                    </Typography>
                    <Typography variant="body2" sx={{ color: 'white' }}>
                      {answeredPrompts.size} of {storyPrompts.length} prompts considered
                    </Typography>
                  </>
                )}
              </Box>
              <LinearProgress
                variant="determinate"
                value={(answeredPrompts.size / storyPrompts.length) * 100}
                sx={{
                  backgroundColor: 'rgba(198, 171, 112, 0.1)',
                  '& .MuiLinearProgress-bar': {
                    backgroundColor: answeredPrompts.size === storyPrompts.length ? 
                      'rgba(198, 171, 112, 0.9)' : '#C6AB70',
                    transition: 'all 0.3s ease'
                  },
                  height: 6,
                  borderRadius: 3,
                }}
              />
              {answeredPrompts.size === storyPrompts.length && (
                <Box 
                  sx={{ 
                    mt: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: 2,
                    animation: 'fadeIn 0.5s ease-out',
                    '@keyframes fadeIn': {
                      '0%': { opacity: 0, transform: 'translateY(10px)' },
                      '100%': { opacity: 1, transform: 'translateY(0)' }
                    }
                  }}
                >
                  <Typography 
                    variant="body2" 
                    sx={{ 
                      color: 'rgba(255, 255, 255, 0.9)',
                      textAlign: 'center',
                      fontSize: '0.9rem',
                      lineHeight: 1.6,
                      maxWidth: '500px',
                      fontStyle: 'italic'
                    }}
                  >
                    You've thoughtfully considered all aspects of your treasure's story. 
                    Each reflection adds depth and meaning to your cherished memory.
                  </Typography>
                  <Button
                    onClick={onClose}
                    variant="contained"
                    sx={{
                      bgcolor: '#C6AB70',
                      color: '#11311B',
                      '&:hover': {
                        bgcolor: 'rgba(198, 171, 112, 0.9)',
                        transform: 'translateY(-2px)',
                        boxShadow: '0 4px 15px rgba(198, 171, 112, 0.3)'
                      },
                      transition: 'all 0.3s ease',
                      textTransform: 'none',
                      fontSize: '0.95rem',
                      fontWeight: 600,
                      padding: '8px 24px',
                      borderRadius: '8px',
                      letterSpacing: '0.02em',
                      boxShadow: '0 4px 10px rgba(198, 171, 112, 0.2)'
                    }}
                  >
                    Begin Writing Your Story
                  </Button>
                </Box>
              )}
            </ProgressIndicator>

            <StorySection>
              {storyPrompts.map((item, index) => (
                <FeatureBox 
                  isAnswered={answeredPrompts.has(index)}
                  onClick={() => {
                    const newAnswered = new Set(answeredPrompts);
                    if (newAnswered.has(index)) {
                      newAnswered.delete(index);
                    } else {
                      newAnswered.add(index);
                    }
                    setAnsweredPrompts(newAnswered);
                  }}
                  sx={{ cursor: 'pointer' }}
                >
                  <Box sx={{ 
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: 32,
                    height: 32,
                    borderRadius: '2px',
                    backgroundColor: 'rgba(198, 171, 112, 0.1)',
                    border: '1px solid rgba(198, 171, 112, 0.2)',
                    flexShrink: 0,
                  }}>
                    {item.icon}
                  </Box>
                  <Box sx={{ flex: 1 }}>
                    <Typography variant="h6" sx={{ 
                      mb: 0.5,
                      color: '#C6AB70',
                      textShadow: '0 0 8px rgba(198, 171, 112, 0.2)',
                      letterSpacing: '0.03em',
                      fontWeight: 500,
                      fontSize: '0.95rem',
                    }}>
                      {item.question}
                    </Typography>
                    <Typography variant="body2" sx={{ 
                      opacity: 1,
                      lineHeight: 1.5,
                      fontSize: '0.85rem',
                      color: 'rgba(255,255,255,0.9)',
                      letterSpacing: '0.02em',
                    }}>
                      {item.prompt}
                    </Typography>
                  </Box>
                  <CheckCircleIcon 
                    sx={{ 
                      opacity: answeredPrompts.has(index) ? 1 : 0.2,
                      color: '#C6AB70',
                      transition: 'all 0.3s ease',
                      ml: 1
                    }} 
                  />
                </FeatureBox>
              ))}
            </StorySection>

            <FooterText variant="body2">
              Let these questions guide you in sharing your treasure's unique story and the memories it holds.
            </FooterText>
          </VaultContent>
        </ContentAnimation>
      </DialogContent>
    </Dialog>
  );
};

export default StoryInspirationModal; 