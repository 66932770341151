import { useState, useRef, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Box,
  Button,
  IconButton,
  Switch,
  FormControlLabel,
  DialogActions,
  LinearProgress,
  useMediaQuery,
  useTheme,
  TextField,
  Tooltip,
  Zoom
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Close as CloseIcon, PlayArrow, Info as InfoIcon } from '@mui/icons-material';
import { useToast } from '../contexts/ToastContext';
import ConfirmDialog from './common/ConfirmDialog';
import api from '../services/api';
import { getAuth } from 'firebase/auth';
import { styled } from '@mui/material/styles';
import ExpandedImageView from './common/ExpandedImageView';
import { usePreventMobileScroll } from '../hooks/useScrollFocus';
import * as Yup from 'yup';
import VideoPlayer from './common/VideoPlayer';
import axios from 'axios';
import StoryInspirationModal from './StoryInspirationModal';

const ElegantSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 14,
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundImage: 'linear-gradient(45deg, #4caf50, #81c784)',
        opacity: 1,
      },
    },
  },
  '& .MuiSwitch-thumb': {
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    backgroundColor: theme.palette.grey[300],
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

const StyledSwitch = styled(Switch)(({ theme }) => ({
  width: 32,
  height: 16,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundImage: 'linear-gradient(45deg, #11311B, #0e2716)',
        opacity: 0.7,
        border: 0,
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 12,
    height: 12,
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    backgroundColor: theme.palette.grey[400],
    opacity: 0.3,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

const isValidDate = (year, month, day) => {
  if (!month || !year) return false;
  if (!day) return true;
  
  const monthIndex = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 
                     'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'].indexOf(month);
  
  const date = new Date(year, monthIndex, day);
  return date.getMonth() === monthIndex;
};

const ImageContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  position: 'relative',
  paddingTop: '100%',
  borderRadius: theme.shape.borderRadius * 2,
  overflow: 'hidden',
  cursor: 'pointer',
  transition: 'transform 0.3s ease, box-shadow 0.3s ease',
  boxShadow: '0 2px 8px rgba(0,0,0,0.06)',
  '&:hover': {
    transform: 'scale(1.05)',
    boxShadow: '0 6px 16px rgba(0,0,0,0.12)',
  },
  '& img': {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    transition: 'transform 0.3s ease',
  },
}));

const StoryPrompt = styled(Typography)(({ theme }) => ({
  fontSize: '1.1rem',
  fontWeight: 500,
  color: theme.palette.text.primary,
  marginBottom: theme.spacing(1),
  position: 'relative',
  '&::after': {
    content: '""',
    position: 'absolute',
    bottom: -4,
    left: 0,
    width: '100%',
    height: '2px',
    background: 'linear-gradient(90deg, transparent 0%, rgba(0,0,0,0.1) 50%, transparent 100%)',
    borderRadius: '2px'
  }
}));

const StoryTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    backgroundColor: theme.palette.background.paper,
    borderRadius: '16px',
    transition: 'all 0.2s cubic-bezier(0.4, 0, 0.2, 1)',
    height: '200px',
    boxShadow: '0 2px 8px rgba(0,0,0,0.02)',
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
      boxShadow: '0 4px 12px rgba(0,0,0,0.05)',
      transform: 'translateY(-1px)',
    },
    '&.Mui-focused': {
      backgroundColor: theme.palette.background.paper,
      boxShadow: '0 4px 16px rgba(0,0,0,0.08)',
      transform: 'translateY(-1px)',
      '& .MuiOutlinedInput-notchedOutline': {
        borderWidth: '1.5px',
        borderColor: theme.palette.primary.main,
      }
    }
  },
  '& .MuiInputBase-inputMultiline': {
    height: '100% !important',
    padding: theme.spacing(2),
    fontSize: '0.875rem',
    lineHeight: '1.6',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: 'rgba(0,0,0,0.1)',
    borderRadius: '16px',
    borderWidth: '1px',
    transition: 'all 0.2s cubic-bezier(0.4, 0, 0.2, 1)',
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: 'rgba(0,0,0,0.2)',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.primary.main,
    borderWidth: '1.5px',
  },
  '& .MuiInputBase-root': {
    background: `linear-gradient(to bottom, ${theme.palette.background.paper} 0%, rgba(255,255,255,0.8) 100%)`,
  }
}));

const PlayButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: 8,
  right: 8,
  backgroundColor: 'rgba(0,0,0,0.4)',
  padding: 8,
  '&:hover': {
    backgroundColor: 'rgba(0,0,0,0.6)',
  },
  '& .MuiSvgIcon-root': {
    fontSize: 20,
    color: 'white'
  }
}));

const ContentContainer = styled(Box)(({ theme }) => ({
  backgroundColor: '#ffffff',
  padding: theme.spacing(4),
  borderRadius: 16,
  boxShadow: '0 4px 12px rgba(0,0,0,0.08)',
  margin: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2),
    margin: 0,
    borderRadius: 0,
    boxShadow: 'none',
    width: '100%'
  }
}));

const ProgressButton = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '45px', // Match button height
  position: 'relative',
  borderRadius: '12px',
  backgroundColor: '#11311B',
  overflow: 'hidden',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  boxShadow: '0 4px 16px rgba(0,0,0,0.1)',
  transition: 'all 0.2s ease-in-out',
}));

const TreasurePreview = ({ open, onClose, onSave, treasureData }) => {
  const isMobile = useMediaQuery('(max-width:600px)');
  const theme = useTheme();
  const [isPublic, setIsPublic] = useState(true);
  const [story, setStory] = useState('');
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const { showToast } = useToast();
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [showExpandedImage, setShowExpandedImage] = useState(false);
  const [storyError, setStoryError] = useState('');
  const storyInputRef = useRef(null);
  const [isExiting, setIsExiting] = useState(false);
  const { enablePreventScroll, disablePreventScroll } = usePreventMobileScroll();
  const storyValidationSchema = Yup.string()
    .trim()
    .min(75, 'Your story is valuable, please share a bit more!')
    .max(5000, 'Story must be less than 5000 characters')
    .matches(
      /^[a-zA-Z0-9\s.,!?'"$()\-_:;\n\r]*$/,
      'Story contains invalid characters'
    );
  const [showVideo, setShowVideo] = useState(false);
  const [openInspiration, setOpenInspiration] = useState(false);

  const handleSave = async () => {
    try {
      // Basic content validation
      if (!story.trim()) {
        setStoryError('Please add a story about your treasure');
        return;
      }

      // Validate story content
      try {
        await storyValidationSchema.validate(story);
      } catch (validationError) {
        setStoryError(validationError.message);
        return;
      }
      
      // Clear error if validation passes
      setStoryError('');
      
      // Sanitize the story text while keeping original formatting
      const sanitizedStory = story
        .trim()
        .replace(/[<>]/g, '') // Remove < and > characters
        .replace(/javascript:/gi, '') // Remove javascript: protocol
        .replace(/on\w+=/gi, ''); // Remove event handlers

      // Validate date
      if (!isValidDate(treasureData.year, treasureData.month, treasureData.day)) {
        showToast(`Invalid date: ${treasureData.month} ${treasureData.day} is not valid`, 'error');
        return;
      }

      // Validate month and year
      if (!treasureData.month || !treasureData.year) {
        showToast('Please select Month and Year', 'error');
        return;
      }

      setIsUploading(true);
      
      // Construct date with optional day
      let dateAcquired = null;
      if (treasureData.month && treasureData.year) {
        const monthIndex = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 
                           'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
                           .indexOf(treasureData.month);
        const day = treasureData.day || 1;
        dateAcquired = new Date(treasureData.year, monthIndex, day);
      }

      const auth = getAuth();
      const token = await auth.currentUser.getIdToken();

      let mainContentUrl = treasureData.imageUrl;
      let thumbnailUrl = null;

      if (treasureData.isVideo) {
        const presignedResponse = await api.post('/api/treasures/video-presigned-url', 
          {
            videoType: treasureData.imageBlob.type,
            videoSize: treasureData.imageBlob.size,
            thumbnailType: 'image/jpeg',
            thumbnailSize: treasureData.thumbnailBlob.size
          });

        // Upload both files with progress tracking
        const totalSize = treasureData.imageBlob.size + treasureData.thumbnailBlob.size;
        let uploadedSize = 0;

        // Upload video first
        await axios.put(presignedResponse.data.video.url, treasureData.imageBlob, {
          headers: { 'Content-Type': treasureData.imageBlob.type },
          onUploadProgress: (progressEvent) => {
            uploadedSize = progressEvent.loaded;
            const progress = (uploadedSize / totalSize) * 100;
            setUploadProgress(progress);
          }
        });

        // Then upload thumbnail
        await axios.put(presignedResponse.data.thumbnail.url, treasureData.thumbnailBlob, {
          headers: { 'Content-Type': 'image/jpeg' },
          onUploadProgress: (progressEvent) => {
            const progress = ((uploadedSize + progressEvent.loaded) / totalSize) * 100;
            setUploadProgress(progress);
          }
        });

        // Use the public URLs directly from the response
        mainContentUrl = presignedResponse.data.video.publicUrl;     // Should match image flow
        thumbnailUrl = presignedResponse.data.thumbnail.publicUrl;   // Should match image flow

        console.log('Final URLs:', { mainContentUrl, thumbnailUrl });

      } else {
        // Existing image upload logic
        if (treasureData.imageBlob) {
          const presignedResponse = await api.post('/api/treasures/presigned-url', 
            {
              fileName: `treasure-${Date.now()}.webp`,
              fileType: 'image/webp'
            });

          await axios.put(presignedResponse.data.uploadUrl, treasureData.imageBlob, {
            headers: { 'Content-Type': 'image/webp' },
            onUploadProgress: (progressEvent) => {
              const progress = (progressEvent.loaded / progressEvent.total) * 100;
              setUploadProgress(progress);
            }
          });

          mainContentUrl = presignedResponse.data.publicUrl;
        }
      }

      // Create treasure with database structure
      const response = await api.post('/api/treasures', {
        name: treasureData.name,
        from: treasureData.from,
        category: treasureData.category,
        month: treasureData.month,
        day: treasureData.day,
        year: treasureData.year,
        story: sanitizedStory,
        image_url: mainContentUrl,
        video_thumbnail: thumbnailUrl,
        is_video: treasureData.isVideo,
        is_public: isPublic
      });

      console.log('Treasure creation response:', response.data);  // Debug log

      // Reset states
      setIsUploading(false);
      setUploadProgress(0);
      setStory('');
      setIsPublic(true);

      showToast('Each memory adds depth to your treasure chest', 'success', 3000);

      // After successful upload and database save
      const newTreasure = {
        id: Number(response.data[0].id),  // Convert string ID to number and access from first array element
        name: treasureData.name,
        from_person: treasureData.from,
        category: treasureData.category,
        date_acquired: dateAcquired.toISOString().split('T')[0],
        story: sanitizedStory,
        image_url: mainContentUrl,
        video_thumbnail: thumbnailUrl,
        is_video: treasureData.isVideo,
        is_public: isPublic
      };

      console.log('Constructed newTreasure:', newTreasure);  // Debug log

      onSave(newTreasure);

      // If it's a video, force a dashboard refresh
      if (treasureData.isVideo) {
        window.location.reload();
      }

      // Update mobile handling
      if (window.innerWidth <= 1024) {
        document.activeElement?.blur();
        window.scrollTo(0, 0);
        // Re-enable scrolling on main content
        const mainContent = document.querySelector('.MuiBox-root');
        if (mainContent) {
          mainContent.focus();
          mainContent.scrollIntoView({ behavior: 'smooth' });
        }
      }

      // Before sending to database
      console.log('Sending to database:', {
        image_url: mainContentUrl,
        video_thumbnail: thumbnailUrl,
        is_video: treasureData.isVideo,
        is_public: isPublic
      });

    } catch (error) {
      setIsUploading(false);
      setUploadProgress(0);
      console.error('Error saving treasure:', error);
      showToast(
        error.response?.data?.message || 
        'Failed to save your treasure. Please try again.',
        'error'
      );
    }
  };

  const handleClose = () => {
    setShowConfirmDialog(true);
  };

  const handleConfirmClose = () => {
    setShowConfirmDialog(false);
    setStory('');
    setIsPublic(true);
    document.body.style.overflow = 'auto';
    disablePreventScroll();
    onClose(true);
  };

  const handleBack = () => {
    setIsExiting(true);
    document.body.style.overflow = 'auto';
    disablePreventScroll();
    setTimeout(() => {
      onClose(false);
      setIsExiting(false);
    }, 400);
  };

  const handleExpandImage = () => {
    setShowExpandedImage(true);
  };

  const handleCloseExpanded = () => {
    setShowExpandedImage(false);
  };

  const handleStoryChange = (e) => {
    const safeText = e.target.value
      .replace(/[^a-zA-Z0-9\s.,!?'"()\-_\n\r]/g, ''); // Only allow our permitted characters
    setStory(safeText);
    setStoryError('');
  };

  // Add cleanup on modal close
  useEffect(() => {
    return () => {
      document.body.style.overflow = 'auto';
      disablePreventScroll();
    };
  }, [disablePreventScroll]);

  return (
    <>
      <Dialog 
        open={open} 
        onClose={(event, reason) => {
          if (isMobile && reason === 'backdropClick') return;
          handleClose();
        }}
        maxWidth={580}
        fullScreen={isMobile}
        TransitionProps={{
          timeout: {
            enter: 400,
            exit: 300
          },
          onExiting: () => {
            const dialog = document.querySelector('.MuiDialog-paper');
            if (dialog && isExiting) {
              dialog.style.animation = 'slideOutDown 400ms ease-in forwards';
            }
          }
        }}
        PaperProps={{
          sx: {
            borderRadius: isMobile ? 0 : '16px',
            boxShadow: isMobile ? 'none' : '0 8px 32px rgba(0,0,0,0.08)',
            backgroundImage: 'none',
            position: isMobile ? 'relative' : 'fixed',
            height: 'auto',
            overflowY: isMobile ? 'visible' : 'auto',
            ...((!isMobile) && {
              maxHeight: '90vh',
              width: '550px !important',
              maxWidth: '90vw',
            }),
            '&.MuiDialog-paper': {
              animation: isMobile ? 'none' : 'slideIn 300ms ease-out'
            }
          }
        }}
      >
        <Box sx={{ 
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden'
        }}>
          <DialogTitle 
            id="preview-dialog-title" 
            sx={{ 
              p: 2,
              backgroundColor: 'rgba(255,255,255,0.8)',
              backdropFilter: 'blur(10px)',
              borderBottom: 'none',
              textAlign: 'center',
              position: 'relative'
            }}
          >
            <IconButton 
              onClick={handleBack}
              sx={{ 
                position: 'absolute', 
                left: 8, 
                top: '50%', 
                transform: 'translateY(-50%)', 
                color: 'text.secondary' 
              }}
            >
              <ArrowBackIcon />
            </IconButton>
            <Typography variant="h6" sx={{ fontWeight: 600, color: '#1a1a1a' }}>
              Preserve Your Story
            </Typography>
            <IconButton 
              onClick={handleClose}
              sx={{ 
                position: 'absolute', 
                right: 8, 
                top: '50%', 
                transform: 'translateY(-50%)', 
                color: 'text.secondary' 
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>

          <DialogContent 
            sx={{ 
              flexGrow: 1,
              overflowY: 'auto',
              p: 0,
              ...(isMobile && {
                padding: 0,
                overflowY: 'visible'
              })
            }}
          >
            <ContentContainer>
              <Box>
                <Box 
                  sx={{ 
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    mb: 2
                  }}
                >
                  <Box 
                    sx={{ 
                      display: 'flex',
                      alignItems: 'center',
                      gap: 2,
                      mb: 1,
                      width: '100%',
                      maxWidth: '100%',
                      overflow: 'hidden',
                      justifyContent: 'center'
                    }}
                  >
                    <Box sx={{ 
                      display: 'flex', 
                      alignItems: 'center',
                      minWidth: 0,
                      maxWidth: '45%'
                    }}>
                      <Typography 
                        variant="body2" 
                        color="text.secondary"
                        sx={{ 
                          opacity: 0.6,
                          fontStyle: 'italic',
                          fontSize: '0.75rem',
                          mr: 0.5,
                          flexShrink: 0
                        }}
                      >
                        Name:
                      </Typography>
                      <Typography 
                        variant="body1" 
                        sx={{ 
                          fontWeight: 500,
                          fontSize: '0.875rem',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap'
                        }}
                      >
                        {treasureData?.name}
                      </Typography>
                    </Box>
                    <Typography 
                      variant="body2" 
                      color="text.secondary"
                      sx={{ 
                        flexShrink: 0,
                        mx: 1,
                        opacity: 0.4
                      }}
                    >
                      •
                    </Typography>
                    <Box sx={{ 
                      display: 'flex', 
                      alignItems: 'center',
                      minWidth: 0,
                      maxWidth: '45%'
                    }}>
                      <Typography 
                        variant="body2" 
                        color="text.secondary"
                        sx={{ 
                          opacity: 0.6,
                          fontStyle: 'italic',
                          fontSize: '0.75rem',
                          mr: 0.5,
                          flexShrink: 0
                        }}
                      >
                        From:
                      </Typography>
                      <Typography 
                        variant="body1" 
                        sx={{ 
                          fontWeight: 500,
                          fontSize: '0.875rem',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap'
                        }}
                      >
                        {treasureData?.from}
                      </Typography>
                    </Box>
                  </Box>

                  <Box 
                    sx={{ 
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      width: { xs: '120px', sm: '150px' },
                      animation: 'float 6s ease-in-out infinite',
                      mt: 1
                    }}
                  >
                    <ImageContainer 
                      onClick={() => {
                        if (treasureData?.isVideo) {
                          setShowVideo(true);
                        } else {
                          handleExpandImage();
                        }
                      }}
                    >
                      <img
                        src={treasureData?.isVideo ? treasureData?.thumbnailUrl : treasureData?.imageUrl}
                        alt={treasureData?.name}
                        style={{
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          width: '100%',
                          height: '100%',
                          objectFit: 'cover'
                        }}
                      />
                      {treasureData?.isVideo && (
                        <PlayButton>
                          <PlayArrow />
                        </PlayButton>
                      )}
                    </ImageContainer>

                    <Typography 
                      variant="caption" 
                      color="text.secondary"
                      sx={{ 
                        mt: 1.5,
                        opacity: 0.8,
                        fontStyle: 'italic',
                        textAlign: 'center',
                        maxWidth: '100%',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        display: 'block'
                      }}
                    >
                      {treasureData?.category}
                    </Typography>

                    <Box sx={{ 
                      display: 'flex', 
                      alignItems: 'center', 
                      gap: 0.5,
                      mt: 2 
                    }}>
                      <Typography 
                        variant="caption" 
                        sx={{ 
                          color: 'text.secondary',
                          opacity: isPublic ? 0.5 : 0.9,
                          fontSize: '0.75rem',
                          transition: 'opacity 0.2s ease-in-out'
                        }}
                      >
                        Private
                      </Typography>
                      <StyledSwitch
                        checked={isPublic}
                        onChange={(e) => setIsPublic(e.target.checked)}
                        size="small"
                      />
                      <Typography 
                        variant="caption" 
                        sx={{ 
                          color: 'text.secondary',
                          opacity: isPublic ? 0.9 : 0.5,
                          fontSize: '0.75rem',
                          transition: 'opacity 0.2s ease-in-out'
                        }}
                      >
                        Public
                      </Typography>
                      <Tooltip 
                        title="Every treasure holds a tale and by keeping your story public, you contribute to our growing community of sentimental storytellers. Your memories can inspire, connect, and resonate with others who cherish the beauty of meaningful keepsakes. Prefer to keep it private? That's okay too. You have full control—choose to make your story visible only to you, or share it with the world. Either way, your treasure's story is safe, preserved, and always yours to keep."
                        placement="top"
                        arrow
                        enterTouchDelay={0}
                        leaveTouchDelay={5000}
                        TransitionComponent={Zoom}
                        componentsProps={{
                          tooltip: {
                            sx: {
                              bgcolor: '#11311B',
                              color: '#ffffff',
                              '& .MuiTooltip-arrow': {
                                color: '#11311B',
                              },
                              boxShadow: '0 6px 20px rgba(0,0,0,0.2)',
                              borderRadius: '10px',
                              p: 2.5,
                              fontSize: '0.9rem',
                              maxWidth: 320,
                              lineHeight: 1.6,
                              border: '1px solid rgba(255,255,255,0.1)',
                              // Mobile specific styles
                              [theme.breakpoints.down('sm')]: {
                                position: 'fixed',
                                maxWidth: '85vw',
                                fontSize: '0.85rem',
                                p: 2,
                                left: '50% !important',
                                top: '60% !important',
                                transform: 'translate(-50%, -50%) !important',
                                '& .MuiTooltip-arrow': {
                                  display: 'none' // Hide arrow on mobile to prevent positioning issues
                                }
                              }
                            }
                          },
                          popper: {
                            sx: {
                              zIndex: 1500,
                              '& .MuiTooltip-tooltip': {
                                transition: 'all 0.3s ease-in-out'
                              },
                              [theme.breakpoints.down('sm')]: {
                                left: '0 !important',
                                right: '0 !important',
                                position: 'fixed !important',
                                transform: 'none !important'
                              }
                            }
                          }
                        }}
                      >
                        <IconButton 
                          size="small" 
                          sx={{ 
                            p: 0.5, 
                            color: 'rgba(0,0,0,0.35)',
                            transition: 'all 0.2s ease',
                            '&:hover': {
                              color: 'rgba(0,0,0,0.6)',
                              transform: 'scale(1.1)'
                            },
                            // Mobile specific styles for better tap target
                            [theme.breakpoints.down('sm')]: {
                              p: 1.5,
                              margin: '-0.5rem', // Negative margin to create larger touch area without affecting layout
                              '& svg': {
                                fontSize: '1.35rem'
                              }
                            }
                          }}
                          TouchRippleProps={{ 
                            center: false,
                            sx: { 
                              opacity: 0.4
                            }
                          }}
                          aria-label="Learn more about public and private stories"
                        >
                          <InfoIcon sx={{ 
                            fontSize: '1.1rem',
                          }} />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </Box>
                </Box>

                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                  <Box sx={{ 
                    width: '100%',
                    px: { xs: 0, sm: 2 },
                    mb: 2 
                  }}>
                    <Typography
                      variant="caption"
                      sx={{
                        color: 'text.secondary',
                        opacity: 0.7,
                        fontStyle: 'italic',
                        ml: 0.5,
                        mb: 1,
                        display: 'block'
                      }}
                    >
                      Share your Story
                    </Typography>
                    {storyError && (
                      <Typography
                        color="error"
                        variant="caption"
                        sx={{
                          ml: 0.5,
                          mb: 1,
                          display: 'block'
                        }}
                      >
                        {storyError}
                      </Typography>
                    )}
                    <StoryTextField
                      fullWidth
                      multiline
                      value={story}
                      onChange={handleStoryChange}
                      placeholder="Every treasure has a story. What makes this one special?"
                      variant="outlined"
                      sx={{ 
                        height: '200px',
                        width: '100%',
                        '& .MuiOutlinedInput-root': {
                          width: '100%'
                        }
                      }}
                    />
                    <Typography 
                      variant="caption" 
                      sx={{ mt: 1, color: '#11311B', cursor: 'pointer' }}
                      onClick={() => setOpenInspiration(true)}
                    >
                    Need help writing?
                    </Typography>
                  </Box>
                </Box>

                <DialogActions>
                  <Box sx={{ 
                    width: '100%',
                    position: 'relative',
                    mt: 1,
                  }}>
                    {isUploading ? (
                      <ProgressButton>
                        <LinearProgress
                          variant="determinate"
                          value={uploadProgress}
                          sx={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            right: 0,
                            height: '100%',
                            borderRadius: '12px',
                            backgroundColor: 'rgba(255, 255, 255, 0.08)',
                            '& .MuiLinearProgress-bar': {
                              backgroundColor: 'rgba(255, 255, 255, 0.2)',
                              borderRadius: '12px',
                              backgroundImage: 'linear-gradient(45deg, rgba(255,255,255,.15) 25%, transparent 25%, transparent 50%, rgba(255,255,255,.15) 50%, rgba(255,255,255,.15) 75%, transparent 75%, transparent)',
                              backgroundSize: '40px 40px',
                              animation: 'progress-bar-stripes 1s linear infinite'
                            }
                          }}
                        />
                        <Typography 
                          sx={{ 
                            color: 'white',
                            fontWeight: 600,
                            zIndex: 1,
                            fontSize: '1rem'
                          }}
                        >
                          {Math.round(uploadProgress)}%
                        </Typography>
                      </ProgressButton>
                    ) : (
                      <Button 
                        fullWidth
                        variant="contained"
                        onClick={handleSave}
                        sx={{ 
                          py: 1.25,
                          borderRadius: '12px',
                          fontSize: '1rem',
                          fontWeight: 600,
                          textTransform: 'none',
                          bgcolor: '#11311B',
                          '&:hover': {
                            bgcolor: '#11311B',
                            opacity: 0.9,
                          },
                          boxShadow: '0 4px 16px rgba(0,0,0,0.1)',
                          transition: 'all 0.2s ease-in-out'
                        }}
                      >
                        Upload
                      </Button>
                    )}
                  </Box>
                </DialogActions>
              </Box>
            </ContentContainer>
          </DialogContent>
        </Box>
      </Dialog>

      <ExpandedImageView 
        open={showExpandedImage}
        onClose={handleCloseExpanded}
        imageUrl={treasureData?.imageUrl}
        altText={treasureData?.name}
      />

      <ConfirmDialog
        open={showConfirmDialog}
        onClose={() => setShowConfirmDialog(false)}
        onConfirm={handleConfirmClose}
      />

      <VideoPlayer
        open={showVideo}
        onClose={() => setShowVideo(false)}
        videoUrl={treasureData?.imageUrl}
      />

      <StoryInspirationModal 
        open={openInspiration} 
        onClose={() => setOpenInspiration(false)} 
      />
    </>
  );
};

export default TreasurePreview; 