import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check';

const requiredEnvVars = [
  'REACT_APP_FIREBASE_API_KEY',
  'REACT_APP_FIREBASE_AUTH_DOMAIN',
  'REACT_APP_FIREBASE_PROJECT_ID',
  'REACT_APP_FIREBASE_APP_ID',
  'REACT_APP_RECAPTCHA_SITE_KEY'
];

// Check for development environment variables in public code
if (process.env.NODE_ENV === 'development' && 
    process.env.REACT_APP_FIREBASE_API_KEY?.includes('AIzaSy')) {
  console.error('Warning: Development API key detected in build');
}

// Validate environment variables silently in production
requiredEnvVars.forEach(varName => {
  if (!process.env[varName]) {
    console.error('Configuration error');
    window.location.href = '/error';
  }
});

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

const app = initializeApp(firebaseConfig);

// Initialize App Check with different configurations for development and production
let appCheck;

if (process.env.NODE_ENV === 'development') {
  // For development: Set the debug token before initializing App Check
  if (typeof window !== 'undefined') {
    // Debug token value from Firebase Console (token name: DebugSomethingTold)
    window.FIREBASE_APPCHECK_DEBUG_TOKEN = '25D3289A-F1F1-44D9-BF40-719B0A0DC81B';
    console.log('Firebase App Check debug mode enabled with debug token');
  }

  try {
    appCheck = initializeAppCheck(app, {
      provider: new ReCaptchaV3Provider(process.env.REACT_APP_RECAPTCHA_SITE_KEY),
      isTokenAutoRefreshEnabled: true
    });
    console.log('App Check initialized in development mode');
  } catch (error) {
    console.error('App Check initialization error in development:', error);
  }
} else {
  // Production configuration
  try {
    appCheck = initializeAppCheck(app, {
      provider: new ReCaptchaV3Provider(process.env.REACT_APP_RECAPTCHA_SITE_KEY),
      isTokenAutoRefreshEnabled: true
    });
  } catch (error) {
    console.error('App Check initialization error in production:', error);
  }
}

const auth = getAuth(app);

// Export auth instance and appCheck
export { auth, appCheck };

export default app;