import React, { useState, useEffect, useRef, useMemo } from 'react';
import {
  Box,
  Typography,
  IconButton,
  styled,
  useTheme,
  useMediaQuery,
  Button,
} from '@mui/material';
import { ExpandMore, ExpandLess, KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';

// Styled components
const StoryContainer = styled(Box)(({ theme, expanded }) => ({
  height: 'auto',
  padding: theme.spacing(3),
  backgroundColor: theme.palette.grey[50],
  borderRadius: theme.shape.borderRadius,
  border: `1px solid ${theme.palette.divider}`,
  transition: 'all 0.3s ease',
  position: 'relative',
  overflow: 'hidden',
  '&:hover': {
    backgroundColor: theme.palette.background.paper,
    boxShadow: '0 4px 20px rgba(0,0,0,0.08)',
  }
}));

// Keep the normal text size at 1em, only the drop cap is larger
const StoryText = styled(Typography)(({ theme }) => ({
  fontSize: '1em', // Base font size updated to 1em
  lineHeight: 1.8,
  color: theme.palette.text.secondary,
  '& p': {
    marginBottom: '1.25em',
    textIndent: '2em',
  },
  '& p:first-of-type': {
    textIndent: 0, // No indent for the first paragraph since we have a drop cap
  },
  '& p:first-of-type::first-letter': {
    fontWeight: 600,
    fontSize: '2.9em', // Reduced from 3.2em to maintain proportion with the new 1em base font
    float: 'left',
    lineHeight: '0.75', // Adjusted from 0.8 to better align with the new font size
    marginTop: '0.12em', // Adjusted from 0.15em
    marginRight: '0.12em', // Adjusted from 0.15em
    marginBottom: '0.05em',
    paddingRight: '0.05em',
    textTransform: 'uppercase',
    verticalAlign: 'top',
    color: '#1a4a2b', // Forest green color
    [theme.breakpoints.down('sm')]: {
      fontSize: '2.6em', // Reduced from 2.8em for mobile
      lineHeight: '0.75',
      marginTop: '0.08em', // Adjusted from 0.1em
      marginRight: '0.08em' // Adjusted from 0.1em
    }
  },
  textAlign: 'justify',
  [theme.breakpoints.down('sm')]: {
    fontSize: '1em', // Mobile font size matches desktop now
  }
}));

// New styled component for the expand/collapse footer
const ExpandFooter = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: theme.spacing(1.25, 0),
  marginTop: theme.spacing(1),
  borderTop: 'none', // Remove solid border
  cursor: 'pointer',
  // Add subtle gradient border instead of solid line
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: '5%',
    right: '5%',
    height: '1px',
    background: 'linear-gradient(90deg, rgba(0,0,0,0.01), rgba(0,0,0,0.1), rgba(0,0,0,0.01))',
  },
  transition: 'all 0.3s ease',
  '&:hover': {
    backgroundColor: 'rgba(26, 74, 43, 0.04)',
    '& .expand-text': {
      transform: 'translateY(0) scale(1.02)',
      letterSpacing: '0.02em',
    },
    '& .expand-icon': {
      transform: 'scale(1.2)',
    }
  },
}));

/**
 * StoryDisplayBox - A reusable component for displaying story content with expand/collapse functionality
 * with improved paragraph formatting and readability
 * 
 * @param {string} story - The story text to display
 * @param {object} sx - Additional style props to apply to the container
 */
const StoryDisplayBox = ({ story, sx = {} }) => {
  const [expanded, setExpanded] = useState(false);
  const [isTextTruncated, setIsTextTruncated] = useState(false);
  const storyRef = useRef(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  
  // Format the story text properly with improved paragraph handling
  const formattedStory = useMemo(() => {
    if (!story) return '';
    
    // Process the text to properly handle paragraphs
    return story
      .trim()
      .replace(/\s+/g, ' ')                // Replace multiple spaces with single space
      .split(/\n\s*\n|\n/)                 // Split on single or double newlines
      .filter(para => para.trim() !== '')  // Remove empty paragraphs
      .map(para => `<p>${para.trim()}</p>`) // Wrap in paragraph tags
      .join('');
  }, [story]);
  
  // Update the effect to check if content is truncated
  useEffect(() => {
    const checkTextTruncation = () => {
      if (storyRef.current) {
        const element = storyRef.current;
        // Calculate based on element height
        const wrapper = element.closest('.truncate-wrapper');
        if (wrapper) {
          // If not expanded, check if content is truncated
          if (!expanded) {
            const isTruncated = element.scrollHeight > wrapper.clientHeight;
            setIsTextTruncated(isTruncated);
          } else {
            // When expanded, we still need a way to collapse
            setIsTextTruncated(true);
          }
        }
      }
    };

    const timer = setTimeout(() => {
      checkTextTruncation();
    }, 100);

    window.addEventListener('resize', checkTextTruncation);
    
    return () => {
      clearTimeout(timer);
      window.removeEventListener('resize', checkTextTruncation);
    };
  }, [story, expanded]);

  const handleExpandToggle = (e) => {
    e.stopPropagation();
    setExpanded(!expanded);
  };

  return (
    <StoryContainer expanded={expanded} sx={sx}>
      <Box 
        className="truncate-wrapper"
        sx={{ 
          maxHeight: expanded ? 'none' : '500px',
          overflow: 'hidden',
          position: 'relative',
          transition: 'max-height 0.5s ease', // Add smooth transition for height changes
          // Only add the fade effect when content is truncated and not expanded
          ...(isTextTruncated && !expanded && {
            '&::after': {
              content: '""',
              position: 'absolute',
              bottom: 0,
              left: 0,
              right: 0,
              height: '70px',
              background: `linear-gradient(to bottom, rgba(255,255,255,0), ${theme.palette.grey[50]})`,
              pointerEvents: 'none',
              zIndex: 5,
            }
          })
        }}
      >
        <StoryText
          ref={storyRef}
          variant="body1"
          component="div"
          dangerouslySetInnerHTML={{ __html: formattedStory }}
        />
      </Box>
      
      {/* Show the expand/collapse footer only when needed */}
      {isTextTruncated && (
        <ExpandFooter 
          onClick={handleExpandToggle}
          sx={{
            // Add subtle background gradient based on state
            background: expanded 
              ? 'linear-gradient(180deg, rgba(26,74,43,0.02) 0%, rgba(26,74,43,0.06) 100%)' 
              : 'linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(26,74,43,0.03) 100%)',
            borderRadius: '8px', // Fixed 8px border radius as requested
            mt: 2, // Increased margin top for better separation
            mx: 'auto', // Center horizontally
            width: 'fit-content', // Width determined by content
            minWidth: 'auto', // Override any minimum width
            px: 3, // Add horizontal padding
            py: 1, // Adjust vertical padding
            boxShadow: '0 1px 2px rgba(0,0,0,0.02)', // Subtle shadow for depth
            border: '1px solid rgba(26,74,43,0.1)', // Add subtle border for definition
            position: 'relative', // Ensure z-index works properly
            zIndex: 10, // Place above the fade gradient
          }}
        >
          <Typography 
            variant="body2" 
            className="expand-text"
            sx={{ 
              fontSize: '0.825rem',
              display: 'flex',
              alignItems: 'center',
              gap: 0.75,
              fontWeight: 500,
              color: '#1a4a2b',
              letterSpacing: '0.01em',
              transition: 'all 0.3s ease',
              transform: 'translateY(0)',
              textShadow: '0 1px 1px rgba(255,255,255,0.7)',
            }}
          >
            {expanded ? (
              <>
                Read less <KeyboardArrowUp className="expand-icon" fontSize="small" sx={{ transition: 'transform 0.3s ease' }} />
              </>
            ) : (
              <>
                Continue reading <KeyboardArrowDown className="expand-icon" fontSize="small" sx={{ transition: 'transform 0.3s ease' }} />
              </>
            )}
          </Typography>
        </ExpandFooter>
      )}
    </StoryContainer>
  );
};

export default StoryDisplayBox; 