import { createContext, useContext, useState, useEffect } from 'react';
import { Alert, Snackbar, Fade } from '@mui/material';
import { styled } from '@mui/material/styles';
import FirstTimeUserModal from '../components/FirstTimeUserModal';

const ToastContext = createContext();

const StyledSnackbar = styled(Snackbar)(({ theme }) => ({
  '& .MuiAlert-root': {
    backgroundColor: '#4caf50', // Lighter green
    color: '#FFFFFF',
    boxShadow: '0 4px 20px rgba(76, 175, 80, 0.3)', // Adjusted shadow for lighter green
    borderRadius: '8px',
    padding: '6px 16px', // Original padding maintained
    position: 'relative', // Add positioning context
    '& .MuiAlert-icon': {
      color: '#FFFFFF',
    },
    '& .MuiAlert-message': {
      fontSize: '0.95rem',
      fontWeight: 500
    },
    backdropFilter: 'blur(8px)',
    touchAction: 'none', // Enable custom touch handling
    cursor: 'pointer',
    // Invisible touch extension
    '&::after': {
      content: '""',
      position: 'absolute',
      top: '-20px',
      bottom: '-20px',
      left: '-20px',
      right: '-20px',
      zIndex: -1
    },
    minWidth: '320px',
    maxWidth: '420px',
  }
}));

export const ToastProvider = ({ children }) => {
  const [toast, setToast] = useState({
    open: false,
    message: '',
    severity: 'success',
    duration: 5000 // Changed to 3 seconds
  });
  const [swipeDelta, setSwipeDelta] = useState(0);
  const [touchStartY, setTouchStartY] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [verificationModalOpen, setVerificationModalOpen] = useState(false);
  const [verificationEmail, setVerificationEmail] = useState("");

  const showToast = (message, severity = 'success', duration = 5000) => {
    setToast({
      open: true,
      message,
      severity,
      duration
    });
    setSwipeDelta(0); // Reset swipe position on new toast
  };

  const hideToast = () => {
    setToast(prev => ({
      ...prev,
      open: false
    }));
  };

  // Handle touch gestures
  const handleTouchStart = (e) => {
    setTouchStartY(e.touches[0].clientY);
  };

  const handleTouchMove = (e) => {
    const delta = touchStartY - e.touches[0].clientY;
    setSwipeDelta(delta > 0 ? delta : 0);
  };

  const handleTouchEnd = () => {
    if (swipeDelta > 30) {
      hideToast();
      setSwipeDelta(100);
      setTimeout(() => setSwipeDelta(0), 300);
    } else {
      setSwipeDelta(0);
    }
  };

  // Always close after duration
  useEffect(() => {
    if (toast.open) {
      const timer = setTimeout(hideToast, toast.duration);
      return () => clearTimeout(timer);
    }
  }, [toast.open, toast.duration]);

  const showVerificationModal = (email) => {
    console.log("showVerificationModal called with:", email);
    setVerificationEmail(email);
    setVerificationModalOpen(true);
  };

  const hideVerificationModal = () => {
    setVerificationModalOpen(false);
  };

  const value = {
    showToast,
    closeModal: () => setModalOpen(false),
    showVerificationModal,
    hideVerificationModal,
    verificationModalOpen,
    verificationEmail,
  };

  return (
    <ToastContext.Provider value={value}>
      {children}
      <StyledSnackbar
        open={toast.open}
        onClose={hideToast}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        TransitionComponent={Fade}
        TransitionProps={{ timeout: 600 }}
        severity={toast.severity}
        onClick={hideToast}
      >
        <Alert 
          severity={toast.severity}
          variant="filled"
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
          sx={{ 
            width: '100%',
            minWidth: '300px',
            maxWidth: '400px',
            alignItems: 'center',
            background: 'linear-gradient(135deg, #4caf50 0%, #66bb6a 100%)', // Lighter green gradient
            color: '#FFFFFF',
            '& .MuiAlert-icon': {
              fontSize: '24px',
              color: '#FFFFFF'
            },
            '& .MuiAlert-message': {
              color: '#FFFFFF'
            },
            transform: `translateY(-${swipeDelta}px)`,
            transition: swipeDelta > 0 ? 'transform 0.2s ease' : 'transform 0.3s ease',
            userSelect: 'none',
            WebkitTapHighlightColor: 'transparent'
          }}
        >
          {toast.message}
        </Alert>
      </StyledSnackbar>
      <FirstTimeUserModal 
        open={modalOpen} 
        setShowModal={setModalOpen} 
      />
    </ToastContext.Provider>
  );
};

export const useToast = () => {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error('useToast must be used within a ToastProvider');
  }
  return context;
}; 