import { useEffect } from 'react';
import { useMediaQuery, useTheme } from '@mui/material';

const useScrollFocus = (ref, shouldFocus = true) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    if (ref.current && shouldFocus && !isMobile) {
      ref.current.scrollIntoView({ behavior: 'smooth' });
    }
    if (ref.current && shouldFocus && isMobile) {
      ref.current.focus();
    }
  }, [ref, shouldFocus, isMobile]);
};

// New hook specifically for preventing mobile scroll
const usePreventMobileScroll = () => {
  const preventScroll = (e) => {
    e.preventDefault();
  };

  const enablePreventScroll = () => {
    document.addEventListener('touchmove', preventScroll, { passive: false });
  };

  const disablePreventScroll = () => {
    document.removeEventListener('touchmove', preventScroll);
  };

  return { enablePreventScroll, disablePreventScroll };
};

export default useScrollFocus;
export { usePreventMobileScroll }; 